import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { BoxingFixedBoxQuantity, BoxingSizeDivisor, BoxRestrictionType } from '../../../models/box-restriction';
import { getSizingFromArticleQuantity } from '../../../lib/article-utils';
import { Article } from '../../../models/sizing-data';
import { Col, Row, Space, Tooltip, Typography } from '@retail-core/rds';
import { SizingGroup } from '../../../models/sizing-group';
import useTablesScrollSync from '../../../lib/use-tables-scroll-sync';
import { CheckOutlined, CloseOutlined, InfoCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { Matrix } from '../../Matrices/Matrix/Matrix';
import './BoxDetails.less';
import { Distribution } from '../../../models/sizing';
import { Button } from '@retail-core/rds';
import isEqual from 'fast-deep-equal';
import { SizeDistribution } from '../../../models/response';
import { MatrixDataset } from '../../../models/sizing-table';

type Props = {
  article: Article;
  group: SizingGroup;
  onSaveEdit: (boxing: BoxingFixedBoxQuantity | BoxingSizeDivisor, sizing: Distribution) => void;
  onReset: () => void;
};

const BoxDetails: FC<Props> = (props) => {
  const { article, group, onSaveEdit, onReset } = props;
  const idealQuantity = article.quantity - (article.boxing?.deviation || 0);
  const idealSizing = getSizingFromArticleQuantity(idealQuantity, group?.selectedSizeKey?.distribution);
  const tableRef1 = useRef<HTMLDivElement>(null);
  const tableRef2 = useRef<HTMLDivElement>(null);
  useTablesScrollSync(tableRef1, tableRef2, true);
  const isFixedBox = article?.boxing?.boxRestrictionType === BoxRestrictionType.FIXED_BOX;
  const isSizeDivisor = article?.boxing?.boxRestrictionType === BoxRestrictionType.DIVISOR;
  const divisor = isSizeDivisor
    ? (article?.boxing as BoxingSizeDivisor)?.sizeDivisor
    : (article?.boxing as BoxingFixedBoxQuantity)?.boxDivisor;
  const [distribution, setDistribution] = useState<Distribution>(article.sizing || {});
  const onChangeDistributionWhileComparing = (sizeDistribution: SizeDistribution, dataSet?: MatrixDataset) =>
    setDistribution(sizeDistribution.distribution);

  const localDistributionTotal = Object.keys(distribution).reduce((prev, curr) => prev + distribution[curr], 0);
  const localDeviationFromIdeal = localDistributionTotal - idealQuantity;
  const fixBoxRemainder = isFixedBox ? localDistributionTotal % divisor : 0;
  const fixBoxError = isFixedBox
    ? `Remove ${fixBoxRemainder} or add ${divisor - fixBoxRemainder} units to match divisor multiplier`
    : '';
  const isBoxingBeingEdited = localDistributionTotal - article.quantity;
  const islocalDistributionValid = useMemo(() => {
    if (isSizeDivisor) {
      return Object.keys(distribution).reduce((prev, curr) => {
        const isValid = prev && !isNaN(distribution[curr]);
        return isValid && !(distribution[curr] % divisor);
      }, true);
    } else if (isFixedBox) {
      return localDistributionTotal % divisor === 0;
    }
  }, [distribution, divisor, isFixedBox, isSizeDivisor, localDistributionTotal]);
  const onCancelEdit = () => {
    setDistribution(article.sizing || {});
  };
  const _onSaveEdit = () => {
    const updatedBoxing = {
      ...(article.boxing as BoxingSizeDivisor | BoxingFixedBoxQuantity),
      deviation: localDeviationFromIdeal,
    };
    onSaveEdit(updatedBoxing, { ...distribution, total: localDistributionTotal });
  };
  useEffect(() => {
    if (!isEqual(article.sizing, distribution)) {
      setDistribution(article.sizing || {});
    }
  }, [article.sizing]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <Row gutter={32} align="top" className="box-details-achieved-quantities">
        <Col className="box-details-divisor-wrapper">
          <Typography.Text>
            <Space direction="vertical" className="box-details-divisor-space" size={0}>
              <div className="box-details-divisor-label">Divisor per {isSizeDivisor ? 'size' : 'box'}</div>
              <div className="box-details-divisor-value">{divisor}</div>
            </Space>
          </Typography.Text>
        </Col>
        <Col className="box-details-matrix-wrapper compare-modal-radio-group">
          {group?.selectedSizeKey && group?.selectedSizeKey.distribution && group?.groupKey && article.sizing && (
            <Matrix
              sizeKey={group.selectedSizeKey}
              data={distribution}
              groupKey={group.groupKey}
              referenceId={group.groupKey}
              dataIsPercentage={false}
              highlighted={false}
              expectedTotal={article.quantity}
              onChangeDistributionWhileComparing={onChangeDistributionWhileComparing}
              ref={tableRef2}
              showTableHeader
              referenceData={idealSizing}
              maxScrollHeight={81}
              sizeDivisorForValidation={isSizeDivisor ? (article?.boxing as BoxingSizeDivisor)?.sizeDivisor : undefined}
            />
          )}
        </Col>
        <Col className="box-details-total-wrapper">
          <Typography.Text>
            <Space direction="vertical" className="box-details-total-space" size={0}>
              <div className="box-details-total-label">Total</div>
              <Tooltip title={fixBoxError} visible={isFixedBox && !islocalDistributionValid} color="red">
                <div
                  className="box-details-total-value"
                  style={{
                    backgroundColor: localDeviationFromIdeal
                      ? `rgba(252, 182, 42, ${Math.abs(localDeviationFromIdeal) / localDistributionTotal})`
                      : '#FAFAFA',
                    fontWeight: localDeviationFromIdeal ? 600 : 'initial',
                    borderColor: isFixedBox && !islocalDistributionValid ? '#BE3013' : 'inherit',
                  }}
                >
                  {localDistributionTotal}
                </div>
              </Tooltip>
            </Space>
          </Typography.Text>
        </Col>
        <Col className="box-details-actions-wrapper" data-testid="box-quantity-edit-action-buttons">
          {isBoxingBeingEdited ? (
            <Space size={8}>
              <Tooltip title="Cancel edits" className="spacing">
                <Button
                  size="small"
                  icon={<CloseOutlined />}
                  onClick={onCancelEdit}
                  data-testid="cancel-box-quantity-edit-button"
                />
              </Tooltip>
              <Tooltip title="Apply new quantities" className="spacing">
                <Button
                  type="primary"
                  size="small"
                  icon={<CheckOutlined />}
                  onClick={_onSaveEdit}
                  data-testid="save-box-quantity-edit-button"
                  disabled={!islocalDistributionValid}
                />
              </Tooltip>
            </Space>
          ) : article.boxing?.editedBoxQuantities ? (
            <Tooltip title="Reset to default optimization" className="spacing">
              <Button size="small" icon={<UndoOutlined />} onClick={onReset} data-testid="reset-box-quantity-button" />
            </Tooltip>
          ) : null}
        </Col>
      </Row>
      <Row gutter={32} align="top">
        <Col className="box-details-ideal-label-wrapper">
          <Typography.Text className="box-details-ideal-label">
            <Space>
              <span>Ideal quantities</span>
              <Tooltip placement="top" title={`Recommended or edited quantities for this article`}>
                <InfoCircleOutlined data-testid="ideal-article-quantities-tooltip" />
              </Tooltip>
            </Space>
          </Typography.Text>
        </Col>
        <Col className="box-details-matrix-wrapper compare-modal-radio-group">
          {group?.selectedSizeKey && group?.selectedSizeKey.distribution && group?.groupKey && (
            <Matrix
              sizeKey={group.selectedSizeKey}
              data={idealSizing}
              groupKey={group.groupKey}
              referenceId={group.groupKey}
              dataIsPercentage={false}
              showTableHeader={false}
              highlighted={false}
              expectedTotal={idealQuantity}
              onChangeDistributionWhileComparing={() => null}
              ref={tableRef1}
              readOnly
              maxScrollHeight={81}
            />
          )}
        </Col>
        <Col className="box-details-total-wrapper">
          <Typography.Text>
            <div className="box-details-total-space">
              <div className="box-details-total-value">{idealQuantity}</div>
            </div>
          </Typography.Text>
        </Col>
      </Row>
    </>
  );
};

export default BoxDetails;

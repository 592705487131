import { Table } from '@retail-core/rds';
import isEqual from 'fast-deep-equal';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BoxDistribution, BoxingPresetSizingLotGroup, LOTGroupBox } from '../../../models/box-restriction';
import { Distribution, SizeKey } from '../../../models/sizing';
import './LotsDetails.less';
import LotsDetailsSummary from './LotsDetailsSummary';
import useLotDetailsColumns from './useLotDetailsColumns';

type Props = {
  boxing: BoxingPresetSizingLotGroup;
  quantity: number;
  selectedSizeKey: SizeKey;
  boxRestriction: LOTGroupBox;
  manuallyEdited: boolean;
  isAvailabilityEditingEnabled: boolean;
  onSaveAvailabilityEdit: (boxing: BoxingPresetSizingLotGroup) => void;
  onSaveEdit: (boxing: BoxingPresetSizingLotGroup, sizing: Distribution) => void;
  onReset: () => void;
};

const LotsDetails: FC<Props> = (props) => {
  const {
    boxing,
    quantity,
    selectedSizeKey,
    boxRestriction,
    onSaveEdit,
    isAvailabilityEditingEnabled,
    manuallyEdited,
    onReset,
    onSaveAvailabilityEdit,
  } = props;

  const [localBoxing, setLocalBoxing] = useState(boxing);
  const idealQuantity = quantity - (localBoxing?.deviation || 0);
  const dataSource = useMemo(() => {
    const data = (localBoxing as BoxingPresetSizingLotGroup).boxDistributions.map(
      (boxDistribution: BoxDistribution) => {
        const lotGroup = boxRestriction.lotGroup.find((lot) => lot.name === boxDistribution.box.name);
        const sizeQuantities = Object.keys(boxDistribution.box.sizeQuantities).length
          ? boxDistribution.box.sizeQuantities
          : Object.keys(lotGroup?.distribution || {}).reduce(
              (quantityObject, size) => ({
                ...quantityObject,
                [size]: 0,
              }),
              {}
            );
        return {
          key: boxDistribution.box.name,
          ...boxDistribution,
          ...boxDistribution.box,
          ...sizeQuantities,
          total: Object.values(boxDistribution.box.sizeQuantities).reduce<number>(
            (sum: number, value: number) => sum + value,
            0
          ),
        };
      }
    );
    if (!selectedSizeKey.lengthList?.length) {
      return data;
    }

    return data.reduce(
      (prev: any[], curr: any) => [
        ...prev,
        ...(selectedSizeKey.lengthList?.map((length) => ({ ...curr, length, key: `${curr.key}-${length}` })) || []),
      ],
      []
    );
  }, [boxRestriction.lotGroup, localBoxing, selectedSizeKey.lengthList]);
  const updateLocalLotQuantity = useCallback(
    (name: string, value: string) => {
      const boxDistributionIdx = localBoxing.boxDistributions.findIndex(
        (boxDistribution) => boxDistribution.box.name === name
      );
      const lotGroup = boxRestriction.lotGroup.find((lot) => lot.name === name);

      if (lotGroup) {
        const updatedBoxDistribution = {
          ...localBoxing.boxDistributions[boxDistributionIdx],
          box: {
            name,
            sizeQuantities: Object.keys(lotGroup.distribution).reduce((prev, curr) => {
              return {
                ...prev,
                [curr]: lotGroup.distribution[curr] * (Number(value) || 0),
              };
            }, {}),
          },
          quantity: Number(value) || 0,
        };

        setLocalBoxing({
          ...localBoxing,
          boxDistributions: Object.assign(localBoxing.boxDistributions.slice(), {
            [boxDistributionIdx]: updatedBoxDistribution,
          }),
        });
      }
    },
    [boxRestriction.lotGroup, localBoxing]
  );

  const updateLocalAvailability = useCallback(
    (name: string, value: string) => {
      const boxDistributionIdx = localBoxing.boxDistributions.findIndex(
        (boxDistribution) => boxDistribution.box.name === name
      );
      const lotGroup = boxRestriction.lotGroup.find((lot) => lot.name === name);

      if (lotGroup) {
        const updatedBoxDistribution = {
          ...localBoxing.boxDistributions[boxDistributionIdx],
          availability: !Number.isNaN(Number.parseInt(value)) ? Math.abs(Number.parseInt(value)) : undefined,
        };

        setLocalBoxing({
          ...localBoxing,
          boxDistributions: Object.assign(localBoxing.boxDistributions.slice(), {
            [boxDistributionIdx]: updatedBoxDistribution,
          }),
        });
      }
    },
    [boxRestriction.lotGroup, localBoxing]
  );

  const isAvailabilityBeingEdited = Boolean(
    boxing.boxDistributions.filter(
      (boxDistribution, index) => boxDistribution.availability !== localBoxing.boxDistributions[index].availability
    ).length
  );

  const isBoxingBeingEdited = Boolean(
    boxing.boxDistributions.filter(
      (boxDistribution, index) => boxDistribution.quantity !== localBoxing.boxDistributions[index].quantity
    ).length
  );

  const isQuantityMoreThanAvailability = Boolean(
    localBoxing.boxDistributions.filter(
      (boxDistribution) => boxDistribution.quantity > Number(boxDistribution.availability)
    ).length
  );

  const columns = useLotDetailsColumns(
    selectedSizeKey,
    updateLocalLotQuantity,
    updateLocalAvailability,
    isBoxingBeingEdited,
    isAvailabilityEditingEnabled,
    isAvailabilityBeingEdited
  );

  const _onSaveEdit = (totalSizing: Distribution) => {
    const updatedBoxing = {
      ...localBoxing,
      deviation: totalSizing.total - quantity + (localBoxing?.deviation || 0),
    };
    setLocalBoxing(updatedBoxing);
    onSaveEdit(updatedBoxing, totalSizing);
  };

  const onCancelEdit = () => {
    setLocalBoxing(boxing);
  };

  const _onSaveAvailabilityEdit = () => {
    onSaveAvailabilityEdit(localBoxing);
  };

  const _onCancelAvailabilityEdit = () => {
    setLocalBoxing(boxing);
  };

  useEffect(() => {
    if (!isEqual(boxing, localBoxing)) {
      setLocalBoxing(boxing);
    }
  }, [boxing]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="lots-details table compare-modal-radio-group">
      <Table
        size="small"
        dataSource={dataSource}
        columns={columns}
        bordered
        tableLayout="fixed"
        pagination={false}
        scroll={{ x: 'max-width', y: 150 }}
        summary={(data) => (
          <LotsDetailsSummary
            data={data}
            quantity={quantity}
            idealQuantity={idealQuantity}
            selectedSizeKey={selectedSizeKey}
            isBoxingBeingEdited={isBoxingBeingEdited}
            isAvailabilityBeingEdited={isAvailabilityBeingEdited}
            isQuantityMoreThanAvailability={isQuantityMoreThanAvailability}
            manuallyEdited={manuallyEdited}
            onSaveEdit={_onSaveEdit}
            onCancelEdit={onCancelEdit}
            onReset={onReset}
            onSaveAvailabilityEdit={_onSaveAvailabilityEdit}
            onCancelAvailabilityEdit={_onCancelAvailabilityEdit}
          />
        )}
      />
    </div>
  );
};

export default LotsDetails;

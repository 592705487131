import { Result, Button } from '@retail-core/rds';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

export const NoLinkedSizeChartsError: FC = observer(() => {
  return (
    <Result
      className="no-linked-size-charts"
      title="Link or create size chart"
      subTitle={
        <>
          No size chart available yet for this brand & commodity group combination. <br />
          To select and distribute sizes, first link or create size charts.
        </>
      }
      status="info"
      extra={
        <Button
          type="primary"
          href="https://sites.google.com/a/zalando.de/sizing-business-integration/size-charts"
          target="_blank"
        >
          More about sizing JIRA Tickets
        </Button>
      }
    />
  );
});

import { runInAction } from 'mobx';
import { sendSizingAppliedEvent } from '../../lib/fragment-helper';
import { Boxing } from '../../models/box-restriction';
import { Distribution } from '../../models/sizing';
import { Article } from '../../models/sizing-data';
import { SizingGroup } from '../../models/sizing-group';
import { SizingStore } from './sizing-store';

export class UpdateBoxQuantitiesAction {
  async updateBoxQuantities(
    this: SizingStore,
    referenceId: string,
    boxing: Boxing,
    sizing?: Distribution
  ): Promise<void> {
    let selectedGroup: SizingGroup;
    let selectedArticle: Article;
    runInAction(() => {
      this.sizingGroups.forEach((group: SizingGroup) => {
        group.articles.forEach((article: Article) => {
          if (article.referenceId === referenceId) {
            // NOTE: This doesnt yet include blockOrderBoxing update
            if (sizing) {
              article.sizing = sizing;
              delete article.sizing.total;
              article.quantity = sizing.total;
            }
            article.boxing = boxing;
            article.manuallyEdited = sizing && true;
            article.boxing.editedBoxQuantities = sizing && true;
            selectedArticle = article;
            selectedGroup = group;
          }
        });
      });
      this.articles.forEach((article: Article) => {
        if (article.referenceId === referenceId && selectedArticle) {
          article = selectedArticle;
        }
      });
      if (selectedGroup && selectedArticle && sizing) {
        sendSizingAppliedEvent(selectedGroup, [selectedArticle]);
      }
    });
  }
}

import { Article, SizingData } from '../models/sizing-data';
import { notification } from '@retail-core/rds';

function show_invalid_data_notification(description: string) {
  notification.error({ message: 'Invalid Sizing Data', description: description, duration: 0, placement: 'topRight' });
}

function hasValidSizeChartCode(articles: Article[]) {
  const invalidArticle = articles.find((article) => !article.sizeChartEditable && !article.sizeChartCode);
  if (invalidArticle) {
    show_invalid_data_notification(
      `Sizing could not be done because the article "${invalidArticle.referenceId}" has non editable size chart: "${invalidArticle.sizeChartCode}"`
    );
    return false;
  }
  return true;
}

function hasValidCGCode(articles: Article[]) {
  const areArticleCGCode3sValid = articles.every(
    (article) => article.commodityGroup.codes[2] && article.commodityGroup.codes[1] !== article.commodityGroup.codes[2]
  );
  if (!areArticleCGCode3sValid)
    show_invalid_data_notification('Sizing could not be done as there are missing CG3 codes in the article data');
  return areArticleCGCode3sValid;
}

export function isSizingDataValid(sizingData: SizingData): boolean {
  return hasValidCGCode(sizingData.articles) && hasValidSizeChartCode(sizingData.articles);
}

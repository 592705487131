import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { SizingGroup as ISizingGroup } from '../../models/sizing-group';
import { ArticleList } from './ArticleList/ArticleList';
import './SizingGroup.less';
import { GroupHeader } from './GroupHeader/GroupHeader';
import { Article } from '../../models/sizing-data';
import { useStore } from '../StoreContext/StoreContext';
import { GroupUIState } from '../../models/ui-state';

export enum SizingGroupState {
  COLLAPSED = 'collapsed',
  EXPANDED = 'expanded',
  HIDDEN = 'hidden',
}

interface IProps {
  group: ISizingGroup;
  showNonReviewed?: boolean;
  readOnly?: boolean;
  uIState?: Record<string, GroupUIState>;
}

export const containsNonEditableSizeChart = (articles: Article[]): boolean => {
  return articles.some((article) => !article.sizeChartEditable);
};

export const SizingGroup: FC<IProps> = observer((props) => {
  const { group, showNonReviewed, readOnly, uIState } = props;
  const groupArticleSelectionState = uIState?.[group.groupKey];
  const isReviewed = group.isReviewed;
  const [articlesSelected, setArticlesSelected] = useState<Record<string, boolean>>({});
  const [groupExpanded, setGroupExpanded] = useState(false);
  const { uiStore } = useStore();
  const groupArticlesLength = group.articles.length;
  const middleArticlesLength = groupArticlesLength > 2 ? groupArticlesLength - 2 : 0;
  let groupArticlesSelected = 0;
  let middleArticlesSelected = 0;
  group.articles.forEach((article, index) => {
    if (articlesSelected[article.referenceId]) {
      if (index !== 0 && index !== groupArticlesLength - 1) {
        middleArticlesSelected += 1;
      }
      groupArticlesSelected += 1;
    }
  });
  const allArticlesSelected = groupArticlesLength === groupArticlesSelected;
  const noArticlesSelected = groupArticlesSelected === 0;
  const someArticlesSelected = !allArticlesSelected && !noArticlesSelected;
  const allMiddleArticlesSelected = allArticlesSelected || middleArticlesSelected === middleArticlesLength;
  const noMiddleArticlesSelected = noArticlesSelected || middleArticlesSelected === 0;
  const someMiddleArticlesSelected = !allMiddleArticlesSelected && !noMiddleArticlesSelected;
  let state = SizingGroupState.COLLAPSED;

  if (showNonReviewed) {
    state = isReviewed ? SizingGroupState.HIDDEN : state;
  }
  const toggleGroupSelection = () => {
    if (noArticlesSelected || someArticlesSelected) {
      const newArticlesSelected = group.articles.reduce((prev, curr) => ({ ...prev, [curr.referenceId]: true }), {});
      setArticlesSelected(newArticlesSelected);
      uiStore.setSelectedArticles(group.groupKey, newArticlesSelected);
    } else {
      uiStore.unselectArticles([group.groupKey]);
    }
  };
  const updateArticleSelected = (key: string) => {
    setArticlesSelected((state: Record<string, boolean>) => {
      const updatedArticlesSelection = { ...state };
      if (key === 'separator') {
        group.articles.forEach((article, index) => {
          if (index !== 0 && index !== group.articles.length - 1) {
            updatedArticlesSelection[article.referenceId] = !allMiddleArticlesSelected;
          }
        });
      } else {
        updatedArticlesSelection[key] = !state[key];
      }
      const updatedNoArticlesSelected = !group.articles.some(
        (article) => updatedArticlesSelection[article.referenceId]
      );
      if (updatedNoArticlesSelected) {
        uiStore.unselectArticles([group.groupKey]);
      } else {
        uiStore.setSelectedArticles(group.groupKey, updatedArticlesSelection);
      }
      return updatedArticlesSelection;
    });
  };
  const onExpand = () => {
    setGroupExpanded(true);
    const groupList = document.querySelector('.sizing-group-list') as HTMLElement;
    const groupElement = document.getElementById(group.groupKey) as HTMLElement;
    const top = groupElement.getBoundingClientRect().top - (groupList.getBoundingClientRect().top || 0);
    window.scrollTo({ top, behavior: 'smooth' });
  };
  useEffect(() => {
    if (!groupArticleSelectionState || !groupArticleSelectionState.selected) {
      setArticlesSelected({});
    } else if (groupArticlesSelected !== groupArticleSelectionState.selected) {
      const newArticlesSelected = group.articles.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.referenceId]: groupArticleSelectionState.articles[curr.referenceId]?.selected,
        }),
        {}
      );
      setArticlesSelected(newArticlesSelected || {});
    }
  }, [groupArticleSelectionState?.selected, group.articles]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div
      className={`sizing-group ${state === 'hidden' && 'is-hidden'}`}
      id={group.groupKey}
      data-testid={`sizing-group${state === 'hidden' ? '-hidden' : ''}`}
    >
      <GroupHeader
        group={group}
        state={state}
        readOnly={readOnly}
        isGroupSelected={allArticlesSelected}
        toggleGroupSelection={toggleGroupSelection}
        collapseGroup={() => {
          if (groupExpanded) setGroupExpanded(false);
        }}
        partiallySelected={someArticlesSelected}
      />
      <ArticleList
        sizeKey={group.selectedSizeKey}
        articles={group.articles}
        groupKey={group.groupKey}
        enableBox={group.enableBox}
        readOnly={readOnly}
        collapsed={!groupExpanded}
        onExpand={onExpand}
        onCollapse={() => setGroupExpanded(false)}
        articlesSelected={articlesSelected}
        updateArticleSelected={updateArticleSelected}
        separatorSelected={allMiddleArticlesSelected}
        separatorPartiallySelected={someMiddleArticlesSelected}
        uIState={uiStore.uIState}
      />
    </div>
  );
});

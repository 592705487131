import { Fragment } from 'monteur';
import { ArticleQuantityChangedEvent } from '../models/events/article-quantity-changed-event';
import { GroupsToBeReviewedEvent } from '../models/events/groups-to-be-reviewed-event';
import { SizedArticle, SizingAppliedEvent } from '../models/events/sizing-applied-event';
import { Distribution, SortKey } from '../models/sizing';
import { Article } from '../models/sizing-data';
import { SizingGroup } from '../models/sizing-group';
import { SizingStore } from '../stores/sizing/sizing-store';
import { ArticlesAddedEvent } from '../models/events/articles-added-event';
import { BulkArticlesRemovedEvent } from '../models/events/bulk-articles-removed-event';
import { LoadedArticle, PresizeLoadedEvent } from '../models/events/PresizeLoadedEvent';
import { ArticleChangedEvent } from '../models/events/article-changed-event';
import { convertTotalSizedQuantitiesToPerLot } from './article-utils';
import { captureMessage } from './tracing/sentry';

export function getCookie(cname: string): string {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function addEventListener(name: string, callback: (...args: any[]) => any): void {
  const isE2E = getCookie('is_e2e');
  if (isE2E !== 'true') {
    Fragment.addEventListener(name, callback);
  } else {
    const callbackv2 = (event: Event) => {
      return callback((event as CustomEvent).detail.payload);
    };
    window.addEventListener(name, callbackv2);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function customDispatchEvent(name: string, payload?: any): void {
  const isE2E = getCookie('is_e2e');
  if (isE2E !== 'true') {
    Fragment.dispatchEvent(name, payload);
  } else {
    window.dispatchEvent(new CustomEvent(name, { detail: payload }));
  }
}

export function sendSizingAppliedEvent(sizingGroup: SizingGroup, articles: Article[]): void {
  const payload: SizingAppliedEvent = {
    sizedArticles: convertArticlesToSizedArticles(sizingGroup, articles),
  };
  if (!payload.sizedArticles.length) return;
  customDispatchEvent('sizingApplied', payload);
}

export function sendSizingAppliedEventForGroups(sizingGroups: SizingGroup[], articles?: Article[]): void {
  const payload: SizingAppliedEvent = {
    sizedArticles: sizingGroups.flatMap((sizingGroup) =>
      convertArticlesToSizedArticles(
        sizingGroup,
        articles
          ? articles.filter((a) =>
              sizingGroup.articles.some((groupArticle) => groupArticle.referenceId === a.referenceId)
            )
          : sizingGroup.articles
      )
    ),
  };
  if (!payload.sizedArticles.length) return;
  customDispatchEvent('sizingApplied', payload);
}

export function sendNoLinkedSizeChartEvent(): void {
  customDispatchEvent('noLinkedSizeCharts');
}

export function listenForArticleQuantityChanges(store: SizingStore): void {
  addEventListener('articleQuantityChanged', (payload: ArticleQuantityChangedEvent) => {
    store.changeArticleQuantity(payload.referenceId, payload.quantity, payload.blockOrderQuantity);
  });
}

export function listenForArticleChanged(store: SizingStore): void {
  addEventListener('articleChanged', (payload: ArticleChangedEvent) => {
    store.changeArticle(payload.referenceId, payload.commodityGroup, payload.comment);
  });
}

export function sendGroupsToBeReviewedEvent(groupsToBeReviewed: number): void {
  customDispatchEvent('groupsToBeReviewed', { groupsToBeReviewed } as GroupsToBeReviewedEvent);
}

export function listenForArticlesAdded(store: SizingStore): void {
  addEventListener('articlesAdded', (payload: ArticlesAddedEvent) => {
    store.addArticles(payload.sizingData);
  });
}

export function listenForBulkArticlesRemoved(store: SizingStore): void {
  addEventListener('bulkArticlesRemoved', (payload: BulkArticlesRemovedEvent) => {
    store.removeArticles(payload.referenceId, payload.articleReferenceIds);
  });
}

export function sendPresizeLoadedEvent(articles: Article[]): void {
  const loadedArticles = articles.map((article) => {
    return {
      referenceId: article.referenceId,
      manuallyEdited: article.manuallyEdited,
    } as LoadedArticle;
  });
  customDispatchEvent('presizeLoaded', { loadedArticles } as PresizeLoadedEvent);
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const removeTypename = (value: any) => {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map((v) => removeTypename(v));
  } else if (typeof value === 'object') {
    const newObj = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== '__typename') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};

function convertArticlesToSizedArticles(sizingGroup: SizingGroup, articles: Article[]): SizedArticle[] {
  if (!sizingGroup.selectedSizeChart?.sizeChartCode) {
    const articleRefIds = articles.map((article) => article.referenceId).join();
    captureMessage(
      `SizingGroup with key ${sizingGroup.groupKey} with articles ${articleRefIds} failed to size correctly!`
    );
    return [];
  }
  // Deep clone the articles list in order not to change it's existing sizing
  const sizedArticles = JSON.parse(JSON.stringify(articles)) as Article[];
  unify2DSeparator(sizingGroup, sizedArticles, 'x');

  return sizedArticles.map<SizedArticle>((article) => ({
    sizeChartCode: sizingGroup.selectedSizeChart?.sizeChartCode as string,
    lengthChartCode: sizingGroup.selectedSizeChart?.lengthChartCode,
    sizeLengthSeparator: sizingGroup.selectedSizeChart?.lengthChartCode ? 'x' : '',
    referenceId: article.referenceId,
    quantity: article.quantity,
    blockOrderQuantity: article.blockOrderQuantity,
    editedQuantities: !!article.manuallyEdited,
    sizing: article.sizing as Distribution,
    blockOrderSizing: article.blockOrderSizing as Distribution,
    sizeStructure: article.sizeStructure,
    sizingNote: article.sizingNote,
    boxing: removeTypename(convertTotalSizedQuantitiesToPerLot(article.boxing)),
    blockOrderBoxing: removeTypename(convertTotalSizedQuantitiesToPerLot(article.blockOrderBoxing)),
  }));
}

export function unify2DSeparator(sizingGroup: SizingGroup, articles?: Article[], unifiedSeparator?: string): void {
  const { sizeList = [], lengthList = [], sizeLengthSeparator = '' } = sizingGroup.selectedSizeChart || {};

  if (!lengthList?.length || sizeLengthSeparator === 'x') {
    return;
  }

  const oldSeparator = unifiedSeparator ? sizeLengthSeparator : 'x';
  const newSeparator = unifiedSeparator ? 'x' : sizeLengthSeparator;

  (articles || sizingGroup.articles).forEach((article) => {
    article.sizing = article.sizing
      ? replaceSizeLengthSeparator(article.sizing || {}, sizeList, lengthList, oldSeparator, newSeparator)
      : article.sizing;
    article.blockOrderSizing = article.blockOrderSizing
      ? replaceSizeLengthSeparator(article.blockOrderSizing || {}, sizeList, lengthList, oldSeparator, newSeparator)
      : article.blockOrderSizing;
  });
}

export function replaceSizeLengthSeparator(
  sizing: Distribution,
  sizeList: SortKey,
  lengthList: SortKey,
  oldSeparator: string,
  newSeparator: string
): Distribution {
  const sizeKey = Object.keys(sizing);

  return sizeList.reduce<Distribution>(
    (sizing: Distribution, size: string) =>
      lengthList.reduce<Distribution>((newSizing: Distribution, length: string) => {
        const oldSize = `${size}${oldSeparator}${length}`;
        const newSize = `${size}${newSeparator}${length}`;
        if (~sizeKey.indexOf(oldSize)) {
          sizing[newSize] = sizing[oldSize];
          delete sizing[oldSize];
        }
        return newSizing;
      }, sizing as Distribution),
    sizing as Distribution
  );
}

import React, { FC } from 'react';
import { Tag } from '@retail-core/rds';
import { ToolOutlined } from '@ant-design/icons';

import { isLocalDevelopment, isProduction, isShowCase } from '../../lib/environment';

import './TagEnvDeploy.less';

declare let REACT_APP_CDP_BUILD: string;

export const TagEnvDeploy: FC = () => {
  const environment = () => (isShowCase() ? 'Showcase' : isLocalDevelopment() ? 'Localhost' : 'Testing');
  return isProduction() ? (
    <></>
  ) : (
    <Tag className="tagEnvironment" icon={<ToolOutlined />}>
      {REACT_APP_CDP_BUILD ? environment() + ' - ' + REACT_APP_CDP_BUILD : environment()}
    </Tag>
  );
};

import React, { FC } from 'react';
import { Anchor, AnchorLinkProps, Typography } from '@retail-core/rds';

const { Text } = Typography;
const { Link } = Anchor;

interface IProps extends AnchorLinkProps {
  disabled?: boolean;
}

export const TreeLink: FC<IProps> = ({ disabled = false, ...props }) => {
  if (disabled) {
    return (
      <div className="ant-anchor-link">
        <span className="ant-anchor-link-title">
          <Text disabled>{props.title}</Text>
        </span>
        {props.children}
      </div>
    );
  }

  return <Link {...props} />;
};

import React, { FC, useRef } from 'react';
import { Col, Row, Space, Tooltip, Typography } from '@retail-core/rds';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Matrix } from '../../Matrices/Matrix/Matrix';
import useTablesScrollSync from '../../../lib/use-tables-scroll-sync';
import { SizingGroup } from '../../../models/sizing-group';
import { Article } from '../../../models/sizing-data';
import './BoxRestrictionsArticleSizing.less';
import { getSizingFromArticleQuantity } from '../../../lib/article-utils';

type Props = {
  group: SizingGroup;
  article: Article;
  shouldSyncTables: boolean;
  type: 'distribution' | 'quantities';
};

const BoxRestrictionsArticleSizing: FC<Props> = (props) => {
  const { group, article, shouldSyncTables, type } = props;
  const dataIsPercentage = type === 'distribution';
  const achievedDistribution = article.sizing
    ? dataIsPercentage
      ? Object.keys(article.sizing).reduce((distribution, size) => {
          const rawValue = (100 * (article?.sizing?.[size] || 0)) / article.quantity;
          return {
            ...distribution,
            [size]: rawValue,
          };
        }, {})
      : article.sizing
    : undefined;
  const idealQuantity = article.quantity - (article.boxing?.deviation || 0);
  const idealSizing = getSizingFromArticleQuantity(idealQuantity, group?.selectedSizeKey?.distribution);
  const tableRef1 = useRef<HTMLDivElement>(null);
  const tableRef2 = useRef<HTMLDivElement>(null);
  useTablesScrollSync(tableRef1, tableRef2, shouldSyncTables);
  return (
    <>
      <Row gutter={32} align="bottom">
        <Col flex="221px" className="box-calculation-modal-article-sizing-label-wrapper">
          <Typography.Text className="box-calculation-modal-article-sizing-label">
            <Space>
              <span>Ideal {type}</span>
              <Tooltip placement="top" title={`Recommended or edited ${type} for this article`}>
                <InfoCircleOutlined data-testid="ideal-article-sizing-tooltip" />
              </Tooltip>
            </Space>
          </Typography.Text>
        </Col>
        <Col flex="auto" className="box-calculation-modal-article-sizing-matrix compare-modal-radio-group">
          {group?.selectedSizeKey && group?.selectedSizeKey.distribution && group?.groupKey && (
            <Matrix
              sizeKey={group.selectedSizeKey}
              data={dataIsPercentage ? group.selectedSizeKey.distribution : idealSizing}
              groupKey={group.groupKey}
              referenceId={group.groupKey}
              dataIsPercentage={dataIsPercentage}
              showTableHeader
              highlighted={dataIsPercentage}
              expectedTotal={dataIsPercentage ? 100 : article.quantity}
              onChangeDistributionWhileComparing={() => null}
              ref={tableRef1}
              readOnly
              maxScrollHeight={81}
            />
          )}
        </Col>
        <Col flex="229px" />
      </Row>
      <Row gutter={32} align="bottom">
        <Col flex="221px" className="box-calculation-modal-article-sizing-label-wrapper">
          <Typography.Text className="box-calculation-modal-article-sizing-label">
            <Space>
              <span>Achieved {type}</span>
              <Tooltip placement="top" title={`Sized ${type} based on the box restriction calculation`}>
                <InfoCircleOutlined data-testid="achieved-article-sizing-tooltip" />
              </Tooltip>
            </Space>
          </Typography.Text>
        </Col>
        <Col flex="auto" className="box-calculation-modal-article-sizing-matrix compare-modal-radio-group">
          {group?.selectedSizeKey && group?.selectedSizeKey.distribution && group?.groupKey && achievedDistribution && (
            <Matrix
              sizeKey={group.selectedSizeKey}
              data={achievedDistribution}
              groupKey={group.groupKey}
              referenceId={group.groupKey}
              dataIsPercentage={dataIsPercentage}
              highlighted={dataIsPercentage}
              expectedTotal={dataIsPercentage ? 100 : article.quantity}
              onChangeDistributionWhileComparing={() => null}
              ref={tableRef2}
              readOnly
              showTableHeader={false}
              referenceData={group.selectedSizeKey.distribution}
              maxScrollHeight={81}
            />
          )}
        </Col>
        <Col flex="229px" />
      </Row>
    </>
  );
};

export default BoxRestrictionsArticleSizing;

import React, { FC } from 'react';
import { Button } from '@retail-core/rds';
import { EditOutlined } from '@ant-design/icons';
import './Actions.less';

interface IProps {
  onClick: () => void;
}

export const EditSizes: FC<IProps> = (props) => {
  const { onClick } = props;

  const onClickHandler = (e: any) => {
    e.preventDefault();
    onClick();
  };

  return (
    <div className="edit-sizes-btn">
      <Button type="text" icon={<EditOutlined />} onClick={onClickHandler} className="ant-btn-txt-default" size="small">
        Edit sizes
      </Button>
    </div>
  );
};

import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { DistributionType } from '../../../models/sizing';
import { Popover, Tooltip } from '@retail-core/rds';
//TODO remove this once we have the FIx in rds for forwardRef
import { Tag } from 'antd';
import {
  InfoCircleOutlined,
  SwitcherOutlined,
  WarningOutlined,
  TagsOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useStore } from '../../StoreContext/StoreContext';
import { SizingGroup as ISizingGroup } from '../../../models/sizing-group';
import { containsNonEditableSizeChart } from '../SizingGroup';
import { BoxRestrictionType, BoxRestrictionStatus } from '../../../models/box-restriction';

interface IProps {
  group: ISizingGroup;
}

export const SizingGroupTags: FC<IProps> = observer((props) => {
  const {
    group,
    group: { selectedSizeKey, sizing, enableBox },
  } = props;

  const { sizing: sizingStore } = useStore();
  const articlesWithEditedQty = group.numberOfArticlesWithEditedQuantity();
  const isSizeChartNotEditable = containsNonEditableSizeChart(group.articles);
  const referenceBrandUsed = !!sizing?.referenceBrandCode;
  const reusedDecision = group?.reusedDecision && !group?.reusedDecision.isAutoReused;
  const autoReusedDecision = group?.reusedDecision && group?.reusedDecision.isAutoReused;

  const boxRestrictionTagText: string | undefined = getBoxRestrictionTagText(group);
  if (group.loadingSizing) return <></>;
  return (
    <span className="tags">
      {enableBox && boxRestrictionTagText && (
        <Tag color="neutral">
          <SwitcherOutlined /> {boxRestrictionTagText}
        </Tag>
      )}
      {sizingStore.enableBox && !enableBox && <Tag color="neutral">Disabled box restrictions</Tag>}
      {reusedDecision && <Tag color="neutral">Re-used decision</Tag>}
      {autoReusedDecision && <Tag color="neutral">Auto re-used decision</Tag>}
      {referenceBrandUsed && <Tag color="neutral">Reference brand used</Tag>}
      {selectedSizeKey?.distributionType === DistributionType.MANUAL && <Tag color="purple">Edited %</Tag>}
      {group.silo &&
        (selectedSizeKey?.distributionType === DistributionType.SILO ? (
          <Tag icon={<CheckCircleOutlined />} color="green">
            Silo size curve recommendation
          </Tag>
        ) : (
          <Tag color="neutral">No silo size curve recommendation</Tag>
        ))}
      {selectedSizeKey?.distributionType === DistributionType.FALLBACK && (
        <Popover
          trigger="hover"
          placement="bottom"
          overlayClassName="fallback-popover"
          title="Check fallback recommendation"
          content={
            <>
              <span>The historical data is not relevant enough for this combination of sizes and CGs.</span>
              <br />
              <span>
                A fallback recommendation is provided to simulate the curve we would expect for articles with the same
                characteristics.
              </span>
            </>
          }
        >
          <Tag color={group.isReviewed ? 'neutral' : 'gold'} icon={<InfoCircleOutlined />}>
            Fallback recommendation
          </Tag>
        </Popover>
      )}
      {articlesWithEditedQty !== 0 && (
        <Tooltip
          title={`Changed quantities on ${articlesWithEditedQty} article${articlesWithEditedQty > 1 ? 's' : ''}`}
        >
          <Tag color="purple">Edited quantities</Tag>
        </Tooltip>
      )}
      {!selectedSizeKey && <Tag color="gold">Sizes to be selected</Tag>}
      {!group.isReviewed && selectedSizeKey && <Tag color="gold">To be reviewed</Tag>}
      {isSizeChartNotEditable && (
        <Tooltip placement="bottom" title="Size chart is locked in the Buying Article">
          <Tag icon={<TagsOutlined />} color="neutral">
            Size Chart not editable
          </Tag>
        </Tooltip>
      )}
      {enableBox && sizingStore.boxRestrictions.length === 0 && <Tag color="red">No Box restriction in Catalog</Tag>}
      {group.enableBox && group.boxRestriction?.boxRestrictionStatus === BoxRestrictionStatus.BR_UPDATED && (
        <Tooltip
          placement="top"
          title={`This box restriction was updated & article quantities were automatically re-optimized`}
        >
          <Tag
            color="gold"
            className="box-size-tag"
            icon={<WarningOutlined />}
            data-testid="box-restriction-updated-tag"
          >
            Box restriction updated
          </Tag>
        </Tooltip>
      )}
      {group.enableBox &&
        (group.boxRestriction?.boxRestrictionStatus === BoxRestrictionStatus.BR_DELETED ||
          group.boxRestriction?.boxRestrictionStatus === BoxRestrictionStatus.BR_RESET) && (
          <Tooltip
            placement="top"
            title={`The box restriction previously selected for this group is now incompatible or deleted`}
          >
            <Tag
              color="gold"
              className="box-size-tag"
              icon={<WarningOutlined />}
              data-testid="box-restriction-deleted-tag"
            >
              Box restriction reset
            </Tag>
          </Tooltip>
        )}
    </span>
  );
});

const getBoxRestrictionTagText = (group: ISizingGroup) => {
  const boxRestrictionType = group.boxRestriction?.boxRestrictionType;
  let boxRestrictionTagText: string | undefined;
  switch (boxRestrictionType) {
    case BoxRestrictionType.DIVISOR:
      boxRestrictionTagText = 'Divisor per size';
      break;
    case BoxRestrictionType.LOT_GROUP:
      boxRestrictionTagText = 'Preset sizing lot';
      break;
    case BoxRestrictionType.FIXED_BOX:
      boxRestrictionTagText = 'Divisor per box';
      break;
  }
  return boxRestrictionTagText;
};

import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Tooltip, Button } from '@retail-core/rds';
import { CheckOutlined } from '@ant-design/icons';
import { useStore } from '../StoreContext/StoreContext';
import { ConditionalWrapper } from '../partials/ConditionalWrapper/ConditionalWrapper';

interface Props {
  isReviewed: boolean;
  disabled: boolean;
}

export const ReviewButton: FC<Props> = observer(({ isReviewed, disabled }) => {
  const { sizing } = useStore();

  const readOnly = sizing.readOnly;
  const isMarkAllAsReviewedDisabled = sizing.sizingGroups.some((group) => !group.isReviewed && !group.isSized());
  const markAllAsReviewedHandler = async () => {
    await sizing.markAllGroupsAsReviewed();
  };

  return (
    <div className="review-btn">
      <ConditionalWrapper
        condition={isMarkAllAsReviewedDisabled}
        wrapper={(children) => (
          <Tooltip
            title="Please complete sizing for all groups before marking all as reviewed."
            placement="bottomRight"
          >
            {children}
          </Tooltip>
        )}
      >
        <Button
          data-testid="mark-all-reviewed-btn"
          className={isReviewed ? 'is-reviewed' : undefined}
          type={isReviewed ? 'primary' : 'default'}
          icon={<CheckOutlined />}
          onClick={markAllAsReviewedHandler}
          disabled={disabled || readOnly || isMarkAllAsReviewedDisabled}
        >
          {isReviewed ? 'All reviewed' : 'Mark all as reviewed'}
        </Button>
      </ConditionalWrapper>
    </div>
  );
});

import { SizingStore } from './sizing-store';
import { Article } from '../../models/sizing-data';
import { sendEvent } from '../../services/event-tracking';
import { convertArticleToArticleInput } from '../../lib/article-utils';
import presizeBackend from '../../services/presize-backend';

export class RemoveArticlesAction {
  async removeArticles(this: SizingStore, referenceId: string, articleReferenceIds: string[]): Promise<void> {
    if (referenceId !== this.referenceId) {
      throw new Error(`Trying to remove articles not matching current article list!`);
    }

    this.articles = this.removeArticlesFromList(this.articles, articleReferenceIds);
    const { brandCode, seasonCode, groupingCgLevel, enableBox } = this;
    await presizeBackend.updateOrder(
      this.referenceId,
      brandCode,
      seasonCode,
      groupingCgLevel,
      enableBox,
      this.articles.map(convertArticleToArticleInput)
    );
    const calls: any = [];
    this.sizingGroups.forEach((group) => {
      const includedArticles = group.articles.filter((articleFromGroup) =>
        articleReferenceIds.includes(articleFromGroup.referenceId)
      );

      if (includedArticles.length > 0) {
        group.articles = this.removeArticlesFromList(
          group.articles,
          includedArticles.map((a) => a.referenceId)
        );

        calls.push(
          sendEvent(group, 'removeArticle', includedArticles, {
            supplierCode: this.supplierCode,
            subUnitCode: this.subUnitCode,
            buyerEmail: this.buyerEmail,
            brandCode: this.brandCode,
            brandName: this.brandName,
            orderReferenceId: this.referenceId,
            season: this.seasonCode,
            cgGroupingLevel: this.groupingCgLevel,
            isImportSupplierStockUsed: false,
          })
        );
      }
    });

    // Remove potentially empty sizing groups.
    this.sizingGroups = this.sizingGroups.filter((group) => group.articles.length > 0);
    await Promise.all(calls);
  }

  private removeArticlesFromList(articles: Article[], referenceIds: string[]): Article[] {
    return articles.filter((article) => !referenceIds.includes(article.referenceId));
  }
}

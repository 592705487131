import { observable } from 'mobx';
import { initialSelectionState, UIStore } from './ui-store';
import { SizingGroup } from '../../models/sizing-group';

export class ArticleSelectionAction {
  setSelectedArticles(this: UIStore, selectedGroup: string, selectedArticles: Record<string, boolean>): void {
    if (selectedGroup && this.uIState[selectedGroup]) {
      let selectedArticlesLength = 0;
      Object.keys(this.uIState[selectedGroup].articles).forEach((referenceId) => {
        this.uIState[selectedGroup].articles[referenceId].selected = selectedArticles[referenceId];
        if (selectedArticles[referenceId]) {
          selectedArticlesLength += 1;
        }
      });
      this.uIState[selectedGroup].selected = selectedArticlesLength;
      this.updateSelectedCG3List();
    }
  }
  selectArticles(this: UIStore, groups: Array<string>, selectMatched?: boolean): void {
    groups.forEach((group: string) => {
      if (this.uIState[group]) {
        let selectedArticlesLength = 0;
        Object.keys(this.uIState[group].articles).forEach((referenceId) => {
          this.uIState[group].articles[referenceId].selected = selectMatched
            ? this.uIState[group].articles[referenceId].matched
            : true;
          selectedArticlesLength += Number(this.uIState[group].articles[referenceId].selected);
        });
        this.uIState[group].selected = selectedArticlesLength;
      }
    });
    this.updateSelectedCG3List();
  }
  unselectArticles(this: UIStore, groups?: Array<string>): void {
    (groups || Object.keys(this.uIState)).forEach((group: string) => {
      if (this.uIState[group] && this.uIState[group].selected) {
        this.uIState[group].selected = 0;
        Object.keys(this.uIState[group].articles).forEach(
          (referenceId) => (this.uIState[group].articles[referenceId].selected = false)
        );
      }
    });
    this.updateSelectedCG3List();
  }
  moveArticles(
    this: UIStore,
    articlesUpdatedSizingGroupsMap: Record<string, string>,
    newGroups: Array<SizingGroup>,
    selectedGroups: Array<string>
  ): void {
    newGroups.forEach((newGroup) => {
      if (!this.uIState[newGroup.groupKey]) {
        this.uIState[newGroup.groupKey] = observable({
          selected: 0,
          matched: 0,
          distributionSelectionState: initialSelectionState,
          cg3: newGroup.commodityGroup.codes[2],
          articles: {},
        });
      }
    });
    selectedGroups.forEach((groupKey) => {
      Object.keys(this.uIState[groupKey].articles).forEach((articleId) => {
        if (articlesUpdatedSizingGroupsMap[articleId] && articlesUpdatedSizingGroupsMap[articleId] !== groupKey) {
          this.uIState[articlesUpdatedSizingGroupsMap[articleId]].articles[articleId] = this.uIState[groupKey].articles[
            articleId
          ];
          delete this.uIState[groupKey].articles[articleId];
          this.uIState[articlesUpdatedSizingGroupsMap[articleId]].selected += 1;
          this.uIState[groupKey].selected -= 1;
          if (this.uIState[articlesUpdatedSizingGroupsMap[articleId]].articles[articleId].matched) {
            this.uIState[articlesUpdatedSizingGroupsMap[articleId]].matched += 1;
            this.uIState[groupKey].matched -= 1;
          }
        }
      });
    });
    Object.keys(this.uIState).forEach((groupKey) => {
      if (!Object.keys(this.uIState[groupKey].articles).length) {
        delete this.uIState[groupKey];
      }
    });
  }

  updateSelectedCG3List(this: UIStore): void {
    this.selectedCG3List = Object.keys(this.uIState).reduce<string[]>((cg3List, groupKey) => {
      const cg3 = this.uIState[groupKey].cg3;
      if (this.uIState[groupKey].selected && cg3List.indexOf(cg3) === -1) {
        return [...cg3List, this.uIState[groupKey].cg3];
      }
      return cg3List;
    }, []);
  }
}

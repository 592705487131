import { runInAction } from 'mobx';
import { DistributionType } from '../../models/sizing';
import { SizingGroup } from '../../models/sizing-group';
import { sendEvent } from '../../services/event-tracking';
import presizeBackend from '../../services/presize-backend';
import { SizingStore } from './sizing-store';
import { SizeDistribution } from '../../models/response';

export class UpdateSizingDistributionAction {
  async updateSizingDistribution(
    this: SizingStore,
    group: SizingGroup,
    newSizeDistribution: SizeDistribution
  ): Promise<void> {
    // TODO Note for future refactoring: updating the size distribution and resetting the size distribution should be done via a dedicated mutation instead of misusing the applySizing() mutation
    // Using one super mutation for multiple purposes makes the code hard to understand and maintain. I have already wasted few days.

    const selectedSizeKey = group.selectedSizeKey;
    const selectedSizeChart = group.selectedSizeChart;

    if (!selectedSizeKey) {
      throw new Error('Size key could not be found');
    }

    if (!selectedSizeChart) {
      throw new Error('Size chart could not be found');
    }

    selectedSizeKey.distribution = newSizeDistribution.distribution;
    selectedSizeKey.distributionType = newSizeDistribution.distributionType;
    selectedSizeKey.recommendationLevel = newSizeDistribution.recoLevel;
    selectedSizeKey.recommendationStrategy = newSizeDistribution.recoStrategy;

    const sizeDistribution = await presizeBackend.applySizing(
      this.referenceId,
      this.brandCode,
      this.seasonCode,
      group,
      selectedSizeChart,
      selectedSizeKey,
      newSizeDistribution.distributionType !== DistributionType.MANUAL,
      undefined,
      group.sizeStructure,
      group.sizingNote
    );
    selectedSizeKey.distribution = sizeDistribution.distribution;
    selectedSizeKey.distributionType = sizeDistribution.distributionType;
    selectedSizeKey.recommendationLevel = sizeDistribution.recoLevel;
    selectedSizeKey.recommendationStrategy = sizeDistribution.recoStrategy;
    group.resetEditedQuantities();
    group.isReviewed = false;

    await runInAction(async () => {
      group.selectedSizeKey = selectedSizeKey;
      if (group.sizing) {
        group.sizing.referenceBrandCode = null;
        group.reusedDecision = null;
        this.processReferenceBrandsWithUsage();
      }
      await this.calculateQuantities(false, group);
      sendEvent(group, 'changeDistribution', undefined, this.additionalData());
    });
  }
}

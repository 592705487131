import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Select, Space, Tooltip } from '@retail-core/rds';
import { ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useStore } from '../StoreContext/StoreContext';
import { CgGroupingLevel } from '../../models/sizing-group';
import './GroupLevelSelect.less';

const { Option } = Select;

export const GroupLevelSelect: FC = observer(() => {
  const { sizing, uiStore } = useStore();

  const readOnly = sizing.readOnly;
  const numberOfChangedOrReviewedGroups = sizing.sizingGroups.filter((group) => group.isReviewed || group.hasChanges())
    .length;

  const changeGroupingLevelHandler = async (level: CgGroupingLevel) => {
    if (numberOfChangedOrReviewedGroups) {
      Modal.confirm({
        title: 'Overwrite changes?',
        icon: <ExclamationCircleOutlined />,
        content: `${numberOfChangedOrReviewedGroups} group(s) have already been reviewed or edited. All manual changes will be overwritten if you continue.`,
        okText: 'Yes, change CG level',
        cancelText: 'Cancel',
        centered: true,
        mask: false,
        className: 'cg-confirm',
        async onOk() {
          if (uiStore.searchQuery) {
            uiStore.searchArticles('');
          }
          await sizing.changeGroupingLevel(level);
        },
      });
    } else {
      if (uiStore.searchQuery) {
        uiStore.searchArticles('');
      }
      await sizing.changeGroupingLevel(level);
    }
  };

  return (
    <Space className="cg-dropdown">
      Set CG level for sizing
      <Select
        value={sizing.groupingCgLevel}
        onChange={changeGroupingLevelHandler}
        size="small"
        virtual={false}
        disabled={readOnly}
      >
        <Option value={CgGroupingLevel.CG5}>5</Option>
        <Option value={CgGroupingLevel.CG4}>4</Option>
        <Option value={CgGroupingLevel.CG3}>3</Option>
      </Select>
      <Tooltip
        placement="top"
        title="Pick a higher CG level for more precise sizing, or a lower CG level for faster sizing in bulk"
      >
        <InfoCircleOutlined />
      </Tooltip>
    </Space>
  );
});

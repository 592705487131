import { BoxRestrictions } from '@ism/box-restrictions';
import { Modal } from '@retail-core/rds';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback } from 'react';
import { isProduction, isShowCase } from '../../../lib/environment';
import { useStore } from '../../StoreContext/StoreContext';
import './BoxRestrictionsModal.less';
import { OrderType } from '@ism/box-restrictions/dist/types';

const ENV = isProduction() ? 'PRODUCTION' : isShowCase() ? 'STAGING' : null;

export const BoxRestrictionsModal: FC = observer(function BoxRestrictionsModal() {
  const { sizing: sizingStore, uiStore } = useStore();
  const { boxCatalogView, boxCatalogCg3, boxCatalogSizeChartId, boxCatalogSizeKey, boxRestrictionId } = uiStore;
  const { subUnitCode, brandCode, supplierCode, cgCode1, cgCode2 } = sizingStore;

  const handleBoxRestrictionCreate = useCallback(
    async (box) => {
      await sizingStore.getBoxRestrictions();
      if (uiStore.boxCatalogSizingGroupKey) {
        await sizingStore.setGroupBoxRestriction(uiStore.boxCatalogSizingGroupKey as string, box.id);
      }
      uiStore.hideBoxCatalog();
    },
    [sizingStore, uiStore]
  );
  const handleBoxRestrictionUpdate = useCallback(
    async (box) => {
      await sizingStore.getBoxRestrictions();
      if (box.id) {
        await sizingStore.loadSizing();
      }
      uiStore.hideBoxCatalog();
    },
    [sizingStore, uiStore]
  );

  const handleCancel = useCallback(async () => {
    // Temporary catch for onDelete on list view
    if (!uiStore.boxCatalogSizingGroupKey) {
      await sizingStore.getBoxRestrictions();
      await sizingStore.loadSizing();
    }
    uiStore.hideBoxCatalog();
  }, [sizingStore, uiStore]);

  return (
    <>
      <Modal
        title="Box restrictions catalog"
        className={'box-catalog-modal'}
        wrapClassName="presize-root"
        centered
        visible={!!boxCatalogView}
        onCancel={handleCancel}
        footer={null}
        width={1400}
        destroyOnClose={true}
        focusTriggerAfterClose={false}
      >
        <BoxRestrictions
          env={ENV}
          initialView={boxCatalogView}
          initialProps={{
            brandCode,
            supplierCode,
            subunitCode: subUnitCode,
            cg1Code: cgCode1,
            cg2Code: cgCode2,
            cg3Codes: boxCatalogCg3 ? [boxCatalogCg3] : undefined,
            sizeChartId: boxCatalogSizeChartId,
            sizeRange: boxCatalogSizeKey,
            basicOrderType: OrderType.PREORDER,
            boxRestrictionId,
          }}
          events={{
            onCreate: handleBoxRestrictionCreate,
            onUpdate: handleBoxRestrictionUpdate,
            onCancel: handleCancel,
          }}
        />
      </Modal>
    </>
  );
});

import presizeBackend from '../../services/presize-backend';
import { SizingStore } from './sizing-store';
import { runInAction } from 'mobx';
import { sendSizingAppliedEventForGroups } from '../../lib/fragment-helper';
import { SizingGroup, SizingGroupBoxRestrictions } from '../../models/sizing-group';
import { sendEvent } from '../../services/event-tracking';

export class ToggleEnableBoxAction {
  async toggleEnableBox(this: SizingStore): Promise<void> {
    const newBoxState = !this.enableBox;

    await presizeBackend.applyOrderData(this.referenceId, undefined, newBoxState);
    const applyGroupBoxRestrictionsInput = this.sizingGroups.reduce((groupsList, { groupKey }: SizingGroup) => {
      groupsList.push({
        groupKey,
        enableBox: newBoxState,
      });
      return groupsList;
    }, [] as SizingGroupBoxRestrictions[]);
    await presizeBackend.applyGroupBoxRestrictions(this.referenceId, applyGroupBoxRestrictionsInput);

    await this.markAllGroupsAsNotReviewed();

    if (newBoxState) {
      await this.getBoxRestrictions();
      await Promise.all(
        this.sizingGroups.map((sizingGroup) => {
          runInAction(() => (sizingGroup.enableBox = true));
          return this.optimizeBoxQuantities(sizingGroup);
        })
      );
    } else {
      await Promise.all(
        this.sizingGroups
          .filter((group) => group.boxRestriction?.boxRestrictionId)
          .map((group) => {
            sendEvent(group, 'removeBoxRestriction', group.articles, this.additionalData());
            return this.setGroupBoxRestriction(group.groupKey);
          })
      );
    }

    runInAction(() => {
      this.enableBox = newBoxState;
      if (!this.enableBox) {
        this.removeBoxDetailsGeneric('sizing', 'boxing', 'quantity');
        this.removeBoxDetailsGeneric('blockOrderSizing', 'blockOrderBoxing', 'blockOrderQuantity');
      }
    });
    sendSizingAppliedEventForGroups(this.sizingGroups);
  }

  async toggleGroupEnableBox(this: SizingStore, sizingGroupKey: string): Promise<void> {
    const selectedGroup = this.sizingGroups.find((group) => group.groupKey === sizingGroupKey);

    if (!selectedGroup) {
      throw new Error('Size group could not be found');
    }

    const newBoxState = !selectedGroup.enableBox;

    await presizeBackend.applyGroupBoxRestrictions(this.referenceId, [
      {
        groupKey: selectedGroup.groupKey,
        enableBox: newBoxState,
      },
    ]);

    await this.markAsNotReviewed(selectedGroup);

    runInAction(() => (selectedGroup.enableBox = newBoxState));

    if (!selectedGroup.enableBox) {
      sendEvent(selectedGroup, 'removeBoxRestriction', selectedGroup.articles, this.additionalData());
      this.setGroupBoxRestriction(selectedGroup.groupKey);
    } else if (!this.enableBox) {
      await presizeBackend.applyOrderData(this.referenceId, undefined, newBoxState);
      runInAction(() => (this.enableBox = newBoxState));
      await this.getBoxRestrictions();
    }

    sendSizingAppliedEventForGroups([selectedGroup]);
  }

  private removeBoxDetailsGeneric(
    this: SizingStore,
    sizingFieldName: 'sizing' | 'blockOrderSizing',
    boxingFieldName: 'boxing' | 'blockOrderBoxing',
    quantityFieldName: 'quantity' | 'blockOrderQuantity'
  ) {
    this.sizingGroups.forEach((sizingGroup) => {
      runInAction(() => (sizingGroup.enableBox = false));
      this.removeSizingGroupBoxDetailsGeneric(sizingGroup, sizingFieldName, boxingFieldName, quantityFieldName);
    });
  }
}

import { Button, message as Message, Table, Tooltip } from '@retail-core/rds';
import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  createCopiableMatrix,
  distributionWith100Percent,
  getDataSetValues,
  getDistribution,
  getMatrixColumns,
  getMatrixDataset,
  getMaxDataset,
  parsePastedData,
  sumMatrixDataset,
  updateMatrixDataset,
  updateMatrixDatasetRange,
} from '../matrices-utils';
import './Matrix.less';
import { Distribution, DistributionType, SizeKey } from '../../../models/sizing';
import { EditableCell } from './EditableCell/EditableCell';
import { Actions } from './Actions/Actions';
import { EditSizes } from './Actions/EditSizes';
import isEqual from 'fast-deep-equal';
import { QuantitiesOverwriteModal } from '../../partials/ModalConfirm/ModalConfirm';
import { BarChartOutlined, CopyOutlined, SnippetsOutlined } from '@ant-design/icons';
import { SelectionState } from '../../../models/selection-state';
import { MatrixDataset } from '../../../models/sizing-table';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { SizeDistribution } from '../../../models/response';

function render1dCell(
  maxPercentage: number,
  dataIsPercentage?: boolean,
  highlighted?: boolean,
  referenceDataSet?: MatrixDataset,
  expectedTotal?: number,
  sizeDivisorForValidation?: number
) {
  return (value?: number, record?: Record<string, string>, index?: number) => {
    const referenceRecord = referenceDataSet && index !== undefined ? referenceDataSet[index] : {};
    const offset = ((value || 0) / maxPercentage) * 100;
    const gradient = `linear-gradient(to top, #F1E8F9 ${offset}%, rgba(0,0,0,0) ${offset}%)`;
    return {
      props: {
        style: { backgroundImage: highlighted && gradient },
        'data-percentage': offset.toString(), // Used for testing https://github.com/testing-library/jest-dom/issues/170
        referenceRecord,
        expectedTotal,
        sizeDivisorForValidation,
      },
      children: <>{value === null || value === undefined ? '' : value.toFixed(dataIsPercentage ? 1 : 0)}</>,
    };
  };
}

function render2dCell(
  maxPercentage: number,
  dataIsPercentage?: boolean,
  highlighted?: boolean,
  referenceDataSet?: MatrixDataset,
  expectedTotal?: number,
  sizeDivisorForValidation?: number
) {
  return (value?: number, record?: Record<string, string>, index?: number) => {
    const referenceRecord = referenceDataSet && index !== undefined ? referenceDataSet[index] : {};
    const offset = (value || 0) / maxPercentage;
    return {
      props: {
        style: {
          backgroundColor: highlighted && value !== undefined ? `rgba(184, 143, 230, ${offset})` : '',
        },
        'data-percentage': offset.toString(), // Used for testing
        referenceRecord,
        expectedTotal,
        sizeDivisorForValidation,
      },
      children: <>{value === null || value === undefined ? '' : value.toFixed(dataIsPercentage ? 1 : 0)}</>,
    };
  };
}

function useEditing(): [boolean, () => void, () => void] {
  const [isEditing, setEditing] = useState(false);
  const stopEdit = () => {
    setEditing(false);
  };
  const startEdit = () => {
    if (!isEditing) {
      setEditing(true);
    }
  };

  return [isEditing, startEdit, stopEdit];
}

interface IProps {
  sizeKey: SizeKey;
  data: Distribution;
  referenceData?: Distribution;
  showTableHeader?: boolean;
  highlighted?: boolean;
  dataIsPercentage?: boolean;
  expectedTotal?: number;
  readOnly?: boolean;
  numberOfEditedArticles?: number;
  referenceId?: string;
  selectionState?: SelectionState;
  setSelectionState?: (groupKey: string | undefined, referenceId: string | undefined, state: SelectionState) => void;
  clearSelectionState?: () => void;
  onAdjustTotal?: (total: number, distribution: Distribution) => void;
  openCompare?: () => void;
  onCancel?: () => void;
  onSizeDistributionChange?: (sizeDistribution: SizeDistribution) => Promise<void> | void;
  onSizingSelector?: () => void;
  groupHeaderHover?: boolean;
  hasReferenceBrand?: boolean;
  hasReusedDecision?: boolean;
  onChangeDistributionWhileComparing?: (sizeDistribution: SizeDistribution, dataSet?: MatrixDataset) => void;
  groupKey?: string;
  children?: ReactNode;
  maxScrollHeight?: number;
  sizeDivisorForValidation?: number;
}

export const Matrix = React.forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const {
    children,
    sizeKey,
    data,
    referenceData,
    showTableHeader,
    highlighted,
    dataIsPercentage,
    expectedTotal,
    numberOfEditedArticles = 0,
    readOnly,
    onAdjustTotal,
    openCompare,
    onCancel,
    onSizeDistributionChange,
    onSizingSelector,
    referenceId,
    selectionState,
    setSelectionState,
    clearSelectionState,
    groupHeaderHover,
    hasReferenceBrand,
    hasReusedDecision,
    onChangeDistributionWhileComparing,
    groupKey,
    maxScrollHeight = 162,
    sizeDivisorForValidation,
  } = props;
  const is2D = !!sizeKey.lengthList?.length;
  const originalDataSet = useMemo(() => getMatrixDataset(sizeKey, data), [sizeKey, data]);
  const referenceDataSet = useMemo(() => (referenceData ? getMatrixDataset(sizeKey, referenceData) : []), [
    sizeKey,
    referenceData,
  ]);
  const [dataSet, setDataSet] = useState(originalDataSet);
  const [dataSetChanged, setDataSetChanged] = useState(false);
  const [maxPercentage, setMaxPercentage] = useState(getMaxDataset(dataSet));
  const [isEditing, startEdit, stopEdit] = useEditing();
  const [isHoverReset, setIsHoverReset] = useState<boolean>(false);
  const [saving, setSaving] = useState(false);
  const [resettable, setResettable] = useState(sizeKey.distributionType === DistributionType.MANUAL);
  const distributionRef = useRef<SizeDistribution>({
    distribution: data,
    distributionType: sizeKey.distributionType || DistributionType.NONE,
    recoLevel: sizeKey.recommendationLevel,
    recoStrategy: sizeKey.recommendationStrategy,
  });
  const tableRef = useRef<HTMLDivElement>(null);

  // Update matrix on prop change
  useEffect(() => {
    const newOriginalDataSet = getMatrixDataset(sizeKey, data);
    setDataSet(newOriginalDataSet);
    setMaxPercentage(getMaxDataset(newOriginalDataSet));
    distributionRef.current = {
      distribution: data,
      distributionType: sizeKey.distributionType || DistributionType.NONE,
      recoLevel: sizeKey.recommendationLevel,
      recoStrategy: sizeKey.recommendationStrategy,
    };
  }, [sizeKey, data, Object.values(data).join(',')]);

  const onCellChange = useCallback(
    (row: string, column: string, value: number, text?: string): void => {
      let newDataSet = dataSet;
      if (text) {
        try {
          const parsedData = parsePastedData(text, dataIsPercentage);
          newDataSet = updateMatrixDatasetRange(dataSet, sizeKey, row, column, parsedData, dataIsPercentage);
        } catch (e) {
          Message.error(`Can't paste ${dataIsPercentage ? 'distribution' : 'quantities'}: invalid data`);
        }
      } else {
        newDataSet = updateMatrixDataset(dataSet, row, column, value);
      }
      setDataSet(newDataSet);
      setMaxPercentage(getMaxDataset(newDataSet));
      setDataSetChanged(!isEqual(originalDataSet, newDataSet));
      const newDistribution = getDistribution(newDataSet, is2D ? sizeKey.sizeLengthSeparator : '');
      const isChanged = !isEqual(newDistribution, distributionRef.current?.distribution);
      if (dataIsPercentage && isChanged) {
        setResettable(true);
      }
      if (referenceData && onChangeDistributionWhileComparing) {
        const sizeDist: SizeDistribution = {
          distribution: newDistribution,
          distributionType: isChanged ? DistributionType.MANUAL : distributionRef.current?.distributionType,
          recoLevel: isChanged ? undefined : distributionRef.current?.recoLevel,
          recoStrategy: isChanged ? undefined : distributionRef.current?.recoStrategy,
        };
        onChangeDistributionWhileComparing(sizeDist, newDataSet);
      }
    },
    [dataIsPercentage, dataSet, is2D, onChangeDistributionWhileComparing, originalDataSet, referenceData, sizeKey]
  );
  const onCancelClick = () => {
    if (dataSetChanged) {
      setDataSet(getMatrixDataset(sizeKey, data));
      setDataSetChanged(false);
      setResettable(sizeKey.distributionType === DistributionType.MANUAL);
    }
    stopEdit();
    if (onCancel) {
      onCancel();
    }
  };
  const onSaveClickHandler = () => {
    if (dataIsPercentage && numberOfEditedArticles > 0) {
      QuantitiesOverwriteModal(numberOfEditedArticles, onSaveClick);
    } else {
      onSaveClick();
    }
  };
  const onSaveClick = useCallback(async () => {
    setSaving(true);
    if (onSizeDistributionChange && dataSetChanged) {
      try {
        const distribution = getDistribution(dataSet, is2D ? sizeKey.sizeLengthSeparator : '');
        const isChanged = !isEqual(distribution, distributionRef.current?.distribution);
        const sizeDist: SizeDistribution = {
          distribution,
          distributionType: isChanged ? DistributionType.MANUAL : distributionRef.current?.distributionType,
          recoLevel: isChanged ? undefined : distributionRef.current?.recoLevel,
          recoStrategy: isChanged ? undefined : distributionRef.current?.recoStrategy,
        };
        await onSizeDistributionChange(sizeDist);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    setSaving(false);
    stopEdit();
    setDataSetChanged(false);
  }, [dataSetChanged, dataSet, is2D, onSizeDistributionChange, sizeKey.sizeLengthSeparator, stopEdit]);
  const openCompareDistributionDialogClick = () => {
    if (openCompare) {
      openCompare();
    }
  };
  const onAdjustTotalClick = (total: number) => {
    onAdjustTotal && onAdjustTotal(total, getDistribution(dataSet, is2D ? sizeKey.sizeLengthSeparator : ''));
    stopEdit();
    setDataSetChanged(false);
  };

  const onScaleDistributionClick = useCallback(async () => {
    const scaledDistribution = distributionWith100Percent(getDistribution(dataSet, sizeKey.sizeLengthSeparator || ''));
    const updatedDataSet = getMatrixDataset(sizeKey, scaledDistribution);
    setDataSet(updatedDataSet);
  }, [dataSet, sizeKey]);
  const showActions = !referenceData && dataSetChanged;
  const isSiloReco = sizeKey.distributionType === DistributionType.SILO;
  const showCompareButton =
    (resettable || hasReferenceBrand || hasReusedDecision || isSiloReco) &&
    dataIsPercentage &&
    groupHeaderHover &&
    !showActions;
  const showCopyPasteButtons = dataIsPercentage && groupHeaderHover && !showActions;
  const onCopyDistribution = async () => {
    //  Handle copy distribution
    await navigator.clipboard.writeText(createCopiableMatrix(getDataSetValues(dataSet, sizeKey)));
    Message.success('Distribution copied', 3);
  };
  const [autoSaveTrigger, setAutoSaveTrigger] = useState(false);
  const onPasteDistribution = async () => {
    //  Handle paste distribution
    const text = await navigator.clipboard.readText();
    const [sizeStart, lengthStart = '0'] = sizeKey.sizeKey[0].split(sizeKey?.sizeLengthSeparator || ' '); // cannot be empty string to avoid splitting 1d sizes
    onCellChange(lengthStart, sizeStart, 0, text);

    setAutoSaveTrigger(true);
  };
  useEffect(() => {
    if (autoSaveTrigger && dataSetChanged) {
      const total = sumMatrixDataset(dataSet);
      if (total === expectedTotal) {
        onSaveClick();
      }
      Message.success('Distribution pasted', 3);
      setAutoSaveTrigger(false);
    }
  }, [autoSaveTrigger, dataSetChanged]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setResettable(sizeKey.distributionType === DistributionType.MANUAL);
  }, [sizeKey.distributionType]);
  useDeepCompareEffect(() => {
    if (onChangeDistributionWhileComparing) {
      const newDistribution = getDistribution(dataSet, is2D ? sizeKey.sizeLengthSeparator : '');
      const isChanged = !isEqual(distributionRef.current?.distribution, newDistribution);
      const sizeDist: SizeDistribution = {
        distribution: newDistribution,
        distributionType: isChanged ? DistributionType.MANUAL : distributionRef.current?.distributionType,
        recoLevel: isChanged ? undefined : distributionRef.current?.recoLevel,
        recoStrategy: isChanged ? undefined : distributionRef.current?.recoStrategy,
      };
      onChangeDistributionWhileComparing(sizeDist, dataSet);
    }
  }, [dataSet]); // eslint-disable-line react-hooks/exhaustive-deps
  const columns = getMatrixColumns(
    sizeKey,
    is2D
      ? render2dCell(
          maxPercentage,
          dataIsPercentage,
          highlighted,
          referenceDataSet,
          expectedTotal,
          sizeDivisorForValidation
        )
      : render1dCell(
          maxPercentage,
          dataIsPercentage,
          highlighted,
          referenceDataSet,
          expectedTotal,
          sizeDivisorForValidation
        ),
    {
      editable: !readOnly,
      dataIsPercentage,
      onCellChange,
      tableRef,
      referenceId,
      selectionState,
      setSelectionState,
      clearSelectionState,
      groupKey,
    }
  );
  return (
    <div ref={ref || tableRef} className="table" onFocus={() => !isHoverReset && startEdit()} onBlur={stopEdit}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        className={`matrix-${is2D ? 2 : 1}d ${isEditing || dataSetChanged ? 'is-editing' : ''}`}
        data-testid={`matrix-${is2D ? 2 : 1}d`}
        columns={columns}
        dataSource={dataSet}
        pagination={false}
        bordered={true}
        showHeader={showTableHeader}
        size="small"
        scroll={onChangeDistributionWhileComparing ? { y: Number(maxScrollHeight) || undefined, x: 'max-width' } : {}}
      />
      {children}
      <div
        className="matrix-actions"
        onMouseEnter={() => {
          setIsHoverReset(true);
        }}
        onMouseLeave={() => {
          setIsHoverReset(false);
        }}
      >
        {!readOnly && onSizingSelector && <EditSizes onClick={onSizingSelector} />}
        {onChangeDistributionWhileComparing && <div style={{ height: '39px' }} />}
        {showCompareButton && (
          <Button
            className="ant-btn-txt-default"
            size="small"
            type="text"
            icon={<BarChartOutlined data-testid="reset-button-percent" />}
            onClick={() => {
              openCompareDistributionDialogClick && openCompareDistributionDialogClick();
            }}
            data-testid="compare-to-reco-button"
          >
            Compare to reco
          </Button>
        )}
        {showCopyPasteButtons ? (
          <Tooltip title="Copy distribution" className="spacing" placement="bottom">
            <Button
              className="ant-btn-txt-default"
              size="small"
              type="text"
              icon={<CopyOutlined data-testid="copy-distribution" />}
              onClick={onCopyDistribution}
            >
              Copy
            </Button>
          </Tooltip>
        ) : null}
        {showCopyPasteButtons ? (
          <Tooltip title="Paste distribution" className="spacing" placement="bottom">
            <Button
              className="ant-btn-txt-default"
              size="small"
              type="text"
              icon={<SnippetsOutlined data-testid="paste-distribution" />}
              onClick={onPasteDistribution}
            >
              Paste
            </Button>
          </Tooltip>
        ) : null}
        {showActions && (
          <Actions
            dataSet={dataSet}
            expectedTotal={expectedTotal}
            dataIsPercentage={dataIsPercentage}
            savable={dataSetChanged}
            saving={saving}
            onSave={onSaveClickHandler}
            onCancel={onCancelClick}
            onAdjustTotal={onAdjustTotalClick}
            onScaleDistribution={onScaleDistributionClick}
            isComparison={!!onChangeDistributionWhileComparing}
          />
        )}
      </div>
    </div>
  );
});

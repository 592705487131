import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from '@retail-core/rds';
import { observer } from 'mobx-react-lite';
import { BoxRestrictionsModal } from '../components/BoxRestrictions/BoxRestrictionsModal/BoxRestrictionsModal';
import { useStore } from '../components/StoreContext/StoreContext';
import { useClickOutside } from '../lib/use-click-outside';
import { NoLinkedSizeChartsError } from './NoLinkedSizeChartsError/NoLinkedSizeChartsError';
import { SizingGroupList } from './SizingGroupList/SizingGroupList';
import { Header } from '../components/Header/Header';
import { CgTree } from '../components/CgTree/CgTree';
import { TagEnvDeploy } from '../components/TagEnvDeploy/TagEnvDeploy';

import './Main.less';
import { BoxRestrictionCalculationModal } from '../components/BoxRestrictions/BoxRestrictionCalculationModal/BoxRestrictionCalculationModal';

export const Main: FC = observer(() => {
  const { sizing, uiStore } = useStore();
  const [showNonReviewed, setShowNonReviewed] = useState(false);
  useClickOutside();
  useEffect(() => {
    async function loadData() {
      if (sizing.enableBox) await sizing.getBoxRestrictions();
      await sizing.getReferenceBrands();
    }
    loadData();
  }, [
    sizing,
    sizing.articles.length, // we check the length of the articles to trigger sizing loading when articles are added/removed from buying home
  ]);

  useEffect(() => {
    uiStore.setInitialUIState(sizing.sizingGroups);
  }, [uiStore, sizing.sizingGroups]);

  return (
    <>
      {sizing.noLinkedSizeCharts ? (
        <NoLinkedSizeChartsError />
      ) : (
        <Row className="main-row">
          <Col span={4} sm={3} md={4} xxl={3} className="cg-tree-container">
            <CgTree showNonReviewed={showNonReviewed} />
          </Col>
          <Col span={20} sm={21} md={20} xxl={21}>
            <Header showNonReviewed={showNonReviewed} setShowNonReviewed={setShowNonReviewed} />
            <SizingGroupList showNonReviewed={showNonReviewed} />
          </Col>
        </Row>
      )}
      <BoxRestrictionCalculationModal />
      <BoxRestrictionsModal />
      <TagEnvDeploy />
    </>
  );
});

import * as Sentry from '@sentry/browser';
import { getEnv, isLocalDevelopment } from '../environment';

export type SentryLevel = 'debug' | 'info' | 'warning' | 'error' | 'fatal';

export function initSentry(): void {
  Sentry.init({
    dsn: `https://45003d535ae540fd9e480b118a2eff2b@sentry.stups.zalan.do/2376`,
    environment: isLocalDevelopment() ? 'DEV' : getEnv(),
  });
}

export function captureMessage(message: string): void {
  Sentry.captureMessage(message);
}

export function captureException(error: Error): void {
  Sentry.captureException(error);
}

import {
  Boxing,
  BoxingSizeDivisor,
  BoxOptimisationResponse,
  BoxRestriction,
  BoxRestrictionsResponse,
  BoxRestrictionType,
  LOTGroupBox,
  OrderType,
  SelectedBoxRestriction,
} from '../models/box-restriction';
import { Distribution, DistributionType, SizeKey, Sizing, SizingV2 } from '../models/sizing';
import { Article, SizingData } from '../models/sizing-data';

import { CgGroupingLevel, SizingGroup } from '../models/sizing-group';
import { ArticleResponse, GroupResponse, Order } from '../models/response';
import { ArticleInput } from '../models/request';
import { SizingStore } from '../stores/sizing/sizing-store';

export function randomString(minLength: number, maxLength: number): string {
  const max = Math.pow(16, maxLength);
  const min = Math.pow(16, minLength);
  return Math.round(Math.random() * (max - min) + min).toString(16);
}

export const distributionDummy = (modifier?: Partial<Distribution>): Distribution => ({
  XS: 1,
  S: 2,
  M: 5,
  L: 2,
  XL: 1,
  ...(modifier || {}),
});

export const boxingDummy = (modifier?: Partial<Boxing>): Boxing =>
  Object.assign(
    {
      boxRestrictionId: '1',
      boxRestrictionType: BoxRestrictionType.DIVISOR,
      deviation: 0,
      sizeDivisor: 5,
    } as BoxingSizeDivisor,
    modifier || {}
  );
export const articleDummy = (modifier?: Partial<Article>): Article =>
  Object.assign(
    {
      commodityGroup: {
        code: '2-1-E',
        codes: ['2', '2-1', '2-1-E'],
        names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
      },
      quantity: 1500,
      referenceId: randomString(1, 8),
      reviewed: false,
      sizeStructure: 'XS-L',
      sizingNote: 'wide',
      supplierArticleName: 'sample article',
      supplierArticleNumber: 'crazy number 1',
      supplierColorCode: 'FANCY BLACK',
      supplierColorDescription: 'FANCY BLACK DESSERT BLA',
      comment: 'I am a dummyArticle',
      zalandoColorCode: '800',
      sizeChartEditable: true,
    },
    modifier || {}
  );

export const articleInputDummy = (modifier?: Partial<ArticleInput & { id: string }>): ArticleInput => {
  const input = new ArticleInput(randomString(1, 8), '2-1-E', 1500);
  input.buyingArticleConfigId = '1234';
  input.quantity = 1500;
  input.sizeStructure = 'XS-L';
  input.sizingNote = 'wide';
  input.lengthChartCode = '750';
  input.sizeChartEditable = true;
  return Object.assign(input, modifier || {});
};

export const sizingDataDummy = (modifier?: Partial<SizingData>): SizingData =>
  Object.assign(
    {
      referenceSystem: 'buying-home',
      referenceId: 'Z2100091',
      brandCode: 'VE1',
      supplierCode: 'K70240',
      subunitCode: 'SUNIT261',
      seasonCode: 'FS21',
      groupingCgLevel: CgGroupingLevel.CG5,
      enableBox: false,
      enableBoxAvailability: true,
      readOnly: false,
      lastDownloaded: '2021-07-12T09:27:31.956271+02:00',
      lastModified: '2021-07-12T09:27:31.956271+02:00',
      articles: [
        {
          referenceId: '14224',
          supplierArticleName: 'BH-ATTR--ALL-TOP-LINE',
          supplierArticleNumber: '215132125415',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: undefined,
          commodityGroup: {
            code: '2-1-C-08-01',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08', '2-1-C-08-01'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses', '123'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14225',
          supplierArticleName: 'BH-ATTR--ALL-TOP-AND-BOTTOM-LINE',
          supplierArticleNumber: '123125415123',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: '',
          commodityGroup: {
            code: '2-1-C-08-02',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08', '2-1-C-08-02'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses', '124'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14226',
          supplierArticleName: 'BH-ATTR--ALL-TOP-AND-BOTTOM-LINE',
          supplierArticleNumber: '123125415123',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'Best seller article',
          commodityGroup: {
            code: '2-1-C-08-03',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08', '2-1-C-08-03'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses', '125'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14227',
          supplierArticleName: 'BH-ATTR--ALL-NOT-DISPLAYED',
          supplierArticleNumber: '123123145152',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: undefined,
          commodityGroup: {
            code: '2-1-C-08-04',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08', '2-1-C-08-04'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses', '126'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14228',
          supplierArticleName: 'BH-ATTR--Age-Group-Adult',
          supplierArticleNumber: '2131451513',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08-05',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08', '2-1-C-08-05'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses', '127'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14229',
          supplierArticleName: 'BH-ATTR--Age-Group-Baby',
          supplierArticleNumber: '3245234234',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08-06',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08', '2-1-C-08-06'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses', '128'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14230',
          supplierArticleName: 'BH-ATTR--Age-Group-Kid',
          supplierArticleNumber: '64049659',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08-07',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08', '2-1-C-08-07'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses', '129'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14231',
          supplierArticleName: 'BH-ATTR--Age-Group-Teenager',
          supplierArticleNumber: '1177832380',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-C', codes: ['2', '2-1', '2-1-C'], names: ['Clothing', 'Women', 'Dresses'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14232',
          supplierArticleName: 'BH-ATTR--Age-Group-Kid, Teenager',
          supplierArticleNumber: '2291615101',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-05',
            codes: ['2', '2-1', '2-1-C', '2-1-C-05'],
            names: ['Clothing', 'Women', 'Dresses', 'Smart'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14233',
          supplierArticleName: 'BH-ATTR--Age-Group-Baby, Kid',
          supplierArticleNumber: '3405397822',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-05',
            codes: ['2', '2-1', '2-1-C', '2-1-C-05'],
            names: ['Clothing', 'Women', 'Dresses', 'Smart'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14234',
          supplierArticleName: 'BH-ATTR--Age-Group-Baby, Kid, Teenager',
          supplierArticleNumber: '224213247',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-C', codes: ['2', '2-1', '2-1-C'], names: ['Clothing', 'Women', 'Dresses'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14235',
          supplierArticleName: 'BH-ATTR--Key-Style-Flag-Yes',
          supplierArticleNumber: '1337995968',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14236',
          supplierArticleName: 'BH-ATTR--Key-Style-Flag-no',
          supplierArticleNumber: '2451778689',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14237',
          supplierArticleName: 'BH-ATTR--Key-Style-Date-01.01.2020-empty',
          supplierArticleNumber: '729405886',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E-Z',
            codes: ['2', '2-1', '2-1-E', '2-1-E-Z'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops', 'Shirts'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14238',
          supplierArticleName: 'BH-ATTR--Key-Style-Date-01.01.2020-yes',
          supplierArticleNumber: '384376835',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E-Z',
            codes: ['2', '2-1', '2-1-E', '2-1-E-Z'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops', 'Shirts'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14239',
          supplierArticleName: 'BH-ATTR--Key-Style-Date-01.01.2020-no',
          supplierArticleNumber: '1498159556',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E-Z',
            codes: ['2', '2-1', '2-1-E', '2-1-E-Z'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops', 'Shirts'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14240',
          supplierArticleName: 'BH-ATTR--Special-Mark-Ups-Yes',
          supplierArticleNumber: '2611942277',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E-Z',
            codes: ['2', '2-1', '2-1-E', '2-1-E-Z'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops', 'Shirts'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14241',
          supplierArticleName: 'BH-ATTR--Special-Mark-Ups-No',
          supplierArticleNumber: '569242298',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14242',
          supplierArticleName: 'BH-ATTR--Supplier Availability (Type)-Regular',
          supplierArticleNumber: '544540423',
          supplierColorCode: '111444',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14243',
          supplierArticleName: 'BH-ATTR--Supplier Availability (Type)-NOS',
          supplierArticleNumber: '1658323144',
          supplierColorCode: '111555',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14244',
          supplierArticleName: 'BH-ATTR--Supplier Availability (Type)-CarryOver',
          supplierArticleNumber: '2772105865',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14245',
          supplierArticleName: 'BH-ATTR--Sustainabilty-Yes',
          supplierArticleNumber: '409078710',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14246',
          supplierArticleName: 'BH-ATTR--Sustainabilty-No',
          supplierArticleNumber: '704704011',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14247',
          supplierArticleName: 'BH-ATTR--Multipack-Yes',
          supplierArticleNumber: '12312415551',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14248',
          supplierArticleName: 'BH-ATTR--AssortmentType - Catalog',
          supplierArticleNumber: '5165324635',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14249',
          supplierArticleName: 'BH-ATTR--AssortmentType -Maternity',
          supplierArticleNumber: '1235456436',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14250',
          supplierArticleName: 'BH-ATTR--AssortmentType - Petite',
          supplierArticleNumber: '34663767345',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14251',
          supplierArticleName: 'BH-ATTR--AssortmentType - Plus Size',
          supplierArticleNumber: '3425374373',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-N-09',
            codes: ['2', '2-1', '2-1-N', '2-1-N-09'],
            names: ['Clothing', 'Women', 'Jeans', 'Cropped'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14252',
          supplierArticleName: 'BH-ATTR--AssortmentType - Tall',
          supplierArticleNumber: '345637345345',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-N-09',
            codes: ['2', '2-1', '2-1-N', '2-1-N-09'],
            names: ['Clothing', 'Women', 'Jeans', 'Cropped'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14253',
          supplierArticleName: 'BH-ATTR--Pattern-ASIAN',
          supplierArticleNumber: '1123424131123',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-N-09',
            codes: ['2', '2-1', '2-1-N', '2-1-N-09'],
            names: ['Clothing', 'Women', 'Jeans', 'Cropped'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14254',
          supplierArticleName: 'BH-ATTR--Comment',
          supplierArticleNumber: '1251243123a',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-I', codes: ['2', '2-1', '2-1-I'], names: ['Clothing', 'Women', 'Knitwear'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14255',
          supplierArticleName: 'BH-ATTR--Comment-Whithout-Special-Sign',
          supplierArticleNumber: '123145154a',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-D', codes: ['2', '2-1', '2-1-D'], names: ['Clothing', 'Women', 'Jersey'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14256',
          supplierArticleName: 'BH-ATTR--ALL-TOP-LINE',
          supplierArticleNumber: '215132125415',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'blaa',
          sizingNote: 'stretch',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14257',
          supplierArticleName: 'BH-ATTR--ALL-TOP-AND-BOTTOM-LINE',
          supplierArticleNumber: '123125415123',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'blaa',
          sizingNote: 'stretch',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14258',
          supplierArticleName: 'BH-ATTR--ALL-TOP-AND-BOTTOM-LINE',
          supplierArticleNumber: '123125415123',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'blaa',
          sizingNote: 'stretch',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14259',
          supplierArticleName: 'BH-ATTR--ALL-NOT-DISPLAYED',
          supplierArticleNumber: '123123145152',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'bliii',
          sizingNote: 'stretch',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14260',
          supplierArticleName: 'BH-ATTR--Age-Group-Adult',
          supplierArticleNumber: '2131451513',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'bliii',
          sizingNote: 'stretch',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14261',
          supplierArticleName: 'BH-ATTR--Age-Group-Baby',
          supplierArticleNumber: '3245234234',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'bliii',
          sizingNote: 'stretch',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14262',
          supplierArticleName: 'BH-ATTR--Age-Group-Kid',
          supplierArticleNumber: '64049659',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'bloo',
          sizingNote: 'stretch',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14263',
          supplierArticleName: 'BH-ATTR--Age-Group-Teenager',
          supplierArticleNumber: '1177832380',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-C', codes: ['2', '2-1', '2-1-C'], names: ['Clothing', 'Women', 'Dresses'] },
          sizeStructure: 'bloo',
          sizingNote: 'stretch',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14264',
          supplierArticleName: 'BH-ATTR--Age-Group-Kid, Teenager',
          supplierArticleNumber: '2291615101',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-05',
            codes: ['2', '2-1', '2-1-C', '2-1-C-05'],
            names: ['Clothing', 'Women', 'Dresses', 'Smart'],
          },
          sizeStructure: 'bloo',
          sizingNote: 'stretch',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14265',
          supplierArticleName: 'BH-ATTR--Age-Group-Baby, Kid',
          supplierArticleNumber: '3405397822',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-05',
            codes: ['2', '2-1', '2-1-C', '2-1-C-05'],
            names: ['Clothing', 'Women', 'Dresses', 'Smart'],
          },
          sizeStructure: 'bloo',
          sizingNote: 'stretch',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14266',
          supplierArticleName: 'BH-ATTR--Age-Group-Baby, Kid, Teenager',
          supplierArticleNumber: '224213247',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-C', codes: ['2', '2-1', '2-1-C'], names: ['Clothing', 'Women', 'Dresses'] },
          sizeStructure: 'bloo',
          sizingNote: 'stretch',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14267',
          supplierArticleName: 'BH-ATTR--Key-Style-Flag-Yes',
          supplierArticleNumber: '1337995968',
          supplierColorCode: '111555',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '21312',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14268',
          supplierArticleName: 'BH-ATTR--Key-Style-Flag-no',
          supplierArticleNumber: '2451778689',
          supplierColorCode: '111444',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '21312',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14269',
          supplierArticleName: 'BH-ATTR--Key-Style-Date-01.01.2020-empty',
          supplierArticleNumber: '729405886',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E-Z',
            codes: ['2', '2-1', '2-1-E', '2-1-E-Z'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops', 'Shirts'],
          },
          sizeStructure: '21312',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
          sizing: distributionDummy(),
        },
        {
          referenceId: '14270',
          supplierArticleName: 'BH-ATTR--Key-Style-Date-01.01.2020-yes',
          supplierArticleNumber: '384376835',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E-Z',
            codes: ['2', '2-1', '2-1-E', '2-1-E-Z'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops', 'Shirts'],
          },
          sizeStructure: '21312',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14271',
          supplierArticleName: 'BH-ATTR--Key-Style-Date-01.01.2020-no',
          supplierArticleNumber: '1498159556',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E-Z',
            codes: ['2', '2-1', '2-1-E', '2-1-E-Z'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops', 'Shirts'],
          },
          sizeStructure: 'adsad',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14272',
          supplierArticleName: 'BH-ATTR--Special-Mark-Ups-Yes',
          supplierArticleNumber: '2611942277',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E-Z',
            codes: ['2', '2-1', '2-1-E', '2-1-E-Z'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops', 'Shirts'],
          },
          sizeStructure: '1231',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14273',
          supplierArticleName: 'BH-ATTR--Special-Mark-Ups-No',
          supplierArticleNumber: '569242298',
          supplierColorCode: '111444',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '1231',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14274',
          supplierArticleName: 'BH-ATTR--Supplier Availability (Type)-Regular',
          supplierArticleNumber: '544540423',
          supplierColorCode: '111555',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '1231',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14275',
          supplierArticleName: 'BH-ATTR--Supplier Availability (Type)-NOS',
          supplierArticleNumber: '1658323144',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '1231',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14276',
          supplierArticleName: 'BH-ATTR--Supplier Availability (Type)-CarryOver',
          supplierArticleNumber: '2772105865',
          supplierColorCode: '111444',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '1231',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14277',
          supplierArticleName: 'BH-ATTR--Sustainabilty-Yes',
          supplierArticleNumber: '409078710',
          supplierColorCode: '111444',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '1231',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14278',
          supplierArticleName: 'BH-ATTR--Sustainabilty-No',
          supplierArticleNumber: '704704011',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14279',
          supplierArticleName: 'BH-ATTR--Multipack-Yes',
          supplierArticleNumber: '12312415551',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14280',
          supplierArticleName: 'BH-ATTR--AssortmentType - Catalog',
          supplierArticleNumber: '5165324635',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14281',
          supplierArticleName: 'BH-ATTR--AssortmentType -Maternity',
          supplierArticleNumber: '1235456436',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: '44',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14282',
          supplierArticleName: 'BH-ATTR--AssortmentType - Petite',
          supplierArticleNumber: '34663767345',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: '44',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14283',
          supplierArticleName: 'BH-ATTR--AssortmentType - Plus Size',
          supplierArticleNumber: '3425374373',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-N-09',
            codes: ['2', '2-1', '2-1-N', '2-1-N-09'],
            names: ['Clothing', 'Women', 'Jeans', 'Cropped'],
          },
          sizeStructure: '666',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14284',
          supplierArticleName: 'BH-ATTR--AssortmentType - Tall',
          supplierArticleNumber: '345637345345',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-N-09',
            codes: ['2', '2-1', '2-1-N', '2-1-N-09'],
            names: ['Clothing', 'Women', 'Jeans', 'Cropped'],
          },
          sizeStructure: '666',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14285',
          supplierArticleName: 'BH-ATTR--Pattern-ASIAN',
          supplierArticleNumber: '1123424131123',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-N-09',
            codes: ['2', '2-1', '2-1-N', '2-1-N-09'],
            names: ['Clothing', 'Women', 'Jeans', 'Cropped'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14286',
          supplierArticleName: 'BH-ATTR--Comment',
          supplierArticleNumber: '1251243123a',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-I', codes: ['2', '2-1', '2-1-I'], names: ['Clothing', 'Women', 'Knitwear'] },
          sizeStructure: 'bi',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14287',
          supplierArticleName: 'BH-ATTR--Comment-Whithout-Special-Sign',
          supplierArticleNumber: '123145154a',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-D', codes: ['2', '2-1', '2-1-D'], names: ['Clothing', 'Women', 'Jersey'] },
          sizeStructure: 'bi',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14288',
          supplierArticleName: 'BH-ATTR--ALL-TOP-LINE',
          supplierArticleNumber: '215132125415',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'blaa',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14289',
          supplierArticleName: 'BH-ATTR--ALL-TOP-AND-BOTTOM-LINE',
          supplierArticleNumber: '123125415123',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'blaa',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14290',
          supplierArticleName: 'BH-ATTR--ALL-TOP-AND-BOTTOM-LINE',
          supplierArticleNumber: '123125415123',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'blaa',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14291',
          supplierArticleName: 'BH-ATTR--ALL-NOT-DISPLAYED',
          supplierArticleNumber: '123123145152',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'bliii',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14292',
          supplierArticleName: 'BH-ATTR--Age-Group-Adult',
          supplierArticleNumber: '2131451513',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'bliii',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14293',
          supplierArticleName: 'BH-ATTR--Age-Group-Baby',
          supplierArticleNumber: '3245234234',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'bliii',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14294',
          supplierArticleName: 'BH-ATTR--Age-Group-Kid',
          supplierArticleNumber: '64049659',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-08',
            codes: ['2', '2-1', '2-1-C', '2-1-C-08'],
            names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
          },
          sizeStructure: 'bloo',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14295',
          supplierArticleName: 'BH-ATTR--Age-Group-Teenager',
          supplierArticleNumber: '1177832380',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-C', codes: ['2', '2-1', '2-1-C'], names: ['Clothing', 'Women', 'Dresses'] },
          sizeStructure: 'bloo',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14296',
          supplierArticleName: 'BH-ATTR--Age-Group-Kid, Teenager',
          supplierArticleNumber: '2291615101',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-05',
            codes: ['2', '2-1', '2-1-C', '2-1-C-05'],
            names: ['Clothing', 'Women', 'Dresses', 'Smart'],
          },
          sizeStructure: 'bloo',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14297',
          supplierArticleName: 'BH-ATTR--Age-Group-Baby, Kid',
          supplierArticleNumber: '3405397822',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-C-05',
            codes: ['2', '2-1', '2-1-C', '2-1-C-05'],
            names: ['Clothing', 'Women', 'Dresses', 'Smart'],
          },
          sizeStructure: 'bloo',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14298',
          supplierArticleName: 'BH-ATTR--Age-Group-Baby, Kid, Teenager',
          supplierArticleNumber: '224213247',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-C', codes: ['2', '2-1', '2-1-C'], names: ['Clothing', 'Women', 'Dresses'] },
          sizeStructure: 'bloo',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14299',
          supplierArticleName: 'BH-ATTR--Key-Style-Flag-Yes',
          supplierArticleNumber: '1337995968',
          supplierColorCode: '111444',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '21312',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14300',
          supplierArticleName: 'BH-ATTR--Key-Style-Flag-no',
          supplierArticleNumber: '2451778689',
          supplierColorCode: '111555',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '21312',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14301',
          supplierArticleName: 'BH-ATTR--Key-Style-Date-01.01.2020-empty',
          supplierArticleNumber: '729405886',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E-Z',
            codes: ['2', '2-1', '2-1-E', '2-1-E-Z'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops', 'Shirts'],
          },
          sizeStructure: '21312',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14302',
          supplierArticleName: 'BH-ATTR--Key-Style-Date-01.01.2020-yes',
          supplierArticleNumber: '384376835',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E-Z',
            codes: ['2', '2-1', '2-1-E', '2-1-E-Z'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops', 'Shirts'],
          },
          sizeStructure: '21312',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14303',
          supplierArticleName: 'BH-ATTR--Key-Style-Date-01.01.2020-no',
          supplierArticleNumber: '1498159556',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E-Z',
            codes: ['2', '2-1', '2-1-E', '2-1-E-Z'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops', 'Shirts'],
          },
          sizeStructure: 'adsad',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14304',
          supplierArticleName: 'BH-ATTR--Special-Mark-Ups-Yes',
          supplierArticleNumber: '2611942277',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E-Z',
            codes: ['2', '2-1', '2-1-E', '2-1-E-Z'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops', 'Shirts'],
          },
          sizeStructure: '1231',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14305',
          supplierArticleName: 'BH-ATTR--Special-Mark-Ups-No',
          supplierArticleNumber: '569242298',
          supplierColorCode: '111555',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '1231',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          silo: 'SiloA',
        },
        {
          referenceId: '14306',
          supplierArticleName: 'BH-ATTR--Supplier Availability (Type)-Regular',
          supplierArticleNumber: '544540423',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '1231',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          silo: 'SiloA',
        },
        {
          referenceId: '14307',
          supplierArticleName: 'BH-ATTR--Supplier Availability (Type)-NOS',
          supplierArticleNumber: '1658323144',
          supplierColorCode: '111444',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '1231',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          silo: 'SiloA',
        },
        {
          referenceId: '14308',
          supplierArticleName: 'BH-ATTR--Supplier Availability (Type)-CarryOver',
          supplierArticleNumber: '2772105865',
          supplierColorCode: '111555',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '1231',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          silo: 'SiloA',
        },
        {
          referenceId: '14309',
          supplierArticleName: 'BH-ATTR--Sustainabilty-Yes',
          supplierArticleNumber: '409078710',
          supplierColorCode: '111555',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-E',
            codes: ['2', '2-1', '2-1-E'],
            names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
          },
          sizeStructure: '1231',
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
          silo: 'SiloA',
        },
        {
          referenceId: '14310',
          supplierArticleName: 'BH-ATTR--Sustainabilty-No',
          supplierArticleNumber: '704704011',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14311',
          supplierArticleName: 'BH-ATTR--Multipack-Yes',
          supplierArticleNumber: '12312415551',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14312',
          supplierArticleName: 'BH-ATTR--AssortmentType - Catalog',
          supplierArticleNumber: '5165324635',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14313',
          supplierArticleName: 'BH-ATTR--AssortmentType -Maternity',
          supplierArticleNumber: '1235456436',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: '44',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14314',
          supplierArticleName: 'BH-ATTR--AssortmentType - Petite',
          supplierArticleNumber: '34663767345',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-N', codes: ['2', '2-1', '2-1-N'], names: ['Clothing', 'Women', 'Jeans'] },
          sizeStructure: '44',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14315',
          supplierArticleName: 'BH-ATTR--AssortmentType - Plus Size',
          supplierArticleNumber: '3425374373',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-N-09',
            codes: ['2', '2-1', '2-1-N', '2-1-N-09'],
            names: ['Clothing', 'Women', 'Jeans', 'Cropped'],
          },
          sizeStructure: '666',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14316',
          supplierArticleName: 'BH-ATTR--AssortmentType - Tall',
          supplierArticleNumber: '345637345345',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-N-09',
            codes: ['2', '2-1', '2-1-N', '2-1-N-09'],
            names: ['Clothing', 'Women', 'Jeans', 'Cropped'],
          },
          sizeStructure: '666',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14317',
          supplierArticleName: 'BH-ATTR--Pattern-ASIAN',
          supplierArticleNumber: '1123424131123',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: {
            code: '2-1-N-09',
            codes: ['2', '2-1', '2-1-N', '2-1-N-09'],
            names: ['Clothing', 'Women', 'Jeans', 'Cropped'],
          },
          sizeStructure: null,
          sizingNote: null,
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14318',
          supplierArticleName: 'BH-ATTR--Comment',
          supplierArticleNumber: '1251243123a',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-I', codes: ['2', '2-1', '2-1-I'], names: ['Clothing', 'Women', 'Knitwear'] },
          sizeStructure: 'bi',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
        {
          referenceId: '14319',
          supplierArticleName: 'BH-ATTR--Comment-Whithout-Special-Sign',
          supplierArticleNumber: '123145154a',
          supplierColorCode: '111333',
          supplierColorDescription: 'black',
          comment: 'This is a dummy comment',
          commodityGroup: { code: '2-1-D', codes: ['2', '2-1', '2-1-D'], names: ['Clothing', 'Women', 'Jersey'] },
          sizeStructure: 'bi',
          sizingNote: 'wide',
          quantity: 24,
          sizeChartEditable: true,
        },
      ],
    },
    modifier || {}
  );

export const sizingDataLocal = (): SizingData =>
  Object.assign(sizingDataDummy(), {
    ...sizingDataDummy(),
    referenceId: 'Z1234567',
    brandCode: 'AD1',
    seasonCode: 'HW24',
    articles: [
      {
        referenceId: '14224',
        supplierArticleName: 'BH-ATTR--ALL-TOP-LINE',
        supplierArticleNumber: '215132125415',
        supplierColorCode: '111333',
        supplierColorDescription: 'black',
        comment: undefined,
        commodityGroup: {
          code: '1-5-O-01',
          codes: ['1', '1-5', '1-5-O', '1-5-O-01'],
          names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
        },
        sizeStructure: null,
        sizingNote: null,
        quantity: 24,
        sizeChartEditable: true,
        sizing: distributionDummy(),
      },
      {
        referenceId: '14225',
        supplierArticleName: 'BH-ATTR--ALL-TOP-LINE2',
        supplierArticleNumber: '215132125416',
        supplierColorCode: '111333',
        supplierColorDescription: 'black',
        comment: undefined,
        commodityGroup: {
          code: '1-5-O-01',
          codes: ['1', '1-5', '1-5-O', '1-5-O-01'],
          names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
        },
        sizeStructure: null,
        sizingNote: null,
        quantity: 100,
        sizeChartEditable: true,
        sizing: distributionDummy(),
      },
      {
        referenceId: '14226',
        supplierArticleName: 'BH-ATTR--ALL-TOP-LINE3',
        supplierArticleNumber: '215132125417',
        supplierColorCode: '111333',
        supplierColorDescription: 'black',
        comment: undefined,
        commodityGroup: {
          code: '1-5-O-01',
          codes: ['1', '1-5', '1-5-O', '1-5-O-01'],
          names: ['Clothing', 'Women', 'Dresses', 'Dirndl Dresses'],
        },
        sizeStructure: null,
        sizingNote: null,
        quantity: 200,
        sizeChartEditable: true,
        sizing: distributionDummy(),
      },
    ],
  });

export const sizingGroupDummy = (modifier?: Partial<SizingGroup>): SizingGroup => {
  const group = new SizingGroup(
    '1',
    'gk2-1-E-Z',
    CgGroupingLevel.CG5,
    {
      code: '2-1-E',
      codes: ['2', '2-1', '2-1-E'],
      names: ['Clothing', 'Women', 'Blouses / Woven Tops'],
    },
    'XS-L',
    'wide',
    [articleDummy()],
    {
      sizeChartId: '2FAE000A2A',
      sizeChartCode: '2FAE000A2A',
      sizeList: [
        'XXS',
        'XXS/XS',
        'XS',
        'XS/S',
        'S',
        'S/M',
        'M',
        'M/L',
        'L',
        'L/XL',
        'XL',
        'XL/XXL',
        'XXL',
        'XXL/3XL',
        '3XL',
        '3XL/4XL',
        '4XL',
        '4XL/5XL',
        '5XL',
        '5XL/6XL',
        '6XL',
        '6XL/7XL',
      ],
      lengthChartCode: undefined,
      lengthList: undefined,
      sizeLengthSeparator: '',
      description: 'Textil , Women, US [3XS - 6XL/7XL]',
      sizeKeys: [
        createSizeKey(['XS', 'S', 'M', 'L', 'XL'], undefined, { XS: 12.4, S: 27.1, M: 30.6, L: 19.2, XL: 10.7 }),
        createSizeKey(['XS', 'S', 'M', 'L']),
        createSizeKey(['XS', 'S', 'M', 'XL']),
        createSizeKey(['S', 'M', 'L', 'XL']),
        createSizeKey(['S', 'M', 'L']),
      ],
    },
    createSizeKey(['XS', 'S', 'M', 'L', 'XL'], undefined, {
      XS: 12.4,
      S: 27.1,
      M: 30.6,
      L: 19.2,
      XL: 10.7,
    }),
    sizingDummy()
  );
  return Object.assign(group, modifier || {});
};

export const sizingGroupDummy2D = (modifier?: Partial<SizingGroup>): SizingGroup => {
  const selectedSizeChart = {
    sizeChartId: '2FHW000A2B;2FHU000U3A',
    sizeChartCode: '2FHW000A2B',
    sizeList: [
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
      '32',
      '33',
      '34',
      '35',
      '36',
      '37',
      '38',
      '39',
      '40',
      '41',
      '42',
      '43',
      '44',
      '45',
      '46',
      '47',
      '48',
      '49',
      '50',
      '52',
      '54',
    ],
    lengthChartCode: '2FHU000U3A',
    lengthList: ['24', '25', '26', '27', '28', '30', '32', '34', '36', '37', '38'],
    sizeLengthSeparator: 'x',
    description: 'Appearal, Woman, Trouserwidth US [21-54]',
    sizeKeys: [
      createSizeKey(
        ['23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34'],
        ['28', '30', '32', '34', '36'],
        {
          '23x28': 1.1,
          '23x30': 0.7,
          '23x32': 0.3,
          '23x34': 0.1,
          '23x36': 0,
          '24x28': 1.7,
          '24x30': 1.6,
          '24x32': 0.7,
          '24x34': 0.1,
          '24x36': 0,
          '25x28': 2.4,
          '25x30': 3,
          '25x32': 1.4,
          '25x34': 0.3,
          '25x36': 0.1,
          '26x28': 2.2,
          '26x30': 4.5,
          '26x32': 2.5,
          '26x34': 1.1,
          '26x36': 0.2,
          '27x28': 3.4,
          '27x30': 5.9,
          '27x32': 4.8,
          '27x34': 1.4,
          '27x36': 0.5,
          '28x28': 2.9,
          '28x30': 5.8,
          '28x32': 5.6,
          '28x34': 1.9,
          '28x36': 0.9,
          '29x28': 1.4,
          '29x30': 3.7,
          '29x32': 5.1,
          '29x34': 2,
          '29x36': 1.1,
          '30x28': 1.1,
          '30x30': 2.2,
          '30x32': 3.6,
          '30x34': 1.9,
          '30x36': 1.1,
          '31x28': 1,
          '31x30': 1.6,
          '31x32': 2.9,
          '31x34': 1.3,
          '31x36': 1,
          '32x28': 0.5,
          '32x30': 1.3,
          '32x32': 1.8,
          '32x34': 1,
          '32x36': 0.6,
          '33x28': 0.3,
          '33x30': 1,
          '33x32': 1,
          '33x34': 0.7,
          '33x36': 0.2,
          '34x30': 1.2,
          '34x32': 1.1,
          '34x34': 0.8,
          '34x36': 0.4,
        },
        'x'
      ),
      createSizeKey(
        ['25', '26', '27', '28', '29', '30', '31', '32', '33', '34'],
        ['28', '30', '32', '34', '36'],
        {
          '25x28': 2.4,
          '25x30': 3,
          '25x32': 1.4,
          '25x34': 0.3,
          '25x36': 0.1,
          '26x28': 2.2,
          '26x30': 4.5,
          '26x32': 2.5,
          '26x34': 1.1,
          '26x36': 0.2,
          '27x28': 3.4,
          '27x30': 5.9,
          '27x32': 4.8,
          '27x34': 1.4,
          '27x36': 0.5,
          '28x28': 2.9,
          '28x30': 5.8,
          '28x32': 5.6,
          '28x34': 1.9,
          '28x36': 0.9,
          '29x28': 1.4,
          '29x30': 3.7,
          '29x32': 5.1,
          '29x34': 2,
          '29x36': 1.1,
          '30x28': 1.1,
          '30x30': 2.2,
          '30x32': 3.6,
          '30x34': 1.9,
          '30x36': 1.1,
          '31x28': 1,
          '31x30': 1.6,
          '31x32': 2.9,
          '31x34': 1.3,
          '31x36': 1,
          '32x28': 0.5,
          '32x30': 1.3,
          '32x32': 1.8,
          '32x34': 1,
          '32x36': 0.6,
          '33x28': 0.3,
          '33x30': 1,
          '33x32': 1,
          '33x34': 0.7,
          '33x36': 0.2,
          '34x30': 1.2,
          '34x32': 1.1,
          '34x34': 0.8,
          '34x36': 0.4,
        },
        'x'
      ),
      createSizeKey(
        ['23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34'],
        ['28', '30', '32', '34', '36'],
        undefined,
        'x'
      ),
      createSizeKey(
        ['22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36'],
        ['26', '28', '30', '32', '34', '36'],
        undefined,
        'x'
      ),
      createSizeKey(
        ['24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34'],
        ['30', '32', '34'],
        undefined,
        'x'
      ),
    ],
  };
  const selectedSizeKey = createSizeKey(
    ['25', '26', '27', '28', '29', '30', '31', '32', '33', '34'],
    ['28', '30', '32', '34', '36'],
    {
      '25x28': 2.4,
      '25x30': 3,
      '25x32': 1.4,
      '25x34': 0.3,
      '25x36': 0.1,
      '26x28': 2.2,
      '26x30': 4.5,
      '26x32': 2.5,
      '26x34': 1.1,
      '26x36': 0.2,
      '27x28': 3.4,
      '27x30': 5.9,
      '27x32': 4.8,
      '27x34': 1.4,
      '27x36': 0.5,
      '28x28': 2.9,
      '28x30': 5.8,
      '28x32': 5.6,
      '28x34': 1.9,
      '28x36': 0.9,
      '29x28': 1.4,
      '29x30': 3.7,
      '29x32': 5.1,
      '29x34': 2,
      '29x36': 1.1,
      '30x28': 1.1,
      '30x30': 2.2,
      '30x32': 3.6,
      '30x34': 1.9,
      '30x36': 1.1,
      '31x28': 1,
      '31x30': 1.6,
      '31x32': 2.9,
      '31x34': 1.3,
      '31x36': 1,
      '32x28': 0.5,
      '32x30': 1.3,
      '32x32': 1.8,
      '32x34': 1,
      '32x36': 0.6,
      '33x28': 0.3,
      '33x30': 1,
      '33x32': 1,
      '33x34': 0.7,
      '33x36': 0.2,
      '34x30': 1.2,
      '34x32': 1.1,
      '34x34': 0.8,
      '34x36': 0.4,
    },
    'x'
  );
  const group = Object.assign(sizingGroupDummy(), { selectedSizeChart, selectedSizeKey, sizing: sizingDummy2D() });
  return Object.assign(group, modifier || {});
};

export const sizingDummy = (modifier?: Partial<Sizing>): Sizing =>
  Object.assign(
    {
      sizingGroup: {
        groupKey: 'gk2-1-E-Z',
        commodityGroupCode: '2-1-E-Z',
      },
      reviewed: false,
      enableBox: false,
      selectedSizing: {
        sizeKeyId: 'XS;S;M;L;XL',
        sizeChartId: '2FAE000A2A',
      },
      recommendedSizing: {
        sizeKeyId: 'XS;S;M;L;XL',
        sizeChartId: '2FAE000A2A',
      },
      sizeChartList: [
        {
          sizeChartId: '2FAE000A2A',
          sizeChartCode: '2FAE000A2A',
          sizeList: [
            'XXS',
            'XXS/XS',
            'XS',
            'XS/S',
            'S',
            'S/M',
            'M',
            'M/L',
            'L',
            'L/XL',
            'XL',
            'XL/XXL',
            'XXL',
            'XXL/3XL',
            '3XL',
            '3XL/4XL',
            '4XL',
            '4XL/5XL',
            '5XL',
            '5XL/6XL',
            '6XL',
            '6XL/7XL',
          ],
          lengthChartCode: null,
          lengthList: null,
          sizeLengthSeparator: '',
          description: 'Textil , Women, US [3XS - 6XL/7XL]',
          sizeKeys: [
            createSizeKey(['XS', 'S', 'M', 'L', 'XL'], undefined, {
              L: 19.2,
              M: 30.6,
              S: 27.1,
              XL: 10.7,
              XS: 12.4,
            }),
            createSizeKey(['XS', 'S', 'M', 'L'], undefined, undefined, undefined, true),
            createSizeKey(['XS', 'S', 'M', 'XL']),
            createSizeKey(['S', 'M', 'L', 'XL']),
            createSizeKey(['S', 'M', 'L']),
            createSizeKey(['XS', 'XS/S', 'S', 'L', 'L/XL', 'XL', 'XL/XXL', 'XXL']),
            createSizeKey(['XS', 'XS/S', 'S']),
          ],
        },
        {
          sizeChartId: '5AAU000S4A',
          sizeChartCode: '5AAU000S4A',
          sizeList: ['One Size'],
          lengthChartCode: null,
          lengthList: null,
          sizeLengthSeparator: '',
          description: 'Description not available',
          sizeKeys: [],
        },
        {
          sizeChartId: '2FKO000S2A;2FHU000U3A',
          sizeChartCode: '2FKO000S2A',
          sizeList: [
            '3XS',
            'XXS',
            'XS',
            'S',
            'M',
            'L',
            'XL',
            'XXL',
            '3XL',
            '4XL',
            '5XL',
            '6XL',
            '7XL',
            '8XL',
            '9XL',
            '10X',
          ],
          lengthChartCode: '2FHU000U3A',
          lengthList: null,
          sizeLengthSeparator: '',
          description: 'Description not available',
          sizeKeys: [],
        },
        {
          sizeChartId: '2FKO000S2A',
          sizeChartCode: '2FKO000S2A',
          sizeList: [
            '3XS',
            'XXS',
            'XS',
            'S',
            'M',
            'L',
            'XL',
            'XXL',
            '3XL',
            '4XL',
            '5XL',
            '6XL',
            '7XL',
            '8XL',
            '9XL',
            '10X',
          ],
          lengthChartCode: null,
          lengthList: null,
          sizeLengthSeparator: '',
          description: 'Description not available',
          sizeKeys: [],
        },
        {
          sizeChartId: '2FP0PE3A2A',
          sizeChartCode: '2FP0PE3A2A',
          sizeList: ['XXS/XS', 'XS/S', 'S/M', 'M/L', 'L/XL', 'XL/XXL'],
          lengthChartCode: null,
          lengthList: null,
          sizeLengthSeparator: '',
          description: 'Clothing, Women, Double Sizes, PIECES, US [XXS/XS-XL/XXL]',
          sizeKeys: [],
        },
        {
          sizeChartId: '2FP1PE3A2A',
          sizeChartCode: '2FP1PE3A2A',
          sizeList: ['XS', 'S', 'M', 'L', 'XL'],
          lengthChartCode: null,
          lengthList: null,
          sizeLengthSeparator: '',
          description: 'Clothing, Women, PIECES, US [XS - XL]',
          sizeKeys: [createSizeKey(['L', 'M', 'S', 'XL', 'XS'])],
        },
      ],
    },
    modifier || {}
  );

export const sizingDummy2D = (modifier?: Partial<Sizing>): Sizing =>
  Object.assign(
    {
      sizingGroup: {
        groupKey: '2-1-N-02-s-xl',
        commodityGroupCode: '2-1-N-02',
      },
      reviewed: false,
      enableBox: false,
      selectedSizing: {
        sizeKeyId:
          '23x28;23x30;23x32;23x34;23x36;24x28;24x30;24x32;24x34;24x36;25x28;25x30;25x32;25x34;25x36;26x28;26x30;26x32;26x34;26x36;27x28;27x30;27x32;27x34;27x36;28x28;28x30;28x32;28x34;28x36;29x28;29x30;29x32;29x34;29x36;30x28;30x30;30x32;30x34;30x36;31x28;31x30;31x32;31x34;31x36;32x28;32x30;32x32;32x34;32x36;33x28;33x30;33x32;33x34;33x36;34x30;34x32;34x34;34x36',
        sizeChartId: '2FHW000A2B;2FHU000U3A',
      },
      recommendedSizing: {
        sizeKeyId:
          '23x28;23x30;23x32;23x34;23x36;24x28;24x30;24x32;24x34;24x36;25x28;25x30;25x32;25x34;25x36;26x28;26x30;26x32;26x34;26x36;27x28;27x30;27x32;27x34;27x36;28x28;28x30;28x32;28x34;28x36;29x28;29x30;29x32;29x34;29x36;30x28;30x30;30x32;30x34;30x36;31x28;31x30;31x32;31x34;31x36;32x28;32x30;32x32;32x34;32x36;33x28;33x30;33x32;33x34;33x36;34x30;34x32;34x34;34x36',
        sizeChartId: '2FHW000A2B;2FHU000U3A',
      },
      sizeChartList: [
        {
          sizeChartId: '2FAE000A2A',
          sizeChartCode: '2FAE000A2A',
          sizeList: [
            'XXS',
            'XXS/XS',
            'XS',
            'XS/S',
            'S',
            'S/M',
            'M',
            'M/L',
            'L',
            'L/XL',
            'XL',
            'XL/XXL',
            'XXL',
            'XXL/3XL',
            '3XL',
            '3XL/4XL',
            '4XL',
            '4XL/5XL',
            '5XL',
            '5XL/6XL',
            '6XL',
            '6XL/7XL',
          ],
          lengthChartCode: null,
          lengthList: null,
          sizeLengthSeparator: '',
          description: 'Textil , Women, US [3XS - 6XL/7XL]',
          sizeKeys: [],
        },
        {
          sizeChartId: '2FKO000E2A',
          sizeChartCode: '2FKO000E2A',
          sizeList: ['30', '32', '34', '36', '38', '40', '42', '44', '46', '48', '50', '52', '54', '56', '58', '60'],
          lengthChartCode: null,
          lengthList: null,
          sizeLengthSeparator: '',
          description: 'Description not available',
          sizeKeys: [],
        },
        {
          sizeChartId: '2FHW000A2B;2FHU000U3A',
          sizeChartCode: '2FHW000A2B',
          sizeList: [
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '36',
            '37',
            '38',
            '39',
            '40',
            '41',
            '42',
            '43',
            '44',
            '45',
            '46',
            '47',
            '48',
            '49',
            '50',
            '52',
            '54',
          ],
          lengthChartCode: '2FHU000U3A',
          lengthList: ['26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38'],
          sizeLengthSeparator: 'x',
          description: 'Appearal, Woman, Trouserwidth US [21-54]',
          sizeKeys: [
            createSizeKey(
              ['23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34'],
              ['28', '30', '32', '34', '36'],
              {
                '23x28': 1.1,
                '23x30': 0.7,
                '23x32': 0.3,
                '23x34': 0.1,
                '23x36': 0,
                '24x28': 1.7,
                '24x30': 1.6,
                '24x32': 0.7,
                '24x34': 0.1,
                '24x36': 0,
                '25x28': 2.4,
                '25x30': 3,
                '25x32': 1.4,
                '25x34': 0.3,
                '25x36': 0.1,
                '26x28': 2.2,
                '26x30': 4.5,
                '26x32': 2.5,
                '26x34': 1.1,
                '26x36': 0.2,
                '27x28': 3.4,
                '27x30': 5.9,
                '27x32': 4.8,
                '27x34': 1.4,
                '27x36': 0.5,
                '28x28': 2.9,
                '28x30': 5.8,
                '28x32': 5.6,
                '28x34': 1.9,
                '28x36': 0.9,
                '29x28': 1.4,
                '29x30': 3.7,
                '29x32': 5.1,
                '29x34': 2,
                '29x36': 1.1,
                '30x28': 1.1,
                '30x30': 2.2,
                '30x32': 3.6,
                '30x34': 1.9,
                '30x36': 1.1,
                '31x28': 1,
                '31x30': 1.6,
                '31x32': 2.9,
                '31x34': 1.3,
                '31x36': 1,
                '32x28': 0.5,
                '32x30': 1.3,
                '32x32': 1.8,
                '32x34': 1,
                '32x36': 0.6,
                '33x28': 0.3,
                '33x30': 1,
                '33x32': 1,
                '33x34': 0.7,
                '33x36': 0.2,
                '34x30': 1.2,
                '34x32': 1.1,
                '34x34': 0.8,
                '34x36': 0.4,
              },
              'x'
            ),
            createSizeKey(
              ['23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34'],
              ['28', '29', '30', '31', '32', '33'],
              undefined,
              'x'
            ),
            createSizeKey(
              ['22', '23', '24', '25', '26', '27', '28', '30', '31', '32', '33', '34', '35', '36'],
              ['26', '27', '28'],
              undefined,
              'x'
            ),
            createSizeKey(
              ['22', '23', '24', '25', '26', '27', '28', '30', '31', '32', '33', '34', '35', '36'],
              ['26', '27', '28', '32', '34', '35'],
              undefined,
              'x'
            ),
            createSizeKey(
              ['23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34'],
              ['30', '32', '34'],
              undefined,
              'x'
            ),
            createSizeKey(
              ['22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36'],
              ['26', '28', '30', '32', '34', '36'],
              undefined,
              'x'
            ),
            createSizeKey(
              ['22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36'],
              ['26', '28', '30', '32', '34', '36'],
              undefined,
              'x'
            ),
          ],
        },
        {
          sizeChartId: '2FHW000A2B',
          sizeChartCode: '2FHW000A2B',
          sizeList: [
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '36',
            '37',
            '38',
            '39',
            '40',
            '41',
            '42',
            '43',
            '44',
            '45',
            '46',
            '47',
            '48',
            '49',
            '50',
            '52',
            '54',
          ],
          lengthChartCode: null,
          lengthList: null,
          sizeLengthSeparator: '',
          description: 'Appearal, Woman, Trouserwidth US [21-54]',
          sizeKeys: [
            createSizeKey(['24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34']),
            undefined,
            undefined,
            'x',
          ],
        },
        {
          sizeChartId: '2FHW000A2B;2FG1GS1X3A',
          sizeChartCode: '2FHW000A2B',
          sizeList: [
            '21',
            '22',
            '23',
            '24',
            '25',
            '26',
            '27',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '36',
            '37',
            '38',
            '39',
            '40',
            '41',
            '42',
            '43',
            '44',
            '45',
            '46',
            '47',
            '48',
            '49',
            '50',
            '52',
            '54',
          ],
          lengthChartCode: '2FG1GS1X3A',
          lengthList: null,
          sizeLengthSeparator: '',
          description: 'Appearal, Woman, Trouserwidth US [21-54]',
          sizeKeys: [],
        },
        {
          sizeChartId: '5AAU000S4A',
          sizeChartCode: '5AAU000S4A',
          sizeList: ['One Size'],
          lengthChartCode: null,
          lengthList: null,
          sizeLengthSeparator: '',
          description: 'Description not available',
          sizeKeys: [],
        },
        {
          sizeChartId: '2FAD000S2A',
          sizeChartCode: '2FAD000S2A',
          sizeList: ['XXS/XS', 'XS/S', 'S/M', 'M/L', 'L/XL', 'XL/XXL', 'XXL/3XL', '3XL/4XL', '4XL/5XL', '5XL/6XL'],
          lengthChartCode: null,
          lengthList: null,
          sizeLengthSeparator: '',
          description: 'Description not available',
          sizeKeys: [],
        },
      ],
    },
    modifier || {}
  );

export function createSizeKey(
  sizeList: string[] = ['S', 'M'],
  lengthList?: string[],
  distribution: Distribution = { S: 19, M: 20 },
  separator = '',
  manualSizeKey?: boolean,
  distributionType = DistributionType.RECOMMENDED,
  recommendationStrategy?: string,
  recommendationLevel?: string
): SizeKey {
  const sizeKeys = sizeList.reduce<string[]>((sizeKeys, size) => {
    if (lengthList) {
      lengthList.forEach((length) => sizeKeys.push(`${size}${separator}${length}`));
    } else {
      sizeKeys.push(size);
    }
    return sizeKeys;
  }, []);

  return {
    manualSizeKey: !!manualSizeKey,
    sizeList: sizeList,
    lengthList: lengthList,
    sizeKey: sizeKeys,
    sizeKeyId: sizeKeys.join(';'),
    sizeLengthSeparator: separator,
    distribution,
    distributionType: distributionType,
    recommendationStrategy: recommendationStrategy,
    recommendationLevel: recommendationLevel,
  };
}

export const sizeKeyDummy = (modifier?: Partial<SizeKey>): SizeKey =>
  Object.assign(
    createSizeKey(['XS', 'S', 'M', 'L', 'XL'], undefined, {
      L: 19.2,
      M: 30.6,
      S: 27.1,
      XL: 10.7,
    }),
    modifier
  );

export const sizeKeyDummy2D = (): SizeKey =>
  createSizeKey(
    ['24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34'],
    ['28', '30', '32', '34', '36'],
    {
      '24x28': 1.7,
      '24x30': 1.6,
      '24x32': 0.7,
      '24x34': 0.1,
      '24x36': 0,
      '25x28': 2.4,
      '25x30': 3,
      '25x32': 1.4,
      '25x34': 0.3,
      '25x36': 0.1,
      '26x28': 2.2,
      '26x30': 4.5,
      '26x32': 2.5,
      '26x34': 1.1,
      '26x36': 0.2,
      '27x28': 3.4,
      '27x30': 5.9,
      '27x32': 4.8,
      '27x34': 1.4,
      '27x36': 0.5,
      '28x28': 2.9,
      '28x30': 5.8,
      '28x32': 5.6,
      '28x34': 1.9,
      '28x36': 0.9,
      '29x28': 1.4,
      '29x30': 3.7,
      '29x32': 5.1,
      '29x34': 2,
      '29x36': 1.1,
      '30x28': 1.1,
      '30x30': 2.2,
      '30x32': 3.6,
      '30x34': 1.9,
      '30x36': 1.1,
      '31x28': 1,
      '31x30': 1.6,
      '31x32': 2.9,
      '31x34': 1.3,
      '31x36': 1,
      '32x28': 0.5,
      '32x30': 1.3,
      '32x32': 1.8,
      '32x34': 1,
      '32x36': 0.6,
      '33x28': 0.3,
      '33x30': 1,
      '33x32': 1,
      '33x34': 0.7,
      '33x36': 0.2,
      '34x30': 1.2,
      '34x32': 1.1,
      '34x34': 0.8,
      '34x36': 0.4,
    },
    'x'
  );

export const dummySelectedBoxRestriction = (modifier?: Partial<SelectedBoxRestriction>): SelectedBoxRestriction => ({
  boxRestrictionId: '12',
  lastUpdated: '1990-01-01T00:00:00',
  boxRestrictionType: BoxRestrictionType.LOT_GROUP,
  ...(modifier || {}),
});

export const dummyBoxRestriction = <BOX extends BoxRestriction>(modifier?: Partial<BOX>): BOX =>
  Object.assign(
    {
      id: '12',
      name: 'GS1-divisor-5',
      type: BoxRestrictionType.DIVISOR,
      orderType: OrderType.PREORDER,
      boxValue: 5,
      updatedAt: '1990-01-01T00:00:00',
    },
    modifier || {}
  ) as BOX;

export const dummyBoxOptimizationResponse = (
  modifier?: Partial<BoxOptimisationResponse>,
  type: BoxRestrictionType = BoxRestrictionType.DIVISOR
): BoxOptimisationResponse =>
  Object.assign(
    {
      article_id: '167633',
      box_restriction_id: '8686',
      desired_size_level_quantity: { S: 445, XL: 200, XS: 193, L: 414, M: 584, XXL: 264 },
      desired_total_quantity: 2100,
      optimum_size_level_quantity: { L: 414, M: 584, S: 445, XL: 200, XS: 193, XXL: 264 },
      optimum_total_quantity: 2100,
      size_level_deviation: { L: 0, M: 0, S: 0, XL: 0, XS: 0, XXL: 0 },
      total_deviation: 0,
      fallback_level: 'no_fallback',
      ...(type === BoxRestrictionType.LOT_GROUP
        ? {
            optimum_lot_quantities: [
              {
                name: 'Lot name',
                quantity: 78,
                size_level_quantity: { L: 624, M: 468, S: 390, XL: 234, XS: 312, XXL: 78 },
              },
            ],
            box_restriction: dummyBoxRestrictionLotGroup(),
          }
        : type === BoxRestrictionType.DIVISOR
        ? {
            box_restriction: dummyBoxRestrictionDivisor(),
          }
        : {
            optimum_fixed_box_quantity: 700,
            box_restriction: dummyBoxRestrictionFixedQuantity(),
          }),
    },
    modifier || {}
  );

export const dummyBoxRestrictionDivisor = (modifier?: Partial<BoxRestrictionsResponse>): BoxRestrictionsResponse =>
  Object.assign(
    {
      id: '8686',
      name: 'Test',
      brand_code: 'AX5',
      supplier_code: 'K70240',
      cg1: { code: '2', name: 'Clothing' },
      cg2: { code: '2-1', name: 'Women' },
      cg3: [{ code: '2-1-E', name: 'Blouses / Woven Tops' }],
      type: 'DIVISOR',
      basic_order_type: 'preorder',
      box_value: 1,
      created_at: '2021-11-01T14:15:11.008656',
      updated_at: '2021-11-01T14:15:11.008662',
    },
    modifier || {}
  );

export const dummyBoxRestrictionLotGroup = (modifier?: Partial<BoxRestrictionsResponse>): BoxRestrictionsResponse =>
  Object.assign(
    {
      id: '8701',
      name: 'Lot restriction',
      brand_code: 'AX5',
      supplier_code: 'K70240',
      cg1: { code: '2', name: 'Clothing' },
      cg2: { code: '2-1', name: 'Women' },
      cg3: [{ code: '2-1-E', name: 'Blouses / Woven Tops' }],
      type: 'LOT_GROUP',
      size_range: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
      lot_group: [
        { name: 'Lot name', distribution: { L: 8, M: 6, S: 5, XL: 3, XS: 4, XXL: 1 }, box_restriction_id: '8701' },
      ],
      created_at: '2021-11-10T09:14:37.016135',
      updated_at: '2021-11-10T09:14:37.016142',
    },
    modifier || {}
  );

export const dummyBoxRestrictionFixedQuantity = (
  modifier?: Partial<BoxRestrictionsResponse>
): BoxRestrictionsResponse =>
  Object.assign(
    {
      id: '8687',
      name: 'Test3',
      brand_code: 'AX5',
      supplier_code: 'K70240',
      cg1: { code: '2', name: 'Clothing' },
      cg2: { code: '2-1', name: 'Women' },
      cg3: [{ code: '2-1-E', name: 'Blouses / Woven Tops' }],
      type: 'FIXED_BOX_QUANTITY',
      box_value: 3,
      created_at: '2021-11-01T14:29:02.362376',
      updated_at: '2021-11-01T14:29:02.362384',
    },
    modifier || {}
  );

export const dummyBoxRestrictionLotGroupBox = (modifier?: Partial<LOTGroupBox>): LOTGroupBox =>
  Object.assign(
    {
      id: '8726',
      name: 'Test lot group',
      orderType: OrderType.PREORDER,
      type: BoxRestrictionType.LOT_GROUP,
      sizeRange: ['S', 'M', 'L'],
      lotGroup: [
        {
          id: '8726',
          name: 'one',
          distribution: {
            S: 1,
            M: 2,
            L: 3,
          },
        },
        {
          id: '8727',
          name: 'two',
          distribution: {
            S: 1,
            M: 2,
            L: 3,
          },
        },
      ],
      updatedAt: '2021-12-14T09:52:13.837953',
    },
    modifier || {}
  );

export const dummySizingV2 = (modifier?: Partial<SizingV2>): SizingV2 =>
  Object.assign(
    {
      id: 'S;M;L',
      sizeChartCode: 'a',
      lengthChartCode: '',
      sizeKeyId: 'XS;S;M;L;XL',
      sizeList: ['XXS/XS', 'XS/S', 'S/M', 'M/L', 'L/XL', 'XL/XXL'],
      lengthList: [''],
      sizeLengthSeparator: '/',
      sizeDistribution: {
        L: 19.2,
        M: 30.6,
        S: 27.1,
        XL: 10.7,
        XS: 12.4,
      },
      sizeDistributionType: 'RECOMMENDED',
    },
    modifier || {}
  );

export const updateOrderResponseWithoutArticles = (modifier?: Partial<Order>): Order =>
  Object.assign(
    {
      id: '163705',
      referenceId: 'Z2001042',
      seasonCode: 'FS23',
      brandCode: 'NI1',
      cgGroupingLevel: CgGroupingLevel.CG4,
      enableBox: false,
      status: 'OPEN',
      buyerEmail: 'user@zalando.de',
      isNewOrderCreated: false,
      groups: [],
    },
    modifier || {}
  );

export const updateOrderResponseForExistingSizingInStore = (modifier?: Partial<Order>): Order =>
  Object.assign(
    {
      id: '163705',
      referenceId: 'Z2100091',
      seasonCode: 'FS21',
      brandCode: 'VE1',
      cgGroupingLevel: CgGroupingLevel.CG5,
      enableBox: false,
      status: 'OPEN',
      buyerEmail: 'user@zalando.de',
      isNewOrderCreated: false,
      groups: groupsInStoreToGroupResponseConversion(),
    },
    modifier || {}
  );

const groupsInStoreToGroupResponseConversion = (store?: SizingStore) => {
  if (store === undefined) {
    store = new SizingStore(sizingDataDummy());
  }
  return store.sizingGroups.map(convertGroupToResponse);
};

export const convertGroupToResponse = (group: SizingGroup): GroupResponse => ({
  id: `01-${group.groupKey}}`,
  key: group.groupKey,
  commodityGroupCode: group.commodityGroup.code,
  isReviewed: false,
  sizeChartEditable: true,
  enableBox: false,
  referenceBrandCode: '',
  selectedBoxRestriction: { boxRestrictionId: group.boxRestriction?.boxRestrictionId },
  sizeStructure: group.sizeStructure,
  sizingNote: group.sizingNote,
  sizeChartId: group.selectedSizeChart?.sizeChartId,
  sizeChartList: [],
  selectedSizing: dummySizingV2(group.selectedSizing),
  sizings: [dummySizingV2(group.selectedSizing)],
  articles: group.articles.reduce<ArticleResponse[]>((articles, article) => {
    articles.push(ArticleResponse.fromArticleInput(articleInputDummy(article)));
    return articles;
  }, [] as ArticleResponse[]),
});

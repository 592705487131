import { CgGroupingLevel, SizingGroup, TreeNode, TreeData } from '../../models/sizing-group';

export function getAllowedCgLevels(groupingCgLevel: CgGroupingLevel): string[] {
  switch (groupingCgLevel) {
    case CgGroupingLevel.CG3:
      return [CgGroupingLevel.CG3];
    case CgGroupingLevel.CG4:
      return [CgGroupingLevel.CG3, CgGroupingLevel.CG4];
    case CgGroupingLevel.CG5:
      return [CgGroupingLevel.CG3, CgGroupingLevel.CG4, CgGroupingLevel.CG5];
  }
}

function createTreeNode(obj: TreeNode, name: string, disabled: boolean, cgLevel: string): TreeNode {
  if (obj) {
    obj.disabled = obj.disabled && disabled;
    return obj;
  }

  return {
    name,
    disabled,
    cgLevel,
    children: {},
  };
}

export function getTreeData(groups: SizingGroup[], showNonReviewed: boolean): TreeData {
  const groupIds = groups.map((group) => group.groupId);

  return groups.reduce((acc: TreeData, group) => {
    // need to copy the codes into a new array to avoid mobx warning about `mobx [mobx] Out of bounds read: X`
    const [, , cg3Name, cg4Name, cg5Name] = [...group.commodityGroup.names];
    const [, , cg3Key, cg4Key, cg5Key] = [...group.commodityGroup.codes];
    const disabled = group.isReviewed && showNonReviewed;

    acc[cg3Key] = createTreeNode(acc[cg3Key], cg3Name, disabled, CgGroupingLevel.CG3);

    if (!groupIds.includes(cg3Key)) {
      acc[cg3Key].disabled = true;
    }

    if (cg4Key) {
      acc[cg3Key].children[cg4Key] = createTreeNode(
        acc[cg3Key].children[cg4Key],
        cg4Name,
        disabled,
        CgGroupingLevel.CG4
      );

      if (!groupIds.includes(cg4Key)) {
        acc[cg3Key].children[cg4Key].disabled = true;
      }
    }

    if (cg5Key && groupIds.includes(cg5Key)) {
      acc[cg3Key].children[cg4Key].children[cg5Key] = createTreeNode(
        acc[cg3Key].children[cg4Key].children[cg5Key],
        cg5Name,
        disabled,
        CgGroupingLevel.CG5
      );
    }

    return acc;
  }, {});
}

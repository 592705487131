import { observer } from 'mobx-react-lite';
import React, { FC, useMemo, useState } from 'react';
import { Select, Popover, Popconfirm, message as Message } from '@retail-core/rds';
import isEqual from 'fast-deep-equal';
import { Sizing, SizeKey, SizingKey, SizeChart, SortKey } from '../../../models/sizing';
import { QuantitiesOverwriteModal } from '../../partials/ModalConfirm/ModalConfirm';
import { ConditionalWrapper } from '../../partials/ConditionalWrapper/ConditionalWrapper';

type IProps = {
  sizing: Sizing;
  selectedSizeKey?: SizeKey;
  isSizeChartNotEditable: boolean;
  numberOfEditedArticles?: number;
  enableCompatibility?: boolean;
  readOnly?: boolean;
  onSizingChange: (sizingKey: SizingKey, keepSizing?: boolean, sizeChartManuallyChanged?: boolean) => void;
};

export const SizeChartPicker: FC<IProps> = observer((props) => {
  const { Option, OptGroup } = Select;
  const {
    onSizingChange,
    sizing: {
      sizeChartList,
      selectedSizing: { sizeChartId },
    },
    selectedSizeKey,
    numberOfEditedArticles = 0,
    isSizeChartNotEditable,
    enableCompatibility,
    readOnly,
  } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const [sizingKey, setSizingKey] = useState({
    sizeChartId,
    sizeKeyId: '',
  });
  const [keepSizing, setKeepSizing] = useState(false);

  const getMatchingSizeList = (superSet: SortKey, subSet: SortKey): SortKey => {
    return superSet.reduce((matchedSet, item) => {
      return ~subSet.indexOf(item) ? [...matchedSet, item] : matchedSet;
    }, [] as string[]);
  };

  const sizeChartIsCompatible = (sizeChart: SizeChart, sizeKey: SizeKey): boolean => {
    if (!!sizeChart.lengthList?.length !== !!sizeKey.lengthList?.length) {
      return false;
    }
    const matchedSizeList = getMatchingSizeList(sizeChart.sizeList, sizeKey.sizeList);
    const matchedLengthList = getMatchingSizeList(sizeChart.lengthList || [], sizeKey.lengthList || []);

    return isEqual(matchedSizeList, sizeKey.sizeList) && isEqual(matchedLengthList, sizeKey.lengthList);
  };

  const [compatibleSizeCharts, otherSizeCharts] = useMemo(
    () =>
      enableCompatibility && selectedSizeKey
        ? sizeChartList.reduce(
            ([compatible, other], sizeChart) => {
              if (sizeChartIsCompatible(sizeChart, selectedSizeKey)) {
                compatible.push(sizeChart);
              } else {
                other.push(sizeChart);
              }
              return [compatible, other];
            },
            [[], []] as [SizeChart[], SizeChart[]]
          )
        : [[], sizeChartList],
    [selectedSizeKey, sizeChartList, sizeChartId] //eslint-disable-line react-hooks/exhaustive-deps
  );
  const handleChange = (value: string) => {
    // Will be empty when user clicks on manual selection entry
    if (value === 'create-size-chart') {
      window.open('https://sites.google.com/a/zalando.de/sizing-business-integration/home', '_blank');
      return;
    }
    const newSizingKey = {
      sizeChartId: value,
      sizeKeyId: '',
    };

    const newKeepSizing =
      !!enableCompatibility && compatibleSizeCharts.filter((sizeChart) => sizeChart.sizeChartId === value).length !== 0;

    setSizingKey(newSizingKey);

    if (enableCompatibility && !newKeepSizing) {
      setKeepSizing(newKeepSizing);
      setShowConfirm(true);
    } else if (numberOfEditedArticles > 0) {
      QuantitiesOverwriteModal(numberOfEditedArticles, () => {
        onSizingChange(newSizingKey, newKeepSizing, true);
      });
    } else {
      onSizingChange(newSizingKey, newKeepSizing, true);
      Message.success('Size chart updated');
    }
  };

  const handleConfirm = () => {
    onSizingChange(sizingKey, keepSizing, true);
    setShowConfirm(false);
    Message.success('New sizing applied');
  };

  const handleCancel = () => {
    const oldSizingKey = {
      sizeChartId,
      sizeKeyId: '',
    };
    setSizingKey(oldSizingKey);
    setShowConfirm(false);
  };

  const SizeChartNotEditablePopOverTitle = <b>Size chart already used in previous orders</b>;
  const SizeChartNotEditablePopOverContent = (
    <>
      The articles within this group were previously ordered with this sizechart. To change their <br /> size chart,
      please edit these articles manually in the Article &amp; delivery date selection tab
    </>
  );

  return (
    <>
      <ConditionalWrapper
        condition={isSizeChartNotEditable}
        wrapper={(children: any) => (
          <Popover
            placement="bottom"
            title={SizeChartNotEditablePopOverTitle}
            content={SizeChartNotEditablePopOverContent}
            trigger="hover"
          >
            {children}
          </Popover>
        )}
      >
        <Popconfirm
          visible={showConfirm}
          title="Use this new size chart and overwrite current sizing decisions? You may also have to share a new preliminary order afterwards."
          placement="bottomLeft"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
          okText="Overwrite"
          cancelText="Cancel"
          overlayClassName="overwrite-sizing-confirm"
        >
          <Select
            value={sizingKey.sizeChartId}
            onChange={handleChange}
            size="small"
            dropdownClassName="size-chart-dropdown"
            virtual={false}
            data-testid="sizeChart-picker-select"
            disabled={readOnly || isSizeChartNotEditable}
          >
            {compatibleSizeCharts.length && (
              <OptGroup label="Keep sizing decisions" key="compatible">
                {compatibleSizeCharts.map(({ sizeChartId, description }, index) => {
                  const displayValue = `${sizeChartId.replace(';', '/')} - ${description}`;
                  return (
                    <Option key={sizeChartId + index} value={sizeChartId} title={displayValue}>
                      {displayValue}
                    </Option>
                  );
                })}
              </OptGroup>
            )}
            <OptGroup label="Overwrite sizing decisions" key="others">
              {otherSizeCharts.map(({ sizeChartId, description }, index) => {
                const displayValue = `${sizeChartId.replace(';', '/')} - ${description}`;
                return (
                  <Option key={sizeChartId + index} value={sizeChartId} title={displayValue}>
                    {displayValue}
                  </Option>
                );
              })}
            </OptGroup>
            <Option value="create-size-chart">
              <span className="create-size-chart">Missing a size chart?</span>
              <span className="rds-link">Link it to the unit, brand and CG1-2 of this order</span>
            </Option>
          </Select>
        </Popconfirm>
      </ConditionalWrapper>
    </>
  );
});

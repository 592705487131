import React, { FC } from 'react';
import { Skeleton, Space, Table } from '@retail-core/rds';
import './MatrixSkeleton.less';

interface IProps {
  className?: string;
  columns: number;
  rows: number;
}

export const MatrixSkeleton: FC<IProps> = (props) => {
  const columns = new Array(props.columns).fill(0).map((_, col) => ({
    key: col,
    dataIndex: col,
    title: () => (
      <Space>
        <Skeleton.Button size="small" active={true} />
      </Space>
    ),
    render: () => (
      <Space>
        <Skeleton.Button size="small" active={true} />
      </Space>
    ),
  }));

  return (
    <div className="table matrix-skeleton">
      <Table
        data-testid="matrix-skeleton"
        className={props.className}
        columns={columns}
        dataSource={new Array(props.rows)
          .fill(0)
          .map((_, row) => new Array(props.columns).fill(0).reduce((obj, col) => ({ ...obj, [col]: 1 }), { key: row }))}
        pagination={false}
        bordered={true}
        size="small"
      />
    </div>
  );
};

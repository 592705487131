import React, { FC, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../StoreContext/StoreContext';
import { Select, message as Message } from '@retail-core/rds';
import {
  exportArticlesTemplate,
  getArticlesRowsList,
  getArticlesFromRows,
  validateArticlesData,
  articlesToJSON,
  getTemplateName,
} from '../../lib/xlsx-utils';
import { QuantitiesTemplateErrorsModal } from './QuantitiesTemplateErrorsModal';
import { SheetRow } from '../../models/quantities-template';

interface Props {
  disabled?: boolean;
}

export const QuantitiesTemplateSelect: FC<Props> = observer((props) => {
  const { disabled } = props;
  const { Option } = Select;
  const { sizing } = useStore();
  const [modalVisible, setModalVisible] = useState(false);
  const [articleRows, setArticleRows] = useState<SheetRow[]>([]);
  const [errorsCount, setErrorsCount] = useState(0);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const IMPORT_MSG_KEY = 'IMPORT_MESSAGE';

  const handleOnChange = (value: string) => {
    if (value === 'download-template') {
      const articlesJSON = articlesToJSON(sizing.sizingGroups);
      exportArticlesTemplate(articlesJSON, getTemplateName([sizing.brandName, sizing.orderName]));
    } else if (value === 'upload-template') {
      fileInputRef.current?.click();
    }
  };

  const handleModalOnCancel = () => {
    setModalVisible(false);
  };

  const handelModalOnErrorsDownload = () => {
    exportArticlesTemplate(articleRows, getTemplateName([sizing.brandName, sizing.orderName, 'errors']));
  };

  const handleModalOnReUpload = () => {
    fileInputRef.current?.click();
  };

  const handleTemplateUpload = (e: any) => {
    const reader = new FileReader();
    reader.onload = function (e: any) {
      Message.loading({
        content: 'Importing quantities...',
        key: IMPORT_MSG_KEY,
      });
      const data = new Uint8Array(e.target.result);
      try {
        const { rows, errorsCount } = validateArticlesData(getArticlesRowsList(data), sizing);

        setArticleRows(rows);
        setErrorsCount(errorsCount);
        if (errorsCount) {
          Message.destroy(IMPORT_MSG_KEY);
          setModalVisible(true);
        } else {
          if (modalVisible) {
            setModalVisible(false);
          }
          const articleList = getArticlesFromRows(rows);
          sizing.changeArticleSizingBulk(articleList);
          Message.success({
            content: 'Quantities per size successfully imported!',
            key: IMPORT_MSG_KEY,
          });
        }
      } catch (e) {
        Message.error({
          content: 'Invalid quantities file!',
          key: IMPORT_MSG_KEY,
        });
      }
    };
    reader.readAsArrayBuffer(e.target.files[0]);
    if (fileInputRef.current) {
      // Clear file input value to fix issue with onChange being triggered only once
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      <Select
        value="Import supplier stock"
        data-testid="quantities-template-select"
        disabled={disabled}
        onChange={handleOnChange}
        className="quantities-template-select"
        dropdownMatchSelectWidth={false}
      >
        <Option value="download-template">Download stock template</Option>
        <Option value="upload-template">Upload stock sheet</Option>
      </Select>
      <input type="file" ref={fileInputRef} onChange={handleTemplateUpload} style={{ display: 'none' }} />
      <QuantitiesTemplateErrorsModal
        visible={modalVisible}
        errorsCount={errorsCount}
        templateName={getTemplateName([sizing.brandName, sizing.orderName, 'errors'])}
        onDownload={handelModalOnErrorsDownload}
        onReUpload={handleModalOnReUpload}
        onCancel={handleModalOnCancel}
      />
    </>
  );
});

import React, { FC, useEffect, useState } from 'react';
import { Checkbox } from '@retail-core/rds';

interface IProps {
  className?: string;
  isVisible?: boolean;
  isSelected?: boolean;
  partiallySelected?: boolean;
  toggleSelected?: () => void;
}

export const SelectionCheckbox: FC<IProps> = ({
  partiallySelected = false,
  isSelected,
  toggleSelected = () => null,
  isVisible,
  className,
}) => {
  const [selectionLocal, setSelectionLocal] = useState(isSelected);
  useEffect(() => {
    if (isSelected !== selectionLocal) {
      setSelectionLocal(isSelected);
    }
  }, [isSelected]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isSelected !== selectionLocal) {
      toggleSelected();
    }
  }, [selectionLocal]); // eslint-disable-line react-hooks/exhaustive-deps
  const toggleLocalSelection = () => {
    setSelectionLocal((state) => !state);
  };
  return isVisible || selectionLocal || partiallySelected ? (
    <Checkbox
      data-testid="selection-checkbox"
      className={className}
      checked={selectionLocal}
      data-partial={partiallySelected}
      data-selected={selectionLocal}
      onClick={toggleLocalSelection}
      indeterminate={partiallySelected}
    />
  ) : null;
};

import React, { FC, useCallback, useState } from 'react';
import { BoxRestrictionView } from '@ism/box-restrictions';
import { Switch, Typography, Popconfirm, Tooltip } from '@retail-core/rds';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../StoreContext/StoreContext';
import './BoxRestrictionsToggle.less';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Text } = Typography;

export const BoxRestrictionsToggle: FC = observer(function BoxRestrictionsToggle() {
  const { sizing: sizingStore, uiStore } = useStore();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const { enableBox, autoEnableBox } = sizingStore;

  const handleOpenBoxCatalog = useCallback(() => {
    uiStore.showBoxCatalog(BoxRestrictionView.List);
  }, [uiStore]);

  const toggleBoxRestriction = useCallback(() => {
    if (enableBox && !confirmVisible) {
      return setConfirmVisible(true);
    } else if (confirmVisible) {
      setConfirmVisible(false);
    }
    return sizingStore.toggleEnableBox();
  }, [confirmVisible, enableBox, sizingStore]);

  const handleCancel = () => setConfirmVisible(false);
  return (
    <>
      {autoEnableBox && (
        <>
          <Tooltip
            placement="top"
            title={`For certain Nike brands and CG1-2 combinations box restrictions are enabled by default. Turn the toggle off if you don’t need it.`}
          >
            <InfoCircleOutlined style={{ color: '#faa43c', marginRight: '2px' }} />
          </Tooltip>
        </>
      )}
      <Text className="enable-box-label">Enable box restrictions</Text>
      <Popconfirm
        visible={confirmVisible}
        title="Box restrictions recommendations will be lost if you continue."
        placement="bottomLeft"
        onConfirm={toggleBoxRestriction}
        onCancel={handleCancel}
        okText="Yes, disable box restrictions"
        okButtonProps={{ type: 'primary', danger: true }}
        cancelText="Cancel"
        overlayClassName="disable-box-restrictions-confirm"
      >
        <Switch
          checked={enableBox}
          onChange={toggleBoxRestriction}
          size="small"
          data-testid="switch-box-restrictions"
        />
      </Popconfirm>
      {enableBox && (
        <div className="box-catalog-link">
          <span className="rds-link" onClick={handleOpenBoxCatalog}>
            Open catalog
          </span>
        </div>
      )}
    </>
  );
});

import { Tooltip, Input } from '@retail-core/rds';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import './EditableCell.less';
import { createCopiableMatrix, eventToCellLocation } from '../../matrices-utils';
import { SelectionState } from '../../../../models/selection-state';

const getReferenceBasedBackGroundCellColor = (referenceValue: number, originalValue: number, expectedTotal: number) => {
  return referenceValue !== originalValue
    ? `rgba(252, 182, 42, ${Math.abs(referenceValue - originalValue) / expectedTotal})`
    : '#FAFAFA';
};

interface Item {
  [key: string]: string;
}

export interface IProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Item;
  referenceRecord: Item;
  dataIsPercentage?: boolean;
  onCellChange: (row: string, column: string, value: number, text?: string) => void;
  onEditChange: (row: string, column: string, editing: boolean) => void;
  tableRef?: React.RefObject<HTMLDivElement>;
  referenceId?: string;
  selectionState?: SelectionState;
  setSelectionState?: (groupKey: string | undefined, referenceId: string | undefined, state: SelectionState) => void;
  clearSelectionState?: () => void;
  groupKey?: string;
  expectedTotal?: number;
  sizeDivisorForValidation?: number;
  style?: Record<any, any>;
}

export const EditableCell: FC<IProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record = {},
  referenceRecord = {},
  dataIsPercentage,
  onCellChange,
  tableRef,
  referenceId,
  selectionState,
  setSelectionState = () => null,
  clearSelectionState = () => null,
  groupKey,
  expectedTotal,
  sizeDivisorForValidation,
  ...restProps
}) => {
  const referenceValue = Number(referenceRecord[dataIndex]).toFixed(dataIsPercentage ? 1 : 0);
  const originalValue = Number(record[dataIndex]).toFixed(dataIsPercentage ? 1 : 0);
  const [inputValue, setInputValue] = useState(originalValue);
  const [error, setError] = useState<string>();
  const [cellClassName, setCellClassName] = useState('');
  const tdRef = useRef<HTMLTableDataCellElement>(null);
  useEffect(() => {
    if (inputValue !== originalValue) {
      setInputValue(originalValue);
    }
  }, [originalValue, setInputValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSave = useCallback(() => {
    if (originalValue === inputValue) {
      return;
    }

    onCellChange(record.key, dataIndex, parseFloat(inputValue));
  }, [dataIndex, record.key, inputValue, onCellChange, originalValue]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);

      if (!event.target.value) {
        setError('A value is required');
        return;
      }

      const value = Number(event.target.value);

      if (isNaN(value)) {
        setError('Value must be numeric');
        return;
      }

      if (value < 0) {
        setError('Value must be >= 0');
        return;
      }

      if (sizeDivisorForValidation !== undefined) {
        const remainder = value % sizeDivisorForValidation;
        if (remainder !== 0) {
          setError(
            `Remove ${remainder} or add ${sizeDivisorForValidation - remainder} units to match divisor multiplier`
          );
          return;
        }
      }

      if (!isNaN(Number(referenceValue))) {
        onCellChange(record.key, dataIndex, parseFloat(event.target.value));
      }
      setError('');
    },
    [dataIndex, onCellChange, record.key, referenceValue, sizeDivisorForValidation]
  );

  useEffect(() => {
    let className = '';
    if (editable) {
      const cellValue = parseFloat(record[dataIndex]);
      if (cellValue === 0) {
        className = 'zero-value-cell';
      } else if (isNaN(cellValue)) {
        className = 'non-editable-cell';
      }
    }
    if (!isNaN(Number(referenceValue)) && originalValue !== referenceValue) {
      className = `${className} reference-mismatched`;
    }
    if (tdRef.current && selectionState && selectionState?.referenceId && selectionState?.referenceId === referenceId) {
      const array = selectionState?.value;
      const parent = tdRef?.current?.parentElement as HTMLTableRowElement;
      if (
        array &&
        parent.rowIndex < array.length &&
        array[parent.rowIndex] &&
        tdRef.current.cellIndex < array[parent.rowIndex].length &&
        array[parent.rowIndex][tdRef.current.cellIndex] !== null
      ) {
        className = `${className} selected-cell`;
        if (selectionState?.startRow === parent.rowIndex && selectionState?.startColumn === tdRef.current.cellIndex) {
          className = `${className} first-selected-cell`;
        }
      }
    }
    setCellClassName(className);
  }, [record, selectionState, selectionState?.referenceId, selectionState?.selectionStarted, referenceValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
    const isLeftClick = e.button === 0;
    const isTouch = e.type !== 'mousedown';
    const isShiftKey = e.shiftKey;
    if (
      (!selectionState?.selectionStarted || selectionState?.referenceId !== referenceId) &&
      (isLeftClick || isTouch) &&
      !isShiftKey
    ) {
      // Starting selection on normal mousedown
      const { row, column } = eventToCellLocation(e);
      setSelectionState(groupKey, referenceId, {
        referenceId,
        selectionStarted: true,
        startRow: row,
        startColumn: column,
        endRow: row,
        endColumn: column,
        value: [[]],
        tableRef,
      });
    } else if (selectionState?.referenceId === referenceId && (isLeftClick || isTouch)) {
      if (isShiftKey) {
        // Ending selection on shift click
        handleMouseUp(e);
      } else {
        // Clear selection start made by a previous click on mousedown of subsequent click
        clearSelectionState();
      }
    }
    (e.target as HTMLInputElement).focus();
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>) => {
    const isLeftClick = e.button === 0;
    const isTouch = e.type !== 'mousedown';
    const isShiftKey = e.shiftKey;
    if (selectionState?.referenceId === referenceId && (isLeftClick || isTouch)) {
      if (selectionState?.selectionStarted || (isShiftKey && !isTouch)) {
        const table = tableRef?.current?.getElementsByTagName('table')[0];
        const { row, column } = eventToCellLocation(e);
        if (row === selectionState?.startRow && column === selectionState?.startColumn) {
          return clearSelectionState();
        }
        const minRow = Math.min(selectionState?.startRow || 0, row);
        const maxRow = Math.max(selectionState?.startRow || 0, row);
        const minColumn = Math.min(selectionState?.startColumn || 0, column);
        const maxColumn = Math.max(selectionState?.startColumn || 0, column);
        const value = new Array(maxRow + 1).fill(null).map(() => new Array(maxColumn + 1).fill(null));
        for (let row = minRow; row <= maxRow; row++) {
          for (let column = minColumn; column <= maxColumn; column++) {
            value[row][column] = table?.rows[row]?.cells[column]?.getElementsByTagName('input')[0]?.value || '';
          }
        }
        setSelectionState(groupKey, referenceId, {
          value,
          referenceId,
          selectionStarted: false,
          startRow: minRow,
          startColumn: minColumn,
          endRow: maxRow,
          endColumn: maxColumn,
          tableRef,
        });
      }
    } else {
      clearSelectionState();
    }
    (e.target as HTMLInputElement).focus();
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    onCellChange(record.key, dataIndex, 0, e.clipboardData.getData('Text'));
  };

  const handleCopy = useCallback(
    (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      let selectedMatrixData = selectionState?.value && createCopiableMatrix(selectionState?.value);
      selectedMatrixData = selectedMatrixData || (e.target as HTMLInputElement).value;
      navigator.clipboard.writeText(selectedMatrixData);
    },
    [selectionState, selectionState?.value] // eslint-disable-line react-hooks/exhaustive-deps
  );
  if (!restProps?.style?.backgroundColor && !restProps?.style?.backgroundImage && expectedTotal) {
    restProps.style = {
      ...restProps.style,
      backgroundColor: getReferenceBasedBackGroundCellColor(
        Number(referenceValue),
        Number(originalValue),
        expectedTotal
      ),
    };
  }
  return (
    <td
      {...restProps}
      className={cellClassName}
      onClick={handleClick}
      onMouseDown={handleClick}
      onMouseUp={handleMouseUp}
      ref={tdRef}
      data-testid={record.length ? `editable-cell-${dataIndex}-${record.length}` : `editable-cell-${dataIndex}`}
    >
      {editable && record[dataIndex] !== undefined ? (
        <Tooltip title={error} visible={!!error} color="red">
          <div className={error ? 'ant-form-item-has-error' : ''}>
            <Input
              size="small"
              className="editable-cell-input"
              value={inputValue}
              onChange={onChange}
              onPressEnter={onSave}
              onBlur={onSave}
              onPaste={handlePaste}
              onCopy={handleCopy}
              data-testid={
                record.length ? `editable-cell-input-${dataIndex}-${record.length}` : `editable-cell-input-${dataIndex}`
              }
            />
            {dataIsPercentage && <span className="percentage-tag">%</span>}
          </div>
        </Tooltip>
      ) : (
        <div>
          <span style={dataIndex !== 'length' ? { padding: '0 13px 1px 2px', color: '#1A1A1A' } : undefined}>
            {children}
          </span>
          {dataIsPercentage && dataIndex !== 'length' && record[dataIndex] !== undefined && (
            <span className="percentage-tag">%</span>
          )}
        </div>
      )}
    </td>
  );
};

import { Distribution, DistributionType } from './sizing';

export type SizingGroupInput = {
  groupKey: string;
  commodityGroupCode: string;
  preselectedSizeChartId?: string;
  sizeStructure?: string;
  sizingNote?: string;
};

export type ApplySizingGroupInput = {
  sizingGroup: SizingGroupInput;
  sizeChartCode: string;
  lengthChartCode?: string;
  sizeKeyId: string;
  sizeKey: string[];
  manualSizeKey?: boolean;
  manualSizeList?: string[];
  manualLengthList?: string[];
  manualSizeLengthSeparator?: string;
  distribution?: Distribution;
  distributionType?: DistributionType;
  recoStrategy?: string;
  recoLevel?: string;
  resetManualDistribution?: boolean;
  sizingNote?: string;
  sizeStructure?: string;
  referenceBrandCode?: string | null;
};

export type ApplyReviewGroupInput = {
  sizingGroup: SizingGroupInput;
  sizeChartCode: string;
  lengthChartCode?: string;
  sizeKeyId: string;
  sizeKey: string[];
  sizeList: string[];
  lengthList?: string[];
  sizeLengthSeparator?: string;
  manualSizeKey?: boolean;
  manualSizeList?: string[];
  manualLengthList?: string[];
  manualSizeLengthSeparator?: string;
  distribution?: Distribution;
  distributionType?: DistributionType;
  recoStrategy?: string;
  recoLevel?: string;
  resetManualDistribution?: boolean;
  sizingNote?: string;
  sizeStructure?: string;
  referenceBrandCode?: string | null;
};

export type UpdateOrderInput = {
  referenceId: string;
  brandCode: string;
  seasonCode: string;
  cgGroupingLevel: number;
  boxEnabled: boolean;
  articles: [ArticleInput];
};

export class ArticleInput {
  referenceId: string;
  commodityGroupCode: string;
  quantity: number;
  buyingArticleConfigId?: string;
  sizeStructure?: string | null;
  sizingNote?: string | null;
  sizeChartCode?: string;
  lengthChartCode?: string;
  sizeLengthSeparator?: string;
  sizeChartEditable = true;
  silo?: string;

  constructor(
    referenceId: string,
    commodityGroupCode: string,
    quantity: number,
    buyingArticleConfigId?: string,
    sizeStructure?: string | null,
    sizingNote?: string | null,
    sizeChartCode?: string,
    lengthChartCode?: string,
    sizeLengthSeparator?: string,
    sizeChartEditable = true,
    silo?: string
  ) {
    this.referenceId = referenceId;
    this.commodityGroupCode = commodityGroupCode;
    this.quantity = quantity;
    this.buyingArticleConfigId = buyingArticleConfigId;
    this.sizeStructure = sizeStructure;
    this.sizingNote = sizingNote;
    this.sizeChartCode = sizeChartCode;
    this.lengthChartCode = lengthChartCode;
    this.sizeLengthSeparator = sizeLengthSeparator;
    this.sizeChartEditable = sizeChartEditable;
    this.silo = silo;
  }

  commodityGroupCodeAtLevel(level: number): string {
    return this.commodityGroupCode.split('-').slice(0, level).join('-');
  }

  sizeChartId(): string {
    return `${this.sizeChartCode}${this.lengthChartCode ? ',' + this.lengthChartCode : ''}`;
  }
}

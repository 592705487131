import { Distribution, DistributionType, ReusedDecision, SizeChart, SizeKey, Sizing, SizingV2 } from './sizing';
import { Brand } from './brand';
import { ArticleInput } from './request';
import { CgGroupingLevel } from './sizing-group';
import { SelectedBoxRestriction } from './box-restriction';

export type Response = {
  success: boolean;
};

export enum ErrorType {
  NO_LINKED_SIZE_CHART = 'NO_LINKED_SIZE_CHART',
  INVALID_CG_CODE = 'INVALID_CG_CODE',
  INVALID_SEASON_CODE = 'INVALID_SEASON_CODE',
  INVALID_RESPONSE_FROM_SIZING_SPLIT_API = 'INVALID_RESPONSE_FROM_SIZING_SPLIT_API',
}

export type ErrorResponse = Response & {
  errorType: ErrorType;
};

export type SizingResponse = Response & {
  getAndSaveSizing: {
    sizing: Sizing[];
    isNewOrderCreated?: boolean;
    autoEnableBox?: boolean;
    buyerEmail?: string;
  };
};

export type SizeChartsResponse = Response & {
  sizeCharts: {
    sizeCharts: SizeChart[];
  };
};

export class ArticleResponse extends ArticleInput {
  id: string;
  editedQuantities?: Distribution;
  constructor(
    id: string,
    referenceId: string,
    commodityGroupCode: string,
    quantity: number,
    buyingArticleConfigId?: string,
    sizeStructure?: string | null,
    sizingNote?: string | null,
    sizeChartCode?: string,
    lengthChartCode?: string,
    sizeLengthSeparator?: string,
    sizeChartEditable = true,
    silo?: string,
    editedQuantities?: Distribution
  ) {
    super(
      referenceId,
      commodityGroupCode,
      quantity,
      buyingArticleConfigId,
      sizeStructure,
      sizingNote,
      sizeChartCode,
      lengthChartCode,
      sizeLengthSeparator,
      sizeChartEditable,
      silo
    );
    this.id = id;
    this.editedQuantities = editedQuantities;
  }
  static fromArticleInput(input: ArticleInput): ArticleResponse {
    return new ArticleResponse(
      input.referenceId,
      input.referenceId,
      input.commodityGroupCode,
      input.quantity,
      input.buyingArticleConfigId,
      input.sizeStructure,
      input.sizingNote,
      input.sizeChartCode,
      input.lengthChartCode,
      input.sizeLengthSeparator,
      input.sizeChartEditable,
      input.silo
    );
  }
}

export type GroupResponse = {
  id: string;
  key: string;
  commodityGroupCode: string;
  sizeStructure: string;
  sizingNote?: string;
  isReviewed: boolean;
  silo?: string;
  referenceBrandCode?: string;
  selectedBoxRestriction?: SelectedBoxRestriction;
  sizeChartEditable: boolean;
  sizeChartId?: string;
  enableBox: boolean;
  articles: ArticleResponse[];
  sizeChartList: SizeChart[];
  selectedSizing?: SizingV2;
  sizings: SizingV2[];
  reusedDecision?: ReusedDecision;
};

export type Order = {
  id: string;
  referenceId: string;
  seasonCode: string;
  brandCode: string;
  cgGroupingLevel: CgGroupingLevel;
  enableBox: boolean;
  status: string;
  buyerEmail: string;
  isNewOrderCreated: boolean;
  groups: GroupResponse[];
};
export type OrderResponse = {
  updateOrder: Order;
};

export type UpdateOrderErrorResponse = {
  ok: boolean;
  errorType: UpdateOrderErrorType;
};

export enum UpdateOrderErrorType {
  DUPLICATE_ARTICLE_REFERENCE_ID = 'DUPLICATE_ARTICLE_REFERENCE_ID',
  INVALID_CG_GROUPING_LEVEL = 'INVALID_CG_GROUPING_LEVEL',
}

export type ReferenceBrandsErrorResponse = {
  referenceBrands: {
    success?: boolean;
    errorType: ErrorType;
  };
};

export type ReferenceBrandsResponse = {
  referenceBrands: {
    success?: boolean;
    referenceBrands: Brand[];
  };
};

export type SilosResponse = {
  silos: string[];
};

export type SilosErrorResponse = {
  success: boolean;
  errorType: ErrorType;
};

export type ApplySizingGroupResponse = {
  distribution: Distribution;
  distributionType: DistributionType;
  groupKey: string;
  recoLevel: string;
  recoStrategy: string;
};

export type ApplySizingBulkResponse = {
  applySizingBulk: {
    results: ApplySizingGroupResponse[];
    ok: boolean;
  };
};

export enum ApplySizingBulkErrorType {
  NO_RECOMMENDATION = 'NO_RECOMMENDATION',
  MIXED_ORDER = 'MIXED_ORDER',
}

export type ApplySizingBulkErrorResponse = {
  applySizingBulk: {
    ok: boolean;
    errorType: ApplySizingBulkErrorType;
  };
};

export type ApplySizingDecisionResponse = {
  applySizingDecision: {
    ok: boolean;
    distribution: Distribution;
    distributionType: DistributionType;
    recoLevel: string;
    recoStrategy: string;
  };
};

export enum ApplySizingDecisionErrorType {
  INVALID_DECISION_ID = 'INVALID_DECISION_ID',
  DECISION_NOT_EXIST = 'DECISION_NOT_EXIST',
  INTERNAL_FAILURE = 'INTERNAL_FAILURE',
  NO_DECISION_TO_RESET = 'NO_DECISION_TO_RESET',
}

export type ApplySizingDecisionErrorResponse = {
  applySizingDecision: {
    ok: boolean;
    errorType: ApplySizingDecisionErrorType;
  };
};

export type UpdateOrderResponse = OrderResponse | UpdateOrderErrorResponse;
export type SizingQueryResponse = ErrorResponse | SizingResponse;
export type ReferenceBrandsQueryResponse = ReferenceBrandsErrorResponse | ReferenceBrandsResponse;
export type ApplySizingBulkResponseUnion = ApplySizingBulkResponse | ApplySizingBulkErrorResponse;
export type ApplySizingDecisionUnionResponse = ApplySizingDecisionResponse | ApplySizingDecisionErrorResponse;
export type SilosUnionResponse = SilosResponse | SilosErrorResponse;

export type ApplySizingResponse = {
  applySizing: SizeDistribution;
};

export type RecommendationResponse = {
  recommendation: SizeDistribution;
};
export class SizeDistribution {
  distribution: Distribution;
  distributionType: DistributionType;
  recoLevel?: string;
  recoStrategy?: string;
  constructor(
    distribution: Distribution,
    distributionType: DistributionType,
    recoLevel?: string,
    recoStrategy?: string
  ) {
    this.distribution = distribution;
    this.distributionType = distributionType;
    this.recoLevel = recoLevel;
    this.recoStrategy = recoStrategy;
  }

  static fromSizeKey(sizeKey: SizeKey): SizeDistribution {
    return new SizeDistribution(
      sizeKey.distribution!,
      sizeKey.distributionType!,
      sizeKey.recommendationLevel,
      sizeKey.recommendationStrategy
    );
  }
}

import isEqual from 'fast-deep-equal';
import { Box, BoxDistribution, Boxing, BoxRestrictionType } from '../models/box-restriction';
import { ArticleInput } from '../models/request';
import { Distribution } from '../models/sizing';
import { Article } from '../models/sizing-data';
import { SizingGroup } from '../models/sizing-group';

export function articleHasSizing(article: Article, sizingField: 'sizing' | 'blockOrderSizing' = 'sizing'): boolean {
  return !!(article[sizingField] && Object.getOwnPropertyNames(article[sizingField]).length);
}

export function sizeKeysMismatch(existingSizing: Distribution, newSizing: Distribution): boolean {
  return !isEqual(Object.getOwnPropertyNames(existingSizing).sort(), Object.getOwnPropertyNames(newSizing).sort());
}

export function sizechartIsNotEditable(article: Article): boolean {
  return !article.sizeChartEditable;
}
export function sizechartIsOverwritten(article: Article): boolean {
  return article.sizingNote?.endsWith(createSizeChartId(article)) || false;
}
export function getPreselectedSizeChartId(group: SizingGroup): string | undefined {
  return group.articles.some(sizechartIsNotEditable) ? createSizeChartId(group.articles[0]) : undefined;
}
export function getSizechartOverwrite(group: SizingGroup): string | undefined {
  return group.articles.some(sizechartIsOverwritten) ? createSizeChartId(group.articles[0]) : undefined;
}
export function createSizeChartId(article: Article): string {
  return `${article.sizeChartCode}${article.lengthChartCode ? ',' + article.lengthChartCode : ''}`;
}

export function getSizingFromArticleQuantity(articleQuantity: number, distribution?: Distribution): Distribution {
  const differences: Distribution = {};
  let calculatedQuantity = 0;

  const distributionEntries = Object.entries(distribution || {});

  const articleSizing = distributionEntries.reduce<Distribution>((map, [sizeKey, percentage]) => {
    const quantity = articleQuantity * (percentage / 100);
    const roundedQuantity = Math.max(Math.round(quantity), 0);
    calculatedQuantity += roundedQuantity;

    map[sizeKey] = roundedQuantity;

    differences[sizeKey] = quantity - roundedQuantity;

    return map;
  }, {});

  // Rounding lead to not having exact total quantity distributed (499 or 501 instead of 500).
  // Adjust sizes with the biggest difference till quantity is right
  const differencesEntries = Object.entries(differences || {});
  const differenceEntriesSortedByDifference = differencesEntries.sort((a, b) => Math.abs(b[1]) - Math.abs(a[1]));

  while (calculatedQuantity !== articleQuantity) {
    const difference = differenceEntriesSortedByDifference.shift();
    if (!difference) {
      break;
    }
    const [sizeKey, diffQuantity] = difference;
    const nonDecimalDifference = diffQuantity >= 0 ? 1 : -1;
    articleSizing[sizeKey] += nonDecimalDifference;
    calculatedQuantity += nonDecimalDifference;
  }
  return articleSizing;
}
export function addManuallyEditedFlag(article: Article, distribution?: Distribution): void {
  const articleSizing = getSizingFromArticleQuantity(article.quantity, distribution);
  if (
    articleHasSizing(article) &&
    !isEqual(articleSizing, article.sizing) &&
    !sizeKeysMismatch(articleSizing, article.sizing as Distribution) &&
    distribution
  ) {
    article.manuallyEdited = true;
  }
}

export function getBoxWithPerLotQuantity(distribution: BoxDistribution): Box {
  return {
    name: distribution.box.name,
    sizeQuantities: Object.keys(distribution.box.sizeQuantities).reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: distribution.quantity ? distribution.box.sizeQuantities[curr] / distribution.quantity : 0,
      }),
      {}
    ),
  };
}

export function convertTotalSizedQuantitiesToPerLot(boxing?: Boxing): Boxing | undefined {
  if (boxing && boxing.boxRestrictionType === BoxRestrictionType.LOT_GROUP) {
    boxing.boxDistributions = boxing.boxDistributions.map((distribution) => ({
      box: getBoxWithPerLotQuantity(distribution),
      quantity: distribution.quantity,
      availability: distribution.availability,
    }));
  }
  return boxing;
}

export function getBoxWithTotalSizedQuantity(distribution: BoxDistribution): Box {
  return {
    name: distribution.box.name,
    sizeQuantities: Object.keys(distribution.box.sizeQuantities).reduce(
      (prev, curr) => ({ ...prev, [curr]: distribution.box.sizeQuantities[curr] * distribution.quantity }),
      {}
    ),
  };
}

export function convertPerLotSizedQuantitiesToTotal(boxing?: Boxing): Boxing | undefined {
  if (boxing && boxing.boxRestrictionType === BoxRestrictionType.LOT_GROUP) {
    boxing.boxDistributions = boxing.boxDistributions.map((distribution) => ({
      box: getBoxWithTotalSizedQuantity(distribution),
      quantity: distribution.quantity,
      availability: distribution.availability,
    }));
  }
  return boxing;
}

export function convertArticleToArticleInput(article: Article): ArticleInput {
  const articleInput = new ArticleInput(article.referenceId, article.commodityGroup.code, article.quantity);

  articleInput.buyingArticleConfigId = article.buyingArticleConfigId;
  articleInput.sizeStructure = article.sizeStructure;
  articleInput.sizingNote = article.sizingNote;
  articleInput.sizeChartCode = article.sizeChartCode;
  articleInput.lengthChartCode = article.lengthChartCode;
  articleInput.sizeChartEditable = article.sizeChartEditable;
  articleInput.silo = article.silo;

  return articleInput;
}

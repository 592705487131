import React, { FC, useEffect, useState } from 'react';
import './ReuseDecisionSelector.less';
import { ReusedDecision, SizingDecision } from '../../../models/sizing';
import { Button, Select, Space, Typography, Popconfirm, Tag, message as Message } from '@retail-core/rds';
import { CloseOutlined } from '@ant-design/icons';

interface IProps {
  sizingDecisions?: SizingDecision[];
  reusedDecision?: ReusedDecision | null;
  onApplyDecision: (selectedDecisionId?: number) => Promise<boolean>;
  isManuallyEdited?: boolean;
  previouslyOrdered?: boolean;
}

const getReusedDecisionLabel = (decision: SizingDecision, isOption = true) => (
  <Space align={'baseline'}>
    <Typography.Text>{decision.sizeStructure}</Typography.Text>
    {decision.sizingNote ? <span className={'reuse-decision-selector-button-separator'} /> : null}
    {decision.sizingNote ? <Typography.Text>{decision.sizingNote}</Typography.Text> : null}
    {decision.edited && isOption ? <Tag color="purple">Edited %</Tag> : null}
  </Space>
);

const ReuseDecisionSelector: FC<IProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const options =
    props?.sizingDecisions?.map((d) => ({
      label: getReusedDecisionLabel(d),
      value: d.id,
    })) || [];
  const [selectedOption, setSelectedOption] = useState(props.reusedDecision?.selectedDecisionId);
  const onSelect = async (value?: number) => {
    if (props.isManuallyEdited && !popConfirmVisible && !props.reusedDecision) {
      setSelectedOption(value);
      return setPopConfirmVisible(true);
    }
    if (!loading) setLoading(true);
    const applied = await props.onApplyDecision(value);
    if (applied) {
      setSelectedOption(value);
    } else {
      Message.error('Decision could not be applied!');
      setSelectedOption(props.reusedDecision?.selectedDecisionId);
    }
    setLoading(false);
  };
  const clearSelection = async () => {
    setLoading(true);
    await onSelect();
    setLoading(false);
  };
  const confirm = () => {
    onSelect(selectedOption);
    setPopConfirmVisible(false);
  };
  const cancel = () => {
    setSelectedOption(props.reusedDecision?.selectedDecisionId);
    setPopConfirmVisible(false);
  };

  useEffect(() => {
    if (props.reusedDecision?.selectedDecisionId !== selectedOption) {
      setSelectedOption(props.reusedDecision?.selectedDecisionId);
    }
  }, [props.reusedDecision?.selectedDecisionId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (props.previouslyOrdered || !props.sizingDecisions?.length) {
    return (
      <>
        <label className="reuse-decision-label">Reuse decision:</label>
        <Select
          className={'reuse-decision-selector-disabled'}
          size="small"
          disabled
          bordered={false}
          placeholder={props.previouslyOrdered ? `No decisions available` : `No decisions yet this season`}
        />
      </>
    );
  }
  const selectedDecisionId = props.reusedDecision && props.reusedDecision.selectedDecisionId;
  const decision = props?.sizingDecisions?.find((sd) => sd.id === selectedDecisionId);
  if (selectedDecisionId && decision) {
    return (
      <>
        <label className="reuse-decision-label" htmlFor="group-reference-brand-selector">
          Reused decision:
        </label>
        <Button size="small" onClick={clearSelection} className={'reuse-decision-selector-button'}>
          <Space size={4}>
            {getReusedDecisionLabel(decision, false)}
            <CloseOutlined className={'reuse-decision-selector-button-icon'} />
          </Space>
        </Button>
      </>
    );
  }
  return (
    <Popconfirm
      visible={popConfirmVisible}
      title="Overwrite manual edits to re-use this decision?"
      placement="bottom"
      onConfirm={confirm}
      onCancel={cancel}
      okText="Overwrite"
      cancelText="Cancel"
      data-testid={`reuse-decision-selector-confirm`}
      arrowPointAtCenter
    >
      <label className="reuse-decision-label" htmlFor="group-reference-brand-selector">
        Reuse decision:
      </label>
      <Select
        size="small"
        bordered={false}
        dropdownMatchSelectWidth={false}
        placeholder={`${options.length} decisions earlier this season`}
        className={'reuse-decision-selector'}
        dropdownClassName={'reuse-decision-selector-dropdown'}
        onSelect={onSelect}
        value={selectedOption || []}
        loading={loading}
        options={[{ label: 'Decisions earlier this season for these CG-brand', options }]}
        data-testid="reuse-decision-selector"
      />
    </Popconfirm>
  );
};

export default ReuseDecisionSelector;

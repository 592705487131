import intersection from 'lodash.intersection';
import XLSX from 'xlsx';
import { BoxRestriction, BoxRestrictionType } from '../models/box-restriction';
import { SheetRow } from '../models/quantities-template';
import { Article } from '../models/sizing-data';
import { SizingGroup } from '../models/sizing-group';
import { SheetArticleSet } from './../models/quantities-template';
import { SizingStore } from './../stores/sizing/sizing-store';
import { boxingToStockRestrictions } from './box-restrictions-utils';

export function getTemplateName(attributes: string[]): string {
  return `Quantities per size_${attributes.filter(Boolean).join('_')}.xlsx`;
}

export function exportArticlesTemplate(templateJSON: SheetRow[], templateName: string): void {
  const header = [
    'Supplier Article Name',
    'Supplier Article Number',
    'Supplier Color Code',
    'Size',
    'Quantity',
    'Lot Names',
    'Availability Quantities',
  ];
  if (templateName.includes('errors')) {
    header.push('Errors');
  }
  const worksheet = XLSX.utils.json_to_sheet(templateJSON, { header });
  const templateWorkbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(templateWorkbook, worksheet, 'articles');
  XLSX.writeFile(templateWorkbook, templateName);
}

export function articlesToJSON(groups: SizingGroup[]): SheetRow[] {
  const templateRows: SheetRow[] = [];
  groups.forEach((group) => {
    group.articles.forEach((article) => {
      const { supplierArticleName, supplierArticleNumber, supplierColorCode, sizing = {}, boxing } = article;

      const stockRestrictions = boxingToStockRestrictions(boxing);

      const baseRow = {
        'Supplier Article Name': supplierArticleName,
        'Supplier Article Number': supplierArticleNumber,
        'Supplier Color Code': supplierColorCode,
        Size: '',
        Quantity: '',
        'Lot Names': '',
        'Availability Quantities': '',
      };

      if (
        !group.enableBox ||
        boxing?.boxRestrictionType === BoxRestrictionType.DIVISOR ||
        boxing?.boxRestrictionType === BoxRestrictionType.FIXED_BOX
      ) {
        group.selectedSizeKey?.sizeKey.forEach((size) => {
          templateRows.push({
            ...baseRow,
            Size: size,
            Quantity: sizing[size].toString(),
          });
        });

        return;
      }

      if (boxing?.boxRestrictionType === BoxRestrictionType.LOT_GROUP) {
        boxing.boxDistributions.forEach((boxDistribution) => {
          templateRows.push({
            ...baseRow,
            'Lot Names': boxDistribution.box.name,
            'Availability Quantities': stockRestrictions[boxDistribution.box.name] || '',
          });
        });
        return;
      }

      templateRows.push(baseRow);
    });
  });
  return templateRows;
}

export function getArticlesRowsList(data: Uint8Array): SheetRow[] {
  const workbook = XLSX.read(data, { type: 'array' });
  if (!~workbook.SheetNames.indexOf('articles')) {
    throw new Error('articles sheet not found!');
  }
  const sheet = workbook.Sheets['articles'];
  const sheetRows = XLSX.utils.sheet_to_json(sheet, { blankrows: false }) as SheetRow[];
  return sheetRows;
}

export function getArticlesFromRows(rows: SheetRow[]): SheetArticleSet {
  return rows.reduce((articlesMap, row) => {
    const {
      'Supplier Article Name': supplierArticleName,
      'Supplier Article Number': supplierArticleNumber,
      'Supplier Color Code': supplierColorCode,
      Size,
      Quantity,
      'Lot Names': lotName,
      'Availability Quantities': availability,
    } = row;
    const articleId = `${supplierArticleNumber};${supplierColorCode}`;

    const isBoxRestriction = Boolean(lotName);

    if (!(articleId in articlesMap)) {
      articlesMap[articleId] = {
        supplierArticleName,
        supplierArticleNumber,
        supplierColorCode,
        sizing: {},
        quantity: 0,
        availability: {},
      };
    }

    if (isBoxRestriction) {
      articlesMap[articleId].availability[lotName] = Number(availability);
    } else if (Size) {
      articlesMap[articleId].sizing[Size] = Number(Quantity);
      articlesMap[articleId].quantity += Number(Quantity);
    }

    return articlesMap;
  }, {} as SheetArticleSet);
}

export function validateArticlesData(
  sheetRows: SheetRow[],
  sizingStore: SizingStore
): { rows: SheetRow[]; errorsCount: number } {
  let errorsCount = 0;
  sheetRows.forEach((row) => {
    const { articles, boxRestrictions, sizingGroups } = sizingStore;
    const errors = [];
    const articleId = getSheetRowId(row);
    const matchedArticle1 = articles.find((article) => getArticleSheetId(article) === articleId);

    const groupWithSelectedBR =
      matchedArticle1 &&
      sizingGroups.find((group) => group.articles.find((x) => x.referenceId === matchedArticle1.referenceId));
    // TODO: Remove this once the updateArticle action is fixed to keep articles lists in sync
    const matchedArticle = groupWithSelectedBR?.articles.find((article) => getArticleSheetId(article) === articleId);
    const selectedBoxRestriction = boxRestrictions.find(
      (box) => box.id === groupWithSelectedBR?.boxRestriction?.boxRestrictionId
    );
    const isBoxRestrictionEnabled = groupWithSelectedBR?.enableBox;

    const { Quantity, 'Lot Names': lotName, 'Availability Quantities': availability, Size } = row;

    let matchedBoxRestriction: BoxRestriction | null = null;

    if (
      (Quantity || Size) &&
      matchedArticle?.boxing?.boxRestrictionType !== BoxRestrictionType.DIVISOR &&
      matchedArticle?.boxing?.boxRestrictionType !== BoxRestrictionType.FIXED_BOX &&
      isBoxRestrictionEnabled
    ) {
      errors.push('Article has Box Restrictions enabled');
    } else if (isNaN(Number(Quantity)) && !isBoxRestrictionEnabled) {
      errors.push('Invalid quantity value');
    } else if (isBoxRestrictionEnabled && availability !== undefined && isNaN(Number(availability))) {
      errors.push('Available quantity not valid (please enter a numeric value)');
    }

    if (isBoxRestrictionEnabled) {
      const lotNames = findLotNamesInSheet(
        sheetRows,
        matchedArticle?.supplierArticleNumber || '',
        matchedArticle?.supplierColorCode || ''
      );

      const boxRestriction = findBRCandidateLotByLotName(lotNames, boxRestrictions) || selectedBoxRestriction;

      if (boxRestriction && 'lotGroup' in boxRestriction) {
        matchedBoxRestriction = boxRestriction.lotGroup.some((lot) => lot.name === lotName) ? boxRestriction : null;

        if (matchedArticle?.boxing?.boxRestrictionId) {
          const existedBoxRestriction = boxRestrictions.find(
            (BR) => BR.id === matchedArticle?.boxing?.boxRestrictionId
          );

          if (existedBoxRestriction && 'lotGroup' in existedBoxRestriction) {
            if (lotNames.every((lotName) => existedBoxRestriction.lotGroup.map((lot) => lot.name).includes(lotName))) {
              matchedBoxRestriction = existedBoxRestriction;
            } else {
              errors.push('Provided data for availability does not match manually selected box restrictions');
            }
          }
        }
      } else if (matchedArticle?.boxing?.boxRestrictionType === BoxRestrictionType.DIVISOR) {
        if (Quantity && Number(Quantity) % matchedArticle.boxing.sizeDivisor !== 0) {
          errors.push(`This value is not matching divisor per size ${matchedArticle.boxing.sizeDivisor}`);
        } else if (!Quantity) {
          errors.push('Article Box Restriction Type is  Divisor per box or Divisor per Size');
        }
      } else if (matchedArticle?.boxing?.boxRestrictionType === BoxRestrictionType.FIXED_BOX) {
        const quantities = findQuantitiesInSheet(
          sheetRows,
          matchedArticle?.supplierArticleNumber || '',
          matchedArticle?.supplierColorCode || ''
        );

        if (
          Quantity &&
          Number(quantities.reduce((acc, cur) => acc + cur, 0)) % matchedArticle.boxing.boxDivisor !== 0
        ) {
          errors.push(`This value is not matching divisor per box ${matchedArticle.boxing.boxDivisor}`);
        } else if (!Quantity) {
          errors.push('Article Box Restriction Type is  Divisor per box or Divisor per Size');
        }
      } else {
        if (!lotName) {
          errors.push('Lot name not provided');
        }
      }
    }

    if (!matchedArticle && row['Supplier Article Number'] && row['Supplier Color Code']) {
      errors.push('Supplier article number / color code not matching');
    } else {
      ['Supplier Article Name', 'Supplier Article Number', 'Supplier Color Code', 'Size', 'Lot Names'].forEach(
        (field) => {
          let rowField = row[field];
          if (typeof rowField === 'string') {
            rowField = rowField.replace(/\r\n/g, '\n');
          }
          if (
            field === 'Size' &&
            matchedArticle?.sizing &&
            !(row[field] in matchedArticle.sizing) &&
            !isBoxRestrictionEnabled
          ) {
            errors.push(`Size not included in the article's size key`);
          } else if (
            !['Size', 'Lot Names'].includes(field) &&
            matchedArticle &&
            rowField !== matchedArticle[getFieldId(field)]
          ) {
            errors.push(`${field} not matching`);
          } else if (field === 'Lot Names' && !isBoxRestrictionEnabled && (availability || lotName)) {
            errors.push('Article does not have Box Restrictions enabled');
          } else if (field === 'Lot Names' && isBoxRestrictionEnabled && lotName) {
            if (
              matchedArticle?.boxing?.boxRestrictionType === BoxRestrictionType.FIXED_BOX ||
              matchedArticle?.boxing?.boxRestrictionType === BoxRestrictionType.DIVISOR
            ) {
              errors.push('Box restriction type for this article is divisor');
            } else if (!matchedBoxRestriction) {
              errors.push('Lot name not found (check for typos or create the lot in the Box Restrictions Catalog)');
            }
          }
        }
      );
    }

    row['Errors'] = errors.join(', ');
    errorsCount += errors.length;
  });

  return {
    rows: sheetRows,
    errorsCount: errorsCount,
  };
}

export function findLotNamesInSheet(
  sheetRows: SheetRow[],
  supplierArticleNumber: string,
  supplierColorCode: string
): string[] {
  const filteredSheetRows = sheetRows.filter(
    (row) =>
      row['Supplier Article Number'] === supplierArticleNumber &&
      row['Supplier Color Code'] === supplierColorCode &&
      row['Lot Names']
  );

  return filteredSheetRows.map((row) => row['Lot Names']);
}

export function findQuantitiesInSheet(
  sheetRows: SheetRow[],
  supplierArticleNumber: string,
  supplierColorCode: string
): number[] {
  const filteredSheetRows = sheetRows.filter(
    (row) =>
      row['Supplier Article Number'] === supplierArticleNumber &&
      row['Supplier Color Code'] === supplierColorCode &&
      row['Quantity']
  );

  return filteredSheetRows.map((row) => Number(row['Quantity']));
}

export function getBestBRMatchByLotName(lotNames: string[], boxRestriction: BoxRestriction): number {
  if ('lotGroup' in boxRestriction) {
    const existingLotNames = boxRestriction.lotGroup.map((lot) => lot.name);

    return intersection(existingLotNames, lotNames).length;
  }

  return 0;
}

export function findBRCandidateLotByLotName(
  lotNames: string[],
  boxRestrictions: BoxRestriction[]
): BoxRestriction | null {
  const boxRestrictionCandidates = boxRestrictions
    .filter((boxRestriction) => getBestBRMatchByLotName(lotNames, boxRestriction))
    .sort((boxRestriction, boxRestrictionSecondary) => {
      const matchedAccuracyOne = getBestBRMatchByLotName(lotNames, boxRestriction);
      const matchedAccuracyTwo = getBestBRMatchByLotName(lotNames, boxRestrictionSecondary);

      if (matchedAccuracyOne < matchedAccuracyTwo) {
        return 1;
      }

      if (matchedAccuracyOne > matchedAccuracyTwo) {
        return -1;
      }

      return 0;
    });

  return boxRestrictionCandidates[0] || null;
}

// Convert field column name to article key name (i.e. Supplier Article Number => supplierArticleNumber)
export function getFieldId(field: string): keyof Article {
  const fieldIdList = field.split(' ');
  fieldIdList[0] = fieldIdList[0].toLocaleLowerCase();
  return fieldIdList.join('') as keyof Article;
}

export function getArticleSheetId(article: Article): string {
  return `${article.supplierArticleNumber};${article.supplierColorCode}`;
}

export function getSheetRowId(row: SheetRow): string {
  return `${row['Supplier Article Number']};${row['Supplier Color Code']}`;
}

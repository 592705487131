import { Distribution } from '../../models/sizing';
import { Article } from '../../models/sizing-data';
import isEqual from 'fast-deep-equal';
import { articleHasSizing, getSizingFromArticleQuantity, sizeKeysMismatch } from '../../lib/article-utils';

export enum QuantityField {
  PREORDER = 1,
  BLOCKORDER = 2,
  BOTH = 3,
}

export class CalculateArticleQuantitiesAction {
  calculateArticleQuantities(
    article: Article,
    distribution?: Distribution,
    initialCalculation?: boolean,
    fieldToCalculate: QuantityField = QuantityField.BOTH
  ): void {
    if (fieldToCalculate & QuantityField.PREORDER) {
      this.calculateArticleNormalQuantities(article, distribution, initialCalculation);
    }
    if (article.blockOrderQuantity !== undefined && fieldToCalculate & QuantityField.BLOCKORDER) {
      this.calculateArticleBlockQuantities(article, distribution, initialCalculation);
    }
  }

  private calculateArticleBlockQuantities(
    article: Article,
    distribution?: Distribution,
    initialCalculation?: boolean
  ): void {
    if (article.blockOrderQuantity === undefined) {
      return;
    }
    const articleSizing = getSizingFromArticleQuantity(article.blockOrderQuantity, distribution);

    if (initialCalculation && articleHasSizing(article, 'blockOrderSizing')) {
      if (sizeKeysMismatch(articleSizing, article.blockOrderSizing as Distribution)) {
        article.blockOrderSizing = articleSizing;
      }
    } else {
      article.blockOrderSizing = articleSizing;
    }
  }
  private calculateArticleNormalQuantities(
    article: Article,
    distribution?: Distribution,
    initialCalculation?: boolean
  ): void {
    const articleSizing = getSizingFromArticleQuantity(article.quantity, distribution);

    if (initialCalculation && articleHasSizing(article)) {
      if (sizeKeysMismatch(articleSizing, article.sizing as Distribution)) {
        article.sizing = articleSizing;
      } else if (distribution && !isEqual(articleSizing, article.sizing)) {
        article.manuallyEdited = true;
      }
    } else {
      article.manuallyEdited = false;
      article.sizing = articleSizing;
    }
  }
}

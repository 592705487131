import { message } from '@retail-core/rds';
import { runInAction } from 'mobx';
import { getSizingFromArticleQuantity } from '../../lib/article-utils';
import { boxingToLotAvailability, boxingToStockRestrictions } from '../../lib/box-restrictions-utils';
import { sendSizingAppliedEvent } from '../../lib/fragment-helper';
import { Boxing, BoxOptimisationResponse, BoxRestrictionType, LOTQuantity } from '../../models/box-restriction';
import { Article } from '../../models/sizing-data';
import { SizingGroup } from '../../models/sizing-group';
import boxRestrictionsService from '../../services/box-restrictions-service';
import { sendEvent } from '../../services/event-tracking';
import { SizingStore } from './sizing-store';

export class OptimizeBoxQuantitiesAction {
  async optimizeBoxQuantities(this: SizingStore, group: SizingGroup, specificArticle?: Article): Promise<void> {
    // verify also, that there is no boxing field in the articles
    if (!group.boxRestriction?.boxRestrictionId) {
      return;
    }
    const boxRestrictionGotUpdated =
      this.boxRestrictions.filter((br) => group.boxRestriction?.boxRestrictionId === br.id).length !== 0;
    if (!boxRestrictionGotUpdated) {
      return;
    }

    await this.optimizeBoxQuantitiesGeneric(group, 'sizing', 'boxing', 'quantity', specificArticle);
    await this.optimizeBoxQuantitiesGeneric(
      group,
      'blockOrderSizing',
      'blockOrderBoxing',
      'blockOrderQuantity',
      specificArticle
    );
    sendEvent(group, 'applyBoxRestriction', undefined, this.additionalData());

    sendSizingAppliedEvent(group, specificArticle ? [specificArticle] : group.articles);
  }

  async optimizeBoxQuantitiesGeneric(
    this: SizingStore,
    group: SizingGroup,
    sizingFieldName: 'sizing' | 'blockOrderSizing',
    boxingFieldName: 'boxing' | 'blockOrderBoxing',
    quantityFieldName: 'quantity' | 'blockOrderQuantity',
    specificArticle?: Article
  ): Promise<void> {
    const boxOptimizationPayload = group.articles
      .filter(
        (article) =>
          (!specificArticle || article.referenceId === specificArticle.referenceId) &&
          article[quantityFieldName] &&
          group?.selectedSizeKey?.distribution
      )
      .map((article) => {
        const idealQuantity = (article[quantityFieldName] || 0) - (article[boxingFieldName]?.deviation || 0);
        const idealSizing = getSizingFromArticleQuantity(idealQuantity, group?.selectedSizeKey?.distribution);
        return {
          article_id: article.referenceId,
          desired_size_level_quantity: idealSizing || {},
          box_restriction_id: group.boxRestriction?.boxRestrictionId as string,
          stock_restrictions:
            article.boxing?.boxRestrictionId === group.boxRestriction?.boxRestrictionId
              ? boxingToStockRestrictions(article.boxing)
              : {},
        };
      });

    if (boxOptimizationPayload.length > 0) {
      const boxOptimizations = await boxRestrictionsService.optimizeBoxQuantities(boxOptimizationPayload);
      const optimizationsAreOptimal = boxOptimizations.every(
        (article: BoxOptimisationResponse) => article.fallback_level !== 'no_optimal_solution'
      );
      if (optimizationsAreOptimal) {
        const boxOptimizationsMap = boxOptimizations.reduce<Record<string, BoxOptimisationResponse>>(
          (map: Record<string, BoxOptimisationResponse>, article: BoxOptimisationResponse) => ({
            ...map,
            [article.article_id]: article,
          }),
          {}
        );
        const boxRestrictionEvents: any[] = [];
        runInAction(() => {
          group.articles.forEach((article: Article) => {
            const articleOptimization = boxOptimizationsMap[article.referenceId];
            articleOptimization &&
              this.applyArticleOptimization(
                article,
                articleOptimization,
                sizingFieldName,
                boxingFieldName,
                quantityFieldName
              );
          });
          Promise.all(boxRestrictionEvents);
        });
      } else {
        message.error('Optimization failed for some articles, please choose another box restrictions catalog!', 5);
      }
    }
  }

  applyArticleOptimization(
    this: SizingStore,
    article: Article,
    articleOptimization: BoxOptimisationResponse,
    sizingFieldName: 'sizing' | 'blockOrderSizing',
    boxingFieldName: 'boxing' | 'blockOrderBoxing',
    quantityFieldName: 'quantity' | 'blockOrderQuantity'
  ): void {
    article.manuallyEdited = false;
    article[sizingFieldName] = articleOptimization.optimum_size_level_quantity;
    article[quantityFieldName] = articleOptimization.optimum_total_quantity;
    const boxing = {
      boxRestrictionId: articleOptimization.box_restriction.id,
      boxRestrictionType: articleOptimization.box_restriction.type,
      deviation: articleOptimization.total_deviation,
    } as Boxing;
    if (boxing.boxRestrictionType === BoxRestrictionType.LOT_GROUP) {
      boxing.boxDistributions =
        articleOptimization.optimum_lot_quantities?.map((lotQuantity: LOTQuantity) => ({
          box: {
            name: lotQuantity.name,
            sizeQuantities: lotQuantity.size_level_quantity,
          },
          quantity: lotQuantity.quantity,
          availability: boxingToLotAvailability(article.boxing, lotQuantity.name),
        })) || [];
      article[boxingFieldName] = boxing;
    } else if (boxing.boxRestrictionType === BoxRestrictionType.DIVISOR) {
      boxing.sizeDivisor = articleOptimization.box_restriction.box_value || 0;
      article[boxingFieldName] = boxing;
    } else if (boxing.boxRestrictionType === BoxRestrictionType.FIXED_BOX) {
      boxing.boxDivisor = articleOptimization.box_restriction.box_value || 0;
      article[boxingFieldName] = boxing;
    }
  }
}

import React, { FC } from 'react';
import { Checkbox } from '@retail-core/rds';
import './SelectableCell.less';

interface Item {
  [key: string]: string | boolean;
}

export interface IProps {
  title: React.ReactNode;
  selectable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Item;
  onCellChange: (row: string, column: string, value: boolean, multipleSelection: boolean) => void;
}

export const SelectableCell: FC<IProps> = ({ selectable, dataIndex, record, onCellChange }) => {
  if (!record) {
    return null;
  }

  const save = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    onCellChange(record.key as string, dataIndex, record[dataIndex] as boolean, event.shiftKey);
  };

  return selectable ? (
    <td className="selectable-cell">
      <div className="selectable-cell-form">
        <Checkbox checked={!!record[dataIndex]} data-selected={!!record[dataIndex]} onClick={save} />
      </div>
    </td>
  ) : (
    <td className="length-column ant-table-cell-fix-left ant-table-cell-fix-left-last">{record[dataIndex]}</td>
  );
};

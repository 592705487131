import { runInAction } from 'mobx';
import { sendSizingAppliedEventForGroups } from '../../lib/fragment-helper';
import presizeBackend from '../../services/presize-backend';
import { SizingStore } from './sizing-store';
import { DistributionType } from '../../models/sizing';
import { message } from '@retail-core/rds';
import { ApplySizingGroupInput } from '../../models/request';
import { ApplySizingBulkResponse, ApplySizingBulkResponseUnion, ApplySizingGroupResponse } from '../../models/response';
import { getPreselectedSizeChartId } from '../../lib/article-utils';
import { sendEvent } from '../../services/event-tracking';

export class ApplyReferenceBrandAction {
  async bulkApplyReferenceBrand(this: SizingStore, referenceBrandCode?: string | null): Promise<boolean> {
    const groups: ApplySizingGroupInput[] = this.sizingGroups
      .filter((group) => {
        if (referenceBrandCode) {
          return true;
        }
        if (!group.selectedSizeKey || !group.selectedSizeChart) {
          return false;
        }
        return group.sizing?.referenceBrandCode;
      })
      .map((group) => {
        const { commodityGroup, groupKey, selectedSizeKey, selectedSizeChart, sizingNote, sizeStructure } = group;
        if (!selectedSizeKey || !selectedSizeChart) {
          throw new Error('Filter error in bulkApplyReferenceBrand');
        }
        return {
          sizingGroup: {
            groupKey,
            commodityGroupCode: commodityGroup.code,
            preselectedSizeChartId: getPreselectedSizeChartId(group),
            sizeStructure,
            sizingNote,
          },
          sizeChartCode: selectedSizeChart.sizeChartCode,
          lengthChartCode: selectedSizeChart.lengthChartCode,
          sizeKeyId: selectedSizeKey.sizeKeyId,
          sizeKey: selectedSizeKey.sizeKey,
          manualSizeKey: selectedSizeKey.manualSizeKey,
          manualSizeList: selectedSizeKey.manualSizeKey ? selectedSizeKey.sizeList : [],
          manualLengthList: selectedSizeKey.manualSizeKey ? selectedSizeKey.lengthList : [],
          manualSizeLengthSeparator: selectedSizeKey.manualSizeKey ? selectedSizeKey.sizeLengthSeparator : undefined,
          distribution:
            selectedSizeKey.distributionType === DistributionType.MANUAL ? selectedSizeKey.distribution : undefined,
          resetManualDistribution: false,
          sizingNote,
          sizeStructure,
        };
      });
    const applySizingBulkResponse: ApplySizingBulkResponseUnion = await presizeBackend.applySizingBulk(
      this.referenceId,
      this.brandCode,
      this.seasonCode,
      groups,
      referenceBrandCode || null
    );
    if (!applySizingBulkResponse.applySizingBulk.ok) {
      message.error('No recommendation found, please use another reference brand', 5);
      return false;
    }
    await runInAction(async () => {
      const responseMap: Record<string, ApplySizingGroupResponse> = {};
      (applySizingBulkResponse as ApplySizingBulkResponse).applySizingBulk.results.forEach((response) => {
        responseMap[response.groupKey] = response;
      });
      const validGroups = this.sizingGroups.filter((group) => group.selectedSizeKey && responseMap[group.groupKey]);
      for (const group of validGroups) {
        const sizeDist = responseMap[group.groupKey];
        group.selectedSizeKey!.distribution = sizeDist.distribution;
        group.selectedSizeKey!.distributionType = sizeDist.distributionType;
        group.selectedSizeKey!.recommendationLevel = sizeDist.recoLevel;
        group.selectedSizeKey!.recommendationStrategy = sizeDist.recoStrategy;
        if (group.sizing) {
          group.sizing.referenceBrandCode = referenceBrandCode || null;
          group.reusedDecision = null;
        }
        const articlesWithChangedSizing = await this.calculateQuantities(false, group);
        if (articlesWithChangedSizing?.length) {
          sendEvent(group, 'applyReferenceBrand', articlesWithChangedSizing, this.additionalData());
        }
      }
      this.processReferenceBrandsWithUsage();

      // Notify host application about changed quantities
      sendSizingAppliedEventForGroups(this.sizingGroups);
    });
    return true;
  }
  async applyReferenceBrand(
    this: SizingStore,
    referenceBrandCode?: string | null,
    selectedGroupKey?: string
  ): Promise<boolean> {
    const selectedGroup = this.sizingGroups.find((group) => group.groupKey === selectedGroupKey);
    if (!selectedGroup) {
      throw new Error('Size group could not be found');
    }
    const { selectedSizeKey, selectedSizeChart, sizingNote, sizeStructure, sizing } = selectedGroup;
    if (!selectedSizeKey || !selectedSizeChart) {
      return true;
    }
    const sizeDistribution = await presizeBackend.applySizing(
      this.referenceId,
      this.brandCode,
      this.seasonCode,
      selectedGroup,
      selectedSizeChart,
      selectedSizeKey,
      false,
      referenceBrandCode || null,
      sizeStructure,
      sizingNote
    );
    if (sizeDistribution.distributionType === DistributionType.UNKNOWN) {
      message.error('No recommendation found, please use another reference brand', 5);
      return false;
    }
    runInAction(async () => {
      selectedSizeKey.distribution = sizeDistribution.distribution;
      selectedSizeKey.distributionType = sizeDistribution.distributionType;
      selectedSizeKey.recommendationLevel = sizeDistribution.recoLevel;
      selectedSizeKey.recommendationStrategy = sizeDistribution.recoStrategy;
      if (sizing) {
        sizing.referenceBrandCode = referenceBrandCode || null;
        this.processReferenceBrandsWithUsage();
        selectedGroup.reusedDecision = null;
      }
      const articlesWithChangedSizing = await this.calculateQuantities(false, selectedGroup);
      if (articlesWithChangedSizing?.length) {
        await sendEvent(selectedGroup, 'applyReferenceBrand', articlesWithChangedSizing, this.additionalData());
      }
    });
    return true;
  }
}

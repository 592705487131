import { boxApi, HTTP_METHOD } from '../lib/api';
import {
  BoxOptimisationPayloadPerArticle,
  BoxOptimisationResponse,
  BoxRestrictionsResponse,
} from '../models/box-restriction';

export class BoxRestrictionsService {
  getBoxRestrictions(
    brandCode: string,
    commodityGroupCode: string,
    supplierCode?: string
  ): Promise<BoxRestrictionsResponse[]> {
    return boxApi<{ items: BoxRestrictionsResponse[] }>(HTTP_METHOD.GET, 'box-restrictions', {
      brand_code: brandCode,
      cg_code_2: commodityGroupCode,
      supplier_code: supplierCode,
      basic_order_type: 'PREORDER',
    }).then((data) => data.items);
  }
  optimizeBoxQuantities(
    boxOptimisationPayload: BoxOptimisationPayloadPerArticle[]
  ): Promise<BoxOptimisationResponse[]> {
    return boxApi<{ items: BoxOptimisationResponse[] }>(
      HTTP_METHOD.POST,
      'preorder-optimizations',
      boxOptimisationPayload
    ).then((data) => data.items);
  }
}

export default new BoxRestrictionsService();

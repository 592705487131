import { observer } from 'mobx-react-lite';
import React, { FC, useMemo } from 'react';
import { Select } from '@retail-core/rds';
import { SizeChart, SizeKey, SizingKey } from '../../../models/sizing';
import { QuantitiesOverwriteModal } from '../../partials/ModalConfirm/ModalConfirm';
import { abbreviateSizeKey } from '../../../lib/sizing-abbreviator';

type IProps = {
  selectedSizeChart: SizeChart;
  selectedSizeKey?: SizeKey;
  numberOfEditedArticles?: number;
  readOnly?: boolean;
  onSizingChange: (
    sizingKey: SizingKey,
    keepSizing?: boolean,
    sizeChartManuallyChanged?: boolean,
    sizeKeyManuallyChanged?: boolean
  ) => void;
  onSizingSelector: () => void;
};

export const SizeKeyPicker: FC<IProps> = observer((props) => {
  const { Option, OptGroup } = Select;
  const {
    selectedSizeKey,
    selectedSizeChart,
    numberOfEditedArticles = 0,
    readOnly,
    onSizingChange,
    onSizingSelector,
  } = props;

  const handleChange = (value: string) => {
    if (value === 'open-size-selection') {
      onSizingSelector();
      return;
    }
    const sizingKey: SizingKey = {
      sizeChartId: selectedSizeChart.sizeChartId,
      sizeKeyId: value,
    };
    if (numberOfEditedArticles > 0) {
      QuantitiesOverwriteModal(numberOfEditedArticles, () => {
        onSizingChange(sizingKey, undefined, undefined, true);
      });
    } else {
      onSizingChange(sizingKey, undefined, undefined, true);
    }
  };

  const siloSizeKeys = useMemo(
    () =>
      selectedSizeChart.sizeKeys
        .filter((sizeKey) => !sizeKey.manualSizeKey && sizeKey.configCount)
        .map((sizeKey) => ({ ...sizeKey, abbreviation: abbreviateSizeKey(sizeKey.sizeKeyId, selectedSizeChart) })),
    [selectedSizeChart]
  );
  const recommendedSizeKeys = useMemo(
    () =>
      selectedSizeChart.sizeKeys
        .filter((sizeKey) => !sizeKey.manualSizeKey && !sizeKey.configCount)
        .map((sizeKey) => ({ ...sizeKey, abbreviation: abbreviateSizeKey(sizeKey.sizeKeyId, selectedSizeChart) })),
    [selectedSizeChart]
  );
  // We have to add selectedSizeKey to useMemo dependencies in order to update the list when adding new manual sizeKey
  const manualSizeKeys = useMemo(
    () =>
      selectedSizeChart.sizeKeys
        .filter((sizeKey) => sizeKey.manualSizeKey)
        .map((sizeKey) => ({ ...sizeKey, abbreviation: abbreviateSizeKey(sizeKey.sizeKeyId, selectedSizeChart) })),
    [selectedSizeChart, selectedSizeKey] //eslint-disable-line
  );
  return (
    <Select
      placeholder="Select sizes"
      value={selectedSizeKey?.sizeKeyId}
      onSelect={handleChange}
      disabled={readOnly}
      size="small"
      virtual={false}
    >
      {siloSizeKeys.length && (
        <OptGroup
          label="Used for this silo in the last 4 seasons sales, newer season weighted higher (CSKUs)"
          key="silo"
        >
          {siloSizeKeys.map(({ sizeKeyId, abbreviation, configCount }) => {
            return (
              <Option key={sizeKeyId} value={sizeKeyId} title={sizeKeyId}>
                {`${abbreviation} (${configCount})`}
              </Option>
            );
          })}
        </OptGroup>
      )}
      {recommendedSizeKeys.length && (
        <OptGroup label="Most used sizekeys in the mirror season, same brand and CG" key="recommended">
          {recommendedSizeKeys.map(({ sizeKeyId, abbreviation }) => {
            return (
              <Option key={sizeKeyId} value={sizeKeyId} title={sizeKeyId}>
                {abbreviation}
              </Option>
            );
          })}
        </OptGroup>
      )}
      <OptGroup label="New" key="manual">
        {manualSizeKeys.map(({ sizeKeyId, abbreviation }) => {
          return (
            <Option key={sizeKeyId} value={sizeKeyId} title={sizeKeyId}>
              {abbreviation}
            </Option>
          );
        })}
        <Option value="open-size-selection">Select the sizes yourself</Option>
      </OptGroup>
    </Select>
  );
});

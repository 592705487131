import { sendEvent } from '../../services/event-tracking';
import presizeBackend from '../../services/presize-backend';
import { QuantityField } from './calculate-article-quantities-action';
import { SizingStore } from './sizing-store';

export class ChangeArticleQuantityAction {
  async changeArticleQuantity(
    this: SizingStore,
    articleReferenceId: string,
    quantity?: number,
    blockOrderQuantity?: number
  ): Promise<void> {
    const group = this.groupByArticleRefId[articleReferenceId];
    if (!group) {
      throw new Error(`There is no article with referenceId ${articleReferenceId}`);
    }
    const article = group.articles.find((potentialArticle) => potentialArticle.referenceId === articleReferenceId);
    if (!article) {
      throw new Error(`There is no article with referenceId ${articleReferenceId}`);
    }
    let fieldToCalculate = 0;
    if (quantity !== undefined) {
      article.quantity = quantity;
      fieldToCalculate = QuantityField.PREORDER;
      delete article.boxing;
    }
    if (blockOrderQuantity !== undefined) {
      article.blockOrderQuantity = blockOrderQuantity;
      fieldToCalculate |= QuantityField.BLOCKORDER;
      delete article.blockOrderBoxing;
    }
    await this.calculateQuantities(false, group, [article.referenceId], fieldToCalculate);
    await sendEvent(group, 'changeArticleQuantity', [article], this.additionalData());
    presizeBackend
      .resetEditedQuantities(this.referenceId, articleReferenceId)
      .then(() => group.resetEditedQuantities());
    this.sortArticlesByQuantity(group.articles);
  }
}

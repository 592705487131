import React, { useEffect, useState } from 'react';
import { Col, Drawer, message as Message, Row, Tooltip, Typography, Alert, Divider } from '@retail-core/rds';
import { useStore } from '../StoreContext/StoreContext';
import { observer } from 'mobx-react-lite';
import { InfoCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { ArticleFieldSelector } from './ArticleFieldSelector';
import { Article } from '../../models/sizing-data';
import './SelectionSidebar.less';

export const SelectionSidebar = observer((props) => {
  const { uiStore, sizing } = useStore();
  const [loading, setLoading] = useState(false);
  const articleMap: Record<string, Article> = sizing.articles.reduce((articlemap, article) => {
    return { ...articlemap, [article.referenceId]: article };
  }, {});
  const selectedArticles: Array<Article> = [];
  const selectedGroups: Array<string> = Object.keys(uiStore.uIState).reduce(
    (groups: Array<string>, groupKey: string) => {
      if (uiStore.uIState[groupKey].selected) {
        groups.push(groupKey);
        Object.keys(uiStore.uIState[groupKey].articles)
          .filter((articleId) => uiStore.uIState[groupKey].articles[articleId].selected)
          .forEach((articleId) => {
            selectedArticles.push(articleMap[articleId]);
          });
      }
      return groups;
    },
    []
  );
  useEffect(() => {
    async function getSilos() {
      await sizing.getSilos(uiStore.selectedCG3List);
    }
    if (uiStore.selectedCG3List.length) {
      getSilos();
    }
  }, [sizing, uiStore.selectedCG3List]);

  const hasManuallyEditedArticles = selectedArticles.some((article) => article.manuallyEdited);
  const hasBoxEnabledArticles = selectedArticles.some((article) => article.boxing?.boxRestrictionId);
  let title = (
    <div>
      <Typography.Title level={5} className="selection-sidebar-title">
        {selectedArticles[0]?.supplierArticleName || ' '}
      </Typography.Title>
      <Typography.Text type="secondary">{selectedArticles[0]?.supplierColorDescription}</Typography.Text>
    </div>
  );
  if (selectedArticles.length > 1) {
    title = (
      <Typography.Title level={5} className="selection-sidebar-title">
        {selectedArticles.length} articles selected
      </Typography.Title>
    );
  }
  const updateArticles = (articleIds: Array<string>, modifier: Record<string, string | null>) => {
    Message.loading({
      content: `Moving ${selectedArticles.length} article${selectedArticles.length > 1 ? 's' : ''}...`,
      duration: 0,
      key: 'loadingMessage',
    });
    setTimeout(async () => {
      setLoading(true);
      await sizing.updateArticles(selectedGroups, selectedArticles, modifier);
      setLoading(false);
      Message.success({
        content: `Moved ${selectedArticles.length} article${selectedArticles.length > 1 ? 's' : ''}`,
        duration: 1,
        key: 'loadingMessage',
      });
    }, 100);
  };
  const onClose = () => uiStore.unselectArticles(selectedGroups);
  return (
    <Drawer
      data-testid="selection-sidebar"
      className="selection-sidebar"
      closeIcon={<CloseOutlined />}
      title={title}
      placement="right"
      onClose={onClose}
      visible={!!selectedArticles.length || loading}
      mask={false}
      destroyOnClose
      width={426}
    >
      <Row className="sidebar-row-header" style={{ marginBottom: 8 }}>
        <Col span={24}>
          <Typography.Text strong>Sizing</Typography.Text>
        </Col>
      </Row>
      <Row>
        <Col>
          <Alert
            type="info"
            message="Group articles differently? Select articles and specify size structure, Fit or Silo."
            style={{ marginBottom: 8 }}
          />
        </Col>
      </Row>
      <Row align="middle" wrap={false} className="sidebar-row">
        <Col span={10}>
          <Typography.Text type="secondary" className="sidebar-secondary-text" style={{ fontSize: 12 }}>
            Size structure
          </Typography.Text>
        </Col>
        <Col span={14}>
          <ArticleFieldSelector
            selectedArticles={selectedArticles}
            sizingGroups={sizing.sizingGroups}
            updateArticles={updateArticles}
            loading={loading}
            hasManuallyEditedArticles={hasManuallyEditedArticles}
            hasBoxEnabledArticles={hasBoxEnabledArticles}
            field="sizeStructure"
            placeholder="Create or attach a size structure"
            nullOptionDisplay="(No size structure)"
            nullValueDisplay="Add size structure"
          />
        </Col>
      </Row>
      <Row align="middle" wrap={false} className="sidebar-row">
        <Col span={10}>
          <Typography.Text type="secondary" className="sidebar-secondary-text" style={{ fontSize: 12 }}>
            Fit or look &nbsp;
            <Tooltip title="Add a fit or look to group articles by size characteristics and manually improve sizing">
              <InfoCircleOutlined />
            </Tooltip>
          </Typography.Text>
        </Col>
        <Col span={14}>
          <ArticleFieldSelector
            selectedArticles={selectedArticles}
            sizingGroups={sizing.sizingGroups}
            updateArticles={updateArticles}
            loading={loading}
            hasManuallyEditedArticles={hasManuallyEditedArticles}
            hasBoxEnabledArticles={hasBoxEnabledArticles}
            field="sizingNote"
            placeholder="Create or attach a fit or look"
            nullOptionDisplay="(No fit or look)"
            nullValueDisplay="Add fit or look"
            commonOptions={['Half sizes', 'Platform', 'Plus', 'Small fit', 'Wide fit']}
          />
        </Col>
      </Row>
      <Row align="middle" wrap={false} className="sidebar-row">
        <Col span={10}>
          <Typography.Text type="secondary" className="sidebar-secondary-text" style={{ fontSize: 12 }}>
            Silo or style &nbsp;
            <Tooltip
              title="Group articles by certain silos. When silos appear in the dropdown, silo based recommendations are available for them.
              Just used for sizing on this order, NOT forwarded to other systems."
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Typography.Text>
        </Col>
        <Col span={14}>
          <ArticleFieldSelector
            selectedArticles={selectedArticles}
            sizingGroups={sizing.sizingGroups}
            updateArticles={updateArticles}
            loading={loading || sizing.loadingSilos}
            hasManuallyEditedArticles={hasManuallyEditedArticles}
            hasBoxEnabledArticles={hasBoxEnabledArticles}
            field="silo"
            placeholder="Start typing to assign a new silo or style"
            nullOptionDisplay="(No silo or style)"
            nullValueDisplay="Add silo or style"
            commonOptions={sizing.silos}
          />
        </Col>
      </Row>
      <Row style={{ marginLeft: -24, marginRight: -24 }}>
        <Divider></Divider>
      </Row>
      {selectedArticles.length === 1 && (
        <>
          <Row style={{ marginTop: 4 }}>
            <Col span={24}>
              <Typography.Text strong>Comments</Typography.Text>
            </Col>
          </Row>
          <Row align="middle" wrap={false} className="sidebar-row">
            <Col span={24}>
              <Typography.Text>{selectedArticles[0].comment}</Typography.Text>
            </Col>
          </Row>
        </>
      )}
    </Drawer>
  );
});

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CopyOutlined, EditOutlined, ExclamationCircleFilled, WarningOutlined } from '@ant-design/icons';
import { BoxRestrictionView } from '@ism/box-restrictions';
import { Button, Modal, Select, Tag, Tooltip } from '@retail-core/rds';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { abbreviateSizeRange, checkCompatible } from '../../../../lib/box-restrictions-utils';
import { BoxRestriction, BoxRestrictionType } from '../../../../models/box-restriction';
import { CompatibleState, SortKey } from '../../../../models/sizing';
import { SizingGroup } from '../../../../models/sizing-group';
import { ConditionalWrapper } from '../../../partials/ConditionalWrapper/ConditionalWrapper';
import { useStore } from '../../../StoreContext/StoreContext';
import './BoxRestrictionsSelector.less';
import { getCookie } from '../../../../lib/fragment-helper';

const { Option } = Select;

interface IProps {
  sizingGroup: SizingGroup;
  boxRestrictions: BoxRestriction[];
  selectedSizeKey?: SortKey;
}

const BoxRestrictionsSelector: FC<IProps> = observer(function BoxRestrictionsSelector(props: IProps) {
  const { sizingGroup, boxRestrictions } = props;
  const [selectedBoxRestriction, setSelectedBoxRestriction] = useState(sizingGroup.boxRestriction?.boxRestrictionId);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { uiStore, sizing: sizingStore } = useStore();
  const developerMode = getCookie('enable_developer_mode') === 'true';
  const compatibleLotGroups = useMemo(
    () =>
      Object.fromEntries(
        boxRestrictions
          .filter(
            (box) =>
              box.type === BoxRestrictionType.LOT_GROUP &&
              checkCompatible(box.sizeRange, sizingGroup.selectedSizeKey!) !== CompatibleState.NOT
          )
          .map((box) => {
            return [
              box.id,
              box.type === BoxRestrictionType.LOT_GROUP && checkCompatible(box.sizeRange, sizingGroup.selectedSizeKey!),
            ];
          })
      ),
    [boxRestrictions, boxRestrictions.length, sizingGroup.selectedSizeKey] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleSelectBox = useCallback(
    (value: string): void => {
      if (value === 'none') {
        handleDisableBox();
      } else {
        if (!sizingGroup.enableBox) {
          sizingStore.toggleGroupEnableBox(sizingGroup.groupKey);
        }
        setSelectedBoxRestriction(value);
        const compatible = compatibleLotGroups[value];
        const box = boxRestrictions.find(({ id }) => id === value);
        const sizingGroupKey = {
          groupKey: sizingGroup.groupKey,
          commodityGroupCode: sizingGroup.commodityGroup.code,
        };

        if (box?.type !== BoxRestrictionType.LOT_GROUP || compatible === CompatibleState.FULLY) {
          (document.activeElement as HTMLElement)?.blur();
          sizingStore.setGroupBoxRestriction(sizingGroup.groupKey, value);
          return;
        }

        if (compatible === CompatibleState.PARTIALLY) {
          const sizeKey = abbreviateSizeRange(box.sizeRange);
          const newSizingKey = {
            sizeKeyId: box.sizeRange.join(';'),
            sizeChartId: sizingGroup.selectedSizeChart?.sizeChartId as string,
          };

          Modal.confirm({
            title: 'Update size key and overwrite manual adjustments?',
            icon: <ExclamationCircleFilled />,
            content: (
              <p>
                This preset sizing lot requires the following size key: {sizeKey}. <br />
                Update the sizes in your group to match it? <br />
                Any manual adjustments will be overwritten if you proceed.
              </p>
            ),
            okText: 'Update size key',
            centered: true,
            onOk() {
              (document.activeElement as HTMLElement)?.blur();
              sizingStore.changeSizing(sizingGroupKey, newSizingKey);
              sizingStore.setGroupBoxRestriction(sizingGroup.groupKey, value);
            },
            onCancel() {
              setSelectedBoxRestriction(sizingGroup.boxRestriction?.boxRestrictionId ?? undefined);
            },
          });
          return;
        }
      }
    },
    [sizingGroup.groupKey, sizingGroup.enableBox, sizingStore, compatibleLotGroups]
  );

  const handleDisableBox = useCallback(() => {
    Modal.confirm({
      title: 'Box restrictions recommendations will be lost if you continue.',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes, disable box restrictions',
      centered: true,
      onOk() {
        if (sizingGroup.enableBox) {
          sizingStore.toggleGroupEnableBox(sizingGroup.groupKey);
        }
      },
      onCancel() {
        return;
      },
    });
  }, []);

  const openDropdown = useCallback(() => setDropdownOpen(true), []);
  const closeDropdown = useCallback(() => setDropdownOpen(false), []);

  const handleClickCreate = useCallback(
    (event) => {
      event.preventDefault();
      (document.activeElement as HTMLElement)?.blur();
      uiStore.showBoxCatalog(BoxRestrictionView.Create, sizingGroup);
    },
    [sizingGroup, uiStore]
  );
  const handleEditClick = useCallback(
    (event, boxRestrictionId) => {
      event.stopPropagation();
      (document.activeElement as HTMLElement)?.blur();
      uiStore.showBoxCatalog(BoxRestrictionView.Edit, sizingGroup, boxRestrictionId);
    },
    [sizingGroup, uiStore]
  );
  const handleCopyClick = useCallback(
    (event, boxRestrictionId) => {
      event.stopPropagation();
      (document.activeElement as HTMLElement)?.blur();
      uiStore.showBoxCatalog(BoxRestrictionView.Create, sizingGroup, boxRestrictionId);
    },
    [sizingGroup, uiStore]
  );
  useEffect(() => {
    if (sizingGroup.boxRestriction?.boxRestrictionId !== selectedBoxRestriction) {
      setSelectedBoxRestriction(sizingGroup.boxRestriction?.boxRestrictionId ?? undefined);
    }
  }, [sizingGroup.boxRestriction?.boxRestrictionId]);

  return (
    <>
      {boxRestrictions.length === 0 ? (
        <div className="create-box-link">
          <span className="rds-link" onClick={handleClickCreate}>
            Create a box restriction
          </span>
        </div>
      ) : (
        <div className="box-restrictions-selector">
          <label className="selector-label" htmlFor={`${sizingGroup.groupKey}-restriction-select`}>
            Box restriction:
          </label>
          <Select
            size="small"
            placeholder="Select box restriction"
            id={`${sizingGroup.groupKey}-restriction-select`}
            value={sizingGroup.enableBox ? selectedBoxRestriction : 'none'}
            optionLabelProp="label"
            bordered={false}
            dropdownMatchSelectWidth={false}
            onChange={handleSelectBox}
            open={dropdownOpen}
            onFocus={openDropdown}
            onBlur={closeDropdown}
            dropdownRender={(menu) => (
              <div className="presize-root">
                {menu}
                <div className="create-box-option">
                  <span className="rds-link" onMouseDown={handleClickCreate}>
                    Create box restrictions
                  </span>
                </div>
              </div>
            )}
          >
            <Option value="none" data-testid="none" label="None">
              - None -
            </Option>
            {boxRestrictions.map((box) => {
              const isCompatible = box.type !== BoxRestrictionType.LOT_GROUP || compatibleLotGroups[box.id];

              return (
                <Option value={box.id} key={box.id} label={box.name} disabled={!isCompatible}>
                  {box.type === BoxRestrictionType.LOT_GROUP ? (
                    <ConditionalWrapper
                      condition={!isCompatible}
                      wrapper={(children) => (
                        <Tooltip
                          placement="right"
                          title={`Can't select this box restriction because its sizes are incompatible`}
                        >
                          {children}
                        </Tooltip>
                      )}
                    >
                      <div className="box-size-container">
                        <div>
                          <span>
                            {developerMode && `[${box.id}]`}
                            {box.name}
                          </span>
                          {!isCompatible ? (
                            <Tag color="red" className="box-size-tag" icon={<WarningOutlined />}>
                              {abbreviateSizeRange(box.sizeRange)}
                            </Tag>
                          ) : isCompatible === CompatibleState.PARTIALLY ? (
                            <Tag color="gold" className="box-size-tag" icon={<WarningOutlined />}>
                              {abbreviateSizeRange(box.sizeRange)}
                            </Tag>
                          ) : (
                            <Tag color="neutral" className="box-size-tag">
                              {abbreviateSizeRange(box.sizeRange)}
                            </Tag>
                          )}
                        </div>
                        <div>
                          <Tooltip title="Edit">
                            <Button
                              type="text"
                              className="box-size-tag ant-btn-txt-default"
                              icon={<EditOutlined data-testid={`edit-${box.id}`} />}
                              disabled={!isCompatible}
                              onClick={(e) => handleEditClick(e, box.id)}
                              size="small"
                            />
                          </Tooltip>
                          <Tooltip title="Clone and Edit">
                            <Button
                              type="text"
                              className="box-size-tag ant-btn-txt-default"
                              icon={<CopyOutlined data-testid={`copy-${box.id}`} />}
                              disabled={!isCompatible}
                              onClick={(e) => handleCopyClick(e, box.id)}
                              size="small"
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </ConditionalWrapper>
                  ) : (
                    <span>
                      {developerMode && `[${box.id}]`}
                      {box.name}
                    </span>
                  )}
                </Option>
              );
            })}
          </Select>
        </div>
      )}
    </>
  );
});

export default BoxRestrictionsSelector;

import { SizingStore } from './sizing-store';
import { CommodityGroup } from '../../models/sizing-data';
import { message as Message } from '@retail-core/rds';
import { runInAction } from 'mobx';
import { sendEvent } from '../../services/event-tracking';

export class ChangeArticleAction {
  async changeArticle(this: SizingStore, referenceId: string, cg: CommodityGroup, comment?: string): Promise<void> {
    const article = this.articles.find((potentialArticle) => potentialArticle.referenceId === referenceId);
    if (!article) throw new Error(`There is no article with referenceId ${referenceId}`);

    if (article.commodityGroup.code !== cg.code || article.comment !== comment) {
      const prevGroup = this.groupByArticleRefId[article.referenceId];
      await runInAction(async () => {
        article.commodityGroup = cg;
        article.comment = comment;
        await this.groupArticles();
      });
      await new Promise(process.nextTick); // TODO this is required to set the message with "(box restrictions applied)". otherwise it can be removed

      const changedGroup = this.groupByArticleRefId[article.referenceId];
      if (prevGroup.groupKey !== changedGroup.groupKey) {
        sendEvent(changedGroup, 'changeCG', [article], this.additionalData()).then();
      }
      const isNewGroup = prevGroup.groupKey !== changedGroup.groupKey;
      const successMessage = `Moved 1 article${isNewGroup ? ' to a new sizing group' : ''}${
        article.boxing !== undefined && article.boxing !== null ? ' (box restrictions applied)' : ''
      }`;
      Message.success(successMessage, 3);
    }
  }
}

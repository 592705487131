import { Space, Tooltip, Typography } from '@retail-core/rds';
import React, { FC, useMemo } from 'react';
import './ArticleQuantity.less';
import { WarningFilled } from '@ant-design/icons';

type Props = {
  boxingEnabled: boolean;
  articleQuantity: number;
  deviation: number;
  onOpenBoxRestrictionCalculationModal: () => void;
};

export const ArticleQuantity: FC<Props> = (props) => {
  const { boxingEnabled, articleQuantity, deviation, onOpenBoxRestrictionCalculationModal } = props;
  const relativeDeviation = useMemo(() => Math.abs(deviation / articleQuantity), [articleQuantity, deviation]);
  return boxingEnabled ? (
    <Space size={4}>
      <Typography.Text className="article-box-quantity">
        <span onClick={onOpenBoxRestrictionCalculationModal}>{articleQuantity}</span>
      </Typography.Text>
      <Typography.Text
        className="article-box-deviation"
        type={relativeDeviation >= 0.5 ? 'danger' : relativeDeviation >= 0.05 ? 'warning' : undefined}
      >
        {deviation > 0 ? `+${deviation}` : deviation}
      </Typography.Text>
      {relativeDeviation >= 0.5 ? (
        <Tooltip color="#BE3013" title="The recommended box quantity is at least 50% larger than your target quantity">
          <WarningFilled
            className="article-box-deviation-gte-50-warning"
            data-testid="article-box-deviation-gte-50-warning"
          />
        </Tooltip>
      ) : null}
    </Space>
  ) : (
    <Typography.Text>{articleQuantity}</Typography.Text>
  );
};

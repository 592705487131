import { createContext, useContext } from 'react';
import { RootStore } from '../../stores/root-store';

export const StoreContext = createContext<RootStore | null>(null);

export const StoreProvider = StoreContext.Provider;

export function useStore(): RootStore {
  const store = useContext<RootStore | null>(StoreContext);
  if (!store) {
    throw new Error('No store available yet. Is the app correct bootstrapped?');
  }

  return store;
}

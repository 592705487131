import { QuantityField } from './calculate-article-quantities-action';
import { SizingStore } from './sizing-store';
import { SizingGroup } from '../../models/sizing-group';
import { Article } from '../../models/sizing-data';
import { sendSizingAppliedEventForGroups } from '../../lib/fragment-helper';
import { Distribution } from '../../models/sizing';
import isEqual from 'fast-deep-equal';

type ReferenceIdSizingMap = { [referenceId: string]: Distribution | undefined };

export class CalculateQuantitiesAction {
  async calculateQuantities(
    this: SizingStore,
    initialCalculation?: boolean,
    group?: SizingGroup,
    articleReferenceIds?: string[],
    fieldToCalculate?: QuantityField
  ): Promise<Article[] | undefined> {
    const optimizePromises: Promise<void>[] = [];
    const previousSizings: ReferenceIdSizingMap = {};
    const previousBlockOrderSizings: ReferenceIdSizingMap = {};
    let articlesWithChangedSizing: Article[] | undefined = [];

    const groupsToCalculate = group ? [group] : this.sizingGroups;
    for (const group of groupsToCalculate) {
      // If sizechartOverride enabled, dont bother recalculating
      let articles = group.articles.filter((article) => !article.sizeChartOverride);
      if (articleReferenceIds?.length) {
        articles = articles.filter((article) => articleReferenceIds.includes(article.referenceId));
      }

      for (const article of articles) {
        if (article.editedQuantities) {
          article.sizing = article.editedQuantities;
          continue;
        }
        // Keep previous sizing to compare later if something changed
        previousSizings[article.referenceId] = article.sizing && { ...article.sizing };
        previousBlockOrderSizings[article.referenceId] = article.blockOrderSizing
          ? { ...article.blockOrderSizing }
          : {};
        // Apply percentage distribution to config quantity to calculate sizing (simple quantities)
        this.calculateArticleQuantities(
          article,
          group?.selectedSizeKey?.distribution,
          initialCalculation,
          fieldToCalculate
        );
      }
      if (this.enableBox) {
        if (articleReferenceIds?.length) {
          if (articles.length) {
            for (const article of articles) {
              optimizePromises.push(this.optimizeBoxQuantities(group, article));
            }
          }
        } else {
          optimizePromises.push(this.optimizeBoxQuantities(group));
        }
      }
    }

    // All box restrictions must be done before sending sizingAppliedEvent
    await Promise.all(optimizePromises);

    articlesWithChangedSizing = this.notifyAboutChangedArticles(previousSizings, previousBlockOrderSizings);
    return articlesWithChangedSizing;
  }

  private notifyAboutChangedArticles(
    this: SizingStore,
    previousSizings: ReferenceIdSizingMap,
    previousBlockOrderSizings: ReferenceIdSizingMap
  ) {
    const changedArticles = this.findSizingChanges(previousSizings, 'sizing');
    const changedArticleBlockOrders = this.findSizingChanges(previousBlockOrderSizings, 'blockOrderSizing');
    // If sizing quantities changed we have to tell buying home about that
    if (changedArticles.length > 0) {
      sendSizingAppliedEventForGroups(this.sizingGroups, changedArticles);
      return changedArticles;
    } else if (changedArticleBlockOrders.length > 0) {
      sendSizingAppliedEventForGroups(this.sizingGroups, changedArticleBlockOrders);
      return changedArticleBlockOrders;
    }
  }

  private findSizingChanges(
    this: SizingStore,
    sizings: ReferenceIdSizingMap,
    sizingType: 'sizing' | 'blockOrderSizing'
  ): Article[] {
    const articlesByReferenceId = this.articlesByReferenceId();
    return Object.entries(sizings).reduce((list, [referenceId, previousSizing]) => {
      const article = articlesByReferenceId[referenceId];
      if (!article) throw new Error(`Article with referenceId '${referenceId}' not found!`);
      if (article[sizingType] && previousSizing && !isEqual(article[sizingType], previousSizing)) {
        list.push(article);
      }
      return list;
    }, [] as Article[]);
  }
}

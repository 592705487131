import { BoxRestriction, BoxRestrictionType } from '../../models/box-restriction';
import boxRestrictionsService from '../../services/box-restrictions-service';
import { SizingStore } from './sizing-store';

export class GetBoxRestrictionsAction {
  async getBoxRestrictions(this: SizingStore): Promise<void> {
    const boxRestrictions = await boxRestrictionsService.getBoxRestrictions(
      this.brandCode,
      this.cgCode2,
      this.supplierCode
    );
    this.boxRestrictions = boxRestrictions.map<BoxRestriction>((boxRestrictionRaw) => {
      if (boxRestrictionRaw.type === BoxRestrictionType.LOT_GROUP) {
        return {
          id: boxRestrictionRaw.id,
          name: boxRestrictionRaw.name,
          type: boxRestrictionRaw.type,
          orderType: boxRestrictionRaw.basic_order_type,
          sizeRange: boxRestrictionRaw.size_range,
          lotGroup: boxRestrictionRaw.lot_group?.map((lot) => ({
            id: lot.box_restriction_id,
            name: lot.name,
            distribution: lot.distribution,
          })),
          updatedAt: boxRestrictionRaw.updated_at,
        } as BoxRestriction;
      } else {
        return {
          id: boxRestrictionRaw.id,
          name: boxRestrictionRaw.name,
          type: boxRestrictionRaw.type,
          orderType: boxRestrictionRaw.basic_order_type,
          boxValue: boxRestrictionRaw.box_value,
          updatedAt: boxRestrictionRaw.updated_at,
        } as BoxRestriction;
      }
    });
  }
}

import presizeBackend from '../../services/presize-backend';
import { SizingStore } from './sizing-store';
import { CgGroupingLevel } from '../../models/sizing-group';
import { sendSizingAppliedEventForGroups } from '../../lib/fragment-helper';
import { sendEvent } from '../../services/event-tracking';

export class ChangeGroupingLevelAction {
  async changeGroupingLevel(this: SizingStore, level: CgGroupingLevel): Promise<void> {
    const { referenceId, brandCode, seasonCode, enableBox } = this;
    await presizeBackend.updateOrder(referenceId, brandCode, seasonCode, level, enableBox);
    this.groupingCgLevel = level;
    await this.groupArticles();
    sendSizingAppliedEventForGroups(this.sizingGroups);
    this.sizingGroups.forEach((group) => {
      sendEvent(group, 'changeCGGrouping', undefined, this.additionalData());
    });
  }
}

import { BoxRestrictionView } from '@ism/box-restrictions';
import { action, makeObservable, observable } from 'mobx';
import { SizingGroup } from '../../models/sizing-group';
import { Article } from '../../models/sizing-data';
import { ArticleUIState, GroupUIState } from '../../models/ui-state';
import { actionMixin } from '../action-mixin';
import { ArticleSelectionAction } from './article-selection-action';
import { ArticleSearchAction } from './article-search-action';
import { MatrixSelectionAction } from './matrix-selection-action';
import { ShowBoxCatalogAction } from './show-box-catalog-action';
import { HideBoxCatalogAction } from './hide-box-catalog-action';
import { SetBoxCalculationArticleAction } from './set-box-calculation-article-action';
import { SortKey } from '../../models/sizing';
import { ToggleShowArticleCommentsAction } from '../sizing/toggle-show-article-comments';

export const initialSelectionState = {
  selectionStarted: false,
  startRow: 0,
  startColumn: 0,
  endRow: 0,
  endColumn: 0,
  value: [[]],
  referenceId: null,
  tableRef: null,
};

export interface UIStore
  extends ArticleSelectionAction,
    ArticleSearchAction,
    MatrixSelectionAction,
    SetBoxCalculationArticleAction,
    ShowBoxCatalogAction,
    HideBoxCatalogAction,
    ToggleShowArticleCommentsAction {}

export class UIStore extends actionMixin(
  ArticleSelectionAction,
  ArticleSearchAction,
  MatrixSelectionAction,
  SetBoxCalculationArticleAction,
  ShowBoxCatalogAction,
  HideBoxCatalogAction,
  ToggleShowArticleCommentsAction
) {
  public uIState: Record<string, GroupUIState> = {};
  public searchQuery: string | undefined = undefined;
  public articlesSelectionMarker: string | null = null;
  public matrixSelectionMarker: Record<string, string | null> = { distributionId: null, quantityId: null };
  public boxCatalogView: BoxRestrictionView | undefined = undefined;
  public boxCatalogCg3: string | undefined = undefined;
  public boxCatalogSizeChartId: string | undefined = undefined;
  public boxCatalogSizeKey: SortKey | undefined = undefined;
  public boxCatalogSizingGroupKey: string | undefined = undefined;
  public boxCalculationArticleId: string | undefined = undefined;
  public boxRestrictionId: string | undefined = undefined;
  public showArticleComments: boolean | undefined = false;
  public selectedCG3List: string[] = [];

  constructor() {
    super();
    makeObservable(this, {
      uIState: observable,
      searchQuery: observable,
      setInitialUIState: action,
      boxCatalogView: observable,
      boxCatalogCg3: observable,
      boxCatalogSizeChartId: observable,
      boxCatalogSizeKey: observable,
      boxCatalogSizingGroupKey: observable,
      boxCalculationArticleId: observable,
      showArticleComments: observable,
    });
    this.showArticleComments = this.readArticleCommentsVisibility();

    this.setSelectedArticles = this.setSelectedArticles.bind(this);
    this.selectArticles = this.selectArticles.bind(this);
    this.unselectArticles = this.unselectArticles.bind(this);
    this.moveArticles = this.moveArticles.bind(this);
    this.setSelectionState = this.setSelectionState.bind(this);
    this.getSelectionState = this.getSelectionState.bind(this);
    this.clearSelectionState = this.clearSelectionState.bind(this);
    this.toggleShowArticleComments = this.toggleShowArticleComments.bind(this);
    this.updateSelectedCG3List = this.updateSelectedCG3List.bind(this);
  }

  setInitialUIState = (groups: Array<SizingGroup>): void => {
    this.uIState = groups.reduce(
      (groupUIStates: Record<string, GroupUIState>, group: SizingGroup) => ({
        ...groupUIStates,
        [group.groupKey]: observable({
          selected: 0,
          matched: 0,
          distributionSelectionState: initialSelectionState,
          cg3: group.commodityGroup.codes[2],
          articles: group.articles.reduce(
            (articleUIStates: Record<string, ArticleUIState>, article: Article) => ({
              ...articleUIStates,
              [article.referenceId]: observable({
                selected: false,
                matched: false,
                searchString: [
                  article.supplierArticleName,
                  article.supplierArticleNumber,
                  article.supplierColorCode,
                  article.supplierColorDescription,
                  article.comment,
                ].join(''),
                quantitySelectionState: initialSelectionState,
              }),
            }),
            {}
          ),
        }),
      }),
      {}
    );
  };

  readArticleCommentsVisibility = (): boolean => {
    if (localStorage.getItem('showArticleComments') === 'true') {
      return true;
    }
    return false;
  };
}

import { DistributionType, ReusedDecision, SizeChart, SizeKey, Sizing, SizingV2 } from './sizing';
import { Article, CommodityGroup } from './sizing-data';
import { makePropertiesObservable } from '../stores/action-mixin';
import { SelectedBoxRestriction } from './box-restriction';
import { GroupResponse } from './response';
import { min, toInteger } from 'lodash';

export function cgCodeAtLevel(commodityGroup: CommodityGroup, cgLevel: CgGroupingLevel): string {
  return cgCodeAtLevelNumber(commodityGroup, cgGroupingLevelToNumber(cgLevel));
}
export function cgCodeAtLevelNumber(commodityGroup: CommodityGroup, cgLevel: number): string {
  const level = min([cgLevel, commodityGroup.codes.length]) || 1;
  return commodityGroup.codes[level - 1];
}

export enum CgGroupingLevel {
  CG3 = 'CG3',
  CG4 = 'CG4',
  CG5 = 'CG5',
}

export function cgGroupingLevelToNumber(cgLevel: CgGroupingLevel): number {
  return toInteger(cgLevel.slice(2));
}

export function toCGroupingLevel(level: string): CgGroupingLevel {
  switch (level) {
    case 'CG3':
      return CgGroupingLevel.CG3;
    case 'CG4':
      return CgGroupingLevel.CG4;
    default:
      return CgGroupingLevel.CG5;
  }
}

export interface OrderData {
  groupingLevel: CgGroupingLevel;
  enableBox: boolean;
}

export type SizingGroupKey = {
  groupKey: string;
  commodityGroupCode: string;
  preselectedSizeChartId?: string;
  sizeStructure?: string;
  sizingNote?: string;
};

export class SizingGroup {
  id: string;
  groupKey: string;
  commodityGroup: CommodityGroup;
  commodityGroupCode: string;
  sizeStructure: string;
  sizingNote: string;
  articles: Article[];
  selectedSizeChart?: SizeChart;
  selectedSizeKey?: SizeKey;
  sizing?: Sizing;
  groupName: string;
  groupId: string;
  enableBox: boolean;
  boxRestriction?: SelectedBoxRestriction;
  silo?: string;
  sizeChartId?: string;
  selectedSizing?: SizingV2;
  isReviewed = false;
  referenceBrandCode?: string;
  loadingSizing = false;
  reusedDecision?: ReusedDecision | null;

  constructor(
    id: string,
    groupKey: string,
    cgLevel: CgGroupingLevel,
    commodityGroup: CommodityGroup,
    sizeStructure: string,
    sizingNote: string,
    articles: Article[],
    selectedSizeChart?: SizeChart,
    selectedSizeKey?: SizeKey,
    sizing?: Sizing,
    reusedDecision?: ReusedDecision
  ) {
    this.id = id;
    this.groupKey = groupKey;
    this.commodityGroup = commodityGroup;
    this.commodityGroupCode = cgCodeAtLevel(commodityGroup, cgLevel);
    this.sizeStructure = sizeStructure;
    this.sizingNote = sizingNote;
    this.articles = articles;
    this.selectedSizeChart = selectedSizeChart;
    this.selectedSizeKey = selectedSizeKey;
    this.sizing = sizing;
    this.groupName = this.createGroupName(cgLevel);
    this.groupId = this.createGroupId(cgLevel);
    this.enableBox = false;
    this.boxRestriction = {
      boxRestrictionId: undefined,
      lastUpdated: undefined,
      boxRestrictionType: undefined,
      boxRestrictionStatus: undefined,
    };
    this.reusedDecision = reusedDecision;

    makePropertiesObservable(this);
  }

  numberOfArticlesWithEditedQuantity(): number {
    return this.articles.filter((article) => article.editedQuantities).length;
  }

  hasChanges(): boolean {
    return (
      this.selectedSizeKey?.distributionType === DistributionType.MANUAL ||
      this.sizing?.recommendedSizing?.sizeChartId !== this.selectedSizeChart?.sizeChartId ||
      this.sizing?.recommendedSizing?.sizeKeyId !== this.selectedSizeKey?.sizeKeyId ||
      this.numberOfArticlesWithEditedQuantity() > 0
    );
  }

  isSized(): boolean {
    return !!this.selectedSizeKey && !!this.selectedSizeChart && !!this.selectedSizeKey?.distribution;
  }
  getGroupName(): string {
    return `${this.commodityGroup.names.join('').toLowerCase()}${this.sizeStructure}${this.sizingNote}`;
  }
  createGroupName(cgLevel: CgGroupingLevel): string {
    switch (cgLevel) {
      case CgGroupingLevel.CG5:
        return this.commodityGroup.names.slice(2).join(' - ');
      case CgGroupingLevel.CG4:
        return this.commodityGroup.names.slice(2, 4).join(' - ');
      case CgGroupingLevel.CG3:
        return this.commodityGroup.names.slice(2, 3).join(' - ');
    }
  }

  createGroupId(cgLevel: CgGroupingLevel): string {
    // need to copy the codes into a new array to avoid mobx warning about `mobx [mobx] Out of bounds read: X`
    const [, , cg3, cg4, cg5] = [...this.commodityGroup.codes];

    if (cgLevel === CgGroupingLevel.CG3) {
      return cg3;
    }

    if (cg5 && cgLevel === CgGroupingLevel.CG5) {
      return cg5;
    }

    if (cg4) {
      return cg4;
    }

    return cg3;
  }

  static fromGroupResponse(
    groupResponse: GroupResponse,
    cgGroupingLevel: CgGroupingLevel,
    bhArticlesByRefId: Record<string, Article>
  ): SizingGroup {
    const articles: Article[] = groupResponse.articles.map((article) => {
      return Object.assign(bhArticlesByRefId[article.referenceId], {
        sizeStructure: article.sizeStructure,
        sizingNote: article.sizingNote,
        silo: article.silo,
        editedQuantities: article.editedQuantities,
      });
    });
    const sizingGroup = new SizingGroup(
      groupResponse.id,
      groupResponse.key,
      cgGroupingLevel,
      articles[0].commodityGroup,
      groupResponse.sizeStructure,
      groupResponse.sizingNote || '', // TODO why this param is mandatory?
      articles
    );
    sizingGroup.sizeChartId = groupResponse.sizeChartId;
    sizingGroup.selectedSizing = groupResponse.selectedSizing;
    sizingGroup.enableBox = groupResponse.enableBox;
    sizingGroup.boxRestriction = groupResponse.selectedBoxRestriction;
    sizingGroup.referenceBrandCode = groupResponse.referenceBrandCode;
    sizingGroup.silo = groupResponse.silo;
    sizingGroup.isReviewed = groupResponse.isReviewed;
    sizingGroup.reusedDecision = groupResponse.reusedDecision;

    if (sizingGroup?.boxRestriction === undefined) {
      sizingGroup.articles.forEach((article) => (article.boxing = undefined));
    }

    return sizingGroup;
  }

  sizingGroupKey(): SizingGroupKey {
    return {
      groupKey: this.groupKey,
      commodityGroupCode: this.commodityGroupCode,
      preselectedSizeChartId: this.sizeChartId,
      sizeStructure: this.sizeStructure,
      sizingNote: this.sizingNote,
    };
  }

  resetEditedQuantities(): void {
    this.articles.forEach((article) => (article.editedQuantities = undefined));
  }
}

export type SizingGroupReview = {
  groupKey: string;
  reviewed: boolean;
};

export type SizingGroupBoxRestrictions = {
  groupKey: string;
  enableBox: boolean;
};

export type SizingGroupSelectedBoxRestriction = {
  groupKey: string;
  selectedBoxRestriction?: SelectedBoxRestriction;
};

export type TreeNode = {
  name: string;
  disabled: boolean;
  cgLevel: string;
  children: TreeData;
};

export type TreeData = Record<string, TreeNode>;

import { runInAction } from 'mobx';
import { SilosErrorResponse, SilosResponse, SilosUnionResponse } from '../../models/response';
import presizeBackend from '../../services/presize-backend';
import { SizingStore } from './sizing-store';

export class GetSilosAction {
  async getSilos(this: SizingStore, cgCodes: string[]): Promise<void> {
    this.loadingSilos = true;
    const silosResponse: SilosUnionResponse = await presizeBackend.getSilos(this.brandCode, cgCodes);
    runInAction(() => {
      this.loadingSilos = false;
      if ((silosResponse as SilosErrorResponse).success) {
        throw new Error(`Silos could not be loaded ${(silosResponse as SilosErrorResponse).errorType}`);
      } else {
        this.silos = (silosResponse as SilosResponse).silos;
      }
    });
  }
}

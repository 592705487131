import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../StoreContext/StoreContext';
import { sleep } from '../../../lib/sleep';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from '@retail-core/rds';

export const ArticlesSearchBox: FC = observer(() => {
  const inputRef: React.RefObject<Input> = useRef(null);
  const { uiStore } = useStore();
  const EDIT_DELAY = 400;
  const pendingEdits = useRef(0);
  const [query, setQuery] = useState<string>('');
  const handleReset = useCallback(() => {
    pendingEdits.current = 0;
  }, []);
  const keyDownHandler = useCallback(
    (event) => {
      if (event.key === 'F3' || ((event.ctrlKey || event.metaKey) && event.key === 'f')) {
        event.preventDefault();
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      } else if (event.key === 'Escape' && document.activeElement === inputRef.current?.input && query.length) {
        setQuery('');
        handleReset();
        uiStore.searchArticles('');
      }
    },
    [handleReset, query, uiStore]
  );

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      pendingEdits.current++;
      const query = event.target.value;
      const currentEdit = pendingEdits.current;
      setQuery(query);

      await sleep(EDIT_DELAY);
      if (currentEdit !== pendingEdits.current) {
        return;
      }

      handleReset();
      uiStore.searchArticles(query);
    },
    [handleReset, uiStore]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler]);

  useEffect(() => {
    if (uiStore.searchQuery && inputRef.current) {
      inputRef.current.focus();
    }
    if (uiStore.searchQuery !== query) {
      setQuery(uiStore.searchQuery || '');
    }
  }, [uiStore.searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div className="search-box">
      <Input
        className={''}
        allowClear={true}
        onChange={handleChange}
        placeholder="Find Article"
        prefix={<SearchOutlined className={''} data-testid="article-search-icon" />}
        data-testid="article-search-input"
        ref={inputRef}
        value={query}
      />
    </div>
  );
});

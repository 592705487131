import { UIStore } from './ui-store';

export class ArticleSearchAction {
  public searchArticles(this: UIStore, query?: string): void {
    this.searchQuery = query;
    const regExp = new RegExp(this.searchQuery || '', 'i');
    if (this.searchQuery) {
      this.unselectArticles();
      Object.entries(this.uIState).forEach(([key, groupState]) => {
        let matchedArticles = 0;
        Object.entries(groupState.articles).forEach(([key, articleState]) => {
          if (regExp.test(articleState.searchString)) {
            articleState.matched = true;
            matchedArticles += 1;
          } else {
            articleState.matched = false;
          }
        });
        groupState.matched = matchedArticles;
      });
    } else {
      Object.entries(this.uIState).forEach(([key, groupState]) => {
        Object.entries(groupState.articles).forEach(([key, articleState]) => {
          articleState.matched = false;
        });
        groupState.matched = 0;
      });
    }
  }
}

import { Button, Tooltip } from '@retail-core/rds';
import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { MatrixDataset } from '../../../../models/sizing-table';
import { sumMatrixDataset } from '../../matrices-utils';
import { ColumnHeightOutlined, CloseOutlined } from '@ant-design/icons';
import { SaveButton } from './SaveButton';
import './Actions.less';

interface IProps {
  dataSet: MatrixDataset;
  dataIsPercentage?: boolean;
  expectedTotal?: number;
  savable?: boolean;
  saving?: boolean;
  onAdjustTotal?: (total: number) => void;
  onCancel: () => void;
  onSave: () => void;
  onScaleDistribution: () => void;
  isComparison?: boolean;
}

export const Actions: FC<IProps> = observer((props) => {
  const {
    dataSet,
    dataIsPercentage,
    expectedTotal,
    savable,
    saving,
    onSave,
    onCancel,
    onAdjustTotal,
    onScaleDistribution,
    isComparison,
  } = props;
  const total = sumMatrixDataset(dataSet);
  const totalMatching = total === expectedTotal;
  const disableSaveButton = dataIsPercentage ? !(savable && totalMatching) : !savable;

  return (
    <div className="actions">
      <span className="total">
        <span className="total-sign">=</span>
        <span className={totalMatching ? 'green' : 'red'}>
          {total}
          {dataIsPercentage && '%'}
        </span>
      </span>
      <div className="spacing">
        {!totalMatching && dataIsPercentage ? (
          <Tooltip title={`Scale distribution to 100%`}>
            <Button
              icon={<ColumnHeightOutlined />}
              onClick={onScaleDistribution}
              data-testid="scale-distribution-button"
            />
          </Tooltip>
        ) : null}
      </div>
      {!isComparison ? (
        <>
          <div className="separator" />
          <Tooltip title="Cancel edit" className="spacing">
            <Button icon={<CloseOutlined />} onClick={onCancel} data-testid="cancel-button" />
          </Tooltip>
          <SaveButton
            total={total}
            totalMatching={totalMatching}
            tooltipTitle={
              !dataIsPercentage
                ? 'Apply new total quantity'
                : totalMatching
                ? 'Apply distribution'
                : `The sum is not matching ${expectedTotal}${dataIsPercentage ? '%' : ''}`
            }
            onAdjustTotal={onAdjustTotal}
            onSave={onSave}
            loading={!!saving}
            disabled={disableSaveButton}
          />
        </>
      ) : null}
    </div>
  );
});

import { SizingStore } from './sizing-store';
import { SizingData, Article } from '../../models/sizing-data';
import { sendEvent } from '../../services/event-tracking';

export class AddArticlesAction {
  async addArticles(this: SizingStore, sizingData: SizingData): Promise<void> {
    if (
      sizingData.subunitCode !== this.subUnitCode ||
      sizingData.brandCode !== this.brandCode ||
      sizingData.seasonCode !== this.seasonCode ||
      sizingData.referenceId !== this.referenceId
    ) {
      // TODO Is this possible? https://chat.google.com/room/AAAAxv4iZlI/Y5h50vQ5l5M
      throw new Error(`Trying to add articles not matching current article list!`);
    }
    this.articles.push(...sizingData.articles);

    this.groupArticles().then(() => {
      const groupsWithNewArticles: Record<string, Article[]> = {};
      const articlesByReferenceId = this.articlesByReferenceId();

      sizingData.articles.forEach((article) => {
        const group = this.groupByArticleRefId[article.referenceId];
        if (group) {
          if (!groupsWithNewArticles[group.id]) {
            groupsWithNewArticles[group.id] = [];
          }
          groupsWithNewArticles[group.id].push(articlesByReferenceId[article.referenceId]);
        }
      });
      for (const [groupId, articles] of Object.entries(groupsWithNewArticles)) {
        sendEvent(this.groupById[groupId], 'addArticle', articles, this.additionalData());
      }
    });
  }
}

import { SizeKey, Distribution, SortKey, SizeChart } from '../../models/sizing';
import { MatrixDataset, DataSetRow, SizeLengthValueMap } from '../../models/sizing-table';
import { IProps as EditableCellProps } from './Matrix/EditableCell/EditableCell';
import React from 'react';

export function getMaxDistribution(distribution: Distribution): number {
  return Math.max(...Object.values(distribution));
}

export function getMaxDataset(dataSet: MatrixDataset): number {
  let maxDataSet = 0;
  dataSet.forEach((row) => {
    maxDataSet = Object.keys(row)
      .filter((key: string) => !['key', 'length'].includes(key) && row[key])
      .reduce((maxRow, size) => Math.max(Number(row[size]), maxRow), maxDataSet);
  });
  return maxDataSet;
}

export function getSizeValueMap(sizeList: string[], valueMap: Distribution, length: string): SizeLengthValueMap {
  return sizeList.reduce<Distribution>(
    (map, size) => ({
      ...map,
      [size]: valueMap[`${size}${length}`],
    }),
    {}
  );
}

export function getSizeSelectMap(sizeList: string[], valueList: string[], length: string): SizeLengthValueMap {
  return sizeList.reduce<SizeLengthValueMap>(
    (map, size) => ({
      ...map,
      [size]: !!~valueList.indexOf(`${size}${length}`),
    }),
    {}
  );
}

export function getMatrixDataset(sizeKey: SizeKey, valueMap: Distribution): MatrixDataset {
  // Creates 2d matrix like [{key: 30, length: 30, 32: 5, 34: 10, 36: 1}, {key: 32, length: 32, 32: 1, 34: 8, 36: 3}]
  // 1d looks like [{key: '', length: '', 32: 5, 34: 10, 36: 1}]
  return (sizeKey.lengthList?.length ? sizeKey.lengthList : ['']).reduce<MatrixDataset>(
    (matrix, length: string) => [
      ...matrix,
      {
        key: length || '0',
        length,
        ...getSizeValueMap(sizeKey.sizeList, valueMap, length && `${sizeKey.sizeLengthSeparator}${length}`),
      },
    ],
    []
  );
}

export function getDistribution(dataSet: MatrixDataset, separator?: string): Distribution {
  // Convert matrix data set back into distribution
  return dataSet.reduce<Distribution>((distribution, row) => {
    Object.entries(row)
      .filter(([key, value]) => key !== 'key' && key !== 'length' && value !== undefined)
      .forEach(([size, value]) => {
        distribution[`${size}${separator}${row.length}`] = Number(value);
      });

    return distribution;
  }, {});
}

export function getSelectedSizeKey(dataSet: MatrixDataset, sizeChart: SizeChart): SortKey {
  // Get selected size key from selectable matrix
  const { sizeList, lengthList, sizeLengthSeparator: separator = '' } = sizeChart;
  return sizeList.reduce<SortKey>((selectedSizeList, size) => {
    return (lengthList?.length ? lengthList : ['']).reduce<SortKey>((selectedSizeList, length, rowIdx) => {
      if (dataSet[rowIdx][size]) {
        selectedSizeList.push(`${size}${separator}${length}`);
      }
      return selectedSizeList;
    }, selectedSizeList);
  }, []);
}

export function updateMatrixDataset(dataSet: MatrixDataset, row: string, column: string, value: number): MatrixDataset {
  const editedRow = dataSet.find((currentRow) => currentRow.key === row);
  if (editedRow) {
    editedRow[column] = value;
  }
  return [...dataSet];
}

export function updateMatrixDatasetRange(
  dataSet: MatrixDataset,
  sizeKey: SizeKey,
  row: string,
  column: string,
  values: Array<Array<number>>,
  isPercent = false
): MatrixDataset {
  // Get shallow copy of dataSet to avoid partial changes in case of invalid cells
  const editedDataSet = dataSet.map((row) => ({ ...row }));
  const lengthList = sizeKey.lengthList?.length ? sizeKey.lengthList : ['0'];
  const sizeList = sizeKey.sizeList;
  if (isPercent && (values.length > lengthList.length || values[0].length > sizeList.length)) {
    throw new Error('Data length mismatch');
  }
  const rowsToUpdate = lengthList.slice(
    lengthList.indexOf(row),
    lengthList.indexOf(row) + Math.min(lengthList.length, values.length)
  );
  const columnsToUpdate = sizeList.slice(
    sizeList.indexOf(column),
    sizeList.indexOf(column) + Math.min(sizeList.length, values[0].length)
  );

  values.forEach((rowValues, rowIndex) => {
    const editedRow = editedDataSet.find((currentRow) => currentRow.key === rowsToUpdate[rowIndex]);
    if (editedRow) {
      const editableRowCells = Object.keys(editedRow).filter((cell) => editedRow[cell] !== undefined);
      rowValues.forEach((cellValue, cellIndex) => {
        if (~editableRowCells.indexOf(columnsToUpdate[cellIndex])) {
          if (isNaN(cellValue) || cellValue < 0) {
            throw new Error('Invalid data');
          }
          editedRow[columnsToUpdate[cellIndex]] = cellValue;
        }
      });
    }
  });
  return editedDataSet;
}

export function sumMatrixDataset(dataSet: MatrixDataset): number {
  const sum = (list: number[]) => list.reduce((total, value) => total + value, 0);
  const values = dataSet.flatMap((row) =>
    Object.entries(row)
      .filter(([prop, value]) => prop !== 'length' && prop !== 'key')
      .map(([_, value]) => Number(value) || 0)
  );
  return Math.round(sum(values) * 10) / 10; // Round to 1 fraction digit like 99.9
}

export function getMatrixColumns(
  sizeKey: SizeKey,
  cellRenderFn?: (value: any, record: any, index: number) => any,
  cellProps?: Partial<EditableCellProps>
): any[] {
  const columns = sizeKey.sizeList.map((size) => ({
    key: size,
    title: size,
    dataIndex: size,
    ellipsis: true,
    render: cellRenderFn,
    fixed: '',
    width: 61,
    onCell: (record: any) => ({
      record,
      title: size,
      dataIndex: size,
      ...cellProps,
    }),
  }));
  // delete columns[columns.length - 1].width;
  if (sizeKey.lengthList?.length) {
    columns.unshift({
      key: 'length',
      title: '',
      dataIndex: 'length',
      ellipsis: true,
      render: (value) => ({
        props: {
          style: { zIndex: 1 },
        },
        children: value,
      }),
      fixed: 'left',
      width: 61,
      onCell: (record: any) => ({
        record,
        title: '',
        dataIndex: 'length',
        ...cellProps,
        editable: false, // Length column can't be edited
      }),
    });
  }

  return columns;
}

export function getSelectableMatrixColumns(
  sizeKey: Pick<SizeKey, 'sizeList' | 'lengthList'>,
  onCellChange: (row: string, column: string, value: boolean, multipleSelection: boolean) => void
): any[] {
  const columns = sizeKey.sizeList.map((size) => ({
    key: size,
    title: size,
    dataIndex: size,
    fixed: false,
    onCell: (record: any) => ({
      record,
      title: size,
      dataIndex: size,
      onCellChange,
      selectable: true,
    }),
  }));

  if (sizeKey.lengthList?.length) {
    columns.unshift({
      key: 'length',
      fixed: true,
      title: '',
      dataIndex: 'length',
      onCell: (record: any) => ({
        record,
        title: '',
        dataIndex: 'length',
        onCellChange: () => undefined,
        selectable: false, // Length column can't be unselected
      }),
    });
  }

  return columns;
}

export function distributionWith100Percent(distribution: Distribution): Distribution {
  const distributionEntries = Object.entries(distribution || {});

  const totalPercentage = distributionEntries.reduce<number>((sum, [sizeKey, percentage]) => {
    return sum + percentage;
  }, 0);

  if (distribution && totalPercentage !== 100) {
    // Adjust the distribution to be 100%
    return distributionEntries.reduce<Distribution>(
      (map, [sizeKey, percentage]) => ({
        ...map,
        [sizeKey]: (percentage * 100) / totalPercentage,
      }),
      {}
    );
  }

  return distribution;
}

export function getSelectableDataset(sizeChart: SizeChart, distribution?: Distribution): MatrixDataset {
  const { sizeList, lengthList, sizeLengthSeparator } = sizeChart;
  let valueList: string[] = [];
  if (distribution) {
    valueList = Object.keys(distribution);
  } else {
    valueList = [];
  }

  return (lengthList?.length ? lengthList : ['']).reduce<MatrixDataset>(
    (matrix, length: string) => [
      ...matrix,
      {
        key: length || '0',
        length,
        ...getSizeSelectMap(sizeList, valueList, length && `${sizeLengthSeparator}${length}`),
      },
    ],
    []
  );
}

export function applyToAllSelectableDataset(dataset: MatrixDataset, newValue: boolean): MatrixDataset {
  return dataset.map((row) =>
    Object.entries(row).reduce(
      (newRow, [key, value]) => ({
        ...newRow,
        [key]: ['key', 'length'].includes(key) ? value : newValue,
      }),
      {} as DataSetRow
    )
  );
}

export function parsePastedData(text: string, isPercent = false): Array<Array<number>> {
  const parser = isPercent ? parseFloat : parseInt;
  return text.split('\n').map((row) => row.split('\t').map((cell) => (cell ? parser(cell) : 0)));
}

export function eventToCellLocation(e: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>): Record<string, number> {
  let target;
  if (e instanceof TouchEvent && e.touches) {
    const touch = e.touches[0];
    target = document.elementFromPoint(touch.clientX, touch.clientY);
  } else {
    target = e.target as HTMLTableDataCellElement;
    while (target?.tagName !== 'TD') {
      target = target?.parentElement;
    }
  }
  return {
    row: (target?.parentElement as HTMLTableRowElement)?.rowIndex,
    column: (target as HTMLTableDataCellElement)?.cellIndex,
  };
}

export function selectElement(element: HTMLElement): void {
  if (window.getSelection) {
    const sel = window.getSelection();
    sel?.removeAllRanges();
    const range = document.createRange();
    range.selectNodeContents(element);
    sel?.addRange(range);
  } else if ((document as any)?.selection) {
    const textRange = (document.body as any).createTextRange();
    textRange.moveToElementText(element);
    textRange.select();
  }
}

export function createSelection(value: Array<Array<number | string>>): void {
  const existingNode = document.getElementById('tmp-selection');
  if (existingNode) {
    document.body.removeChild(existingNode);
  }
  const fragment = document.createDocumentFragment();
  const wrapper = document.createElement('div');
  wrapper.setAttribute('id', 'tmp-selection');
  wrapper.setAttribute('data-testid', 'selection');
  wrapper.setAttribute('style', 'opacity: 0;height: 0;width: 0;position: fixed;');
  const table = document.createElement('table');
  for (let row = 0; row < value.length; row++) {
    const tr = document.createElement('tr');
    for (let cell = 0; cell < value[row].length; cell++) {
      if (value[row][cell] === null) continue;
      const td = document.createElement('td');
      td.innerHTML = (value[row][cell] + '').replace(/[\n\t]+/g, ' ');
      tr.appendChild(td);
    }
    if (tr.innerHTML === '') continue;
    table.appendChild(tr);
  }
  wrapper.appendChild(table);
  fragment.appendChild(wrapper);
  document.body.appendChild(fragment);
  selectElement(wrapper);
}

export function createCopiableMatrix(value: Array<Array<number | string>>): string {
  let csv = '';
  for (let row = 0; row < value.length; row++) {
    let csvRow = '';
    for (let cell = 0; cell < value[row].length; cell++) {
      if (value[row][cell] === null) continue;
      const cellValue = (value[row][cell] + '').replace(/[\n\t]+/g, ' ');
      csvRow += cellValue;
      if (cell !== value[row].length - 1) {
        csvRow += '\t';
      }
    }
    if (csvRow === '') continue;
    csv += csvRow;
    if (row !== value.length - 1) {
      csv += '\n';
    }
  }
  return csv;
}

export function clearSelection(): void {
  if (window.getSelection) {
    if (window.getSelection()?.empty) {
      window.getSelection()?.empty();
    } else if (window.getSelection()?.removeAllRanges) {
      window.getSelection()?.removeAllRanges();
    }
  } else if ((document as any)?.selection) {
    (document as any)?.selection.empty();
  }
}

export function getDataSetValues(dataSet: MatrixDataset, sizeKey: SizeKey): Array<Array<number | string>> {
  // Get selected size key from selectable matrix
  const { sizeList, lengthList } = sizeKey;
  return (lengthList?.length ? lengthList : ['']).reduce<Array<Array<number | string>>>(
    (selectedDataValueRows, length, rowIdx) => {
      const dataRow = sizeList.reduce<Array<number | string>>((selectedDataValues, size) => {
        if (dataSet[rowIdx][size] !== undefined) {
          selectedDataValues.push(Number(dataSet[rowIdx][size]));
        } else {
          selectedDataValues.push('');
        }
        return selectedDataValues;
      }, []);
      selectedDataValueRows.push(dataRow);
      return selectedDataValueRows;
    },
    []
  );
}

import { runInAction } from 'mobx';
import { isFullyCompatibleKey } from '../../lib/box-restrictions-utils';
import { BoxRestrictionType, LOTGroupBox } from '../../models/box-restriction';
import { DistributionType, SizeChart, SizeKey, SizingKey } from '../../models/sizing';
import { SizingGroupKey } from '../../models/sizing-group';
import { sendEvent } from '../../services/event-tracking';

import presizeBackend from '../../services/presize-backend';
import { SizingStore } from './sizing-store';
import { sendSizingAppliedEvent } from '../../lib/fragment-helper';

export class ChangeSizingAction {
  async changeSizing(
    this: SizingStore,
    sizingGroup: SizingGroupKey,
    sizingKey: SizingKey,
    keepSizing?: boolean,
    sizeChartManuallyChanged?: boolean,
    sizeKeyManuallyChanged?: boolean
  ): Promise<void> {
    const group = this.sizingGroups.find((group) => group.groupKey === sizingGroup.groupKey);
    if (!group) {
      throw new Error('Size group could not be found');
    }
    const selectedSizeChart = group.sizing?.sizeChartList?.find(
      (sizeChart) => sizeChart.sizeChartId === sizingKey.sizeChartId
    );

    if (selectedSizeChart && selectedSizeChart.sizeChartId !== group.selectedSizeChart?.sizeChartId) {
      sizeChartManuallyChanged = true;
    }

    let selectedSizeKey: SizeKey | undefined;

    if (keepSizing && group.selectedSizeKey) {
      const oldSelectedSizeKey = group.selectedSizeKey;
      const existingSizeKey = selectedSizeChart?.sizeKeys.find(
        (sizeKey) => sizeKey.sizeKeyId === oldSelectedSizeKey.sizeKeyId
      );
      if (existingSizeKey) {
        selectedSizeKey = existingSizeKey;
        selectedSizeKey.distribution = { ...oldSelectedSizeKey.distribution };
        selectedSizeKey.distributionType = oldSelectedSizeKey.distributionType;
        selectedSizeKey.recommendationLevel = oldSelectedSizeKey.recommendationLevel;
        selectedSizeKey.recommendationStrategy = oldSelectedSizeKey.recommendationStrategy;
      } else if (oldSelectedSizeKey) {
        selectedSizeKey = { ...oldSelectedSizeKey };
        selectedSizeKey.manualSizeKey = true;
        selectedSizeKey.distributionType = DistributionType.MANUAL;
        selectedSizeChart?.sizeKeys.push(selectedSizeKey);
      }
    } else {
      selectedSizeKey = selectedSizeChart?.sizeKeys.find((sizeKey) => sizeKey.sizeKeyId === sizingKey.sizeKeyId);
    }

    if (!selectedSizeKey) {
      if (sizingKey.sizeKeyId && selectedSizeChart) {
        selectedSizeKey = this.getManualSizeKey(selectedSizeChart, sizingKey);
        selectedSizeChart.sizeKeys.push(selectedSizeKey);
        sizeKeyManuallyChanged = true;
      } else if (selectedSizeChart?.sizeKeys?.length) {
        selectedSizeKey = selectedSizeChart?.sizeKeys[0];
      }
    }

    if (selectedSizeKey && selectedSizeChart) {
      sizingKey.sizeKeyId = selectedSizeKey.sizeKeyId;
      const sizeDistribution = await presizeBackend.applySizing(
        this.referenceId,
        this.brandCode,
        this.seasonCode,
        group,
        selectedSizeChart,
        selectedSizeKey,
        undefined,
        undefined,
        group.sizeStructure,
        group.sizingNote
      );
      group.resetEditedQuantities();
      group.isReviewed = false;
      selectedSizeKey.distribution = sizeDistribution.distribution;
      selectedSizeKey.distributionType = sizeDistribution.distributionType;
      selectedSizeKey.recommendationLevel = sizeDistribution.recoLevel;
      selectedSizeKey.recommendationStrategy = sizeDistribution.recoStrategy;
      // Check if box restriction is still valid
      if (group.boxRestriction?.boxRestrictionType === BoxRestrictionType.LOT_GROUP) {
        const boxRestriction = this.boxRestrictions.find(
          (boxRestriction) => boxRestriction.id === group.boxRestriction?.boxRestrictionId
        ) as LOTGroupBox;

        if (!isFullyCompatibleKey(boxRestriction.sizeRange, selectedSizeKey.sizeKey)) {
          await this.setGroupBoxRestriction(sizingGroup.groupKey, undefined);
        }
      }
    }

    await runInAction(async () => {
      group.selectedSizeChart = selectedSizeChart;
      group.selectedSizeKey = selectedSizeKey;

      if (group.sizing) {
        group.sizing.selectedSizing = sizingKey;
        group.sizing.referenceBrandCode = null;
        group.reusedDecision = null;
        this.processReferenceBrandsWithUsage();
      }

      if (selectedSizeKey && selectedSizeChart) {
        const articlesWithChangedSizing = await this.calculateQuantities(false, group);
        if (keepSizing && !articlesWithChangedSizing?.length) {
          sendSizingAppliedEvent(group, group.articles);
        }
      }
    });

    sendEvent(
      group,
      sizeChartManuallyChanged ? 'changeSizeChart' : 'changeSizeKey',
      group.articles,
      this.additionalData()
    );
  }

  getManualSizeKey(sizeChart: SizeChart, sizingKey: SizingKey): SizeKey {
    const manualSizeKey = {
      manualSizeKey: true,
      sizeKey: (sizingKey.sizeKeyId || '').split(';'),
      sizeKeyId: sizingKey.sizeKeyId || '',
      sizeList: sizeChart.sizeList,
      lengthList: sizeChart.lengthList,
      sizeLengthSeparator: sizeChart.sizeLengthSeparator,
      distribution: undefined,
      distributionType: DistributionType.RECOMMENDED,
    };

    const sizeKeyBoundaries = manualSizeKey.sizeList.reduce(
      (boundaries, size) =>
        (manualSizeKey.lengthList?.length ? manualSizeKey.lengthList : ['']).reduce((boundaries, length) => {
          const sizeKeyName = `${size}${manualSizeKey.sizeLengthSeparator}${length}`;
          if (~manualSizeKey.sizeKey.indexOf(sizeKeyName)) {
            boundaries.sizeList.push(size);
            boundaries.lengthList.push(length);
          }
          return boundaries;
        }, boundaries),
      {
        sizeList: [] as string[],
        lengthList: [] as string[],
      }
    );
    manualSizeKey.sizeList = manualSizeKey.sizeList.filter((size) => sizeKeyBoundaries.sizeList.includes(size));
    manualSizeKey.lengthList = manualSizeKey.lengthList?.filter(
      (length) => !!~sizeKeyBoundaries.lengthList.indexOf(length)
    );

    return manualSizeKey;
  }
}

import React, { FC, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Progress, Space, Switch, Typography } from '@retail-core/rds';
import { sendGroupsToBeReviewedEvent } from '../../lib/fragment-helper';
import { useStore } from '../StoreContext/StoreContext';
import { ReviewButton } from './ReviewButton';
import { QuantitiesTemplateSelect } from './QuantitiesTemplateSelect';
import { ArticlesSearchBox } from './ArticlesSearchBox/ArticlesSearchBox';

import './Header.less';
import { SelectionCheckbox } from '../SizingGroup/SelectionCheckbox/SelectionCheckbox';

const { Text } = Typography;
interface Props {
  showNonReviewed: boolean;
  setShowNonReviewed: (showNonReviewed: boolean) => void;
}

export const Header: FC<Props> = observer(({ showNonReviewed, setShowNonReviewed }) => {
  const { sizing } = useStore();
  const { uiStore } = useStore();
  const articlesSelected = Object.values(uiStore.uIState).reduce((selected, group) => (selected += group.selected), 0);
  const articlesMatched = Object.values(uiStore.uIState).reduce((matched, group) => (matched += group.matched), 0);
  const groupsMatched = Object.entries(uiStore.uIState).reduce<Record<string, boolean>>(
    (matched, group) => (group[1].matched ? { ...matched, [group[0]]: !!group[1].matched } : matched),
    {}
  );
  const articlesCount = articlesMatched || sizing.articles.length;
  const allArticlesSelected = articlesSelected === articlesCount;
  const someArticlesSelected = articlesSelected !== articlesCount && articlesSelected !== 0;
  const sizingGroups = uiStore.searchQuery
    ? sizing.sizingGroups.filter((group) => groupsMatched[group.groupKey])
    : sizing.sizingGroups;
  const toggleSelected = () => {
    if (allArticlesSelected) {
      uiStore.unselectArticles(sizingGroups.map((group) => group.groupKey));
    } else {
      uiStore.selectArticles(
        sizingGroups.map((group) => group.groupKey),
        !!uiStore.searchQuery
      );
    }
  };
  const countGroup = sizing.sizingGroups.length;
  const countReviewedGroup = sizing.sizingGroups.filter((group) => group.isReviewed).length;
  const allGroupsReviewed = countReviewedGroup === countGroup;
  const percentReviewed = (countReviewedGroup / countGroup) * 100;

  useEffect(() => {
    if (sizing.presizeLoaded) {
      sendGroupsToBeReviewedEvent(countGroup - countReviewedGroup);
    }
  }, [countGroup, countReviewedGroup, sizing.presizeLoaded]);

  useEffect(() => {
    if (allGroupsReviewed) {
      setShowNonReviewed(false);
    }
  }, [allGroupsReviewed, setShowNonReviewed]);
  const isBoxingEnabledButNotDone = useMemo(() => {
    if (!sizing.enableBox) return false;
    return sizing.sizingGroups.some((group) => group.enableBox && !group.boxRestriction?.boxRestrictionId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizing.enableBox, JSON.stringify(sizing.sizingGroups)]);
  return (
    <Row className="header">
      <Col span={7}>
        <Space size={20}>
          <span data-testid="countgroup-info">
            <SelectionCheckbox
              className="header-selector"
              isSelected={allArticlesSelected}
              toggleSelected={toggleSelected}
              isVisible={true}
              partiallySelected={someArticlesSelected}
            />
            {articlesSelected
              ? `${articlesSelected} of ${articlesCount} articles selected`
              : `${articlesCount} articles`}
          </span>
          <Space>
            <Text className="hide-reviewed-switch">Hide reviewed groups</Text>
            <Switch
              onChange={setShowNonReviewed}
              checked={showNonReviewed && !uiStore.searchQuery}
              data-testid="switch-show-non-reviewed"
              disabled={allGroupsReviewed || !!uiStore.searchQuery}
              size="small"
            />
          </Space>
        </Space>
      </Col>
      <Col span={17} className="review-bar">
        <Space size={20}>
          {!uiStore.searchQuery ? (
            <>
              <Space data-testid="sizing-group-progress-bar">
                <Progress
                  className="review-bar-progress"
                  percent={percentReviewed}
                  size="small"
                  showInfo={allGroupsReviewed}
                  strokeColor="#59B150"
                />
                {!allGroupsReviewed && <Text>{`${countReviewedGroup} of ${countGroup} reviewed`}</Text>}
              </Space>
              <QuantitiesTemplateSelect disabled={sizing.sizingGroups.length === 0} />
              <span>
                <ReviewButton
                  disabled={sizing.sizingGroups.length === 0 || isBoxingEnabledButNotDone}
                  isReviewed={allGroupsReviewed}
                />
              </span>
            </>
          ) : null}
          <ArticlesSearchBox />
        </Space>
      </Col>
    </Row>
  );
});

import { runInAction } from 'mobx';
import {
  ErrorType,
  ReferenceBrandsErrorResponse,
  ReferenceBrandsQueryResponse,
  ReferenceBrandsResponse,
} from '../../models/response';
import presizeBackend from '../../services/presize-backend';
import { SizingStore } from './sizing-store';
import { Brand } from '../../models/brand';

export class GetReferenceBrandsAction {
  async getReferenceBrands(this: SizingStore): Promise<void> {
    const referenceBrandsResponse: ReferenceBrandsQueryResponse = await presizeBackend.getReferenceBrands(
      this.seasonCode,
      this.cgCode2
    );
    runInAction(() => {
      if (!referenceBrandsResponse.referenceBrands.success) {
        switch ((referenceBrandsResponse as ReferenceBrandsErrorResponse).referenceBrands.errorType) {
          case ErrorType.INVALID_CG_CODE:
            throw new Error('Reference brands could not be loaded due to invalid cg code');
          case ErrorType.INVALID_SEASON_CODE:
            throw new Error('Reference brands could not be loaded due to invalid season code');
          case ErrorType.INVALID_RESPONSE_FROM_SIZING_SPLIT_API:
            throw new Error('Reference brands could not be loaded');
        }
      } else {
        this.referenceBrands.rawBrands = (referenceBrandsResponse as ReferenceBrandsResponse).referenceBrands.referenceBrands.map(
          (brand) => ({
            ...brand,
            filterablestr: `${brand.name}${brand.code}`.toLowerCase().replace(/\s/g, ''),
          })
        );
        this.processReferenceBrandsMap();
        this.processReferenceBrandsWithUsage();
      }
    });
  }
  processReferenceBrandsWithUsage(this: SizingStore): void {
    const usedBrands: Array<Brand> = [];
    const similarBrands: Array<Brand> = [];
    const usedReferenceBrandCodes = new Set(
      this.sizingGroups.map((sg) => sg.sizing?.referenceBrandCode || '').filter((x) => !!x)
    );
    this.referenceBrands.rawBrands.forEach((b) => {
      if (usedReferenceBrandCodes.has(b.code)) {
        usedBrands.push(b);
      } else {
        similarBrands.push(b);
      }
    });
    runInAction(() => {
      this.referenceBrands.processedBrands = { usedBrands, similarBrands };
    });
  }
  processReferenceBrandsMap(this: SizingStore): void {
    runInAction(() => {
      this.referenceBrands.brandsMap = this.referenceBrands.rawBrands.reduce(
        (prev, curr) => ({ ...prev, [curr.code]: curr }),
        {}
      );
    });
  }
}

import { SizeChart } from '../models/sizing';

type TwoDimensionalSizes = {
  [size: string]: string[];
};

type SizeAbb = {
  currentSeq: SizeAbbSeq;
  seqs: SizeAbbSeq[];
};

type SizeAbbSeq = {
  start?: string;
  end?: string;
};

export function abbreviateSizeKey(sizeKeyId: string, sizeChart: SizeChart): string {
  const sizes = sizeKeyId.split(';');

  if (!sizeChart.lengthChartCode) {
    return abbreviate1DSizeKey(sizes, sizeChart);
  }

  return abbreviate2DSizeKey(sizes, sizeChart, sizeChart.sizeLengthSeparator || '');
}

function abbreviate1DSizeKey(sizes: string[], sizeChart: SizeChart): string {
  const abbreviatedSizes = initSizeAbb();
  const sCSizes = sizeChart.sizeList;
  let lastSize = '';

  sizes.forEach((size, sizeIndex) => {
    if (!abbreviatedSizes.currentSeq.start) {
      // Initialize
      abbreviatedSizes.currentSeq.start = size;
    } else {
      const lastSCSizeIndex = sCSizes.indexOf(lastSize);
      const nextSCSize = sCSizes[lastSCSizeIndex + 1];
      if (nextSCSize === size) {
        // We are in a sequence

        //Make sure that we don't lose the last element of the size key
        if (sizeIndex === sizes.length - 1) {
          abbreviatedSizes.currentSeq.end = size;
        }
      } else {
        // We are not in a sequence
        if (!abbreviatedSizes.currentSeq.end) {
          // Don't lose the end of the last sequence
          abbreviatedSizes.currentSeq.end = lastSize;
        }

        abbreviatedSizes.currentSeq.end = lastSize;
        abbreviatedSizes.seqs.push(abbreviatedSizes.currentSeq);
        abbreviatedSizes.currentSeq = { start: size };
      }
    }

    lastSize = size;
  });
  return writeSizeAbb(abbreviatedSizes);
}

function abbreviate2DSizeKey(sizes: string[], sizeChart: SizeChart, separator: string): string {
  const sCSizes = sizeChart.sizeList;
  const sCLengths: string[] = sizeChart.lengthList ? sizeChart.lengthList : [];

  const sizeMatrix = sizes.reduce<TwoDimensionalSizes>((twoDimensionalSizes, twoDSize) => {
    let size: string;
    let length: string;

    if (separator) {
      const sizeParts = twoDSize.split(separator);
      size = sizeParts[0];
      length = sizeParts[1];
    } else {
      // Try to find out the length from the size without separator
      length = sCLengths.find((length) => twoDSize.endsWith(length)) || '';
      size = twoDSize.substring(0, twoDSize.length - length.length);
    }

    twoDimensionalSizes[size] ? twoDimensionalSizes[size].push(length) : (twoDimensionalSizes[size] = [length]);
    return twoDimensionalSizes;
  }, {});

  let abbreviatedSizes = '';

  let abbreviatedLengths = initSizeAbb();
  let lastLengthAbbreviatedSizes = initSizeAbb();
  let lastLength = '';
  let lastLengthUsed = false;

  sCLengths.forEach((length, lengthIndex) => {
    let lastSize = '';
    const lengthAbbreviatedSizes = initSizeAbb();

    let lengthUsed = false;
    Object.keys(sizeMatrix).forEach((size, sizeIndex) => {
      if (sizeMatrix[size].includes(length)) {
        lengthUsed = true;
        if (!lengthAbbreviatedSizes.currentSeq.start) {
          // Initialize
          lengthAbbreviatedSizes.currentSeq.start = size;
        } else {
          const lastSCSizeIndex = sCSizes.indexOf(lastSize);
          const nextSCSize = sCSizes[lastSCSizeIndex + 1];
          if (nextSCSize === size) {
            // We are in a sequence

            //Make sure that we don't lose the last element of the size key
            if (sizeIndex === sizes.length - 1) {
              lengthAbbreviatedSizes.currentSeq.end = size;
            }
          } else {
            // We are not in a sequence
            lengthAbbreviatedSizes.currentSeq.end = lastSize;
            lengthAbbreviatedSizes.seqs.push(lengthAbbreviatedSizes.currentSeq);
            lengthAbbreviatedSizes.currentSeq = { start: size };
          }
        }

        lastSize = size;
      }
    });

    if (lengthUsed) {
      if (!lengthAbbreviatedSizes.currentSeq.end) {
        lengthAbbreviatedSizes.currentSeq.end = lastSize;
      }

      if (abbreviatedLengths.currentSeq.end) {
        abbreviatedLengths.seqs.push(abbreviatedLengths.currentSeq);
        abbreviatedLengths.currentSeq = {};
      }

      if (!abbreviatedLengths.currentSeq.start) {
        abbreviatedLengths.currentSeq.start = length;
      }

      if (
        !sizeAbbsEqual(lastLengthAbbreviatedSizes, lengthAbbreviatedSizes) &&
        lastLengthAbbreviatedSizes.currentSeq.start
      ) {
        abbreviatedSizes += write2DAbb(lastLengthAbbreviatedSizes, abbreviatedLengths, separator);
        abbreviatedSizes += ';';
        abbreviatedLengths = initSizeAbb();
        abbreviatedLengths.currentSeq.start = length;
      }
      lastLengthAbbreviatedSizes = lengthAbbreviatedSizes;
    } else if (lastLengthUsed) {
      abbreviatedLengths.currentSeq.end = lastLength;
    }

    if (lengthIndex === sCLengths.length - 1) {
      if (lengthUsed && !abbreviatedLengths.currentSeq.end) {
        abbreviatedLengths.currentSeq.end = length;
      }
      abbreviatedSizes += write2DAbb(lastLengthAbbreviatedSizes, abbreviatedLengths, separator);
    }

    lastLength = length;
    lastLengthUsed = lengthUsed;
  });

  return abbreviatedSizes;
}

function initSizeAbb() {
  return {
    currentSeq: {},
    seqs: [],
  } as SizeAbb;
}

function sizeAbbsEqual(abb1: SizeAbb, abb2: SizeAbb) {
  if (abb1.seqs.length !== abb2.seqs.length) {
    return false;
  }

  const compareSizeAbbSeq = (first: SizeAbbSeq, second: SizeAbbSeq) =>
    first.start === second.start && first.end === second.end;

  if (!compareSizeAbbSeq(abb1.currentSeq, abb2.currentSeq)) {
    return false;
  }

  for (let i = 0; i < abb1.seqs.length - 1; i++) {
    if (!compareSizeAbbSeq(abb1.seqs[i], abb2.seqs[i])) {
      return false;
    }
  }
  return true;
}

function writeSizeAbb(sizeAbb: SizeAbb) {
  const writeIt = (abb: SizeAbbSeq) => `${abb.start}${abb.end && abb.start !== abb.end ? '-' + abb.end : ''}`;
  return [...sizeAbb.seqs, sizeAbb.currentSeq].map((seq) => writeIt(seq)).join(',');
}

function write2DAbb(sizes: SizeAbb, lengths: SizeAbb, separator: string) {
  return `${writeSizeAbb(sizes)}${separator}${writeSizeAbb(lengths)}`;
}

import { CheckOutlined, CloseOutlined, InfoCircleOutlined, UndoOutlined } from '@ant-design/icons';
import { Space, Table, Tooltip, Typography, Button } from '@retail-core/rds';
import React, { FC } from 'react';
import { getSizingFromArticleQuantity } from '../../../lib/article-utils';
import { Box } from '../../../models/box-restriction';
import { Distribution, SizeKey } from '../../../models/sizing';

type Props = {
  quantity: number;
  idealQuantity: number;
  selectedSizeKey: SizeKey;
  isBoxingBeingEdited: boolean;
  isAvailabilityBeingEdited: boolean;
  isQuantityMoreThanAvailability: boolean;
  manuallyEdited: boolean;
  onSaveEdit: (totalSizing: Distribution) => void;
  onCancelEdit: () => void;
  onReset: () => void;
  onSaveAvailabilityEdit: () => void;
  onCancelAvailabilityEdit: () => void;
  data: readonly { total: number; name: string; sizing: Distribution; box: Box; quantity: number; key: string }[];
};

const LotsDetailsSummary: FC<Props> = (props) => {
  const {
    quantity,
    idealQuantity,
    selectedSizeKey,
    data,
    isBoxingBeingEdited,
    isAvailabilityBeingEdited,
    isQuantityMoreThanAvailability,
    manuallyEdited,
    onSaveEdit,
    onCancelEdit,
    onReset,
    onSaveAvailabilityEdit,
    onCancelAvailabilityEdit,
  } = props;
  const totalSizing: Distribution = {};
  const idealSizing: Distribution = {
    total: idealQuantity,
    ...getSizingFromArticleQuantity(idealQuantity, selectedSizeKey.distribution),
  };
  data.forEach((lotSizing: any, index: number) => {
    selectedSizeKey.sizeList.forEach((size: string) => {
      const sizingKey = lotSizing.length ? `${size}${selectedSizeKey.sizeLengthSeparator}${lotSizing.length}` : size;
      if (totalSizing[sizingKey]) {
        totalSizing[sizingKey] += lotSizing[sizingKey];
      } else {
        totalSizing[sizingKey] = lotSizing[sizingKey];
      }
    });
    if (!(index % (selectedSizeKey?.lengthList?.length || 1))) {
      if (totalSizing.total) {
        totalSizing.total += lotSizing.total;
      } else {
        totalSizing.total = lotSizing.total;
      }
    }
  });
  const is1D = !selectedSizeKey.lengthList?.length;
  return (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0} colSpan={1} className="empty-text-cell" />
        <Table.Summary.Cell index={1} colSpan={1} className="empty-text-cell">
          {isBoxingBeingEdited ? (
            <Space size={7}>
              <Tooltip title="Cancel edits" className="spacing">
                <Button
                  size="small"
                  icon={<CloseOutlined />}
                  onClick={onCancelEdit}
                  data-testid="cancel-lot-quantity-edit-button"
                />
              </Tooltip>
              <Tooltip title="Apply new quantities" className="spacing">
                <Button
                  type="primary"
                  size="small"
                  icon={<CheckOutlined />}
                  onClick={() => onSaveEdit(totalSizing)}
                  data-testid="save-lot-quantity-edit-button"
                  disabled={isQuantityMoreThanAvailability}
                />
              </Tooltip>
            </Space>
          ) : manuallyEdited ? (
            <Tooltip title="Reset to default optimization" className="spacing">
              <Button size="small" icon={<UndoOutlined />} onClick={onReset} data-testid="reset-lot-quantity-button" />
            </Tooltip>
          ) : null}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} colSpan={1} align="center" className="availability-buttons-cell">
          {isAvailabilityBeingEdited && (
            <Space size={7}>
              <Tooltip title="Cancel edits" className="spacing">
                <Button
                  size="small"
                  icon={<CloseOutlined />}
                  onClick={onCancelAvailabilityEdit}
                  data-testid="cancel-lot-availability-edit-button"
                />
              </Tooltip>
              <Tooltip title="Apply new availability &amp; re-calculate Lots" className="spacing">
                <Button
                  type="primary"
                  size="small"
                  icon={<CheckOutlined />}
                  onClick={onSaveAvailabilityEdit}
                  data-testid="save-lot-availability-edit-button"
                />
              </Tooltip>
            </Space>
          )}
        </Table.Summary.Cell>
        {is1D &&
          selectedSizeKey.sizeList.map((size: string, index: number) => (
            <Table.Summary.Cell key={size} index={index + 3} className="achieved-quantities-cell">
              <div
                style={{
                  backgroundColor:
                    totalSizing[size] !== idealSizing[size]
                      ? `rgba(252, 182, 42, ${Math.abs(idealSizing[size] - totalSizing[size]) / quantity})`
                      : '#FAFAFA',
                  padding: '8px',
                  fontWeight: totalSizing[size] !== idealSizing[size] ? 600 : 'initial',
                }}
                data-testid={`achieved-quantities-cell-${index}`}
              >
                {totalSizing[size]}
              </div>
            </Table.Summary.Cell>
          ))}
        {is1D && <Table.Summary.Cell index={selectedSizeKey.sizeList.length + 3} />}
        {is1D && (
          <Table.Summary.Cell index={selectedSizeKey.sizeList.length + 4}>
            {is1D && (
              <Typography.Text strong={totalSizing.total !== idealSizing.total}>{totalSizing.total}</Typography.Text>
            )}
          </Table.Summary.Cell>
        )}
      </Table.Summary.Row>
      {is1D && (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={3} className="row-gap-cell" />
          <Table.Summary.Cell index={3} colSpan={selectedSizeKey.sizeList.length} className="row-gap-cell" />
          <Table.Summary.Cell index={selectedSizeKey.sizeList.length + 3} className="row-gap-cell" />
          <Table.Summary.Cell index={selectedSizeKey.sizeList.length + 4} className="row-gap-cell" />
        </Table.Summary.Row>
      )}
      {is1D && (
        <Table.Summary.Row data-testid="ideal-quantities-row">
          <Table.Summary.Cell colSpan={3} index={0}>
            <Space className="ideal-quantities-label">
              <span>Ideal quantities</span>
              <Tooltip placement="top" title="Recommended or edited quantities for this article">
                <InfoCircleOutlined data-testid="ideal-quantities-tooltip" />
              </Tooltip>
            </Space>
          </Table.Summary.Cell>
          {selectedSizeKey.sizeList.map((size: string, index: number) => (
            <Table.Summary.Cell key={size} index={index + 3}>
              <div data-testid={`ideal-quantities-cell-${index}`}>{idealSizing[size]}</div>
            </Table.Summary.Cell>
          ))}
          <Table.Summary.Cell index={selectedSizeKey.sizeList.length + 3} />
          <Table.Summary.Cell index={selectedSizeKey.sizeList.length + 4}>
            <Typography.Text strong>{idealSizing.total}</Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}
    </>
  );
};

export default LotsDetailsSummary;

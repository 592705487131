import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { SizingGroup } from '../../components/SizingGroup/SizingGroup';
import { useStore } from '../../components/StoreContext/StoreContext';

import './SizingGroupList.less';
import { SelectionSidebar } from '../../components/SelectionSidebar/SelectionSidebar';
import SizingGroupListHeader from '../../components/SizingGroupListHeader/SizingGroupListHeader';
import { EmptyState, Illustrations } from '@retail-core/rds';

interface Props {
  showNonReviewed: boolean;
}

export const SizingGroupList: FC<Props> = observer(({ showNonReviewed }) => {
  const { sizing, uiStore } = useStore();
  const sizingGroups = sizing.sizingGroups.filter(
    (group) => !uiStore.searchQuery || uiStore.uIState[group.groupKey]?.matched > 0
  );
  const isLoading = sizing.sizingGroups.length === 0;
  return (
    <div className="sizing-group-list">
      <SelectionSidebar />
      <SizingGroupListHeader />
      {sizingGroups.length ? (
        sizingGroups.map((group) => (
          <SizingGroup
            key={group.groupKey}
            data-groupkey={group.groupKey}
            group={group}
            showNonReviewed={showNonReviewed && !uiStore.searchQuery}
            readOnly={sizing.readOnly}
            uIState={uiStore.uIState}
          />
        ))
      ) : isLoading ? (
        <EmptyState.Large
          title="Getting Article Groups"
          description="Please wait while the article groups are being fetched"
          image={<Illustrations.InProgressIllustration />}
        />
      ) : (
        <EmptyState.Large
          title="No search results"
          description="Try a different search to find what you are looking for."
          image={<Illustrations.NoResultsIllustration />}
        />
      )}
    </div>
  );
});

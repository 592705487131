import isEqual from 'lodash.isequal';
import { SizeKey } from './../models/sizing';
import { BoxDistribution } from './../models/box-restriction';
import { Boxing, StockRestrictions } from '../models/box-restriction';
import { CompatibleState, SortKey } from '../models/sizing';

export function abbreviateSizeRange(sizeRange: SortKey): string {
  return sizeRange.length > 1 ? `${sizeRange[0]} - ${sizeRange[sizeRange.length - 1]}` : sizeRange[0] || '';
}

export function isFullyCompatibleKey(boxSizeKey: SortKey, selectedSizeKey: SortKey): boolean {
  return isEqual(boxSizeKey, selectedSizeKey);
}

export function is2D(sizeKey?: SizeKey): boolean {
  return Boolean(sizeKey?.lengthList?.length && sizeKey?.sizeLengthSeparator);
}

export function isCompatibleDimension(boxSizeKey: SortKey, sizeKey?: SizeKey): boolean {
  const isBoxRestriction2D = boxSizeKey[0].includes(sizeKey?.sizeLengthSeparator || '');
  const isSizeChart2D = is2D(sizeKey);

  return isBoxRestriction2D === isSizeChart2D;
}

export function isPartially(boxSizeKey: SortKey, selectedSizeKey: SortKey): boolean {
  return selectedSizeKey.some((key) => boxSizeKey.includes(key));
}

export function isPartiallyCompatible(boxSizeKey: SortKey, selectedSizeKey: SortKey): boolean {
  return isPartially(boxSizeKey, selectedSizeKey) || isPartially(selectedSizeKey, boxSizeKey);
}

export function isPartiallyCompatible2D(boxSizeKey: SortKey, sizeKey?: SizeKey): boolean {
  const boxRestrictionSizeList: SortKey = boxSizeKey.map((key) => key.split(sizeKey?.sizeLengthSeparator || '')[0]);
  const boxRestrictionLengthList: SortKey = boxSizeKey.map((key) => key.split(sizeKey?.sizeLengthSeparator || '')[1]);

  if (!boxRestrictionSizeList || !boxRestrictionLengthList) {
    return false;
  }

  if (
    isPartiallyCompatible(boxRestrictionSizeList, sizeKey?.sizeList || []) ||
    isPartiallyCompatible(boxRestrictionLengthList, sizeKey?.lengthList || [])
  ) {
    return true;
  }

  return false;
}

export function checkCompatible(boxSizeKey: SortKey = [], selectedSizeKey?: SizeKey): CompatibleState {
  if (isFullyCompatibleKey(boxSizeKey, selectedSizeKey?.sizeKey || [])) {
    return CompatibleState.FULLY;
  }

  if (is2D(selectedSizeKey)) {
    if (isCompatibleDimension(boxSizeKey, selectedSizeKey) && isPartiallyCompatible2D(boxSizeKey, selectedSizeKey)) {
      return CompatibleState.PARTIALLY;
    }
  } else {
    if (isPartiallyCompatible(boxSizeKey, selectedSizeKey?.sizeKey || [])) {
      return CompatibleState.PARTIALLY;
    }
  }

  return CompatibleState.NOT;
}

export function boxingToStockRestrictions(boxing?: Boxing): StockRestrictions {
  if (!boxing || !('boxDistributions' in boxing)) {
    return {};
  }

  const stockRestrictions = boxing.boxDistributions.reduce((accumulator, currentValue) => {
    if (Number.isNaN(Number(currentValue.availability))) {
      return accumulator;
    }

    return { ...accumulator, [currentValue.box.name]: currentValue.availability };
  }, {});

  return stockRestrictions;
}

export function boxingToLot(boxing: Boxing | undefined, lotName: string): BoxDistribution | undefined {
  return (
    (boxing && 'boxDistributions' in boxing && boxing.boxDistributions.find((d) => d.box.name === lotName)) || undefined
  );
}

export function boxingToLotAvailability(boxing: Boxing | undefined, lotName: string): number | undefined {
  const result =
    boxing && 'boxDistributions' in boxing && boxing.boxDistributions.find((d) => d.box.name === lotName)?.availability;
  if (typeof result === 'number') {
    return result;
  } else {
    return undefined;
  }
}

import { BoxRestrictionView } from '@ism/box-restrictions';
import { SizingGroup } from '../../models/sizing-group';
import { UIStore } from './ui-store';

export class ShowBoxCatalogAction {
  public showBoxCatalog(
    this: UIStore,
    view: BoxRestrictionView,
    sizingGroup?: SizingGroup,
    boxRestrictionId?: string
  ): void {
    this.boxCatalogView = view;
    this.boxCatalogCg3 = sizingGroup?.commodityGroup.codes[2];
    this.boxCatalogSizeChartId = sizingGroup?.selectedSizeChart?.sizeChartId;
    this.boxCatalogSizeKey = sizingGroup?.selectedSizeKey?.sizeKey;
    this.boxCatalogSizingGroupKey = sizingGroup?.groupKey;
    this.boxRestrictionId = boxRestrictionId;
  }
}

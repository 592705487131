import React, { FC } from 'react';
import { PaperClipOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Space, Modal, Typography } from '@retail-core/rds';

const { Text } = Typography;

interface IProps {
  visible: boolean;
  errorsCount: number;
  templateName: string;
  onDownload: () => void;
  onReUpload: () => void;
  onCancel: () => void;
}

export const QuantitiesTemplateErrorsModal: FC<IProps> = (props) => {
  const { visible, errorsCount, templateName, onDownload, onReUpload, onCancel } = props;

  return (
    <Modal
      title="Supplier quantities could not be imported"
      okText="Re-upload quantities sheet"
      cancelText="Close"
      width={640}
      mask={true}
      maskClosable={false}
      visible={visible}
      centered={true}
      onOk={onReUpload}
      onCancel={onCancel}
      wrapClassName="presize-root"
    >
      <Space direction="vertical">
        <Text>
          The quantities sheet you uploaded contains {errorsCount} error{errorsCount > 1 ? 's' : ''}, listed in the
          error sheet below. <br />
          Please fix these errors and re-upload the sheet.
        </Text>
        <Button type="link" danger onClick={onDownload} className="quantities-template-errors-link">
          <PaperClipOutlined /> {templateName}
        </Button>
        <Button danger icon={<DownloadOutlined />} size="small" onClick={onDownload}>
          Download error sheet
        </Button>
      </Space>
    </Modal>
  );
};

import React, { FC, useState } from 'react';
import { Button, Divider, Popconfirm, Select } from '@retail-core/rds';
import './SelectionSidebar.less';
import { Article } from '../../models/sizing-data';
import { SizingGroup } from '../../models/sizing-group';
import { PlusOutlined } from '@ant-design/icons';
import './ArticleFieldSelector.less';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface ArticleFieldSelectorProps {
  sizingGroups: Array<SizingGroup>;
  selectedArticles: Array<Article>;
  updateArticles?: (articleIds: Array<string>, modifier: Record<string, string | null>) => void;
  loading?: boolean;
  hasManuallyEditedArticles?: boolean;
  hasBoxEnabledArticles?: boolean;
  field: 'sizeStructure' | 'sizingNote' | 'silo';
  placeholder?: string;
  nullValueDisplay?: string;
  nullOptionDisplay?: string;
  commonOptions?: Array<string>;
}
export const ArticleFieldSelector: FC<ArticleFieldSelectorProps> = ({
  sizingGroups,
  selectedArticles,
  loading,
  updateArticles,
  hasManuallyEditedArticles,
  hasBoxEnabledArticles,
  field,
  placeholder,
  nullValueDisplay,
  nullOptionDisplay,
  commonOptions,
}) => {
  const uniqueFieldValues = new Set(selectedArticles.map((article) => article?.[field]));
  const selectedValue = uniqueFieldValues.size > 1 ? 'Mixed' : selectedArticles[0]?.[field] || undefined;
  const [selectedFieldValue, setSelectedFieldValue] = useState<string | undefined>(selectedValue);
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const existingFieldValueOptions = sizingGroups.map((sg) => sg[field]).filter((x) => !!x);
  const uniqueFieldValueOptions = [...new Set(existingFieldValueOptions)];
  const handleChange = (value?: string) => {
    setSelectedFieldValue(value);
    if (!popConfirmVisible && (hasBoxEnabledArticles || hasManuallyEditedArticles || uniqueFieldValues.size > 1)) {
      setPopConfirmVisible(true);
      return;
    }
    if (uniqueFieldValues.size === 1 && (uniqueFieldValues.has(value) || (uniqueFieldValues.has(null) && !value))) {
      return;
    }
    !loading &&
      value !== undefined &&
      updateArticles?.(
        selectedArticles.map((article) => article.referenceId),
        { [field]: value }
      );
  };
  const confirm = () => {
    setPopConfirmVisible(false);
    handleChange(selectedFieldValue);
  };

  const cancel = () => {
    setPopConfirmVisible(false);
    setSelectedFieldValue(selectedValue);
  };
  const popconfirmMessage = hasBoxEnabledArticles
    ? 'Box restrictions recommendations for the selected article(s) will be lost when moved to a new group'
    : `${nullValueDisplay?.replace('Add', 'Apply this')} to all selected articles, overwriting existing values?`;
  useDeepCompareEffect(() => {
    setSelectedFieldValue(selectedValue);
  }, [selectedArticles]);
  return (
    <Popconfirm
      overlayStyle={{ width: '426px' }}
      visible={popConfirmVisible}
      title={popconfirmMessage}
      placement="bottom"
      onConfirm={confirm}
      onCancel={cancel}
      okText="Apply"
      cancelText="Cancel"
      data-testid={`${field}-selector-confirm`}
      arrowPointAtCenter
    >
      <Select
        data-testid={`${field}-selector`}
        style={{ display: 'block' }}
        showSearch
        placeholder={nullValueDisplay}
        value={selectedFieldValue}
        loading={loading}
        onSelect={handleChange}
        filterOption={true}
        bordered={false}
        size="small"
        dropdownRender={(menu) => {
          return (
            <div data-testid={`${field}-selector-dropdown`}>
              {menu.props.options.length ? menu : null}
              {menu.props.searchValue && !uniqueFieldValueOptions.includes(menu.props.searchValue) ? (
                <>
                  <Divider className="article-field-selector-dropdown-divider" />
                  <Button
                    type="text"
                    className="article-field-selector-dropdown-create-button"
                    block
                    icon={<PlusOutlined />}
                    onClick={() => {
                      handleChange(menu.props.searchValue);
                      menu.props.onToggleOpen(false);
                    }}
                  >
                    Create group for '{menu.props.searchValue}'
                  </Button>
                </>
              ) : null}
            </div>
          );
        }}
      >
        <Select.Option key={'no-value'} value={''} data-testid={`${field}-selector-`}>
          <div>{nullOptionDisplay}</div>
        </Select.Option>
        {uniqueFieldValueOptions.length ? (
          <Select.OptGroup label="Move to another group">
            {uniqueFieldValueOptions.map((fieldValue, index) => (
              <Select.Option
                key={`existing-${index}-${fieldValue}`}
                value={fieldValue || ''}
                data-testid={`${field}-selector-${fieldValue}`}
              >
                <div>{fieldValue}</div>
              </Select.Option>
            ))}
          </Select.OptGroup>
        ) : null}
        {commonOptions && (
          <Select.OptGroup label="Create common sizing group">
            {commonOptions.map((option, index) => (
              <Select.Option key={`common-${index}-${option}`} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select.OptGroup>
        )}
      </Select>
    </Popconfirm>
  );
};

import React, { FC, useEffect, useState, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Typography, Alert } from '@retail-core/rds';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { SelectableMatrix } from '../Matrices/SelectableMatrix/SelectableMatrix';
import { SizingGroup } from '../../models/sizing-group';
import { SizingKey, SizeKey, SizeChart } from '../../models/sizing';
import './SizingSelector.less';

const { Title, Text } = Typography;
interface IProps {
  visible: boolean;
  group: SizingGroup;
  selectedSizeKey?: SizeKey;
  selectedSizeChart?: SizeChart;
  onApplySizes: (sizingKey: SizingKey) => void;
  onCancel: () => void;
}

function getModalWidth(numberOfColumns: number): number {
  // Calculate width based on number of matrix columns (each column is 58px + 1px border) and add one more 58 for margins
  return Math.max(++numberOfColumns * 61, 700);
}

export const SizingSelector: FC<IProps> = observer((props) => {
  const { visible, group, selectedSizeChart, selectedSizeKey, onApplySizes, onCancel } = props;
  const [selectedSizeKeyId, setSelectedSizeKeyId] = useState(selectedSizeKey?.sizeKeyId || '');
  const [sizeKeyChanged, setSizeKeyChanged] = useState(false);
  const numberOfEditedArticles = group.numberOfArticlesWithEditedQuantity();
  const modalWidth = useMemo(
    () =>
      selectedSizeChart
        ? getModalWidth(selectedSizeChart.sizeList.length + (selectedSizeChart.lengthList?.length ? 1 : 0))
        : 0,
    [selectedSizeChart]
  );

  useEffect(() => {
    setSelectedSizeKeyId(selectedSizeKey?.sizeKeyId || '');
  }, [selectedSizeKey]);

  const onSelectionChange = (sizeKeyId: string): void => {
    setSelectedSizeKeyId(sizeKeyId);
    setSizeKeyChanged(sizeKeyId !== selectedSizeKey?.sizeKeyId);
  };

  const applySizesHandler = () => {
    onApplySizes({ sizeKeyId: selectedSizeKeyId, sizeChartId: selectedSizeChart?.sizeChartId as string });
  };

  return (
    <>
      {selectedSizeChart && (
        <Modal
          className="sizing-selector"
          wrapClassName="presize-root"
          width={modalWidth}
          mask={true}
          centered={true}
          visible={visible}
          maskClosable={false}
          focusTriggerAfterClose={false}
          onOk={applySizesHandler}
          onCancel={onCancel}
          title={
            <Title level={5} style={{ margin: 0 }}>
              {'Select sizes · '}
              {group.commodityGroup.names.slice(2).join(' - ')}
              {group.sizeStructure && <>{` · ${group.sizeStructure}`}</>}
              {group.sizingNote && <>{` · ${group.sizingNote}`}</>}
            </Title>
          }
          footer={[
            <Button key="submit" type="primary" onClick={applySizesHandler} disabled={!selectedSizeKeyId}>
              Apply sizes
            </Button>,
          ]}
        >
          <Text>{selectedSizeChart?.description}</Text>
          <SelectableMatrix sizeChart={selectedSizeChart} sizeKey={selectedSizeKey} onChange={onSelectionChange} />
          {numberOfEditedArticles > 0 && sizeKeyChanged && (
            <Alert
              type="warning"
              className="overwrite-quantities-alert"
              message="Overrides of edited quantities per size"
              description={
                <span>
                  {`${numberOfEditedArticles} article(s) in this group have manually adjusted quantities per size.`}
                  <br />
                  {"These adjustments will be overwritten with the group's distribution if you proceed."}
                </span>
              }
              showIcon
              icon={<ExclamationCircleFilled />}
            />
          )}
        </Modal>
      )}
    </>
  );
});

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, message, Modal, Radio, Row, Tooltip } from '@retail-core/rds';
import { SizingGroup } from '../../../models/sizing-group';
import './DistributionCompareModal.less';
import ReferenceBrandSelector from '../GroupHeader/ReferenceBrandSelector/ReferenceBrandSelector';
import { Brand } from '../../../models/brand';
import { DistributionType } from '../../../models/sizing';
import { Matrix } from '../../Matrices/Matrix/Matrix';
import { CheckOutlined } from '@ant-design/icons';
import { MatrixDataset } from '../../../models/sizing-table';
import {
  distributionWith100Percent,
  getDistribution,
  getMatrixDataset,
  sumMatrixDataset,
} from '../../Matrices/matrices-utils';
import isEqual from 'fast-deep-equal';
import useTablesScrollSync from '../../../lib/use-tables-scroll-sync';
import { SizeDistribution } from '../../../models/response';

interface IProps {
  isVisible: boolean;
  setVisible: (visibility: boolean) => void;
  group: SizingGroup;
  onApplyReferenceBrand: (referenceBrandCode?: string | null) => Promise<boolean>;
  getReferenceBrandsWithUsage: () => Record<string, Array<Brand>>;
  getReferenceBrandByCode: (code: string) => Brand | undefined;
  getSizingDistribution: (
    group: SizingGroup,
    referenceBrand?: string | null,
    silo?: string | null
  ) => Promise<SizeDistribution>;
  onChange: (sizeDistribution: SizeDistribution) => Promise<void> | void;
}
const getModalWidth = (numberOfColumns: number): number => {
  return Math.max(++numberOfColumns * 61 + 300, 600);
};
const DistributionCompareModal: FC<IProps> = (props) => {
  const [selectedDistribution, selectDistribution] = useState<string>();
  const [referenceBrand, setReferenceBrand] = useState<string | null>();
  const [dataSet, setDataSet] = useState<MatrixDataset>();
  // State based currentDistribution update to force ref brand over manual edits
  const [currentDistribution, setCurrentDistribution] = useState<SizeDistribution>();
  const [originalRecommendation, setOriginalRecommendation] = useState<SizeDistribution>();
  const [siloRecommendation, setSiloRecommendation] = useState<SizeDistribution>();
  // TODO verify the setting oof the above dist sets reco frields as well
  // Ref based currentDistribution update to handle manual edits
  const currentDistributionRef = useRef<SizeDistribution>();
  const siloRef = useRef('');
  const groupIdRef = useRef('');
  const changedReferenceBrand = referenceBrand !== props.group.sizing?.referenceBrandCode;
  useEffect(() => {
    if (!selectedDistribution && props.isVisible) {
      selectDistribution('current');
    } else if (!props.isVisible) {
      selectDistribution(undefined);
    }
  }, [selectDistribution, props.isVisible]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!referenceBrand && props.group.sizing?.referenceBrandCode && props.isVisible) {
      setReferenceBrand(props.group.sizing?.referenceBrandCode);
    } else if (!props.isVisible) {
      setReferenceBrand(undefined);
    }
  }, [setReferenceBrand, props.isVisible]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const setCurrentDistributionFn = async () => {
      const selectedSizeKey = props.group.selectedSizeKey;
      if (!currentDistribution && selectedSizeKey && props.isVisible) {
        setCurrentDistribution(SizeDistribution.fromSizeKey(selectedSizeKey));
      } else if (!props.isVisible) {
        setCurrentDistribution(undefined);
      }
    };
    setCurrentDistributionFn();
  }, [currentDistribution, setCurrentDistribution, props.isVisible]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!props.isVisible) return;
    if (!(props.group.selectedSizeKey && props.group.selectedSizeChart)) return;
    if (groupIdRef.current !== props.group.id) {
      props.getSizingDistribution(props.group).then((reco) => setOriginalRecommendation(reco));
      if (!props.group.silo) {
        setSiloRecommendation(undefined);
      } else if (siloRef.current !== props.group.silo) {
        props.getSizingDistribution(props.group, null, props.group.silo).then((reco) => setSiloRecommendation(reco));
        siloRef.current = props.group.silo;
      }
      groupIdRef.current = props.group.id;
    }
  }, [setOriginalRecommendation, setSiloRecommendation, props.group.id, props.isVisible, props.group.silo]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (
      !currentDistributionRef.current &&
      props.group.selectedSizeKey &&
      props.group.selectedSizeChart &&
      props.isVisible
    ) {
      currentDistributionRef.current = SizeDistribution.fromSizeKey(props.group.selectedSizeKey);
    } else if (!props.isVisible) {
      currentDistributionRef.current = undefined;
    }
  }, [currentDistributionRef.current, props.isVisible]); // eslint-disable-line react-hooks/exhaustive-deps
  const onChangeDistributionWhileComparing = (sizeDistribution: SizeDistribution, dataSet?: MatrixDataset) => {
    currentDistributionRef.current = sizeDistribution;
    if (props.group.selectedSizeKey && props.group.selectedSizeKey.distribution) {
      const changedDataSet = !isEqual(
        getMatrixDataset(props.group.selectedSizeKey, props.group.selectedSizeKey.distribution),
        dataSet
      );
      if (changedDataSet && !changedReferenceBrand) setReferenceBrand(null);
    }
    setDataSet(dataSet);
    if (selectedDistribution !== 'current') {
      selectDistribution('current');
    }
  };
  const tableRef1 = useRef<HTMLDivElement>(null);
  const tableRef2 = useRef<HTMLDivElement>(null);
  const shouldSyncTables =
    currentDistribution?.distribution &&
    props.group.selectedSizeKey &&
    originalRecommendation?.distribution &&
    props.isVisible;
  useTablesScrollSync(tableRef1, tableRef2, !!shouldSyncTables);
  const requiresScaleBeforeSave = dataSet && sumMatrixDataset(dataSet) !== 100 && selectedDistribution === 'current';
  const is2D = !!props.group.selectedSizeKey?.lengthList?.length;
  const onScaleDistribution = useCallback(async () => {
    if (dataSet && props.group.selectedSizeKey && props.group.selectedSizeKey.distribution) {
      const scaledDistribution = distributionWith100Percent(
        getDistribution(dataSet, props.group.selectedSizeKey.sizeLengthSeparator || '')
      );
      const updatedDataSet = getMatrixDataset(props.group.selectedSizeKey, scaledDistribution);
      setDataSet(updatedDataSet);
      const isMatrixDataChanged = !isEqual(
        getMatrixDataset(props.group.selectedSizeKey, props.group.selectedSizeKey.distribution),
        updatedDataSet
      );
      if (isMatrixDataChanged && currentDistributionRef.current) {
        const newDistribution = getDistribution(
          updatedDataSet,
          is2D ? props.group.selectedSizeKey.sizeLengthSeparator : ''
        );
        const isDistChanged = !isEqual(currentDistributionRef.current.distribution, newDistribution);
        if (isDistChanged && currentDistributionRef.current) {
          currentDistributionRef.current.distribution = newDistribution;
          currentDistributionRef.current.distributionType = DistributionType.MANUAL;
        }
      }
    }
  }, [is2D, dataSet, props.group.selectedSizeKey]);
  const onApplyDistribution = async () => {
    if (props.group.selectedSizeKey && selectedDistribution === 'current') {
      if (referenceBrand) {
        if (changedReferenceBrand) {
          await props.onApplyReferenceBrand(referenceBrand);
        }
        return props.setVisible(false);
      }
      if (requiresScaleBeforeSave) {
        onScaleDistribution();
      }
      if (
        currentDistributionRef.current &&
        currentDistributionRef.current.distribution &&
        !isEqual(currentDistributionRef.current?.distribution, props.group.selectedSizeKey.distribution)
      ) {
        try {
          const sizeDist: SizeDistribution = {
            distribution: currentDistributionRef.current.distribution,
            distributionType: currentDistributionRef.current?.distributionType,
            recoLevel: currentDistributionRef.current?.recoLevel,
            recoStrategy: currentDistributionRef.current?.recoStrategy,
          };
          await props.onChange(sizeDist);
          return props.setVisible(false);
        } catch (e) {
          console.error(e);
        }
      }
    } else if (
      props.group.selectedSizeKey &&
      originalRecommendation?.distribution &&
      selectedDistribution === 'original'
    ) {
      try {
        await props.onChange(originalRecommendation);
        return props.setVisible(false);
      } catch (e) {
        console.error(e);
      }
    } else if (props.group.selectedSizeKey && siloRecommendation?.distribution && selectedDistribution === 'silo') {
      try {
        await props.onChange(siloRecommendation);
        return props.setVisible(false);
      } catch (e) {
        console.error(e);
      }
    }
    return props.setVisible(false);
  };
  const onApplyReferenceBrand = async (referenceBrandCode?: string | null) => {
    const { distribution, distributionType } = await props.getSizingDistribution(props.group, referenceBrandCode);
    if (distributionType !== DistributionType.UNKNOWN) {
      setCurrentDistribution({
        distribution,
        distributionType,
      });
      setReferenceBrand(referenceBrandCode);
      if (selectedDistribution !== 'current') {
        selectDistribution('current');
      }
      return true;
    }
    message.error('No recommendation found, please use another reference brand', 5);
    return false;
  };
  const modalWidth = useMemo(
    () =>
      props.group.selectedSizeKey
        ? getModalWidth(
            props.group.selectedSizeKey.sizeList.length + (props.group.selectedSizeKey.lengthList?.length ? 1 : 0)
          )
        : 0,
    [props.group.selectedSizeKey]
  );
  const isCurrentDistributionManuallyEdited = props.group.selectedSizeKey?.distributionType === DistributionType.MANUAL;
  return (
    <Modal
      width={modalWidth}
      wrapClassName="presize-root"
      mask={true}
      centered={true}
      maskClosable={false}
      visible={props.isVisible}
      focusTriggerAfterClose={false}
      onCancel={() => props.setVisible(false)}
      title={
        <div data-testid="compare-modal-title" className="compare-modal-title">
          Compare recommendation
          <span className="compare-modal-title-separator" />
          {props.group.groupName}
          {props.group.sizeStructure ? <span className="compare-modal-title-separator" /> : ''}
          {props.group.sizeStructure || ''}
          {props.group.sizingNote ? <span className="compare-modal-title-separator" /> : ''}
          {props.group.sizingNote || ''}
        </div>
      }
      footer={[
        <Button key="cancel" onClick={() => props.setVisible(false)}>
          Cancel
        </Button>,
        <Tooltip
          key="apply"
          title={requiresScaleBeforeSave ? 'Distribution sum will be scaled to 100%' : undefined}
          placement={'bottom'}
        >
          <Button icon={<CheckOutlined />} key="submit" type="primary" onClick={onApplyDistribution}>
            Apply distribution
          </Button>
        </Tooltip>,
      ]}
    >
      <Radio.Group
        className="compare-modal-radio-group"
        onChange={(e) => selectDistribution(e.target.value)}
        value={selectedDistribution}
      >
        <Row data-testid="ref-brand-row">
          <Col flex="150px">{/*empty*/}</Col>
          <Col flex="auto" style={{ width: '100px' }}>
            {!props.group.silo && (
              <ReferenceBrandSelector
                referenceBrandCode={referenceBrand}
                getReferenceBrandsWithUsage={props.getReferenceBrandsWithUsage}
                getReferenceBrandByCode={props.getReferenceBrandByCode}
                onApplyReferenceBrand={onApplyReferenceBrand}
              />
            )}
          </Col>
          <Col flex="150px" style={{ zIndex: -1 }}>
            {/*empty*/}
          </Col>
        </Row>
        {isCurrentDistributionManuallyEdited && (
          <Row data-testid="current-reco-row">
            <Col flex="150px" className="compare-modal-radio-group-col">
              <div className={'compare-modal-radio-group-col-space'} />
              <div className={'compare-modal-radio-group-col-option'}>
                <Radio aria-label="current" value="current">
                  Current
                </Radio>
              </div>
            </Col>
            <Col flex="auto" style={{ width: '100px' }}>
              {props.group.selectedSizeKey && currentDistribution && currentDistribution.distribution && (
                <Matrix
                  sizeKey={props.group.selectedSizeKey}
                  data={currentDistribution.distribution}
                  groupKey={props.group.groupKey}
                  referenceId={props.group.groupKey}
                  dataIsPercentage
                  showTableHeader
                  highlighted
                  expectedTotal={100}
                  onChangeDistributionWhileComparing={onChangeDistributionWhileComparing}
                  ref={tableRef1}
                  maxScrollHeight={0}
                />
              )}
            </Col>
            <Col flex="150px" style={{ zIndex: -1 }}>
              {/*empty*/}
            </Col>
          </Row>
        )}
        {props.group.silo && (
          <Row data-testid="silo-reco-row">
            <Col flex="150px" className="compare-modal-radio-group-col">
              <div className={'compare-modal-radio-group-col-option'}>
                <Radio aria-label="silo" value="silo">
                  Silo size curve recommendation
                </Radio>
              </div>
            </Col>
            <Col flex="auto" style={{ width: '100px' }}>
              {props.group.selectedSizeKey && siloRecommendation?.distribution && (
                <Matrix
                  sizeKey={props.group.selectedSizeKey}
                  data={siloRecommendation.distribution}
                  groupKey={props.group.groupKey}
                  referenceId={props.group.groupKey}
                  dataIsPercentage
                  showTableHeader={!isCurrentDistributionManuallyEdited}
                  highlighted
                  expectedTotal={100}
                  readOnly
                  ref={tableRef2}
                  onChangeDistributionWhileComparing={() => null}
                />
              )}
            </Col>
            <Col flex="150px" style={{ zIndex: -1 }}>
              {/*empty*/}
            </Col>
          </Row>
        )}
        <Row data-testid="original-reco-row">
          <Col flex="150px" className="compare-modal-radio-group-col">
            <div className={'compare-modal-radio-group-col-option'}>
              <Radio aria-label="original" value="original">
                Brand & CG size curve recommendation
              </Radio>
            </div>
          </Col>
          <Col flex="auto" style={{ width: '100px' }}>
            {props.group.selectedSizeKey && originalRecommendation?.distribution && (
              <Matrix
                sizeKey={props.group.selectedSizeKey}
                data={originalRecommendation.distribution}
                groupKey={props.group.groupKey}
                referenceId={props.group.groupKey}
                dataIsPercentage
                expectedTotal={100}
                readOnly
                highlighted
                ref={tableRef2}
                onChangeDistributionWhileComparing={() => null}
                showTableHeader={false}
              />
            )}
          </Col>
          <Col flex="150px" style={{ zIndex: -1 }}>
            {/*empty*/}
          </Col>
        </Row>
      </Radio.Group>
    </Modal>
  );
};

export default DistributionCompareModal;

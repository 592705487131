import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useStore } from '../../../components/StoreContext/StoreContext';
import { SizeKey } from '../../../models/sizing';
import { Article } from '../../../models/sizing-data';
import './ArticleList.less';
import { ArticleLine } from './ArticleLine/ArticleLine';
import { CollapseSeparator } from './CollapseSeparator/CollapseSeparator';
import { GroupUIState } from '../../../models/ui-state';

interface IProps {
  sizeKey?: SizeKey;
  articles: Article[];
  collapsed?: boolean;
  readOnly?: boolean;
  onExpand?: () => void;
  onCollapse?: (key: string) => void;
  articlesSelected?: Record<string, boolean>;
  updateArticleSelected?: (key: string) => void;
  separatorSelected?: boolean;
  separatorPartiallySelected?: boolean;
  groupKey?: string;
  enableBox?: boolean;
  uIState?: Record<string, GroupUIState>;
}

export const ArticleList: FC<IProps> = observer((props) => {
  const {
    articles,
    sizeKey,
    collapsed,
    readOnly,
    onExpand,
    onCollapse,
    articlesSelected,
    updateArticleSelected = () => null,
    separatorSelected,
    separatorPartiallySelected,
    groupKey,
    enableBox,
    uIState,
  } = props;
  const { uiStore } = useStore();
  const matchedArticles = articles.filter(
    (article) =>
      !uiStore.searchQuery ||
      (groupKey &&
        uiStore.uIState[groupKey]?.matched > 0 &&
        uiStore.uIState[groupKey]?.articles[article.referenceId].matched)
  );
  if (!matchedArticles.length) return null;
  return (
    <>
      <ArticleLine
        articleSelected={articlesSelected?.[matchedArticles[0].referenceId]}
        toggleArticleSelected={() => updateArticleSelected(matchedArticles[0].referenceId)}
        article={matchedArticles[0]}
        sizeKey={sizeKey}
        readOnly={readOnly}
        key={matchedArticles[0].referenceId}
        groupKey={groupKey}
        enableBox={enableBox}
        uIState={uIState}
      />
      {matchedArticles.length > 3 && (
        <CollapseSeparator
          key="separator"
          amount={matchedArticles.length - 2}
          collapsed={collapsed}
          onClick={() => (collapsed ? onExpand && onExpand() : onCollapse && onCollapse(''))}
          separatorSelected={separatorSelected}
          separatorPartiallySelected={separatorPartiallySelected}
          toggleSeparatorSelected={() => updateArticleSelected('separator')}
        />
      )}
      {collapsed && matchedArticles.length > 3 ? (
        <>
          <ArticleLine
            articleSelected={articlesSelected?.[matchedArticles[matchedArticles.length - 1].referenceId]}
            toggleArticleSelected={() => updateArticleSelected(matchedArticles[matchedArticles.length - 1].referenceId)}
            article={matchedArticles[matchedArticles.length - 1]}
            sizeKey={sizeKey}
            key={matchedArticles[matchedArticles.length - 1].referenceId}
            readOnly={readOnly}
            groupKey={groupKey}
            enableBox={enableBox}
            uIState={uIState}
          />
        </>
      ) : (
        <>
          {matchedArticles.slice(1).map((article) => (
            <ArticleLine
              articleSelected={articlesSelected?.[article.referenceId]}
              toggleArticleSelected={() => updateArticleSelected(article.referenceId)}
              article={article}
              sizeKey={sizeKey}
              readOnly={readOnly}
              key={article.referenceId}
              groupKey={groupKey}
              enableBox={enableBox}
              uIState={uIState}
            />
          ))}
        </>
      )}
    </>
  );
});

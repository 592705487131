import { SizingStore } from './sizing/sizing-store';
import { UIStore } from './ui/ui-store';

export class RootStore {
  private _sizing?: SizingStore;
  private _uiStore?: UIStore = new UIStore();

  constructor(sizing?: SizingStore) {
    this._sizing = sizing;
  }

  get sizing(): SizingStore {
    if (!this._sizing) {
      throw new Error('No sizing store provided yet');
    }
    return this._sizing;
  }

  set sizing(sizingStore: SizingStore) {
    this._sizing = sizingStore;
  }

  get uiStore(): UIStore {
    if (!this._uiStore) {
      throw new Error('Selection state not ready yet');
    }
    return this._uiStore;
  }

  set uiStore(uiStore: UIStore) {
    this._uiStore = uiStore;
  }
}

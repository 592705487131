import React, { useEffect } from 'react';

const useTablesScrollSync = (
  tableRef1: React.RefObject<HTMLDivElement>,
  tableRef2: React.RefObject<HTMLDivElement>,
  shouldSyncTables: boolean
): void => {
  useEffect(() => {
    if (tableRef1.current && tableRef2.current && shouldSyncTables) {
      const node1 = tableRef1.current.querySelector<HTMLDivElement>('.table .ant-table-body');
      const node2 = tableRef2.current.querySelector<HTMLDivElement>('.table .ant-table-body');
      if (node1 && node2) {
        const syncNode2WithNode1 = () => {
          node2.scrollLeft = node1.scrollLeft;
          node2.scrollTop = node1.scrollTop;
        };
        node1.addEventListener('scroll', syncNode2WithNode1);
        const syncNode1WithNode2 = () => {
          node1.scrollLeft = node2.scrollLeft;
          node1.scrollTop = node2.scrollTop;
        };
        node2.addEventListener('scroll', syncNode1WithNode2);
        return () => {
          node1.removeEventListener('scroll', syncNode2WithNode1);
          node2.removeEventListener('scroll', syncNode1WithNode2);
        };
      }
    }
  }, [shouldSyncTables, tableRef1, tableRef2]);
};

export default useTablesScrollSync;

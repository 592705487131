import React, { FC, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Typography, Button, Tooltip } from '@retail-core/rds';
import { CheckOutlined } from '@ant-design/icons';
import { useSticky } from './use-sticky';
import { useStore } from '../../StoreContext/StoreContext';
import { DistributionType, SizingKey } from '../../../models/sizing';
import { SizingGroup } from '../../../models/sizing-group';
import { ConditionalWrapper } from '../../partials/ConditionalWrapper/ConditionalWrapper';
import { SizingPicker } from '../../SizingPicker/SizingPicker';
import { SizingSelector } from '../../SizingSelector/SizingSelector';
import { DistributionTable } from '../DistributionTable/DistributionTable';
import { containsNonEditableSizeChart, SizingGroupState } from '../SizingGroup';
import { SizingGroupTags } from './SizingGroupTags';
import { SelectionCheckbox } from '../SelectionCheckbox/SelectionCheckbox';
import ReferenceBrandSelector from './ReferenceBrandSelector/ReferenceBrandSelector';
import BoxRestrictionsSelector from './BoxRestrictionsSelector/BoxRestrictionsSelector';
import DistributionCompareModal from '../DistributionCompareModal/DistributionCompareModal';
import ReuseDecisionSelector from '../ReuseDecisionSelector/ReuseDecisionSelector';
import './GroupHeader.less';
import { getCookie } from '../../../lib/fragment-helper';
import { SizeDistribution } from '../../../models/response';

const { Title } = Typography;
interface IProps {
  group: SizingGroup;
  state?: SizingGroupState;
  readOnly?: boolean;
  isGroupSelected?: boolean;
  toggleGroupSelection?: () => void;
  collapseGroup?: () => void;
  partiallySelected?: boolean;
}

export const GroupHeader: FC<IProps> = observer((props) => {
  const {
    group,
    group: { groupName, groupId, sizeStructure, sizingNote, selectedSizeChart, selectedSizeKey, sizing },
    state,
    readOnly,
    isGroupSelected = false,
    toggleGroupSelection,
    collapseGroup,
    partiallySelected = false,
  } = props;

  const { sizing: sizingStore, uiStore } = useStore();
  const [showSizingSelector, setShowSizingSelector] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const groupRef = useRef<HTMLDivElement>(null);
  useSticky(groupRef, state === SizingGroupState.EXPANDED);
  const isSized = group.isSized();
  const numberOfEditedArticles = group.numberOfArticlesWithEditedQuantity();

  const distributionUpdateHandler = async (sizeDistribution: SizeDistribution) => {
    await sizingStore.updateSizingDistribution(group, sizeDistribution);
  };

  const openCompare = () => {
    setShowCompareModal(true);
  };

  const onSizingChange = (
    newSizingKey: SizingKey,
    keepSizing?: boolean,
    sizeChartManuallyChanged?: boolean,
    sizeKeyManuallyChanged?: boolean
  ) => {
    const sizingGroupKey = {
      groupKey: group.groupKey,
      commodityGroupCode: group.commodityGroup.code,
    };

    sizingStore.changeSizing(
      sizingGroupKey,
      newSizingKey,
      keepSizing,
      sizeChartManuallyChanged,
      sizeKeyManuallyChanged
    );
    setShowSizingSelector(false);
  };

  const onApplyReferenceBrand = async (referenceBrandCode?: string | null) => {
    return await sizingStore.applyReferenceBrand(referenceBrandCode, group.groupKey);
  };

  const onApplyDecision = async (selectedDecisionId?: number) => {
    return await sizingStore.applySizingDecision(group, selectedDecisionId);
  };

  const toggleReviewHandler = async () => {
    if (!group.isReviewed && collapseGroup) collapseGroup();
    await sizingStore.toggleReview(group);
  };

  const isSizeChartNotEditable = containsNonEditableSizeChart(group.articles);
  const [groupHeaderHover, setGroupHeaderHover] = useState(false);
  const onGroupHeaderEnter = (e: React.MouseEvent) => {
    e.preventDefault();
    setGroupHeaderHover(true);
  };
  const onGroupHeaderLeave = (e: React.MouseEvent) => {
    e.preventDefault();
    setGroupHeaderHover(false);
  };
  const hasManuallyEditedArticles = group.articles.some((article) => article.manuallyEdited);
  const previouslyOrdered = group.articles.some((article) => !article.sizeChartEditable);
  const hasManuallyEditedDistribution = group.selectedSizeKey?.distributionType === DistributionType.MANUAL;
  const developerMode = getCookie('enable_developer_mode') === 'true';
  return (
    <>
      <div
        className={`group-header`}
        ref={groupRef}
        id={`header-${group.groupKey}`}
        data-testid={`header-${group.groupKey}`}
        onMouseEnter={onGroupHeaderEnter}
        onMouseLeave={onGroupHeaderLeave}
      >
        <SizingSelector
          group={group}
          visible={showSizingSelector}
          selectedSizeKey={selectedSizeKey}
          selectedSizeChart={selectedSizeChart}
          onApplySizes={onSizingChange}
          onCancel={() => setShowSizingSelector(false)}
        />
        <Row gutter={64}>
          <Col span={18}>
            <SelectionCheckbox
              className="group-selector"
              isSelected={isGroupSelected}
              toggleSelected={toggleGroupSelection}
              isVisible={true}
              partiallySelected={partiallySelected}
            />
            <Title
              data-testid={`header-title-${group.groupKey}`}
              id={groupId}
              level={5}
              className={`group-title ${group.isReviewed ? 'group-title-reviewed' : ''}`}
            >
              {developerMode && `[${group.id}] `}
              {groupName}
              {sizeStructure && (
                <>
                  <span className="title-separator" />
                  <Tooltip title="Size Structure">{sizeStructure}</Tooltip>
                </>
              )}
              {sizingNote && (
                <>
                  <span className="title-separator" />
                  <Tooltip title="Fit / Sizing Note">{sizingNote}</Tooltip>
                </>
              )}
              <SizingGroupTags group={group} />
            </Title>
          </Col>
          {!group.loadingSizing && (
            <Col span={6} style={{ paddingRight: 38, paddingLeft: 24 }}>
              <div className="review-btn">
                <ConditionalWrapper
                  condition={!group.isReviewed && !isSized}
                  wrapper={(children) => (
                    <Tooltip
                      title="Please complete sizing for this group before marking it as reviewed."
                      placement="bottomRight"
                    >
                      {children}
                    </Tooltip>
                  )}
                >
                  <Button
                    data-testid="toggle-review-btn"
                    className={group.isReviewed ? 'is-reviewed' : undefined}
                    type={group.isReviewed ? 'primary' : 'default'}
                    icon={<CheckOutlined />}
                    onClick={toggleReviewHandler}
                    disabled={
                      readOnly ||
                      (!group.isReviewed && !isSized) ||
                      (group.enableBox && !group.boxRestriction?.boxRestrictionId)
                    }
                  >
                    {group.isReviewed ? 'Reviewed' : 'Mark as reviewed'}
                  </Button>
                </ConditionalWrapper>
              </div>
            </Col>
          )}
        </Row>
        <Row gutter={64}>
          <Col span={10} xl={9} xxl={8}>
            <ReuseDecisionSelector
              sizingDecisions={group.sizing?.sizingDecisions}
              reusedDecision={group.reusedDecision}
              isManuallyEdited={hasManuallyEditedArticles || hasManuallyEditedDistribution}
              previouslyOrdered={previouslyOrdered}
              onApplyDecision={onApplyDecision}
            />
          </Col>
          <Col flex={1}>
            {sizingStore.enableBox ? (
              <BoxRestrictionsSelector
                boxRestrictions={sizingStore.boxRestrictions}
                sizingGroup={group}
                selectedSizeKey={selectedSizeKey?.sizeKey}
              />
            ) : (
              <ReferenceBrandSelector
                referenceBrandCode={group.sizing?.referenceBrandCode}
                getReferenceBrandsWithUsage={sizingStore.getReferenceBrandsWithUsage}
                getReferenceBrandByCode={sizingStore.getReferenceBrandByCode}
                onApplyReferenceBrand={onApplyReferenceBrand}
                isManuallyEdited={hasManuallyEditedArticles || hasManuallyEditedDistribution}
              />
            )}
          </Col>
        </Row>
        <Row gutter={64} wrap={false} className="sizing">
          <Col span={10} xl={9} xxl={8}>
            <SizingPicker
              sizing={sizing}
              selectedSizeChart={selectedSizeChart}
              selectedSizeKey={selectedSizeKey}
              loading={group.loadingSizing}
              numberOfEditedArticles={numberOfEditedArticles}
              onSizingChange={onSizingChange}
              onSizingSelector={() => setShowSizingSelector(true)}
              isSizeChartNotEditable={isSizeChartNotEditable}
              enableCompatibility={true}
              readOnly={readOnly}
            />
          </Col>
          <div className="flex-col distribution-column">
            <DistributionTable
              groupKey={group.groupKey}
              sizeKey={selectedSizeKey}
              loading={group.loadingSizing}
              readOnly={readOnly}
              showTableHeader={true}
              numberOfEditedArticles={numberOfEditedArticles}
              onChange={distributionUpdateHandler}
              openCompare={openCompare}
              onSizingSelector={() => setShowSizingSelector(true)}
              selectionState={uiStore.uIState}
              setSelectionState={uiStore.setSelectionState}
              clearSelectionState={uiStore.clearSelectionState}
              groupHeaderHover={groupHeaderHover}
              hasReferenceBrand={!!group.sizing?.referenceBrandCode}
              hasReusedDecision={!!group.reusedDecision}
              group={group}
            />
          </div>
        </Row>
      </div>
      <DistributionCompareModal
        getReferenceBrandsWithUsage={sizingStore.getReferenceBrandsWithUsage}
        getReferenceBrandByCode={sizingStore.getReferenceBrandByCode}
        onApplyReferenceBrand={onApplyReferenceBrand}
        group={group}
        isVisible={showCompareModal}
        setVisible={setShowCompareModal}
        getSizingDistribution={sizingStore.getSizingDistribution}
        onChange={distributionUpdateHandler}
      />
    </>
  );
});

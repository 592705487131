import { clearSelection } from '../components/Matrices/matrices-utils';
import { useEffect, useCallback } from 'react';
import { useStore } from '../components/StoreContext/StoreContext';

export function useClickOutside(): void {
  const { uiStore } = useStore();

  const handleClickOutside = useCallback(
    (e: TouchEvent | MouseEvent) => {
      const selectionState = uiStore?.getSelectionState();
      if ((e.target as HTMLElement)?.closest('.ant-tabs-tab-btn')?.id === 'rc-tabs-0-tab-order') {
        uiStore.unselectArticles();
      }
      if (!selectionState?.tableRef?.current?.contains(e.target as Node)) {
        uiStore?.clearSelectionState();
        clearSelection();
      }
    },
    [uiStore]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
}

import { runInAction } from 'mobx';
import presizeBackend from '../../services/presize-backend';
import { SizingStore } from './sizing-store';
import { ApplySizingDecisionResponse } from '../../models/response';
import { SizeChart, SizeKey } from '../../models/sizing';
import { SizingGroup } from '../../models/sizing-group';

export class ApplySizingDecisionAction {
  async applySizingDecision(this: SizingStore, group: SizingGroup, decisionId?: number): Promise<boolean> {
    const appliedSizingDecision = await presizeBackend.applySizingDecision(group.id, decisionId);
    const decision = group?.sizing?.sizingDecisions?.find((d) => d.id === decisionId);

    let selectedSizeChart: SizeChart | undefined;
    let selectedSizeKey: SizeKey | undefined;
    if (group.sizing) {
      if (decision?.sizing) {
        // If new reused decision selected, apply that
        selectedSizeChart = group.sizing.sizeChartList?.find(
          (sizeChart) => sizeChart.sizeChartId === decision?.sizing.sizeChartId
        );
        selectedSizeKey = selectedSizeChart?.sizeKeys.find(
          (sizeKey) => sizeKey.sizeKeyId === decision?.sizing.sizeKeyId
        );
        selectedSizeKey = { ...selectedSizeKey } as SizeKey;

        if (selectedSizeKey) {
          const sizeDist = (appliedSizingDecision as ApplySizingDecisionResponse).applySizingDecision;
          selectedSizeKey.distribution = sizeDist.distribution;
          selectedSizeKey.distributionType = sizeDist.distributionType;
          selectedSizeKey.recommendationLevel = sizeDist.recoLevel;
          selectedSizeKey.recommendationStrategy = sizeDist.recoStrategy;
        }
      } else {
        // If reused decision removed, revert to recommended
        selectedSizeChart =
          group.sizing?.sizeChartList?.find(
            (sizeChart) => sizeChart.sizeChartId === group.sizing?.recommendedSizing?.sizeChartId
          ) || group.selectedSizeChart;
        selectedSizeKey =
          selectedSizeChart?.sizeKeys.find(
            (sizeKey) => sizeKey.sizeKeyId === group.sizing?.recommendedSizing?.sizeKeyId
          ) || group.selectedSizeKey;
        selectedSizeKey = { ...selectedSizeKey } as SizeKey;
        if (selectedSizeKey && selectedSizeChart) {
          const sizeDist = await presizeBackend.getDistribution(
            this.brandCode,
            this.seasonCode,
            group.commodityGroup.code,
            selectedSizeChart,
            selectedSizeKey
          );
          selectedSizeKey.distribution = sizeDist.distribution;
          selectedSizeKey.distributionType = sizeDist.distributionType;
          selectedSizeKey.recommendationLevel = sizeDist.recoLevel;
          selectedSizeKey.recommendationStrategy = sizeDist.recoStrategy;
        }
      }
    }
    if (!appliedSizingDecision.applySizingDecision.ok) {
      return false;
    }
    await runInAction(async () => {
      if (group.sizing) {
        if (decision?.sizing) {
          // If new reused decision selected, apply that
          if (selectedSizeChart && selectedSizeKey) {
            group.selectedSizeChart = selectedSizeChart;
            group.selectedSizeKey = selectedSizeKey;
          }
          group.selectedSizeKey = { ...group.selectedSizeKey } as SizeKey;
          group.reusedDecision = {
            selectedDecision: decision,
            selectedDecisionId: decision.id,
            isAutoReused: false,
          };
          group.sizing.selectedSizing = decision?.sizing;
        } else {
          // If reused decision removed, revert to recommended
          if (selectedSizeChart && selectedSizeKey) {
            group.selectedSizeChart = selectedSizeChart;
            group.selectedSizeKey = selectedSizeKey;
          }
          group.reusedDecision = null;
          group.sizing.selectedSizing = group.sizing.recommendedSizing;
        }
      }
      if (group?.sizing?.referenceBrandCode) {
        // Reset reference brand in either case
        group.sizing.referenceBrandCode = null;
        this.processReferenceBrandsWithUsage();
      }
      if (group.selectedSizeKey && group.selectedSizeChart) {
        await this.calculateQuantities(false, group);
      }
    });
    return true;
  }
}

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Space, Tabs, Tag, Typography, Button } from '@retail-core/rds';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Boxing, BoxingPresetSizingLotGroup, BoxRestrictionType, LOTGroupBox } from '../../../models/box-restriction';
import { Distribution } from '../../../models/sizing';
import { Article } from '../../../models/sizing-data';
import { SizingGroup } from '../../../models/sizing-group';
import { useStore } from '../../StoreContext/StoreContext';
import BoxDetails from '../BoxDetails/BoxDetails';
import BoxRestrictionsArticleSizing from '../BoxRestrictionsArticleSizing/BoxRestrictionsArticleSizing';
import LotsDetails from '../LotsDetails/LotsDetails';
import './BoxRestrictionCalculationModal.less';

export const BoxRestrictionCalculationModal: FC = observer(function BoxRestrictionCalculationModal() {
  const { sizing: sizingStore, uiStore } = useStore();

  const { boxCalculationArticleId } = uiStore;
  const { sizingGroups, boxRestrictions, enableBoxAvailability } = sizingStore;
  const [boxArticles, setBoxArticles] = useState<Array<Article>>([]);
  const [tab, setTab] = useState('quantities');
  useEffect(() => {
    setBoxArticles(
      sizingGroups.flatMap((group: SizingGroup) => group.articles.filter((article: Article) => article.boxing))
    );
  }, [sizingGroups, boxCalculationArticleId]);
  const handleCancel = useCallback(() => uiStore.setBoxCalculationArticle(), [uiStore]);
  const handleSubmit = useCallback(() => uiStore.setBoxCalculationArticle(), [uiStore]);
  const articleIdx = boxArticles.findIndex((article) => article.referenceId === boxCalculationArticleId);
  const shouldSyncTables = boxCalculationArticleId && boxArticles.length && articleIdx > -1;

  const findAndSelectPrevious = useCallback(() => {
    const prevIndex = (articleIdx || boxArticles.length) - 1;
    uiStore.setBoxCalculationArticle(boxArticles[prevIndex].referenceId);
  }, [articleIdx, boxArticles, uiStore]);

  const findAndSelectNext = useCallback(() => {
    const nextIndex = articleIdx + 1 >= boxArticles.length ? 0 : articleIdx + 1;
    uiStore.setBoxCalculationArticle(boxArticles[nextIndex].referenceId);
  }, [articleIdx, boxArticles, uiStore]);
  const article = boxArticles[articleIdx];
  const group = sizingGroups.find((sizingGroup) =>
    sizingGroup.articles.find((article) => article.referenceId === boxCalculationArticleId)
  );
  const deviation = article?.boxing?.deviation || 0;
  const is2D = !!group?.selectedSizeKey?.lengthList?.length;
  const boxRestriction = useMemo(
    () => boxRestrictions.find((boxRestriction) => boxRestriction.id === article?.boxing?.boxRestrictionId),
    [article?.boxing?.boxRestrictionId, boxRestrictions]
  );
  const onSaveEdit = (boxing: Boxing, sizing: Distribution) => {
    sizingStore.updateBoxQuantities(article?.referenceId, boxing, sizing);
  };

  const onSaveAvailabilityEdit = async (boxing: BoxingPresetSizingLotGroup) => {
    await sizingStore.updateBoxQuantities(article?.referenceId, boxing);
    if (group) {
      await sizingStore.optimizeBoxQuantities(group, article);
    }
  };

  const onReset = useCallback(() => {
    sizingStore.resetBoxQuantities(article);
  }, [article, sizingStore]);
  if (articleIdx < 0) return null;
  return (
    <Modal
      title="Box restriction calculation"
      className="box-calculation-modal"
      wrapClassName="presize-root"
      centered
      visible={!!boxCalculationArticleId}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleSubmit}>
          OK
        </Button>,
      ]}
      width={1000}
      destroyOnClose={true}
      focusTriggerAfterClose={false}
    >
      <Space direction="vertical" className="box-calculation-modal-content">
        <Row align="middle">
          <Col>
            <Space>
              <Button
                className="box-calculation-modal-nav-button"
                type="text"
                icon={<LeftOutlined data-testid="box-calculation-modal-nav-button-left" />}
                onClick={findAndSelectPrevious}
              />
              <Typography.Text>
                {articleIdx + 1} / {boxArticles.length}
              </Typography.Text>
              <Button
                className="box-calculation-modal-nav-button"
                type="text"
                icon={<RightOutlined data-testid="box-calculation-modal-nav-button-right" />}
                onClick={findAndSelectNext}
              />
            </Space>
          </Col>
          <Col flex="auto" />
        </Row>
        <Row>
          <Typography.Title level={5} className="box-calculation-modal-title">
            {article.supplierArticleName}
          </Typography.Title>
          <span className="box-calculation-modal-title-separator" />
          <Typography.Title level={5} className="box-calculation-modal-title">
            {article.supplierColorDescription}
          </Typography.Title>
        </Row>
        <Row gutter={64}>
          <Col>
            <Row>
              <Typography.Text className="box-calculation-modal-article-description-label">
                Achieved quantity
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Title level={2} className="box-calculation-modal-article-description-quantities">
                {article.quantity}
              </Typography.Title>
            </Row>
          </Col>
          <Col>
            <Row>
              <Typography.Text className="box-calculation-modal-article-description-label">
                Ideal quantity
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Title level={2} className="box-calculation-modal-article-description-quantities">
                {article.quantity - (article.boxing?.deviation || 0)}
              </Typography.Title>
            </Row>
          </Col>
          <Col>
            <Row>
              <Typography.Text className="box-calculation-modal-article-description-label">
                Quantity deviation
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Title level={2} className="box-calculation-modal-article-description-quantities">
                {deviation > 0 ? `+${deviation}` : deviation}
              </Typography.Title>
              {article.boxing?.editedBoxQuantities && (
                <span className="tags">
                  <Tag color="purple">Manually set</Tag>
                </span>
              )}
            </Row>
          </Col>
          <Col flex="auto" />
        </Row>
      </Space>
      <Row className="box-calculation-modal-article-sizing">
        <Col span={24}>
          {group &&
            (is2D && article.boxing?.boxRestrictionType === BoxRestrictionType.LOT_GROUP ? (
              <>
                <Tabs
                  destroyInactiveTabPane
                  tabBarExtraContent={{ left: <div style={{ width: '221px' }} /> }}
                  data-testid="box-calculation-modal-article-sizing-tabs"
                  onTabClick={(key) => setTab(key)}
                >
                  <Tabs.TabPane tab="Quantities" key="quantities" />
                  <Tabs.TabPane tab="Distribution" key="distribution" />
                </Tabs>
                {tab === 'quantities' ? (
                  <BoxRestrictionsArticleSizing
                    type="quantities"
                    group={group}
                    article={article}
                    shouldSyncTables={!!shouldSyncTables}
                  />
                ) : (
                  <BoxRestrictionsArticleSizing
                    type="distribution"
                    group={group}
                    article={article}
                    shouldSyncTables={!!shouldSyncTables}
                  />
                )}
              </>
            ) : (
              <BoxRestrictionsArticleSizing
                type="distribution"
                group={group}
                article={article}
                shouldSyncTables={!!shouldSyncTables}
              />
            ))}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {group?.selectedSizeKey?.distribution ? (
            article.boxing?.boxRestrictionType === BoxRestrictionType.LOT_GROUP ? (
              <LotsDetails
                boxing={article.boxing as BoxingPresetSizingLotGroup}
                quantity={article.quantity}
                isAvailabilityEditingEnabled={enableBoxAvailability}
                manuallyEdited={!!article.boxing.editedBoxQuantities}
                selectedSizeKey={group?.selectedSizeKey}
                boxRestriction={boxRestriction as LOTGroupBox}
                onSaveEdit={onSaveEdit}
                onReset={onReset}
                onSaveAvailabilityEdit={onSaveAvailabilityEdit}
              />
            ) : (
              <BoxDetails group={group} article={article} onReset={onReset} onSaveEdit={onSaveEdit} />
            )
          ) : null}
        </Col>
      </Row>
    </Modal>
  );
});

import React, { FC } from 'react';
import { Row, Col, Space, Tooltip, Typography } from '@retail-core/rds';
import { BoxRestrictionsToggle } from '../BoxRestrictions/BoxRestrictionsToggle/BoxRestrictionsToggle';
import { GroupLevelSelect } from '../Header/GroupLevelSelect';
import './SizingGroupListHeader.less';
import { useStore } from '../StoreContext/StoreContext';
import { DistributionType } from '../../models/sizing';
import ReferenceBrandSelector from '../SizingGroup/GroupHeader/ReferenceBrandSelector/ReferenceBrandSelector';
import { observer } from 'mobx-react-lite';
import { InfoCircleOutlined } from '@ant-design/icons';
import { ArticleCommentToggle } from '../SizingGroup/ArticleCommentToggle/ArticleCommentToggle';

const { Text, Title } = Typography;

const SizingGroupListHeader: FC = observer(() => {
  const { sizing: sizingStore } = useStore();
  const onApplyReferenceBrand = async (referenceBrandCode?: string | null) => {
    return await sizingStore.bulkApplyReferenceBrand(referenceBrandCode);
  };
  const hasManuallyEditedArticles = sizingStore.articles.some((article) => article.manuallyEdited);
  const hasManuallyEditedDistribution = sizingStore.sizingGroups.some(
    (group) => group.selectedSizeKey?.distributionType === DistributionType.MANUAL
  );
  const referenceBrandCodes = new Set(sizingStore.sizingGroups.map((group) => group?.sizing?.referenceBrandCode));
  return (
    <div className={`sizing-group-header`}>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          <Title level={5}>Sizing preferences for this order</Title>
        </Col>
        <Col span={24}>
          <GroupLevelSelect />
        </Col>
        <Col span={24}>
          <BoxRestrictionsToggle />
        </Col>
        <Col span={24}>
          <Space align="baseline">
            <Text>New brand? Apply reference brand to all groups</Text>
            <ReferenceBrandSelector
              boxRestrictionsEnabled={sizingStore.enableBox}
              bordered
              isMixed={referenceBrandCodes.size > 1}
              referenceBrandCode={referenceBrandCodes.size > 1 ? 'mixed' : referenceBrandCodes.values().next().value}
              getReferenceBrandsWithUsage={sizingStore.getReferenceBrandsWithUsage}
              getReferenceBrandByCode={sizingStore.getReferenceBrandByCode}
              onApplyReferenceBrand={onApplyReferenceBrand}
              isManuallyEdited={hasManuallyEditedArticles || hasManuallyEditedDistribution}
              buttonClassName="order-reference-brand-button"
              className="order-reference-brand-selector"
              dropdownClassName="order-reference-brand-dropdown"
            />
            <Tooltip
              placement="top"
              title="Get more accurate sizing for your articles, by using a similar brand's recommendations"
            >
              <InfoCircleOutlined />
            </Tooltip>
          </Space>
        </Col>
        <Col span={24}>
          <ArticleCommentToggle />
        </Col>
      </Row>
    </div>
  );
});

export default SizingGroupListHeader;

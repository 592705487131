import { SizingGroup } from '../../models/sizing-group';
import presizeBackend from '../../services/presize-backend';
import { SizingStore } from './sizing-store';
import { SizeDistribution } from '../../models/response';

export class GetSizingDistributionAction {
  async getSizingDistribution(
    this: SizingStore,
    group: SizingGroup,
    referenceBrandCode?: string | null,
    silo?: string | null
  ): Promise<SizeDistribution> {
    const {
      commodityGroup: { code },
      selectedSizeChart,
      selectedSizeKey,
      sizeStructure,
    } = group;
    if (!selectedSizeKey) {
      throw new Error('No selected size key could be found');
    }
    if (!selectedSizeChart) {
      throw new Error('No selected size chart could be found');
    }
    return await presizeBackend.getDistribution(
      referenceBrandCode || this.brandCode,
      this.seasonCode,
      code,
      selectedSizeChart,
      selectedSizeKey,
      referenceBrandCode ? sizeStructure : undefined,
      silo
    );
  }
}

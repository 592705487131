import presizeBackend from '../../services/presize-backend';
import { SizingStore } from './sizing-store';
import { runInAction } from 'mobx';
import { SizingGroup } from '../../models/sizing-group';
import { getPreselectedSizeChartId } from '../../lib/article-utils';
import { ApplyReviewGroupInput } from '../../models/request';

export class MarkAllGroupsReviewedAction {
  private async setAllGroupsReviewedValue(this: SizingStore, value: boolean): Promise<void> {
    const groupsReviewedNotEqualValue = this.sizingGroups.filter((group) => group.isReviewed !== value);
    if (groupsReviewedNotEqualValue.length) {
      const newReviews = groupsReviewedNotEqualValue.map((sg) => ({
        groupKey: sg.groupKey,
        reviewed: value,
      }));
      const groupSizingData: ApplyReviewGroupInput[] = [];
      groupsReviewedNotEqualValue.forEach((sizingGroup: SizingGroup) => {
        if (sizingGroup.selectedSizeKey && sizingGroup.selectedSizeChart) {
          const {
            commodityGroup,
            groupKey,
            selectedSizeKey,
            selectedSizeChart,
            sizingNote,
            sizeStructure,
            sizing,
          } = sizingGroup;
          groupSizingData.push({
            sizingGroup: {
              groupKey,
              commodityGroupCode: commodityGroup.code,
              preselectedSizeChartId: getPreselectedSizeChartId(sizingGroup),
            },
            sizeChartCode: selectedSizeChart.sizeChartCode,
            lengthChartCode: selectedSizeChart.lengthChartCode,
            sizeKeyId: selectedSizeKey.sizeKeyId,
            sizeKey: selectedSizeKey.sizeKey,
            sizeList: selectedSizeKey.sizeList,
            lengthList: selectedSizeKey.lengthList,
            sizeLengthSeparator: selectedSizeKey.sizeLengthSeparator,
            manualSizeKey: selectedSizeKey.manualSizeKey,
            manualSizeList: selectedSizeKey.manualSizeKey ? selectedSizeKey.sizeList : [],
            manualLengthList: selectedSizeKey.manualSizeKey ? selectedSizeKey.lengthList : [],
            manualSizeLengthSeparator: selectedSizeKey.manualSizeKey ? selectedSizeKey.sizeLengthSeparator : undefined,
            distribution: selectedSizeKey.distribution,
            distributionType: selectedSizeKey.distributionType,
            recoStrategy: selectedSizeKey?.recommendationStrategy,
            recoLevel: selectedSizeKey?.recommendationLevel,
            resetManualDistribution: false,
            referenceBrandCode: sizing?.referenceBrandCode,
            sizingNote,
            sizeStructure,
          });
        }
      });

      await presizeBackend.applyReview(this.referenceId, newReviews, groupSizingData);

      runInAction(() => groupsReviewedNotEqualValue.forEach((group) => (group.isReviewed = value)));
    }
  }

  async markAllGroupsAsReviewed(this: SizingStore): Promise<void> {
    await this.setAllGroupsReviewedValue(true);
  }

  async markAllGroupsAsNotReviewed(this: SizingStore): Promise<void> {
    await this.setAllGroupsReviewedValue(false);
  }
}

import { initialSelectionState, UIStore } from './ui-store';
import { SelectionState } from '../../models/selection-state';

export class MatrixSelectionAction {
  setSelectionState(
    this: UIStore,
    groupKey: string | undefined,
    referenceId: string | undefined,
    state: SelectionState
  ): void {
    if (
      this.matrixSelectionMarker.distributionId &&
      this.matrixSelectionMarker.quantityId &&
      (groupKey !== this.matrixSelectionMarker.distributionId || referenceId !== this.matrixSelectionMarker.quantityId)
    ) {
      this.clearSelectionState();
    }
    if (groupKey && referenceId && this.uIState[groupKey]) {
      if (groupKey === referenceId) {
        this.uIState[groupKey].distributionSelectionState = {
          ...this.uIState[groupKey].distributionSelectionState,
          ...state,
        };
      } else if (groupKey !== referenceId && this.uIState[groupKey].articles[referenceId]) {
        this.uIState[groupKey].articles[referenceId].quantitySelectionState = {
          ...this.uIState[groupKey].articles[referenceId].quantitySelectionState,
          ...state,
        };
      }
      this.matrixSelectionMarker.quantityId = referenceId;
      this.matrixSelectionMarker.distributionId = groupKey;
    }
  }

  getSelectionState(this: UIStore): SelectionState {
    if (this.matrixSelectionMarker.distributionId) {
      if (
        this.matrixSelectionMarker.quantityId &&
        this.matrixSelectionMarker.quantityId !== this.matrixSelectionMarker.distributionId
      ) {
        return this.uIState[this.matrixSelectionMarker.distributionId].articles[this.matrixSelectionMarker.quantityId]
          .quantitySelectionState;
      } else {
        return this.uIState[this.matrixSelectionMarker.distributionId].distributionSelectionState;
      }
    }
    return initialSelectionState;
  }

  clearSelectionState(this: UIStore): void {
    if (this.matrixSelectionMarker.distributionId) {
      if (
        this.matrixSelectionMarker.quantityId &&
        this.matrixSelectionMarker.quantityId !== this.matrixSelectionMarker.distributionId
      ) {
        this.uIState[this.matrixSelectionMarker.distributionId].articles[
          this.matrixSelectionMarker.quantityId
        ].quantitySelectionState = initialSelectionState;
        this.matrixSelectionMarker.quantityId = null;
      } else {
        this.uIState[this.matrixSelectionMarker.distributionId].distributionSelectionState = initialSelectionState;
      }
      this.matrixSelectionMarker.distributionId = null;
    }
  }
}

import { useEffect, MutableRefObject, useCallback, useRef } from 'react';

type ScrollableTarget = Pick<GlobalEventHandlers, 'addEventListener' | 'removeEventListener'> & {
  scrollTop?: number;
  scrollY?: number;
};

function getScrollParent(element: HTMLElement): ScrollableTarget {
  let parent: HTMLElement | null = element;
  do {
    const style = window.getComputedStyle(parent);
    if (/auto|scroll|hidden/.test(style.overflow + style.overflowY + style.overflowX)) {
      break;
    }
    if (style.position === 'fixed') {
      return window;
    }
    parent = parent.parentElement;
  } while (parent);

  return !parent || parent === document.body ? window : parent;
}

export function useSticky(ref: MutableRefObject<HTMLElement | null>, enabled: boolean): void {
  const listening = useRef(false);
  const elementTop = useRef(0);

  const onScroll = useCallback(
    function (this: ScrollableTarget) {
      if (((this.scrollTop || this.scrollY) as number) > elementTop.current + 50) {
        ref.current?.classList.add('is-sticky');
      } else {
        ref.current?.classList.remove('is-sticky');
      }
    },
    [elementTop.current] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (!ref.current || listening.current) {
      return;
    }
    // Don't rebind scroll listener but clear sticky class
    if (!enabled) {
      ref.current?.classList.remove('is-sticky');
      return;
    }

    elementTop.current = ref.current?.offsetTop || 0;
    const scrollableParent = getScrollParent(ref.current);
    scrollableParent.addEventListener('scroll', onScroll);
    listening.current = true;

    // Used to re-bind the event listener every time when the onScroll callback changes
    return () => {
      scrollableParent.removeEventListener('scroll', onScroll);
      listening.current = false;
    };
  }, [ref, enabled, onScroll, listening]);
}

import { runInAction } from 'mobx';
import { sendNoLinkedSizeChartEvent, sendPresizeLoadedEvent, unify2DSeparator } from '../../lib/fragment-helper';
import { SizingStore } from './sizing-store';
import { SizingGroup } from '../../models/sizing-group';
import { Sizing, SizingResult } from '../../models/sizing';
import { addManuallyEditedFlag } from '../../lib/article-utils';
import { ErrorType } from '../../models/response';
import presizeBackend from '../../services/presize-backend';
import { UserAbortedError } from '../../lib/api';
import { sendEvent } from '../../services/event-tracking';

export class LoadSizingAction {
  private toSizing(group: SizingGroup, sizingResult: SizingResult): Sizing {
    return {
      sizingGroup: group.sizingGroupKey(),
      enableBox: group.enableBox,
      selectedSizing: sizingResult.selectedSizing,
      recommendedSizing: sizingResult.recommendedSizing,
      sizeChartList: sizingResult.sizeChartList,
      referenceBrandCode: group.referenceBrandCode,
      sizingDecisions: sizingResult.sizingDecisions,
      decision: undefined, // TODO  Decision | null;
      boxRestriction: group.boxRestriction,
    };
  }

  private updateGroupSizing(group: SizingGroup, sizingResult: SizingResult) {
    group.sizing = this.toSizing(group, sizingResult);
    group.selectedSizeChart = sizingResult.sizeChartList.find(
      (sc) => sc.sizeChartId === sizingResult.selectedSizing.sizeChartId
    );
    group.selectedSizeKey = group.selectedSizeChart?.sizeKeys.find(
      (sizeKey) => sizeKey.sizeKeyId === sizingResult.selectedSizing.sizeKeyId
    );
  }

  // TODO remove argument forSizingGroups
  async loadSizing(
    this: SizingStore,
    forSizingGroups?: SizingGroup[],
    signal?: AbortSignal,
    isNewOrderCreated?: boolean
  ): Promise<void> {
    const sizingGroupsToLoad = forSizingGroups ? forSizingGroups : this.sizingGroups;
    if (sizingGroupsToLoad.length <= 0) {
      return;
    }

    for (const group of sizingGroupsToLoad) {
      runInAction(() => (group.loadingSizing = true));
      try {
        const sizingResult = await presizeBackend.getSizing(group.id, signal);
        this.updateGroupSizing(group, sizingResult);
        unify2DSeparator(group);
        this.updateBoxRestrictions(group);
        await this.calculateQuantities(true, group); // TODO remove initialCalculation
        if (isNewOrderCreated) {
          sendEvent(group, 'addArticle', undefined, this.additionalData());
        }
      } catch (error) {
        if (error instanceof UserAbortedError) return;
        if (error === ErrorType.NO_LINKED_SIZE_CHART) {
          runInAction(() => (this.noLinkedSizeCharts = true));
          sendNoLinkedSizeChartEvent();
        } else {
          throw error;
        }
      } finally {
        runInAction(() => (group.loadingSizing = false));
      }
    }

    this.processReferenceBrandsWithUsage();

    this.sizingGroups.forEach((sizingGroup) => {
      if (!sizingGroup.boxRestriction?.boxRestrictionId) {
        sizingGroup.articles.forEach((article) => {
          addManuallyEditedFlag(article, sizingGroup?.selectedSizeKey?.distribution);
        });
      }
    });
    if (this.sizingGroups.some((group) => group.enableBox)) {
      await this.getBoxRestrictions();
    }
    runInAction(() => (this.presizeLoaded = true));
    sendPresizeLoadedEvent(this.articles);
  }
}

import React, { FC, useMemo } from 'react';
import { StoreProvider } from './components/StoreContext/StoreContext';
import { SizingData } from './models/sizing-data';
import { Main } from './pages/Main';
import './App.less';
import { RootStore } from './stores/root-store';
import { SizingStore } from './stores/sizing/sizing-store';
import { isSizingDataValid } from './lib/validate';

interface Props {
  sizingData: SizingData;
}

export const App: FC<Props> = (props) => {
  const { sizingData } = props;

  const rootStore = useMemo(() => {
    if (!isSizingDataValid(sizingData)) return;
    return new RootStore(new SizingStore(sizingData));
  }, [sizingData]);

  if (!rootStore) return null;

  return (
    <div className="App">
      <StoreProvider value={rootStore}>
        <Main />
      </StoreProvider>
    </div>
  );
};

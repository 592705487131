import React, { FC } from 'react';
import { Select } from '@retail-core/rds';
import { SizeChartPicker } from './SizeChartPicker/SizeChartPicker';
import { SizeKeyPicker } from './SizeKeyPicker/SizeKeyPicker';
import { Sizing, SizingKey } from '../../models/sizing';
import { SizeChart, SizeKey } from '../../models/sizing';
import './SizingPicker.less';

interface IProps {
  sizing?: Sizing;
  selectedSizeChart?: SizeChart;
  selectedSizeKey?: SizeKey;
  numberOfEditedArticles?: number;
  isSizeChartNotEditable: boolean;
  enableCompatibility?: boolean;
  loading?: boolean;
  readOnly?: boolean;
  onSizingChange: (sizingKey: SizingKey, keepSizing?: boolean, sizeChartManuallyChanged?: boolean) => void;
  onSizingSelector: () => void;
}

const Content: FC<IProps> = (props) => {
  const {
    sizing,
    loading,
    readOnly,
    selectedSizeChart,
    selectedSizeKey,
    numberOfEditedArticles,
    isSizeChartNotEditable,
    enableCompatibility,
    onSizingChange,
    onSizingSelector,
  } = props;
  if (loading) {
    return (
      <>
        <div className="size-chart">
          <Select size="small" loading={true} disabled={true} />
        </div>
        <div className="size-key">
          <Select size="small" loading={true} disabled={true} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="size-chart">
        {sizing ? (
          <SizeChartPicker
            readOnly={readOnly}
            sizing={sizing}
            selectedSizeKey={selectedSizeKey}
            numberOfEditedArticles={numberOfEditedArticles}
            isSizeChartNotEditable={isSizeChartNotEditable}
            enableCompatibility={enableCompatibility}
            onSizingChange={onSizingChange}
          />
        ) : (
          <Select size="small" disabled={true} value={'No size charts found!'} />
        )}
      </div>
      <div className="size-key">
        {selectedSizeChart ? (
          <SizeKeyPicker
            readOnly={readOnly}
            selectedSizeChart={selectedSizeChart}
            selectedSizeKey={selectedSizeKey}
            numberOfEditedArticles={numberOfEditedArticles}
            onSizingChange={onSizingChange}
            onSizingSelector={onSizingSelector}
          />
        ) : (
          <Select size="small" disabled={true} value={'No size keys found!'} />
        )}
      </div>
    </>
  );
};

export const SizingPicker: FC<IProps> = (props) => {
  return (
    <div className="sizing-picker">
      <Content {...props} />
    </div>
  );
};

import { SizingGroupKey } from './sizing-group';
import { SelectedBoxRestriction } from './box-restriction';
import { ErrorResponse } from './response';

export type SortKey = string[];

export type Distribution = {
  [size: string]: number;
};

export enum DistributionType {
  MANUAL = 'MANUAL',
  FALLBACK = 'FALLBACK',
  RECOMMENDED = 'RECOMMENDED',
  UNKNOWN = 'UNKNOWN',
  SILO = 'SILO',
  NONE = '',
}

export enum CompatibleState {
  FULLY = 'fully',
  PARTIALLY = 'partially ',
  NOT = 'not',
}

export type SizingKey = {
  sizeKeyId?: string;
  sizeChartId: string;
};

export type SizeKey = {
  manualSizeKey: boolean;
  manualDistribution?: boolean;
  sizeKey: SortKey;
  sizeKeyId: string;
  abbreviation?: string;
  sizeList: SortKey;
  lengthList?: SortKey;
  recommendationStrategy?: string;
  recommendationLevel?: string;
  sizeLengthSeparator?: string;
  distribution?: Distribution;
  distributionType?: DistributionType;
  configCount?: number;
};

export type SizingResult = {
  groupId: string;
  selectedSizing: SizingKey;
  recommendedSizing: SizingKey;
  sizeChartList: SizeChart[];
  sizingDecisions: SizingDecision[];
};

export type SizingsResponse = {
  sizings: SizingResult[];
};

export type SizingsResponseUnion = {
  sizings: SizingsResponse | ErrorResponse;
};

export type SizeChart = {
  sizeChartId: string;
  sizeChartCode: string;
  sizeList: SortKey;
  lengthChartCode?: string;
  lengthList?: SortKey;
  sizeLengthSeparator?: string;
  description: string;
  sizeKeys: SizeKey[];
};

export type SizingDecision = {
  id: number;
  sizing: SizingKey;
  distribution: Distribution;
  edited: boolean;
  sizeStructure: string;
  sizingNote: string;
};

export type ReusedDecision = {
  selectedDecisionId: number;
  isAutoReused: boolean;
  selectedDecision?: SizingDecision;
};

export type Sizing = {
  sizingGroup: SizingGroupKey;
  enableBox: boolean;
  selectedSizing: SizingKey;
  recommendedSizing: SizingKey;
  sizeChartList: SizeChart[];
  referenceBrandCode?: string | null;
  sizingDecisions?: SizingDecision[];
  boxRestriction?: SelectedBoxRestriction;
  decision?: Decision | null;
};

export type SizingV2 = {
  id: string;
  sizeChartCode: string;
  lengthChartCode: string;
  sizeKeyId: string;
  sizeList: [string];
  lengthList: [string];
  sizeLengthSeparator: string;
  sizeDistribution: Distribution;
  sizeDistributionType: DistributionType;
};

export type Decision = {
  splitDs?: string;
  levelCode?: number;
  preselectBest?: boolean;
  statsDs?: string;
  statsType?: string;
};

import { UIStore } from './ui-store';

export class HideBoxCatalogAction {
  public hideBoxCatalog(this: UIStore): void {
    this.boxCatalogView = undefined;
    this.boxCatalogCg3 = undefined;
    this.boxCatalogSizeChartId = undefined;
    this.boxCatalogSizeKey = undefined;
    this.boxCatalogSizingGroupKey = undefined;
  }
}

export enum ENV {
  PRODUCTION = 'PRODUCTION',
  STAGING = 'STAGING',
  TEST = 'TEST',
  DEV = 'DEV',
}

export type Hosts = {
  API_HOST: string;
  BOX_API_HOST: string;
  PROXY_TRACKING_URL: string;
  BUYING_HOME_HOST: string;
};

export const isProduction = (): boolean => {
  const hostname = window.location.hostname;
  return hostname.endsWith('.retail.zalando.com');
};

export const isShowCase = (): boolean => {
  const hostname = window.location.hostname;
  return hostname.endsWith('.retail-staging.zalando.com') && !hostname.includes('test');
};

export const isLocalDevelopment = (): boolean => {
  const hostname = window.location.hostname;
  return hostname.includes('localhost');
};

const _isTest = typeof process !== undefined && process.env.NODE_ENV === 'test';
export const isTest = (): boolean => {
  return _isTest;
};

export function getEnv(): ENV {
  if (isProduction()) {
    return ENV.PRODUCTION;
  }
  if (isShowCase()) {
    return ENV.STAGING;
  }
  return ENV.TEST;
}

export function getHosts(env?: string): Hosts {
  if (!env) {
    env = getEnv();
  }
  switch (env) {
    case 'PRODUCTION':
      return {
        API_HOST: 'https://presize-backend.in-season-management.zalan.do',
        BOX_API_HOST: 'https://box-restrictions-api.in-season-management.zalan.do',
        PROXY_TRACKING_URL: 'https://proxy-tracking-api.in-season-management.zalan.do',
        BUYING_HOME_HOST: 'https://buying.retail.zalando.com',
      };
    case 'STAGING':
      return {
        API_HOST: 'https://presize-backend.in-season-management-test.zalan.do',
        BOX_API_HOST: 'https://box-restrictions-api.in-season-management-test.zalan.do',
        PROXY_TRACKING_URL: 'https://proxy-tracking-api.in-season-management-test.zalan.do',
        BUYING_HOME_HOST: 'https://buying.retail-staging.zalando.com',
      };
    default:
      return {
        API_HOST: 'https://presize-backend-test.in-season-management-test.zalan.do',
        BOX_API_HOST: 'https://box-restrictions-api.in-season-management-test.zalan.do',
        PROXY_TRACKING_URL: 'https://proxy-tracking-api.in-season-management-test.zalan.do',
        BUYING_HOME_HOST: 'https://buying-test.retail-staging.zalando.com',
      };
  }
}

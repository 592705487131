import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { SizeKey } from '../../../models/sizing';
import { Matrix } from '../../Matrices/Matrix/Matrix';
import { MatrixSkeleton } from '../../Matrices/MatrixSkeleton/MatrixSkeleton';
import { SelectionState } from '../../../models/selection-state';
import { GroupUIState } from '../../../models/ui-state';
import { SizingGroup } from '../../../models/sizing-group';
import { SizeDistribution } from '../../../models/response';

interface IProps {
  sizeKey?: SizeKey;
  loading?: boolean;
  readOnly?: boolean;
  showTableHeader?: boolean;
  numberOfEditedArticles?: number;
  onChange: (sizeDistribution: SizeDistribution) => Promise<void> | void;
  openCompare?: () => void;
  onSizingSelector: () => void;
  groupKey?: string;
  selectionState?: Record<string, GroupUIState>;
  setSelectionState?: (groupKey: string | undefined, referenceId: string | undefined, state: SelectionState) => void;
  clearSelectionState?: () => void;
  groupHeaderHover?: boolean;
  hasReferenceBrand?: boolean;
  hasReusedDecision?: boolean;
  group?: SizingGroup;
}

export const DistributionTable: FC<IProps> = observer((props) => {
  const {
    loading,
    sizeKey,
    showTableHeader,
    numberOfEditedArticles,
    readOnly,
    onChange,
    openCompare,
    onSizingSelector,
    groupKey,
    selectionState,
    setSelectionState,
    clearSelectionState,
    groupHeaderHover,
    hasReferenceBrand,
    hasReusedDecision,
  } = props;

  if (loading) {
    return <MatrixSkeleton columns={6} rows={1} />;
  }
  if (!sizeKey || !sizeKey.distribution) {
    return null;
  }
  let distributionSelectionState;
  if (groupKey && selectionState) {
    distributionSelectionState = selectionState[groupKey]?.distributionSelectionState;
  }
  return (
    <>
      <Matrix
        readOnly={readOnly}
        sizeKey={sizeKey}
        data={sizeKey.distribution}
        groupKey={groupKey}
        referenceId={groupKey}
        dataIsPercentage={true}
        showTableHeader={showTableHeader}
        highlighted={true}
        expectedTotal={100}
        numberOfEditedArticles={numberOfEditedArticles}
        onSizeDistributionChange={onChange}
        openCompare={openCompare}
        onSizingSelector={onSizingSelector}
        selectionState={distributionSelectionState}
        setSelectionState={setSelectionState}
        clearSelectionState={clearSelectionState}
        groupHeaderHover={groupHeaderHover}
        hasReferenceBrand={hasReferenceBrand}
        hasReusedDecision={hasReusedDecision}
      />
    </>
  );
});

import { Checkbox, Input, Tooltip, Typography } from '@retail-core/rds';
import React, { useMemo, useState } from 'react';
import { SizeKey } from '../../../models/sizing';

export const getLengthCellRowSpan = (lengthListLength: number, index: number): number =>
  !(index % lengthListLength) ? lengthListLength : 0;

const useLotDetailsColumns = (
  selectedSizeKey: SizeKey,
  updateLocalLotQuantity: (lot: string, value: string) => void,
  updateLocalLotAvailability: (lot: string, value: string) => void,
  isQuantityBeingEdited: boolean,
  isAvailabilityEditingEnabled: boolean,
  isAvailabilityBeingEdited: boolean
): any[] => {
  return useMemo(
    () => [
      {
        title: 'Lot',
        dataIndex: 'name',
        key: 'name',
        width: '100px',
        fixed: 'left',
        align: 'left',
        ellipsis: true,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        render: (value: any, record: Record<string, string>, index: number) => {
          return {
            props: {
              rowSpan: selectedSizeKey.lengthList?.length
                ? getLengthCellRowSpan(selectedSizeKey.lengthList.length, index)
                : 1,
            },
            children: (
              <Checkbox checked={!!record.quantity} data-testid={`lot-check-${value}`}>
                {value}
              </Checkbox>
            ),
          };
        },
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
        key: 'quantity',
        width: '61px',
        fixed: 'left',
        align: 'left',
        render: (value: any, record: Record<string, string>, index: number) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [inputFocused, setInputFocused] = useState(false);
          const isQuantityMoreThanAvailability = isQuantityBeingEdited && record.quantity > record.availability;
          const displayErrorTooltip = isQuantityMoreThanAvailability && inputFocused;

          return {
            props: {
              rowSpan: selectedSizeKey.lengthList?.length
                ? getLengthCellRowSpan(selectedSizeKey.lengthList.length, index)
                : 1,
            },
            children: (
              <Tooltip
                trigger="focus"
                title="Quantity must be equal to or lower than set availability"
                placement="top"
                color="#C64E39"
                visible={displayErrorTooltip}
              >
                <div className={isQuantityMoreThanAvailability ? 'ant-form-item-has-error' : ''}>
                  <Input
                    className="lot-quantity-input"
                    data-testid={`lot-${record.name}-quantity`}
                    size="small"
                    value={value}
                    disabled={isAvailabilityBeingEdited}
                    onChange={(e) => updateLocalLotQuantity(record.name, e.target.value)}
                    onFocus={() => setInputFocused(true)}
                    onBlur={() => setInputFocused(false)}
                  />
                </div>
              </Tooltip>
            ),
          };
        },
      },
      {
        title: 'Avail.',
        dataIndex: 'availability',
        key: 'availability',
        width: '61px',
        fixed: 'left',
        align: 'left',
        render: (value: any, record: Record<string, string>, index: number) => {
          return {
            props: {
              rowSpan: selectedSizeKey.lengthList?.length
                ? getLengthCellRowSpan(selectedSizeKey.lengthList.length, index)
                : 1,
            },
            children: (
              <Input
                className="lot-availability-input"
                data-testid={`lot-${record.name}-availability`}
                size="small"
                value={value ?? ''}
                placeholder="∞"
                disabled={isQuantityBeingEdited || !isAvailabilityEditingEnabled}
                onChange={(e) => updateLocalLotAvailability(record.name, e.target.value)}
              />
            ),
          };
        },
      },
      ...(selectedSizeKey.lengthList?.length
        ? [
            {
              title: '',
              dataIndex: 'length',
              key: 'length',
              width: '61px',
              render: (value: any) => {
                return {
                  props: {
                    rowSpan: 1,
                  },
                  children: <>{value}</>,
                };
              },
            },
          ]
        : []),
      ...selectedSizeKey.sizeList.map((size) => ({
        title: size,
        dataIndex: size,
        key: size,
        width: '61px',
        render: (value: any, record: Record<any, any>) => {
          return {
            props: {
              rowSpan: 1,
              'data-testid': record.length
                ? `lot-${record.name}-quantity-size-${size}-length-${record.length}`
                : `lot-${record.name}-quantity-size-${size}`,
            },
            children: (
              <>{record.length ? record[`${size}${selectedSizeKey.sizeLengthSeparator}${record.length}`] : value}</>
            ),
          };
        },
      })),
      {
        title: '',
        dataIndex: 'gap',
        key: 'gap',
        width: '10px',
        fixed: 'right',
        render: () => {
          return {
            props: {
              rowSpan: 1,
            },
            children: null,
          };
        },
      },
      {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        width: '61px',
        fixed: 'right',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        render: (value: any, record: Record<string, string>, index: number) => {
          return {
            props: {
              rowSpan: selectedSizeKey.lengthList?.length
                ? getLengthCellRowSpan(selectedSizeKey.lengthList.length, index)
                : 1,
            },
            children: <Typography.Text strong>{value}</Typography.Text>,
          };
        },
      },
    ],
    [
      isAvailabilityBeingEdited,
      isQuantityBeingEdited,
      selectedSizeKey.lengthList?.length,
      selectedSizeKey.sizeLengthSeparator,
      selectedSizeKey.sizeList,
      updateLocalLotAvailability,
      updateLocalLotQuantity,
      isAvailabilityEditingEnabled,
    ]
  );
};

export default useLotDetailsColumns;

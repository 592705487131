import { notification } from '@retail-core/rds';
import { makeObservable, computed, action, observable } from 'mobx';
import { captureException } from '../lib/tracing/sentry';

type Constructor<T = any> = new (...args: any[]) => T;

/**
 * Handle action errors with a notification and track the error to sentry
 * @param fnName store and action name Store.action
 * @param error
 */
function handleError(fnName: string, error: any) {
  notification.error({
    message: 'There was an error on this page',
    description: error.message,
    duration: 0,
    placement: 'topRight',
  });

  captureException(error);
}

/**
 * Used to wrap all actions with an error handler to show error notifications in case of errors
 * @param fnName Like Store.actionName
 * @param fn
 */
function errorFallback(fnName: string, fn: (...args: unknown[]) => unknown) {
  return function errorWrapper(this: any, ...args: any[]): any {
    try {
      const result = fn.apply(this, args);
      if (result instanceof Promise) {
        return result.catch((error) => {
          handleError(fnName, error);
          throw error;
        });
      }
      return result;
    } catch (error) {
      handleError(fnName, error);
      throw error;
    }
  };
}

export function actionMixin(...actions: Constructor[]): Constructor {
  const observables: any = {};
  class BaseClass {
    constructor() {
      makeObservable(this, observables);
    }
  }

  actions.forEach((actionClass: Constructor) => {
    Object.entries(Object.getOwnPropertyDescriptors(actionClass.prototype)).forEach(([name, descriptor]) => {
      if (name === 'constructor') {
        return;
      }

      // Register as observable
      if (descriptor.get) {
        observables[name] = computed;
        descriptor.get = errorFallback(name, descriptor.get);
      } else if (typeof descriptor.value === 'function') {
        observables[name] = action;
        descriptor.value = errorFallback(name, descriptor.value);
      } else if (typeof descriptor.set === 'function') {
        console.warn('Properties in a store should not be modified directly. Create an action instead.');
      }

      Object.defineProperty(BaseClass.prototype, name, descriptor);
    });
  });
  return BaseClass;
}

// eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types
export function makePropertiesObservable(instance: any): void {
  makeObservable(
    instance,
    Object.getOwnPropertyNames(instance).reduce(
      (observables, property) => ({
        ...observables,
        [property]: observable,
      }),
      {}
    )
  );
}

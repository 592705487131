import { SizingStore } from './sizing-store';
import { Article } from '../../models/sizing-data';
import { sendEvent } from '../../services/event-tracking';

export class UpdateArticlesAction {
  async updateArticles(
    this: SizingStore,
    selectedGroups: Array<string>,
    selectedArticles: Array<Article>,
    modifier: Partial<Article>
  ): Promise<void> {
    const selectedArticlesByRefId = new Map(selectedArticles.map((article) => [article.referenceId, article]));

    this.articles = this.articles.map((article) => {
      const selectedArticle = selectedArticlesByRefId.get(article.referenceId);
      if (!selectedArticle) return article;
      return { ...article, ...modifier };
    });

    await this.groupArticles();

    for (const article of selectedArticles) {
      const group = this.groupByArticleRefId[article.referenceId];
      const updatedArticle = group.articles.find((a) => a.referenceId === article.referenceId) as Article;

      if ('sizingNote' in modifier) {
        sendEvent(group, 'addSizeNote', [updatedArticle], this.additionalData());
      } else if ('sizeStructure' in modifier) {
        sendEvent(group, 'adjustSizeStructureGrouping', [updatedArticle], this.additionalData());
      } else if ('silo' in modifier) {
        sendEvent(group, 'updateSilo', [updatedArticle], this.additionalData());
      }
    }
  }
  clearMismatches(this: SizingStore): void {
    this.mismatchedArticles = [];
    this.mismatchedGroups = {};
  }
}

import React, { FC } from 'react';
import './CollapseSeparator.less';
import { Row, Col } from '@retail-core/rds';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { SelectionCheckbox } from '../../SelectionCheckbox/SelectionCheckbox';

interface IProps {
  amount: number;
  collapsed?: boolean;
  separatorSelected?: boolean;
  separatorPartiallySelected?: boolean;
  onClick: () => void;
  toggleSeparatorSelected?: () => void;
}

export const CollapseSeparator: FC<IProps> = (props) => {
  const { amount, collapsed, onClick, separatorSelected, toggleSeparatorSelected, separatorPartiallySelected } = props;
  return (
    <Row gutter={64}>
      <Col span={23}>
        <SelectionCheckbox
          partiallySelected={separatorPartiallySelected}
          isSelected={separatorSelected}
          isVisible={collapsed}
          toggleSelected={toggleSeparatorSelected}
          className="separator-selector"
        />
        <span
          className={`collapse-separator ${collapsed ? 'collapsed' : 'expanded'}`}
          title="Click to show all articles"
          data-testid="collapse-separator"
          onClick={onClick}
        >
          <span className={`amount ${collapsed ? 'collapsed' : 'expanded'}`}>
            {collapsed ? (
              <>
                <span className="number">{amount}</span>
                <span className="icon icon-hover">
                  <UpOutlined className="icon-up" />
                  <DownOutlined className="icon-down" />
                </span>
              </>
            ) : (
              <UpOutlined className="icon" />
            )}
          </span>
        </span>
      </Col>
    </Row>
  );
};

import { Distribution, SortKey } from './sizing';

export enum OrderType {
  PREORDER = 'PREORDER',
}
export enum BoxRestrictionType {
  LOT_GROUP = 'LOT_GROUP',
  DIVISOR = 'DIVISOR',
  FIXED_BOX = 'FIXED_BOX_QUANTITY',
}

export enum BoxRestrictionStatus {
  BR_RESET = 'BR_RESET',
  BR_UPDATED = 'BR_UPDATED',
  BR_DELETED = 'BR_DELETED',
}

interface BoxRestrictionGeneric {
  id: string;
  name: string;
  orderType: OrderType;
  updatedAt: string;
}

export type LOT = {
  id: string;
  name: string;
  distribution: Distribution;
};

export type LOTGroup = LOT[];

export type LOTGroupBox = BoxRestrictionGeneric & {
  type: BoxRestrictionType.LOT_GROUP;
  sizeRange: SortKey;
  lotGroup: LOTGroup;
};

export type FixedBox = BoxRestrictionGeneric & {
  type: BoxRestrictionType.FIXED_BOX;
  boxValue: number;
};

export type DivisorBox = BoxRestrictionGeneric & {
  type: BoxRestrictionType.DIVISOR;
  boxValue: number;
};

export type BoxRestriction = LOTGroupBox | FixedBox | DivisorBox;

type CG = {
  code: string;
  name: string;
};

type LOTResponse = {
  box_restriction_id: string;
  name: string;
  distribution: Distribution;
};

export type BoxRestrictionsResponse = {
  id: string;
  name: string;
  brand_code: string;
  supplier_code: string;
  cg1: CG;
  cg2: CG;
  cg3: CG[];
  basic_order_type?: OrderType;
  type: BoxRestrictionType;
  size_chart_key?: string;
  box_value?: number;
  size_range?: SortKey;
  lot_group?: LOTResponse[];
  updated_at: string;
};

export type StockRestrictions = {
  [name: string]: number;
};

export type BoxOptimisationPayloadPerArticle = {
  article_id: string;
  desired_size_level_quantity: Distribution;
  box_restriction_id: string;
  stock_restrictions?: StockRestrictions;
};

export type BoxOptimisationResponse = {
  box_restriction_id: string;
  article_id: string;
  optimum_size_level_quantity: Distribution;
  optimum_total_quantity: number;
  total_deviation: number;
  fallback_level: string;
  box_restriction: BoxRestrictionsResponse;
  optimum_lot_quantities?: LOTQuantity[];
  input_total_quantity_per_config?: number;
  deviation?: number;
  type_of_box_restriction?: string;
  availability_from_supplier?: number;
};

export type LOTQuantity = {
  name: string;
  quantity: number;
  size_level_quantity: Distribution;
};

export type Boxing = BoxingPresetSizingLotGroup | BoxingSizeDivisor | BoxingFixedBoxQuantity;

interface BoxingGeneric {
  boxRestrictionId: string;
  deviation: number;
  editedBoxQuantities?: boolean;
}

export type BoxingPresetSizingLotGroup = BoxingGeneric & {
  boxRestrictionType: BoxRestrictionType.LOT_GROUP;
  boxDistributions: BoxDistribution[];
};

export type BoxingSizeDivisor = BoxingGeneric & {
  boxRestrictionType: BoxRestrictionType.DIVISOR;
  sizeDivisor: number;
};

export type BoxingFixedBoxQuantity = BoxingGeneric & {
  boxRestrictionType: BoxRestrictionType.FIXED_BOX;
  boxDivisor: number;
};

export type BoxDistribution = {
  box: Box;
  quantity: number;
  availability?: number;
};

export type Box = {
  name: string;
  sizeQuantities: Distribution;
};

export type SelectedBoxRestriction = {
  boxRestrictionId?: string;
  lastUpdated?: string;
  boxRestrictionType?: BoxRestrictionType;
  boxRestrictionStatus?: BoxRestrictionStatus;
};

import React, { FC } from 'react';
import { Button, Tooltip, Popconfirm } from '@retail-core/rds';
import { CheckOutlined } from '@ant-design/icons';
import { ConditionalWrapper } from '../../../../components//partials/ConditionalWrapper/ConditionalWrapper';

interface Props {
  total: number;
  totalMatching: boolean;
  tooltipTitle: string;
  onAdjustTotal?: (total: number) => void;
  onSave: () => void;
  loading: boolean;
  disabled: boolean;
}

export const SaveButton: FC<Props> = ({
  total,
  totalMatching,
  tooltipTitle,
  onAdjustTotal,
  onSave,
  loading,
  disabled,
}) => {
  const showWrapper = !disabled && !totalMatching && typeof onAdjustTotal === 'function';

  const handleOnConfirm = () => {
    onAdjustTotal && onAdjustTotal(total);
  };

  const handleOnClick = () => totalMatching && onSave();

  return (
    <ConditionalWrapper
      condition={showWrapper}
      wrapper={(children) => (
        <Popconfirm
          title={`Adjust total quantity to: ${total}`}
          placement="bottom"
          onConfirm={handleOnConfirm}
          okText="Adjust"
          cancelText="No"
          data-testid="save-button-confirm"
        >
          {children}
        </Popconfirm>
      )}
    >
      <Tooltip title={tooltipTitle}>
        <Button
          type="primary"
          icon={<CheckOutlined />}
          onClick={handleOnClick}
          loading={loading}
          disabled={disabled}
          data-testid="save-button"
        />
      </Tooltip>
    </ConditionalWrapper>
  );
};

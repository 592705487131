import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { Popconfirm, Button, Select, Space } from '@retail-core/rds';
import { Brand } from '../../../../models/brand';
import './ReferenceBrandSelector.less';

interface IProps {
  isMixed?: boolean;
  onApplyReferenceBrand: (referenceBrandCode?: string | null) => Promise<boolean>;
  referenceBrandCode?: string | null;
  isManuallyEdited?: boolean;
  getReferenceBrandsWithUsage: () => Record<string, Array<Brand>>;
  getReferenceBrandByCode: (code: string) => Brand | undefined;
  boxRestrictionsEnabled?: boolean;
  buttonClassName?: string;
  className?: string;
  dropdownClassName?: string;
  bordered?: boolean;
}

const ACTIVE_PLACEHOLDER = 'Type or select a brand';
const PASSIVE_PLACEHOLDER = 'Use reference brand';

const ReferenceBrandSelector: FC<IProps> = observer((props) => {
  const [loading, setLoading] = useState(false);
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const [options, setOptions] = useState<{ label: string; options: { label: string; value: string; id: string }[] }[]>(
    []
  );
  const [placeholder, setPlaceholder] = useState(PASSIVE_PLACEHOLDER);
  const [selectedBrand, setSelectedBrand] = useState(props.referenceBrandCode);
  const clearSelection = async () => {
    setLoading(true);
    await onSelect();
    setLoading(false);
  };
  const loadOptions = () => {
    setLoading(true);
    const { usedBrands, similarBrands } = props.getReferenceBrandsWithUsage();
    const newOptions = [];
    if (usedBrands.length) {
      newOptions.push({
        label: 'Used in this order',
        options: usedBrands.map((b) => ({
          label: b.name,
          value: b.code,
          id: b.code,
          filterablestr: b.filterablestr,
        })),
      });
    }
    if (similarBrands.length) {
      newOptions.push({
        label: 'Brands with shared CG1+CG2',
        options: similarBrands.map((b) => ({
          label: b.name,
          value: b.code,
          id: b.code,
          filterablestr: b.filterablestr,
        })),
      });
    }
    setOptions(newOptions);
    return setLoading(false);
  };
  const onSelect = async (value?: string | null) => {
    if ((props.isManuallyEdited || props.isMixed) && !popConfirmVisible) {
      setSelectedBrand(value);
      return setPopConfirmVisible(true);
    }
    if (!loading) setLoading(true);
    const applied = await props.onApplyReferenceBrand(value);
    if (applied) {
      setSelectedBrand(value);
      if (!value) {
        setPlaceholder(PASSIVE_PLACEHOLDER);
      }
    } else {
      setSelectedBrand(props.referenceBrandCode);
    }
    setLoading(false);
  };
  const confirm = () => {
    onSelect(selectedBrand);
    setPopConfirmVisible(false);
  };

  const cancel = () => {
    setSelectedBrand(props.referenceBrandCode);
    setPopConfirmVisible(false);
  };
  useEffect(() => {
    if (props.referenceBrandCode !== selectedBrand) {
      setSelectedBrand(props.referenceBrandCode);
    }
    if (props.referenceBrandCode) {
      const tmpOption = props.getReferenceBrandByCode(props.referenceBrandCode);
      if (tmpOption) {
        setOptions([
          {
            label: 'Used in this order',
            options: [{ label: tmpOption.name, value: tmpOption.code, id: tmpOption.code }],
          },
        ]);
      }
    }
  }, [props.referenceBrandCode]); // eslint-disable-line react-hooks/exhaustive-deps
  if (props.referenceBrandCode) {
    const selectedBrandObject = props.getReferenceBrandByCode(props.referenceBrandCode);
    if (selectedBrandObject) {
      return (
        <Button
          disabled={loading}
          size="small"
          onClick={clearSelection}
          className={props.buttonClassName || 'reference-brand-button'}
          data-testid="clear-reference-brand-button"
        >
          <Space size={4}>
            {selectedBrandObject?.name}
            {loading ? (
              <LoadingOutlined spin className={'reference-brand-button-icon'} />
            ) : (
              <CloseOutlined className={'reference-brand-button-icon'} />
            )}
          </Space>
        </Button>
      );
    }
  }
  return (
    <Popconfirm
      visible={popConfirmVisible}
      title={
        props.isMixed
          ? 'Reset reference brands on all groups?'
          : props.isMixed !== undefined
          ? 'Reset manual edits on all groups to use this reference brand?'
          : 'Reset manual edits to use this reference brand?'
      }
      placement="bottom"
      onConfirm={confirm}
      onCancel={cancel}
      okText="Reset"
      cancelText="Cancel"
      data-testid={`reference-brand-selector-confirm`}
      arrowPointAtCenter
    >
      {props.isMixed ? (
        <Button
          disabled={loading}
          size="small"
          onClick={clearSelection}
          className={props.buttonClassName || 'reference-brand-button'}
        >
          <Space size={4}>
            Mixed
            {loading ? (
              <LoadingOutlined spin className={'reference-brand-button-icon'} />
            ) : (
              <CloseOutlined className={'reference-brand-button-icon'} />
            )}
          </Space>
        </Button>
      ) : (
        <>
          <label
            className="group-reference-brand-label"
            htmlFor="group-reference-brand-selector"
            data-testid="reference-brand-selector-label"
          >
            Reference brand:
          </label>
          <Select
            disabled={props.boxRestrictionsEnabled}
            size="small"
            showSearch
            dropdownMatchSelectWidth={false}
            bordered={!!props.bordered}
            placeholder={placeholder}
            onBlur={() => setPlaceholder(PASSIVE_PLACEHOLDER)}
            onFocus={() => setPlaceholder(ACTIVE_PLACEHOLDER)}
            className={props.className || 'reference-brand-selector'}
            id="group-reference-brand-selector"
            dropdownClassName={props.dropdownClassName || 'reference-brand-selector-dropdown'}
            onSelect={onSelect}
            value={selectedBrand || []}
            loading={loading}
            options={options}
            onMouseDown={loadOptions}
            data-testid="reference-brand-selector"
            filterOption={(input, option: any) => {
              if (option.options) return false;
              const inputStr = input.toLowerCase().replace(/\s/g, '');
              return option.filterablestr?.includes(inputStr);
            }}
          />
        </>
      )}
    </Popconfirm>
  );
});

export default ReferenceBrandSelector;

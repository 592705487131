import React, { FC, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Table, Button } from '@retail-core/rds';
import {
  getSelectableDataset,
  getSelectableMatrixColumns,
  getSelectedSizeKey,
  applyToAllSelectableDataset,
} from '../matrices-utils';
import { SizeKey, SizeChart } from '../../../models/sizing';
import { SelectableCell } from './SelectableCell/SelectableCell';
import './SelectableMatrix.less';
import { handleMultipleSelection } from './selectable-matrix-utils';
import { Alert } from '@retail-core/rds';
import { MatrixDataset } from '../../../models/sizing-table';

interface IProps {
  sizeChart: SizeChart;
  sizeKey?: SizeKey;
  onChange: (sizeKeyId: string) => void;
}

export const SelectableMatrix: FC<IProps> = observer((props) => {
  const { sizeChart, sizeKey, onChange } = props;
  const is2D = !!sizeChart.lengthList?.length;
  const [dataSet, setDataSet] = useState<MatrixDataset>([]);
  const [multipleSelectionStart, setMultipleSelectionStart] = useState<string[]>([]);

  useEffect(() => {
    setDataSet(getSelectableDataset(sizeChart, sizeKey?.distribution));
  }, [sizeChart, sizeKey?.distribution]);

  const onCellChange = (row: string, column: string, value: boolean, multipleSelection: boolean): void => {
    const editedRow = dataSet.find((currentRow) => currentRow.key === row);
    if (editedRow) {
      setMultipleSelectionStart([column, editedRow.key]);
      if (multipleSelection) {
        handleMultipleSelection(editedRow, column, value, multipleSelectionStart, dataSet);
      } else {
        editedRow[column] = !value;
      }
    }
    const newSizeKey = getSelectedSizeKey(dataSet, sizeChart).join(';');
    onChange(newSizeKey);
  };

  const onToggleSelection = (value: boolean): void => {
    const newDataSet = applyToAllSelectableDataset(dataSet, value);
    setDataSet([...newDataSet]);
    const newSizeKey = getSelectedSizeKey(newDataSet, sizeChart).join(';');
    onChange(newSizeKey);
  };

  if (dataSet.length === 0) {
    return null;
  }

  const columns = getSelectableMatrixColumns(sizeChart, onCellChange);

  return (
    <div className="table">
      <div className="top">
        <div className="info">
          <Alert
            message="Multiple selection: Select first size, hold down SHIFT key, select last size"
            type="info"
            showIcon
          />
        </div>
        <div className="selector-buttons">
          <Button key="deselect-all" type="default" size="small" onClick={() => onToggleSelection(false)}>
            Deselect all sizes
          </Button>
          <Button key="select-all" type="default" size="small" onClick={() => onToggleSelection(true)}>
            Select all sizes
          </Button>
        </div>
      </div>
      <Table
        className={`selectable-matrix-${is2D ? 2 : 1}d`}
        components={{
          body: {
            cell: SelectableCell,
          },
        }}
        columns={columns}
        dataSource={dataSet}
        pagination={false}
        bordered={true}
        size="small"
        scroll={{ x: 300, y: 400 }}
      />
    </div>
  );
});

import { fetchGraphApi, graphApi } from '../lib/api';
import {
  Distribution,
  DistributionType,
  SizeChart,
  SizeKey,
  SizingResult,
  SizingsResponse,
  SizingsResponseUnion,
} from '../models/sizing';
import {
  ApplySizingBulkResponseUnion,
  ApplySizingDecisionUnionResponse,
  ApplySizingResponse,
  ErrorResponse,
  Order,
  OrderResponse,
  RecommendationResponse,
  ReferenceBrandsQueryResponse,
  SilosUnionResponse,
  SizeDistribution,
  UpdateOrderResponse,
} from '../models/response';
import {
  CgGroupingLevel,
  OrderData,
  SizingGroup,
  SizingGroupBoxRestrictions,
  SizingGroupReview,
  SizingGroupSelectedBoxRestriction,
} from '../models/sizing-group';
import { ApplyReviewGroupInput, ApplySizingGroupInput, ArticleInput } from '../models/request';
import { getPreselectedSizeChartId } from '../lib/article-utils';

export class PresizeBackend {
  async getSizing(groupId: string, signal?: AbortSignal): Promise<SizingResult> {
    return this.getSizings([groupId], signal).then((r) => r[0]);
  }
  async getSizings(groupIds?: string[], signal?: AbortSignal): Promise<SizingResult[]> {
    if (!groupIds) return [];
    const query = `query($groupIds: [Int!]!) {
      sizings(groupIds: $groupIds) {
        ...on SizingsResponse {
          sizings {
            groupId,
            selectedSizing {
              sizeKeyId,
              sizeChartId
            },
            recommendedSizing {
              sizeKeyId,
              sizeChartId
            },
            sizeChartList {
              sizeChartId
              sizeChartCode
              sizeList
              lengthChartCode
              lengthList
              sizeLengthSeparator
              description
              sizeKeys {
                sizeKey
                sizeKeyId
                sizeList
                lengthList
                sizeLengthSeparator
                distribution
                distributionType
                recommendationStrategy
                recommendationLevel
                manualSizeKey
                manualDistribution
                configCount
              }
            },
            sizingDecisions {
              id,
              sizing {
                sizeKeyId,
                sizeChartId
              },
              distribution,
              edited,
              sizeStructure,
              sizingNote
            }
          }
        }
        ... on ErrorResponse {
          errorType
        }
      }
    }`;
    return fetchGraphApi<SizingsResponseUnion>(query, { groupIds: groupIds }, 'getSizings', signal)
      .then((response) => response.sizings)
      .then((response) => {
        if ((response as ErrorResponse).errorType) {
          return new Promise<SizingResult[]>((resolve, reject) => reject((response as ErrorResponse).errorType));
        } else {
          return (response as SizingsResponse).sizings;
        }
      });
  }

  async getDistribution(
    brandCode: string,
    seasonCode: string,
    commodityGroupCode: string,
    sizeChart: SizeChart,
    sizeKey: SizeKey,
    sizeStructure?: string,
    silo?: string | null
  ): Promise<SizeDistribution> {
    const query = `query($data: RecommendationInput!) {
      recommendation(data: $data) {
        distribution
        distributionType
        recoLevel
        recoStrategy
      }
    }`;
    const variables = {
      data: {
        brandCode,
        seasonCode,
        commodityGroupCode,
        sizeChartId: sizeChart.sizeChartId,
        sizeKey: sizeKey.sizeKey,
        sizeStructure,
        silo,
      },
    };
    return graphApi<RecommendationResponse>(query, variables, 'getDistribution').then((data) => data.recommendation);
  }

  applySizing(
    referenceId: string,
    brandCode: string,
    seasonCode: string,
    sizingGroup: SizingGroup,
    sizeChart: SizeChart,
    sizeKey: SizeKey,
    resetDistribution?: boolean,
    referenceBrandCode?: string | null,
    sizeStructure?: string,
    sizingNote?: string
  ): Promise<SizeDistribution> {
    const mutation = `mutation($data: ApplySizingInput!) {
      applySizing(data: $data) {
        distribution,
        distributionType,
        recoLevel,
        recoStrategy
      }
    }`;
    const variables = {
      data: {
        referenceId,
        brandCode,
        seasonCode,
        sizingGroup: {
          groupKey: sizingGroup.groupKey,
          commodityGroupCode: sizingGroup.commodityGroup.code,
          preselectedSizeChartId: getPreselectedSizeChartId(sizingGroup),
        },
        sizeChartCode: sizeChart.sizeChartCode,
        lengthChartCode: sizeChart.lengthChartCode,
        sizeKeyId: sizeKey.sizeKeyId,
        sizeKey: sizeKey.sizeKey,
        manualSizeKey: sizeKey.manualSizeKey,
        manualSizeList: sizeKey.manualSizeKey ? sizeKey.sizeList : [],
        manualLengthList: sizeKey.manualSizeKey ? sizeKey.lengthList : undefined,
        manualSizeLengthSeparator: sizeKey.manualSizeKey ? sizeKey.sizeLengthSeparator : undefined,
        distribution:
          sizeKey.distributionType === DistributionType.MANUAL || resetDistribution ? sizeKey.distribution : undefined,
        resetManualDistribution: resetDistribution,
        referenceBrandCode,
        sizeStructure,
        sizingNote,
        sizeList: sizeKey.sizeList,
        lengthList: sizeKey.lengthList,
      },
    };
    return graphApi<ApplySizingResponse>(mutation, variables, 'applySizing').then(
      (applySizingResponse) => applySizingResponse.applySizing
    );
  }

  updateOrder(
    referenceId: string,
    brandCode: string,
    seasonCode: string,
    cgGroupingLevel: string,
    enableBox: boolean,
    articles?: ArticleInput[],
    signal?: AbortSignal
  ): Promise<Order> {
    const mutation = `mutation($data: UpdateOrderInput!) {
      updateOrder(data: $data) {
        ... on Order {
          id
          referenceId
          seasonCode
          brandCode
          cgGroupingLevel
          enableBox
          status
          buyerEmail
          isNewOrderCreated
          groups {
            id
            key
            commodityGroupCode
            isReviewed
            referenceBrandCode
            enableBox
            selectedBoxRestriction {
              boxRestrictionId
              boxRestrictionType
              lastUpdated
              boxRestrictionStatus
            }
            sizeStructure
            sizingNote
            sizeChartId
            silo
            articles {
              id
              referenceId
              commodityGroupCode
              buyingArticleConfigId
              lengthChartCode
              quantity
              sizeChartCode
              sizeChartEditable
              sizeStructure
              sizingNote
              silo
              editedQuantities
            }
            selectedSizing {
              id
              sizeChartCode
              lengthChartCode
              sizeKeyId
              sizeList
              lengthList
              sizeLengthSeparator
              sizeDistribution
              sizeDistributionType
            },
            reusedDecision {
              selectedDecisionId,
              isAutoReused
            }
          }
        }
        ... on UpdateOrderErrorResponse {
          ok
          errorType
        }
      }
    }`;
    const variables = { data: { referenceId, brandCode, seasonCode, cgGroupingLevel, enableBox, articles } };
    return fetchGraphApi<UpdateOrderResponse>(mutation, variables, 'updateOrder', signal)
      .then((updateOrderResponse) => {
        return updateOrderResponse as OrderResponse;
      })
      .then((orderResponse) => orderResponse.updateOrder);
  }

  applySizingDecision(groupId: string, decisionId: number | null = null): Promise<ApplySizingDecisionUnionResponse> {
    const mutation = `mutation($groupId: Int!, $decisionId: Int) {
      applySizingDecision(groupId: $groupId, decisionId: $decisionId) {
        ... on ApplySizingResponse { ok, distribution, distributionType, recoLevel, recoStrategy }
        ... on ApplySizingDecisionErrorResponse { ok, errorType }
      }
    }`;
    return graphApi<ApplySizingDecisionUnionResponse>(
      mutation,
      { groupId: groupId, decisionId: decisionId },
      'applySizingDecision'
    );
  }

  applySizingBulk(
    referenceId: string,
    brandCode: string,
    seasonCode: string,
    groups: Array<ApplySizingGroupInput>,
    referenceBrandCode?: string | null
  ): Promise<ApplySizingBulkResponseUnion> {
    const mutation = `mutation($data: ApplySizingBulkInput!) {
      applySizingBulk(data: $data) {
        ... on ApplySizingBulkResponse {
          results {
            groupKey
            distribution
            distributionType
            recoLevel
            recoStrategy
          }
          ok
        }
        ... on ApplySizingBulkErrorResponse {
          ok
          errorType
        }
      }
    }`;
    const variables = {
      data: {
        referenceId,
        brandCode,
        seasonCode,
        referenceBrandCode,
        groups,
      },
    };
    return graphApi<ApplySizingBulkResponseUnion>(mutation, variables, 'applySizingBulk');
  }

  applyReview(
    referenceId: string,
    sizingGroupReviews: SizingGroupReview[],
    sizingForGroup?: ApplyReviewGroupInput[],
    softReview?: boolean
  ): Promise<void> {
    const mutation = `mutation($data: ApplyReviewInput!) {
      applyReview(data: $data) {
        ok
      }
    }`;

    return graphApi<void>(
      mutation,
      {
        data: {
          referenceId,
          sizingForGroup: sizingForGroup,
          sizingGroupReviews: sizingGroupReviews,
          softReview,
        },
      },
      'applyReview'
    );
  }

  applyGroupBoxRestrictions(
    referenceId: string,
    sizingGroupBoxRestrictions: SizingGroupBoxRestrictions[]
  ): Promise<void> {
    const mutation = `mutation($data: ApplyGroupBoxRestrictionsInput!) {
      applyGroupBoxRestrictions(data: $data) {
        ok
      }
    }`;

    return graphApi<void>(
      mutation,
      {
        data: {
          referenceId,
          sizingGroupBoxRestrictions: sizingGroupBoxRestrictions,
        },
      },
      'applyGroupBoxRestrictions'
    );
  }

  selectBoxRestrictions(referenceId: string, sizingGroups: SizingGroupSelectedBoxRestriction[]): Promise<void> {
    const mutation = `mutation($data: SelectBoxRestrictionsInput!) {
      selectBoxRestrictions(data: $data) {
        ok
      }
    }`;

    return graphApi<void>(
      mutation,
      {
        data: {
          referenceId,
          sizingGroups: sizingGroups,
        },
      },
      'selectBoxRestrictions'
    );
  }

  getSilos(brandCode: string, cgCodes: string[]): Promise<SilosUnionResponse> {
    const query = `query($data: SilosInput!) {
      silos(data: $data) {
        ... on SilosResponse {
          silos
        }
        ... on ErrorResponse {
          success
          errorType
        }
      }
    }`;
    return graphApi<{ silos: { silos: string[] } }>(
      query,
      {
        data: {
          brandCode,
          commodityGroupCodes: cgCodes,
        },
      },
      'silos'
    ).then((data) => {
      return data.silos;
    });
  }

  getOrderData(referenceId: string): Promise<OrderData> {
    const query = `query($data: OrderDataInput!) {
      orderData(data: $data) {
        groupingLevel
        enableBox
      }
    }`;
    return graphApi<{ orderData: { groupingLevel: CgGroupingLevel; enableBox: boolean } }>(
      query,
      {
        data: { referenceId },
      },
      'getOrderData'
    ).then((data) => {
      return data.orderData;
    });
  }

  applyOrderData(
    referenceId: string,
    groupingLevel: CgGroupingLevel | undefined,
    enableBox: boolean | undefined
  ): Promise<void> {
    const mutation = `mutation($data: ApplyOrderDataInput!) {
      applyOrderData(data: $data) {
        ok
      }
    }`;

    return graphApi<void>(
      mutation,
      {
        data: {
          referenceId,
          groupingLevel,
          enableBox,
        },
      },
      'applyOrderData'
    );
  }

  getReferenceBrands(seasonCode: string, commodityGroupCode: string): Promise<ReferenceBrandsQueryResponse> {
    const query = `query($data: ReferenceBrandsInput!) {
      referenceBrands(data: $data) {
        ...on ReferenceBrandsResponse {
          success
          referenceBrands {
            code
            name
          }
        }
        ... on ErrorResponse {
          success
          errorType
        }
      }
    }`;
    return graphApi<ReferenceBrandsQueryResponse>(
      query,
      {
        data: { seasonCode, commodityGroupCode },
      },
      'getReferenceBrands'
    );
  }

  setEditedQuantities(
    orderReferenceId: string,
    articleReferenceId: string,
    quantityPerSize: Distribution
  ): Promise<void> {
    const mutation = `mutation($editedQuantitiesInput: EditedQuantitiesInput!) {
      setEditedQuantities(editedQuantitiesInput: $editedQuantitiesInput) { ok }
    }`;
    const variables = {
      editedQuantitiesInput: { orderReferenceId, articleReferenceId, quantityPerSize: quantityPerSize },
    };
    return fetchGraphApi<void>(mutation, variables, 'setEditedQuantities');
  }

  resetEditedQuantities(orderReferenceId: string, articleReferenceId: string): Promise<void> {
    const mutation = `mutation($orderReferenceId: String!, $articleReferenceId: String!) {
      resetEditedQuantities(orderReferenceId: $orderReferenceId, articleReferenceId: $articleReferenceId) { ok }
    }`;
    const variables = { orderReferenceId, articleReferenceId };
    return fetchGraphApi<void>(mutation, variables, 'resetEditedQuantities');
  }
}

export default new PresizeBackend();

import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal } from '@retail-core/rds';

const QuantitiesOverwriteModal = (numberOfArticles: number, onConfirm: () => void): void => {
  Modal.confirm({
    title: 'Overwrite edits on quantities per size?',
    icon: <QuestionCircleOutlined />,
    content: `${numberOfArticles} article(s) in this group have manually adjusted quantities per size. These adjustments will be overwritten with the group's distribution if you proceed.`,
    okText: 'Overwrite',
    cancelText: 'Cancel',
    centered: true,
    mask: true,
    onOk: onConfirm,
  });
};

export { QuantitiesOverwriteModal };

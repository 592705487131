import { TagsOutlined, UndoOutlined } from '@ant-design/icons';
import { Typography, Tooltip, Col, Row, Tag, Button } from '@retail-core/rds';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Distribution, SizeKey } from '../../../../models/sizing';
import { Article } from '../../../../models/sizing-data';
import { Matrix } from '../../../Matrices/Matrix/Matrix';
import { useStore } from '../../../StoreContext/StoreContext';

import './ArticleLine.less';
import { SelectionCheckbox } from '../../SelectionCheckbox/SelectionCheckbox';
import { GroupUIState } from '../../../../models/ui-state';
import { ArticleQuantity } from '../ArticleQuantity/ArticleQuantity';
import { SizeDistribution } from '../../../../models/response';

const { Text } = Typography;

interface IProps {
  article: Article;
  sizeKey?: SizeKey;
  readOnly?: boolean;
  articleSelected?: boolean;
  toggleArticleSelected?: () => void;
  groupKey?: string;
  enableBox?: boolean;
  uIState?: Record<string, GroupUIState>;
}

export const ArticleLine: FC<IProps> = observer((props) => {
  const {
    article,
    article: {
      referenceId: articleid,
      supplierArticleName: articleName,
      supplierArticleNumber: articleNumber,
      supplierColorCode: colorCode,
      supplierColorDescription: colorName,
      comment,
    },
    sizeKey,
    readOnly,
    articleSelected,
    toggleArticleSelected = () => null,
    groupKey,
    enableBox,
    uIState,
  } = props;
  const { sizing, uiStore } = useStore();
  const [quantity, setQuantity] = useState<number>(article.quantity);
  const [isHoverLine, setIsHoverLine] = useState<boolean>(false);
  const onAdjustQuantity = (quantity: number, distribution: Distribution) => {
    setQuantity(quantity);
    return sizing.changeArticleSizing(article, distribution, quantity);
  };
  const onReset = () => {
    sizing.resetArticleQuantities(article);
  };
  const onCancel = () => {
    setQuantity(article.quantity);
  };
  const onSizingChange = (sizeDistribution: SizeDistribution) => {
    return sizing.changeArticleSizing(article, sizeDistribution.distribution, quantity);
  };
  const onOpenBoxRestrictionCalculationModal = useCallback(() => {
    return uiStore.setBoxCalculationArticle(article.referenceId);
  }, [article.referenceId, uiStore]);
  useEffect(() => {
    setQuantity(article.quantity);
  }, [article.quantity]);
  let className = 'article';
  if (articleSelected) className += ' selected-article';
  let quantitySelectionState;
  if (groupKey && uIState) {
    quantitySelectionState = uIState[groupKey]?.articles[article.referenceId]?.quantitySelectionState;
  }
  return (
    <Row
      className={className}
      wrap={false}
      gutter={64}
      onMouseEnter={() => {
        setIsHoverLine(true);
      }}
      onMouseLeave={() => {
        setIsHoverLine(false);
      }}
    >
      <Col span={10} xl={9} xxl={8} className="article-details">
        <SelectionCheckbox
          className="article-selector"
          isSelected={articleSelected}
          toggleSelected={toggleArticleSelected}
          isVisible={true}
          key={`${articleid}-${articleSelected}`}
        />
        <Row gutter={24}>
          <Col
            span={16}
            className="text-ellipsis"
            onClick={toggleArticleSelected}
            key={`${articleid}-${articleSelected}`}
          >
            <Tooltip title={`${articleName} ${colorName} ${articleNumber} ${colorCode}`} placement="topLeft">
              <Text className="main-fields">
                {!article.sizeChartEditable && (
                  <>
                    <TagsOutlined data-testid="previouslyOrdered-icon" /> &nbsp;
                  </>
                )}
                <span>
                  {articleName} &middot; {colorName}
                </span>
              </Text>
              <Text type="secondary" className="secondary-fields">
                <span className="secondary-fields">
                  {articleNumber} &middot; {colorCode}
                </span>
              </Text>
            </Tooltip>
          </Col>
          <Col span={4} className="align-right">
            <ArticleQuantity
              boxingEnabled={!!enableBox && !!article?.boxing}
              articleQuantity={quantity || article.quantity}
              deviation={article.boxing?.deviation || 0}
              onOpenBoxRestrictionCalculationModal={onOpenBoxRestrictionCalculationModal}
            />
          </Col>
          {uiStore.showArticleComments && (
            <Col span={4} className="text-ellipsis">
              <Tooltip title={`${comment}`} placement="topLeft">
                <Text>{comment}</Text>
              </Tooltip>
            </Col>
          )}
        </Row>
      </Col>
      <div className="flex-col">
        {sizeKey?.distribution && article.sizing && (
          <Matrix
            groupKey={groupKey}
            sizeKey={sizeKey}
            referenceId={article.referenceId}
            selectionState={quantitySelectionState}
            setSelectionState={uiStore.setSelectionState}
            clearSelectionState={uiStore.clearSelectionState}
            data={article.sizing}
            dataIsPercentage={false}
            expectedTotal={quantity || article.quantity}
            showTableHeader={false}
            readOnly={readOnly || (!!article?.boxing && !!enableBox)}
            onAdjustTotal={onAdjustQuantity}
            onCancel={onCancel}
            onSizeDistributionChange={onSizingChange}
          >
            <span className="tags">
              {article.editedQuantities && (
                <Tag data-testid={`${article.referenceId}-edited-tag`} color="purple">
                  Edited
                </Tag>
              )}
              {article.editedQuantities && isHoverLine && (
                <Tooltip title="Reset to group's distribution" className="spacing" placement="bottom">
                  <Button
                    data-testid={`${article.referenceId}-reset-edited-qty-button`}
                    className="ant-btn-txt-default"
                    size="small"
                    type="text"
                    icon={<UndoOutlined data-testid="reset-button-quantity" />}
                    onClick={() => onReset()}
                  >
                    Reset
                  </Button>
                </Tooltip>
              )}
            </span>
          </Matrix>
        )}
      </div>
    </Row>
  );
});

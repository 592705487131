import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Anchor } from '@retail-core/rds';
import { TreeData } from '../../models/sizing-group';
import { useStore } from '../StoreContext/StoreContext';
import { getAllowedCgLevels, getTreeData } from './cg-tree-utils';
import { TreeLink } from './TreeLink';

import './CgTree.less';

function createCgTreeNode(parent: TreeData, allowedCgLevels: string[]) {
  return (key: string) => {
    const { name, disabled, cgLevel, children } = parent[key];

    return (
      allowedCgLevels.includes(cgLevel) && (
        <TreeLink key={name} href={`#${key}`} title={name} disabled={disabled}>
          {Object.keys(children).map(createCgTreeNode(children, allowedCgLevels))}
        </TreeLink>
      )
    );
  };
}

interface Props {
  showNonReviewed: boolean;
}

export const CgTree: FC<Props> = observer(({ showNonReviewed }) => {
  const { sizing } = useStore();
  const allowedCgLevels = getAllowedCgLevels(sizing.groupingCgLevel);
  const treeData = getTreeData(sizing.sizingGroups, showNonReviewed);

  return (
    <Anchor className="cg-tree" offsetTop={20}>
      {Object.keys(treeData).map(createCgTreeNode(treeData, allowedCgLevels))}
    </Anchor>
  );
});

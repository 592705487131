import presizeBackend from '../../services/presize-backend';
import { SizingStore } from './sizing-store';
import { runInAction } from 'mobx';
import { SizingGroup } from '../../models/sizing-group';
import { ApplyReviewGroupInput } from '../../models/request';
import { getPreselectedSizeChartId } from '../../lib/article-utils';

export class ToggleGroupReviewAction {
  private async setReviewValue(this: SizingStore, sizingGroup: SizingGroup, value: boolean): Promise<void> {
    const sizingForGroup: ApplyReviewGroupInput[] = [];
    if (value && sizingGroup.selectedSizeKey && sizingGroup.selectedSizeChart) {
      const {
        commodityGroup,
        groupKey,
        selectedSizeKey,
        selectedSizeChart,
        sizingNote,
        sizeStructure,
        sizing,
      } = sizingGroup;
      sizingForGroup.push({
        sizingGroup: {
          groupKey,
          commodityGroupCode: commodityGroup.code,
          preselectedSizeChartId: getPreselectedSizeChartId(sizingGroup),
        },
        sizeChartCode: selectedSizeChart.sizeChartCode,
        lengthChartCode: selectedSizeChart.lengthChartCode,
        sizeKeyId: selectedSizeKey.sizeKeyId,
        sizeKey: selectedSizeKey.sizeKey,
        sizeList: selectedSizeKey.sizeList,
        lengthList: selectedSizeKey.lengthList,
        sizeLengthSeparator: selectedSizeKey.sizeLengthSeparator,
        manualSizeKey: selectedSizeKey.manualSizeKey,
        manualSizeList: selectedSizeKey.manualSizeKey ? selectedSizeKey.sizeList : [],
        manualLengthList: selectedSizeKey.manualSizeKey ? selectedSizeKey.lengthList : [],
        manualSizeLengthSeparator: selectedSizeKey.manualSizeKey ? selectedSizeKey.sizeLengthSeparator : undefined,
        distribution: selectedSizeKey.distribution,
        distributionType: selectedSizeKey.distributionType,
        recoStrategy: selectedSizeKey?.recommendationStrategy,
        recoLevel: selectedSizeKey?.recommendationLevel,
        resetManualDistribution: false,
        referenceBrandCode: sizing?.referenceBrandCode,
        sizingNote,
        sizeStructure,
      });
    }
    await presizeBackend.applyReview(
      this.referenceId,
      [{ groupKey: sizingGroup.groupKey, reviewed: value }],
      sizingForGroup
    );
    runInAction(() => (sizingGroup.isReviewed = value));
  }

  async toggleReview(this: SizingStore, sizingGroup: SizingGroup): Promise<void> {
    await this.setReviewValue(sizingGroup, !sizingGroup.isReviewed);
  }

  async markAsNotReviewed(this: SizingStore, sizingGroup: SizingGroup): Promise<void> {
    await this.setReviewValue(sizingGroup, false);
  }
}

import { Article } from '../../models/sizing-data';
import { SizingStore } from './sizing-store';
import presizeBackend from '../../services/presize-backend';
import { sendEvent } from '../../services/event-tracking';

export class ResetArticleQuantitiesAction {
  async resetArticleQuantities(this: SizingStore, article: Article): Promise<void> {
    const group = this.groupByArticleRefId[article.referenceId];
    await presizeBackend.resetEditedQuantities(this.referenceId, article.referenceId);
    article.editedQuantities = undefined;
    this.calculateQuantities(false, group, [article.referenceId]);
    sendEvent(group, 'changeArticleSizing', [article], this.additionalData());
  }
}

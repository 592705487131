import { Fragment } from 'monteur';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { convertPerLotSizedQuantitiesToTotal } from './lib/article-utils';
import { sizingDataLocal } from './lib/dummy-data';
import { InitEvent } from './models/events/init-event';
import { SizingData } from './models/sizing-data';
import presizeBackend from './services/presize-backend';
import * as serviceWorker from './serviceWorker';
import { initSentry, captureMessage } from './lib/tracing/sentry';

declare global {
  interface Window {
    RetailPortal: any;
  }
}

async function render(sizingData: SizingData, container: HTMLElement) {
  ReactDOM.render(<App sizingData={sizingData} />, container.querySelector('.presize-root'));
}

function unrender(container: HTMLElement) {
  ReactDOM.unmountComponentAtNode(container.querySelector('.presize-root') as HTMLElement);
}

function loadRetailCenterHeader(): Promise<void> {
  return new Promise((resolve) => {
    const script = window.document.createElement('script');
    script.src = 'https://register.retail-staging.zalando.com/content/staging.js';
    script.addEventListener('load', () => {
      window.RetailPortal.eventEmitter.on('application-ready', resolve);
    });

    const rcWrapper = document.createElement('div');
    rcWrapper.setAttribute('data-retail-portal-app', '');
    rcWrapper.setAttribute('data-retail-portal-auth-protected', '');
    rcWrapper.appendChild(script);
    while (document.body.children.length) {
      rcWrapper.appendChild(document.body.children[0]);
    }

    document.body.appendChild(rcWrapper);
  });
}

(async () => {
  initSentry();
  captureMessage('Sentry initialized');
  if (Fragment.isFragment()) {
    await Fragment.initialize(
      {},
      (eventData: InitEvent, container: Element) => {
        // TODO: Remove getOrderCall and replace it with updateOrder
        presizeBackend.getOrderData(eventData.sizingData.referenceId).then((orderData) => {
          render(
            {
              ...eventData.sizingData,
              articles: eventData.sizingData.articles.map((article) => {
                article.sizingNote = null;
                return {
                  ...article,
                  boxing: convertPerLotSizedQuantitiesToTotal(article.boxing),
                  blockOrderBoxing: convertPerLotSizedQuantitiesToTotal(article.blockOrderBoxing),
                };
              }),
              groupingCgLevel: orderData.groupingLevel,
              enableBox: orderData.enableBox,
              enableBoxAvailability: true,
            },
            container as HTMLElement
          );
        });
      },
      (container: Element) => unrender(container as HTMLElement)
    );
  } else {
    await loadRetailCenterHeader();
    await render(sizingDataLocal(), document.body);
  }
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

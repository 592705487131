import { runInAction } from 'mobx';
import { sendSizingAppliedEvent } from '../../lib/fragment-helper';
import {
  BoxRestriction,
  BoxRestrictionStatus,
  BoxRestrictionType,
  SelectedBoxRestriction,
} from '../../models/box-restriction';
import presizeBackend from '../../services/presize-backend';
import { SizingStore } from './sizing-store';
import { isFullyCompatibleKey } from '../../lib/box-restrictions-utils';
import { SizingGroup } from '../../models/sizing-group';
import { getSizingFromArticleQuantity } from '../../lib/article-utils';
import { notification } from '@retail-core/rds';

export class SetGroupBoxRestrictionAction {
  async updateBoxRestrictions(this: SizingStore, group: SizingGroup): Promise<boolean> {
    // TODO this method needs to be refactored
    const sizingGroupBoxRestrictionPayload: { groupKey: string; selectedBoxRestriction: SelectedBoxRestriction }[] = [];
    const sizingGroupReviewPayload: { groupKey: string; reviewed: boolean }[] = [];
    runInAction(() => {
      if (!group.boxRestriction?.boxRestrictionId && group.articles.every((article) => !article.boxing)) return;
      const boxRestriction = this.boxRestrictions.find(
        (box: BoxRestriction) => box.id === group.boxRestriction?.boxRestrictionId
      );
      if (boxRestriction) {
        group.articles.forEach((article) => {
          if (article.boxing?.editedBoxQuantities) {
            article.manuallyEdited = true;
          }

          if (
            article.boxing?.boxRestrictionType === BoxRestrictionType.LOT_GROUP &&
            article.boxing?.boxRestrictionId !== group.boxRestriction?.boxRestrictionId
          ) {
            article.boxing.boxDistributions.forEach((lot) => (lot.availability = undefined));
          }
        });
        if (new Date(boxRestriction?.updatedAt || 0) > new Date(group.boxRestriction?.lastUpdated || 0)) {
          if (!SetGroupBoxRestrictionAction.isBoxRestrictionCompatible(boxRestriction, group)) {
            this.handleBoxRestrictionUpdated(
              group,
              sizingGroupBoxRestrictionPayload,
              sizingGroupReviewPayload,
              BoxRestrictionStatus.BR_RESET
            );
          } else {
            this.handleBoxRestrictionUpdated(
              group,
              sizingGroupBoxRestrictionPayload,
              sizingGroupReviewPayload,
              BoxRestrictionStatus.BR_UPDATED,
              boxRestriction
            );
            notification.info({
              duration: 8,
              description:
                'Some box restrictions were updated, and affected article quantities were re-optimized automatically.',
              message: 'Box Restrictions Updated',
            });
          }
        }
      } else {
        group.boxRestriction = {
          boxRestrictionId: undefined,
          boxRestrictionType: undefined,
          lastUpdated: undefined,
          boxRestrictionStatus: BoxRestrictionStatus.BR_DELETED,
        };
        this.handleBoxRestrictionUpdated(
          group,
          sizingGroupBoxRestrictionPayload,
          sizingGroupReviewPayload,
          BoxRestrictionStatus.BR_DELETED
        );
      }
    });
    if (sizingGroupBoxRestrictionPayload.length) {
      await presizeBackend.selectBoxRestrictions(this.referenceId, sizingGroupBoxRestrictionPayload);
      await presizeBackend.applyReview(this.referenceId, sizingGroupReviewPayload, undefined, true);
      return true;
    }
    return false;
  }

  async updateBoxRestrictionsLegacy(this: SizingStore): Promise<boolean> {
    const sizingGroupBoxRestrictionPayload: { groupKey: string; selectedBoxRestriction: SelectedBoxRestriction }[] = [];
    const sizingGroupReviewPayload: { groupKey: string; reviewed: boolean }[] = [];
    runInAction(() => {
      this.sizingGroups.forEach((sizingGroup) => {
        if (!sizingGroup.boxRestriction?.boxRestrictionId && sizingGroup.articles.every((article) => !article.boxing))
          return;
        const boxRestriction = this.boxRestrictions.find(
          (box: BoxRestriction) => box.id === sizingGroup.boxRestriction?.boxRestrictionId
        );
        if (boxRestriction) {
          sizingGroup.articles.forEach((article) => {
            if (article.boxing?.editedBoxQuantities) {
              article.manuallyEdited = true;
            }

            if (article.boxing?.boxRestrictionType === BoxRestrictionType.LOT_GROUP) {
              article.boxing.boxDistributions = article.boxing.boxDistributions.map((box) => ({
                ...box,
                availability: undefined,
              }));
            }
          });
          if (new Date(boxRestriction?.updatedAt || 0) > new Date(sizingGroup.boxRestriction?.lastUpdated || 0)) {
            if (!SetGroupBoxRestrictionAction.isBoxRestrictionCompatible(boxRestriction, sizingGroup)) {
              this.handleBoxRestrictionUpdated(
                sizingGroup,
                sizingGroupBoxRestrictionPayload,
                sizingGroupReviewPayload,
                BoxRestrictionStatus.BR_RESET
              );
            } else {
              this.handleBoxRestrictionUpdated(
                sizingGroup,
                sizingGroupBoxRestrictionPayload,
                sizingGroupReviewPayload,
                BoxRestrictionStatus.BR_UPDATED,
                boxRestriction
              );
            }
          }
        } else {
          sizingGroup.boxRestriction = {
            boxRestrictionId: undefined,
            boxRestrictionType: undefined,
            lastUpdated: undefined,
            boxRestrictionStatus: BoxRestrictionStatus.BR_DELETED,
          };
          this.handleBoxRestrictionUpdated(
            sizingGroup,
            sizingGroupBoxRestrictionPayload,
            sizingGroupReviewPayload,
            BoxRestrictionStatus.BR_DELETED
          );
        }
      });
    });
    if (sizingGroupBoxRestrictionPayload.length) {
      await presizeBackend.selectBoxRestrictions(this.referenceId, sizingGroupBoxRestrictionPayload);
      await presizeBackend.applyReview(this.referenceId, sizingGroupReviewPayload, undefined, true);
      return true;
    }
    return false;
  }
  private static isBoxRestrictionCompatible(boxRestriction: BoxRestriction, sizingGroup: SizingGroup) {
    return (
      boxRestriction.type !== BoxRestrictionType.LOT_GROUP ||
      (boxRestriction.type === BoxRestrictionType.LOT_GROUP &&
        sizingGroup?.selectedSizeKey &&
        isFullyCompatibleKey(boxRestriction.sizeRange, sizingGroup?.selectedSizeKey?.sizeKey))
    );
  }
  handleBoxRestrictionUpdated(
    this: SizingStore,
    sizingGroup: SizingGroup,
    sizingGroupBoxRestrictionPayload: { groupKey: string; selectedBoxRestriction: SelectedBoxRestriction }[],
    sizingGroupReviewPayload: { groupKey: string; reviewed: boolean }[],
    status: BoxRestrictionStatus,
    boxRestriction?: BoxRestriction
  ): void {
    if (status === BoxRestrictionStatus.BR_DELETED || status === BoxRestrictionStatus.BR_RESET) {
      this.removeSizingGroupBoxDetailsGeneric(sizingGroup, 'sizing', 'boxing', 'quantity');
      this.removeSizingGroupBoxDetailsGeneric(
        sizingGroup,
        'blockOrderSizing',
        'blockOrderBoxing',
        'blockOrderQuantity'
      );
      sendSizingAppliedEvent(sizingGroup, sizingGroup.articles);
    }
    sizingGroup.boxRestriction = {
      boxRestrictionId: boxRestriction?.id,
      boxRestrictionType: boxRestriction?.type,
      lastUpdated: boxRestriction?.updatedAt,
      boxRestrictionStatus: status,
    };
    sizingGroupBoxRestrictionPayload.push({
      groupKey: sizingGroup.groupKey,
      selectedBoxRestriction: sizingGroup.boxRestriction,
    });
    sizingGroup.isReviewed = false;
    sizingGroupReviewPayload.push({
      groupKey: sizingGroup.groupKey,
      reviewed: false,
    });
  }
  async setGroupBoxRestriction(this: SizingStore, sizingGroupKey: string, boxRestrictionId?: string): Promise<void> {
    const group = this.sizingGroups.find((group) => group.groupKey === sizingGroupKey);

    if (!group) {
      throw new Error('Size group could not be found');
    }

    let boxRestriction: BoxRestriction | undefined;

    await runInAction(async () => {
      // to be persisted in BE later
      if (boxRestrictionId) {
        boxRestriction = this.boxRestrictions.find((box: BoxRestriction) => box.id === boxRestrictionId);
        if (boxRestriction) {
          if (group.boxRestriction?.boxRestrictionType === BoxRestrictionType.LOT_GROUP) {
            group.articles.forEach((article) => {
              if (article.boxing?.boxRestrictionType === BoxRestrictionType.LOT_GROUP) {
                article.boxing.boxDistributions = article.boxing.boxDistributions.map((box) => ({
                  ...box,
                  availability: undefined,
                }));
              }
            });
          }

          group.boxRestriction = {
            boxRestrictionId: boxRestriction?.id,
            boxRestrictionType: boxRestriction?.type,
            lastUpdated: boxRestriction?.updatedAt || new Date().toISOString(),
          };
          await this.optimizeBoxQuantities(group);
          await presizeBackend.selectBoxRestrictions(this.referenceId, [
            { groupKey: group.groupKey, selectedBoxRestriction: group.boxRestriction },
          ]);
        }
        sendSizingAppliedEvent(group, group.articles);
      } else {
        // If box restriction has been reset individually, the caller needs to recalculate quantities
        // That will call the sizing applied event in this case
        group.boxRestriction = {
          boxRestrictionId: undefined,
          boxRestrictionType: undefined,
          lastUpdated: undefined,
          boxRestrictionStatus: BoxRestrictionStatus.BR_RESET,
        };
        await presizeBackend.selectBoxRestrictions(this.referenceId, [
          { groupKey: group.groupKey, selectedBoxRestriction: group.boxRestriction },
        ]);
        this.markAsNotReviewed(group);

        this.removeSizingGroupBoxDetailsGeneric(group, 'sizing', 'boxing', 'quantity');
        this.removeSizingGroupBoxDetailsGeneric(group, 'blockOrderSizing', 'blockOrderBoxing', 'blockOrderQuantity');
      }
    });
  }
  removeSizingGroupBoxDetailsGeneric(
    this: SizingStore,
    sizingGroup: SizingGroup,
    sizingFieldName: 'sizing' | 'blockOrderSizing',
    boxingFieldName: 'boxing' | 'blockOrderBoxing',
    quantityFieldName: 'quantity' | 'blockOrderQuantity'
  ): void {
    sizingGroup.articles.forEach((article) => {
      const idealQuantity = (article[quantityFieldName] || 0) - (article[boxingFieldName]?.deviation || 0);
      const idealSizing = getSizingFromArticleQuantity(idealQuantity, sizingGroup?.selectedSizeKey?.distribution);
      article[quantityFieldName] = idealQuantity;
      article[sizingFieldName] = idealSizing;
      article.manuallyEdited = false;
      delete article[boxingFieldName];
    });
  }
}

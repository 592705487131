import React, { FC } from 'react';
import { Switch, Typography } from '@retail-core/rds';
import { observer } from 'mobx-react-lite';
import './ArticleCommentToggle.less';
import { useStore } from '../../StoreContext/StoreContext';

const { Text } = Typography;

export const ArticleCommentToggle: FC = observer(function ArticleCommentToggle() {
  const { uiStore } = useStore();
  const { showArticleComments } = uiStore;

  const toggleShowArticleComments = () => {
    uiStore.toggleShowArticleComments();
  };

  return (
    <>
      <Text className="article-comment-toggle-label">Show Comments</Text>
      <Switch checked={showArticleComments} onChange={toggleShowArticleComments} size="small" />
    </>
  );
});

import { notification } from '@retail-core/rds';
import { getHosts } from './environment';

function getToken(): string {
  const match = document.cookie.match(/RETAIL_ACCESS_TOKEN=([^;$]+)/);
  if (!match) {
    // Logged out, we have to login again
    // TODO redirect to login page
    throw new Error('Login required');
  }
  return match[1];
}

type GraphQlResponse<D> = {
  data: D;
  errors?: [];
};

export enum HTTP_METHOD {
  GET = 'GET',
  POST = 'POST',
}

export function graphApi<D>(query: string, variables?: Record<string, unknown>, operationName?: string): Promise<D> {
  // TODO deprecate this in favour of 'fetchGraphApi()'
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${getHosts().API_HOST}/graphql?operationName=${operationName}`);
    xhr.setRequestHeader('Authorization', `Bearer ${getToken()}`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('X-Reference-Id', getReferenceId(variables));
    xhr.addEventListener('onerror', () => {
      if (xhr.status === 0) {
        notification.error({
          message: 'There was an error on this page (sizing)',
          description: 'Server error: Status 0',
          duration: 0,
          placement: 'topRight',
        });
        reject(new Error('Server error in sizing: ' + xhr.responseText));
      }
    });
    xhr.addEventListener('load', () => {
      // Graphql should always return json. Even on 400 etc it should return problem json
      if (!/json/.test(xhr.getResponseHeader('Content-Type') || '')) {
        reject(new Error('Response body is not json: ' + xhr.responseText));
        return;
      }
      const body: GraphQlResponse<D> = JSON.parse(xhr.responseText);
      switch (xhr.status) {
        case 401:
          return reject(new Error('Logged out')); // TODO redirect to login page
        case 403:
          notification.error({
            message: 'There was an error on this page (sizing)',
            description: 'Permission denied',
            duration: 0,
            placement: 'topRight',
          });
          return reject(new Error('Permission denied'));
        case 400:
          notification.error({
            message: 'There was an error on this page (sizing)',
            description: 'Bad Request',
            duration: 0,
            placement: 'topRight',
          });
          return reject(new Error('Bad request: ' + xhr.responseText));
        case 500:
          notification.error({
            message: 'There was an error on this page (sizing)',
            description: 'Internal Server error',
            duration: 0,
            placement: 'topRight',
          });
          return reject(new Error('Internal Server Error: ' + xhr.responseText));
        case 504:
          notification.error({
            message: 'There was an error on this page (sizing)',
            description: 'Gateway timeout',
            duration: 0,
            placement: 'topRight',
          });
          return reject(new Error('Gateway timeout: ' + xhr.responseText));
        case 200:
          if (body.errors) {
            notification.error({
              message: 'There was an error on this page (sizing)',
              description: body.errors.map((e) => e['message']).join('\n'),
              duration: 0,
              placement: 'topRight',
            });
            return reject(new Error('GraphQL Error: ' + body.errors));
          } else {
            resolve(body.data);
            break;
          }
        default:
          notification.error({
            message: 'There was an error on this page (sizing)',
            description: 'Server error: Empty response',
            duration: 0,
            placement: 'topRight',
          });
          return reject(new Error('Server error: ' + xhr.responseText));
      }
    });

    xhr.send(JSON.stringify({ query, variables }));
  });
}

const httpStatusDesc: Record<number, string> = {
  401: 'Logged out',
  403: 'Permission denied',
  400: 'Bad Request',
  500: 'Internal Server Error',
  504: 'Gateway timeout',
  0: 'Server error: Empty response',
};

export class UserAbortedError implements Error {
  message: string;
  name: string;

  constructor() {
    this.name = 'UserAbortedError';
    this.message = 'The request is cancelled by the user';
  }
}

async function createFetchError(error: Response | DOMException): Promise<Error> {
  if (error instanceof DOMException) return new UserAbortedError();
  const json = error.json !== undefined ? await error.json() : error;
  const status = error.status !== undefined ? error.status : 200;
  notification.error({
    message: 'There was an error on this page (sizing)',
    description: httpStatusDesc[status] || json.errors?.map((e: any) => e['message']).join('\n'),
    duration: 0,
    placement: 'topRight',
  });
  return new Error(`GraphQL Error (${status}): ` + json.errors?.map((e: any) => '\n' + e['message']).join(''));
}

export function fetchGraphApi<D>(
  query: string,
  variables?: Record<string, unknown>,
  operationName?: string,
  signal?: AbortSignal
): Promise<D> {
  const url = `${getHosts().API_HOST}/graphql?operationName=${operationName}`;
  return fetch(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'Content-Type': 'application/json',
      'X-Reference-Id': getReferenceId(variables),
    },
    body: JSON.stringify({ query, variables }),
    signal: signal,
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.errors) throw response;
      return response.data;
    })
    .catch(async (error) => {
      throw await createFetchError(error);
    });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function boxApi<D>(method: HTTP_METHOD, url: string, payload?: any): Promise<D> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    if (method === HTTP_METHOD.GET && payload) {
      Object.keys(payload).forEach((key) => {
        if (!payload[key]) delete payload[key];
      });
      const params = new URLSearchParams(payload as Record<string, string>).toString();
      url += `?${params}`;
    }
    xhr.open(method, `${getHosts().BOX_API_HOST}/${url}`);
    xhr.setRequestHeader('Authorization', `Bearer ${getToken()}`);
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.addEventListener('load', () => {
      // Graphql should always return json. Even on 400 etc it should return problem json
      if (!/json/.test(xhr.getResponseHeader('Content-Type') || '')) {
        reject(new Error('Response body is not json: ' + xhr.responseText));
        return;
      }
      const body = JSON.parse(xhr.responseText);
      switch (xhr.status) {
        case 401:
          return reject(new Error('Logged out')); // TODO redirect to login page
        case 403:
          return reject(new Error('Permission denied'));
        case 400:
          return reject(new Error('Bad request: ' + xhr.responseText));
        default:
          resolve(body);
      }
    });

    xhr.send(JSON.stringify(payload));
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function sendEventToProxyTracking(data: any) {
  const xhr = new XMLHttpRequest();
  xhr.open('POST', `${getHosts().PROXY_TRACKING_URL}/api/events`);
  xhr.setRequestHeader('Authorization', `Bearer ${getToken()}`);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(JSON.stringify({ events: data }));
}

function getReferenceId(variables?: Record<string, unknown>): string {
  return (variables as Record<string, any>)?.data?.referenceId || '';
}

import { SizingStore } from './sizing-store';
import presizeBackend from '../../services/presize-backend';
import { SizingGroup } from '../../models/sizing-group';
import { convertArticleToArticleInput } from '../../lib/article-utils';
import { runInAction } from 'mobx';
import { Order } from '../../models/response';
import { UserAbortedError } from '../../lib/api';
import { sendEvent } from '../../services/event-tracking';

export class GroupArticlesAction {
  async groupArticles(this: SizingStore): Promise<void> {
    if (this.abortController) {
      this.abortController.abort();
    }
    this.abortController = new AbortController();

    const { referenceId, brandCode, seasonCode, groupingCgLevel, enableBox } = this;
    try {
      const updatedOrder = await presizeBackend.updateOrder(
        referenceId,
        brandCode,
        seasonCode,
        groupingCgLevel,
        enableBox,
        this.articles.map(convertArticleToArticleInput),
        this.abortController.signal
      );
      this.updateStoreGroups(updatedOrder);
      await this.loadSizing(undefined, this.abortController.signal, updatedOrder.isNewOrderCreated);
      if (updatedOrder.isNewOrderCreated) {
        sendEvent(undefined, 'orderCreated', undefined, this.additionalData());
      }
    } catch (error) {
      if (!(error instanceof UserAbortedError)) {
        throw error;
      }
    }
  }

  private updateStoreGroups(this: SizingStore, order: Order) {
    const articlesByReferenceId = this.articlesByReferenceId();
    const sizingGroups = order.groups.map((groupResponse) =>
      SizingGroup.fromGroupResponse(groupResponse, order.cgGroupingLevel, articlesByReferenceId)
    );
    runInAction(() => (this.sizingGroups = sizingGroups));
    this.resetGroupByArticleRefId();
    this.resetGroupById();
    runInAction(() => this.sortGroupsByGroupNames());
  }
}

import { DataSetRow, MatrixDataset } from '../../../models/sizing-table';

export function handleMultipleSelection(
  editedRow: DataSetRow,
  column: string,
  value: boolean,
  multipleSelectionStart: string[],
  dataSet: MatrixDataset
): void {
  if (!multipleSelectionStart || multipleSelectionStart.length === 0) {
    editedRow[column] = !value;
  } else {
    const selectionStartColumn = multipleSelectionStart[0];
    const selectionStartRow = multipleSelectionStart[1];
    const initialRow = dataSet.find((currentRow) => currentRow.key === selectionStartRow);
    let newValue;
    if (initialRow) {
      newValue = initialRow[selectionStartColumn];
    } else {
      newValue = editedRow[selectionStartColumn];
    }

    const rowKeys = dataSet.map((currentRow) => currentRow.key);
    const multipleSelectionStartRowIndex = rowKeys.indexOf(selectionStartRow);
    const multipleSelectionEndRowIndex = rowKeys.indexOf(editedRow.key);
    const rowStart = Math.min(multipleSelectionStartRowIndex, multipleSelectionEndRowIndex);
    const rowEnd = Math.max(multipleSelectionStartRowIndex, multipleSelectionEndRowIndex);
    for (let r = rowStart; r <= rowEnd; r++) {
      const currentRow = dataSet.find((currentRow) => currentRow.key === rowKeys[r]);
      if (currentRow) {
        rowMultipleSelection(currentRow, selectionStartColumn, column, newValue);
      }
    }
  }
}

function rowMultipleSelection(
  currentRow: DataSetRow,
  selectionStartColumn: string,
  column: string,
  newValue: string | number | boolean
) {
  const keys = Object.keys(currentRow);
  const multipleSelectionStartIndex = keys.indexOf(selectionStartColumn);
  const multipleSelectionEndIndex = keys.indexOf(column);
  const start = Math.min(multipleSelectionStartIndex, multipleSelectionEndIndex);
  const end = Math.max(multipleSelectionStartIndex, multipleSelectionEndIndex);
  for (let i = start; i <= end; i++) {
    currentRow[keys[i]] = newValue;
  }
}
